/* body {
    background-color: lightblue;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    font-family: Poppins;
  } */
/*   
  .container {
    position: relative;
    max-width: 1000px;
    background-color: #fff;
    padding-left: 0;
    padding-right: 0;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background: white;
  } */
  
  /* .container-with-parent {
    position: relative;
    background-color: #fff;
    padding-left: 0;
    padding-right: 0;
    background: white;
    border-radius: 20px !important;
    box-shadow: 4px 4px 25px -7px rgba(0, 0, 0, 0.25) !important;
  } */
  
  .cv-about {
    text-align: justify;
    font-size: 12px;
  }
  
  .img-fa {
    width: 20px;
    height: 22px;
    margin-right: 2px;
  }
  
  .img-fa.linkdin {
    width: 12px;
    height: 12px;
  }
  
  .cv-section__wrapper {
    page-break-before: always;
    page-break-inside: avoid;
    transition: all .15s ease;
  }
  
  .cv-section {
    border-bottom: 1px solid #eee;
    padding-bottom: 50px;
  }

  .resume-form .row {
    display: flex;
    gap: 10px;
  }

  .align-items-center {
    align-items: center;
  }
  
  .cv-section__title {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 20px;
    font-weight: bold;
    margin: 5px 0px;
    color: #3474d1;
  }

  i.fa {
    margin: 5px;
  }

  .cv-section li {
    font-family: 'Source Sans Pro', sans-serif;

    font-size: 16px;
  }
  
  .cv-section__title .fa,
  .cv-section__title .fas {
    margin-right: 2px;
  }
  
  .cv-section__experience {
    margin-bottom: 25px;
  }
  
  .cv-section__period,
  .cv-section__description {
    font-size: 14px;
    margin-bottom: 0;
    font-family: 'Source Sans Pro', sans-serif;
    color: #403e3e;
    font-weight: bold;
  }
  
  .cv-section__role,
  .cv-section__experience_title {
    font-weight: bold;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 18px;
    margin-bottom: 0;
  }
  
  .cv-section__experience_title {
    font-size: 16px;
  }
  
  .user-name {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: bold;
    font-size: 40px;
    color: #3474d1;
  }
  
  .title {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 22px;
  }
  
  .cv-sidebar-section {
    margin-bottom: 20px;
  }
  
  .cv-sidebar-section .list-inline,
  .cv-sidebar-section .lang-list {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    color: #7a7878;
    font-weight: 600;
  }
  
  .contact2 {
    font-family: 'Source Sans Pro', sans-serif;
  }
  
  .contact2.email {
    color: #3474d1;
  }
  
  .university {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: lighter;
    color: #928f8f;
    font-size: 14px;
  }
  
  .link {
    font-size: 12px;
  }
  
  .pub-url {
    font-size: 14px;
    word-break: break-all;
  }
  
  @media only screen and (min-width: 768px) {
    .cv-section {
      border-bottom: none;
      padding-bottom: 0;
    }
  
    .cv-section__title .fa,
    .cv-section__title .fas {
      margin-right: 9px;
    }
  
    .cv-section__wrapper {
      padding-right: 18px;
    }
  
    .cv-section__wrapper ul.course-list {
      font-family: 'Source-Sans-Pro-semibold';
      font-size: 20px;
    }
  }
  
  @media only screen and (min-width: 992px) {
    .cv {
      border-top: none;
      border-radius: 2px;
      padding: 20px 60px 10px 60px;
    }
  }
  

  .section-header {
    display: flex;
    gap: 10px;
    align-items: center;
    color: #3474d1;
    
  }

  .section-header > h2 {
    margin: 5px 0px !important;
  }

  i.fa {
    margin: 5px;
    cursor: pointer;
  }

  .about-section .w-100 {
    width: 100%;
  }

  .d-flex {
    display: flex;
  }


  .p-2 {
    padding: 5px;
  }

  .form-field {
    padding: 10px;
  }

  .form-field input, .form-field textarea {
    width: 90%;
  }

  .footer-button button{
        margin: 10px;
  }

  .pb-2 {
    padding-bottom: 15px;
  }