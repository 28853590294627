@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');

/* =========================Header section ======================================= */

html {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    /*filter: invert(1) hue-rotate(180deg);*/
}
/*img{*/
    /*filter: invert(1) hue-rotate(180deg);  */
/*}*/
*,
*:before,
*:after {
    box-sizing: inherit;
}
body {
    position: relative;
    margin: 0;
    font-family: 'Helvetica', 'Arial', 'sans-serif', open sans regular;
    min-height: 100%;
}
*:focus {
    outline: none !important;
}
a {
    cursor: pointer;
}
.fa-eye {
    cursor: pointer;
}
.fa-eye-slash {
    cursor: pointer;
}
.disable-tab {
    pointer-events: none;
    cursor: no-drop;
    color: #b3b0b0;
}
.info-container{
    position: absolute!important;
    right: 190px;
    top:20px;
    float: right;
    color: #ff337d;
}
.info-container ul{
    padding-inline-start: 15px;
}
.info-container ul li{
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #000
}
.info-container .tips-content{
    left: -160px;
    min-width: 250px!important;
}
.navbar-inverse {
    background-color: #fff;
    border-color: #fff;
    padding: 0px 25px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 17px 35px;
}
.navbar-inverse .navbar-toggle .icon-bar {
    background-color: #888!important;
}
.navbar-inverse .navbar-nav > li {
    padding: 5px 10px 0px 0;
}
.navbar-inverse .navbar-nav > li .mega-dropdown-menu {
    margin-top: -10px;
}
.navbar-inverse .navbar-nav>.open>a,
.navbar-inverse .navbar-nav>.open>a:focus,
.navbar-inverse .navbar-nav>.open>a:hover {
    background: transparent!important;
    color: #ff1e9b;
}
.navbar-inverse .navbar-nav > li > a {
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #565a6c;
}
.navbar-inverse .nav > li > a {
    padding: 15px 10px;
}
.navbar-inverse .navbar-nav>li>a:focus,
.navbar-inverse .navbar-nav>li>a:hover {
    color: #ff337d;
}
.navbar-inverse .navbar-nav > .active > a,
.navbar-inverse .navbar-nav > .active > a:focus,
.navbar-inverse .navbar-nav > .active > a:hover {
    color: #ff337d;
    font-size: 14px;
    background-color: transparent;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.navbar-inverse .dp-image {
    height: 40px;
    width: 40px;
    margin-top: -10px;
}
.navbar-inverse .blank-dp {
    height: 45px !important;
    width: 45px !important;
    padding-top: 12px !important;
    margin-top: -12px !important;
}
.navbar-inverse .navbar-logo {
    width: 100px;
    margin-top: 0px;
}
.navbar-inverse .dropdown-menu {
    min-width: 100%;
}
.navbar-inverse .mega-dropdown-menu .browse-more>li>a {
    font-size: 14px;
}
.navbar-inverse .mega-dropdown-menu .xs-heading {
    font-size: 16px;
}
#call-icon img {
    width: 30px;
    /*margin-top: 15px;*/
}
.ol-list {
    margin-left: -25px;
}
.ol-list li {
    font-size: 18px!important;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    margin-bottom: 15px;
}
/*===========================Dashboard Side Bar Navigation=======================*/
.affilliate-main-wrapper{
    padding-left: 190px;
}
.affilliate-page-wrapper{
    margin-top: 90px;
}
.sidebar-nav{
    position: fixed;
    height: 100%;
    top:0;
    bottom: 0;
    left: 0;
    margin-top: 50px;
    background: #fff;
    padding: 10px 20px;
}
.sidebar-nav ul>li{
    margin-top: 15px;
}
.sidebar-nav ul>li>a{
    font-size: 16px;
    color: #747B95;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.sidebar-nav ul>li.active>a{
    color: #ff337d;
}
.affiliate-d-container{
    background: #fff;
    margin-bottom: 20px;
}
.affiliate-d-body{
    padding: 15px 25px;
    border-bottom: 1px solid #E0E0E0;
}
.d-affiliate-report-area{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.d-affiliate-report-card{
    background: #fff;
    margin-bottom: 15px;
    min-height: 100px;
    padding: 10px 10px;
    width: 23%;
    margin-bottom: 15px;
    border-radius: 6px;
}
.d-affiliate-para{
    color: #8289A4;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    margin-bottom: 15px;
}
.d-affiliate-para-count{
    color: #5B6176;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    text-align: center;
}
.affiliate-d-ul{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
}
.affiliate-d-ul li{
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    width: 50%;
}
.affiliate-d-ul li{
    display: flex;
    align-items: center;
}
.affiliate-d-ul li span+span{
    color: #ff337d;
    margin-left: 20px;
    word-break: break-word;
    width: 60%;
}
.affiliate-d-ul li a{
    color: #ff337d;
}
.affiliate-d-heading{
    font-size: 18px;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.affiliate-d-sub-heading{
    font-size: 16px;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    margin-bottom: 15px;
}
.table.affiliate-table thead>tr>th{
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #868DA7;
    padding: 8px 12px;
    border-bottom: 1px solid #DFDFDF;
}
.table.affiliate-table tbody>tr>td{
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #000;
    padding: 10px 12px;
    border-bottom: 1px solid #DFDFDF;
}
.table.affiliate-table tbody>tr>td a{
    color: #ff337d;
}
.table.affiliate-table tbody>tr>td a.btn-link{
   font-size: 16px;
}
.affiliate-kyc-form{
    margin-top: 30px;
}
.affiliate-kyc-form .form-group{
    margin-bottom: 25px;
}
.affiliate-kyc-form .form-group .checkbox{
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    display: flex;
    align-items: flex-end;
}
.affiliate-flex-form{
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
}
.affiliate-flex-form .form-group .form-control{
    box-shadow: none;
    border: 1px solid #D3D3D3;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.affiliate-flex-form .form-group+.form-group{
    margin-left: 20px;
}
.affiliate-kyc-form .btn-link-save{
    background: transparent;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color:#3561D7;
}
/*===========================Dashboard Side Bar Navigation=======================*/
/*=====================Job Posting List===========================================================================*/
.job-posting-panel{
    box-shadow: 0px 4px 10px rgba(197, 197, 197, 0.25);
    border-radius: 6px;
    background-color: #fff;
    margin-top: 15px;
    margin-bottom: 25px;
    border: 1px solid #e0efee;
}
.posting-flex-box{
    display: flex;
    align-items: center;
}
.cover-letter-box{
    border-bottom: 1px solid #d6d6d6;
    padding-bottom: 10px;
    margin-bottom: 15px;
}
.cover-letter-box .cover-heading{
   margin-top: 0px;
}
.cover-letter-box .text-name{
    font-size: 16px;
    margin-top: 20px;
}
.cover-letter-box .text-product-name{
    margin-top: 10px;
}
.cover-letter-box .text-para-reviews{
   font-size: 16px;
}
.job-posting-panel-modal{
    box-shadow: none;
    border: none;
    margin-top: 0px;
}
.job-posting-panel-body{
    padding: 10px 20px;
    position: relative;
}
.job-posting-panel-footer{
    border-top: 1px solid #E6E6E6;
    /*background: #fbf6ef;*/
    padding: 10px 20px;
}
.job-posting-ul{
    margin-bottom: 0px;
    display: flex;
    justify-content: space-between;
}
.job-posting-ul li+li{
    margin-left: 15px;
}
.status-bubble{
    padding: 6px 15px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
    background: #F2EDDE;
    border-radius: 4px;
}
.about-posting-ul{
    display: flex;
    gap: 10%;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: 5px;
}
.about-posting-ul li{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.about-posting-ul li span{
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
     font-size: 14px;
    color: #71758A;
}
.about-posting-ul li span+span{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
     font-size: 14px;
    color: #000;
}
.about-posting-ul.feedback-ul{
    flex-direction: column;
    text-align: left;
    border: none;
    align-items: flex-start;
}
.about-posting-ul.feedback-ul li{
    margin-bottom: 20px;
}
.about-posting-skill{
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 0;
    margin-left: 0px;
}
.about-posting-skill li {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #666e7f;
    font-size: 12px;
    border: 1.2px solid #666e7f;
    background: #fff;
    padding: 4px 8px;
    border-radius: 25px;
    margin: 5px 0;
    text-transform: capitalize;
}
.about-posting-skill li a{
    color: #000;
}
.job-description{
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 15px;
    color: #57636F;
    line-height: 1.5em;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.job-description-detail{
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 1.5em;
    color: #57636F;
}
.btn-see-job-post{
    background: #2C5FE1;
    padding: 8px 25px;
    color: #fff;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.btn-see-job-post:hover,
.btn-see-job-post:focus{
    color: #fff;
}
.btn-share-job-post{
    background: #fff;
    border: 1.5px solid #2C5FE1;
    padding: 8px 25px;
    color: #2C5FE1;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.btn-share-job-post:hover,
.btn-share-job-post:focus{
    color: #2C5FE1;
}
.s-btn-container{
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    padding: 10px 30px;
}
.btn-see-job-posting{
    border: 2px solid #ff337d;
    color: #ff337d;
    padding: 8px 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
}
.btn-edit-job-posting{
    border: 2px solid #2843de;
    color: #2843de;
    padding: 8px 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
}
.btn-see-job-posting:hover,
.btn-see-job-posting:focus{
    color: #ff337d;
}
.btn-edit-job-posting:hover,
.btn-edit-job-posting:focus{
    color: #2843de;
}
.about-expert-stats{
    display: flex;
    gap: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
    align-items: center;
}
.see-detail{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #ff337d!important;
}
.see-detail:hover,
.see-detail:focus{
    color: #2C5FE1;
}
.e-s-bubble{
    background: #E0ECF8;
    color: #000;
    border-radius: 4px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
    padding: 8px 15px;
}
.jop-feed-tab{
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    gap: 30px;
    margin: 20px 0;
    border-bottom: 1px solid #E6E6E6;
}
.jop-feed-tab li{
    padding: 5px 0;
}
.jop-feed-tab li>a{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #6B7788;
    font-size: 14px;
}
.jop-feed-tab li.active{
    border-bottom: 2px solid #ff337d;
}
.jop-feed-tab li.active>a{
    color: #ff337d;
}
.modal .job-posting-ul .btn-edit-job-posting{
    background: #2843de;
    color: #fff
}
.modal .job-posting-ul .btn-see-job-posting{
    background: #ff337d;
    color: #fff
}
.job-posting-ul.space-bwt{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.job-posting-ul.space-bwt .btn{
    width: 100%;
}
.job-posting-ul.space-bwt li{
    width: 25%;
}
.job-posting-ul li .btn-link{
    color: #2C5FE1;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
}
.proposal-count{
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    margin-bottom: 30px;
    color: #881c36;
}
.proposal-number{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 20px;
}
.proposal-price-text{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 20px;
    color: #ff337d;
}
.p-expert-media{
    display: flex;
    align-items: center;
    width: 100%;
}
.p-expert-media .badge-box{
    margin-left: 30px;
}
.job-posting-panel-body .blank-dp{
    line-height: 40px!important;
}
.p-expert-feed-dp{
    height: 55px!important;
    width: 55px!important;
    object-fit: cover;
}
.p-expert-dp{
    height: 65px!important;
    width: 65px!important;
    object-fit: cover;
}
.p-expert-name {
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 5px;
    color: #000;
}
.p-expert-bio {
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    margin-bottom: 0px;
    color: #5c6674;
}
.p-expert-stats-ul{
    margin-top: 10px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
}
.p-expert-stats-ul li+li{
    margin-left: 15px;
}
.p-expert-stats-ul li .fa-star{
    color: gold;
}
.proposal-fee-breakup{
    display: flex;
    align-items: center;
    margin-top: 10px;
}
.proposal-fee-breakup li{
    font-size: 16px;
    color: #4b545;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    margin-bottom:  5px;
    width: 25%;
}
.proposal-fee-breakup li+li{
    width: 75%;
}
.job-posting-panel .minimul-badge .badge-gigzoe-new,
.job-posting-panel .minimul-badge .badge-gigzoe-preferred-text,
.job-posting-panel .minimul-badge .badge-pro-text,
.job-posting-panel .minimul-badge .badge-super-pro-text,
.job-posting-panel .minimul-badge .badge-recommended-text{
    font-size: 14px;
    padding: 8px 10px;
}
@media only screen and (max-device-width: 767px) {
    .proposal-price-text{
        font-family: 'Poppins', sans-serif;
    font-weight: 500;
        font-size: 16px;
        color: #ff337d;
    }
}
/*=====================Job Posting List===========================================================================*/
/*=====================New dashboard ui===========================================================================*/

.jumbotron-primary {
    background-color: #ffc2c2;
    width: 70%;
    margin: auto;
}
.center-modal .modal-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)!important;
}
.center-modal .modal-dialog {
    width: 700px;
}
.add-skill {
    width: 750px;
}
.add-skill .modal-body {
    padding: 15px 30px;
}
.add-skill .form-control {
    box-shadow: none;
    outline: none;
}
.price-card {
    margin-bottom: 10px;
    min-height: auto;
    /*min-height: 300px;*/
    border: 1px solid #979797;
    position: relative;
}
.card-header {
    background: #fbdfdf;
    padding: 15px 10px;
    text-align: center;
}
.card-header span {
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
}
.card-body {
    position: relative;
    padding: 10px 10px;
    height: 250px;
    overflow: auto;
}
.card-body ul {
    margin-left: -20px;
}
/*.card-body li:before {*/

/*    content: '✓';*/

/*    color: green;*/

/*    margin-left: -10px;*/

/*}*/

.card-body li {
    font-size: 14px;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    margin-bottom: 5px;
}
.card-body h4 {
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000;
}
.card-footer .form-control {
    border-radius: 0;
    border: none;
    background: #41D2D2;
    color: #fff!important;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    text-align: center;
    box-shadow: none;
    outline: none;
    margin-top: 10px;
}
.card-footer input::-webkit-input-placeholder,
.card-footer input::-webkit-input-placeholder {
    color: #fff !important;
}
.card-footer select {
    width: 100%;
    padding-top: 0px;
    border: none;
    text-align: center;
    text-align-last: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #41D2D2;
    color: #fff!important;
    font-size: 16px!important;
    background-image: url('../images/arrow-down-sign-to-white.png')!important;
    background-repeat: no-repeat;
    background-position: 95% center;
    background-size: 12px;
    border-radius: 0!important;
}
.skill-heading {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 20px;
    margin-top: 20px;
}
.link-icon {
    display: block;
    height: 42px;
    width: 42px;
    line-height: 50px;
    background-color: #e8eef3;
    border-radius: 50%;
    overflow: hidden;
}
.dropzone {
    width: 100%;
    position: relative;
    max-height: 100px;
    overflow: hidden;
    margin-top: 20px;
    margin-bottom: 10px;
    line-height: 100px;
    text-align: center;
    border-width: 2px;
    border-style: dashed;
    border-color: #e3e3e3;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.dropzone-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: #008CBA;
    overflow: hidden;
    width: 100%;
    height: 0;
    line-height: normal!important;
    transition: .5s ease;
}
.dropzone-button-box .btn {
    margin: 5px;
    font-size: 12px;
}
.dropzone:hover .dropzone-overlay {
    height: 100%;
}
.dropzone-button-box {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}
.dropzone-video {
    width: 100%;
    max-height: 200px;
    margin-top: 10px;
    line-height: 200px;
    overflow: hidden;
    text-align: center;
    border-width: 2px;
    border-style: dashed;
    border-color: #e3e3e3;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.dropzone-doc {
    width: 100%;
    max-height: 46px;
    margin-top: 0px;
    line-height: 46px;
    overflow: hidden;
    text-align: center;
    border-width: 1px;
    border-style: solid;
    border-color: #ccc;
    cursor: pointer;
    border-radius: 4px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.btn-save-profile {
    background: #0c202f;
    color: #fff;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    padding: 8px 30px;
}
.btn-save-profile:hover,
.btn-save-profile:focus {
    background: #0c202f;
    color: #fff;
}
.btn-view-profile {
    background: #fff;
    border: 1.2px solid #2841e4;
    color: #1d2fdc;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    padding: 6px 25px;
    margin-top: 10px;
}
.btn-view-profile:hover,
.btn-view-profile:focus {
    background: #1d2fdc;
    color: #fff;
}
.btn-save-profile+button {
    margin-left: 20px;
}
.btn-save-review {
    background: #ff337d;
    color: #fff;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    padding: 8px 30px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.btn-save-review:hover,
.btn-save-review:focus {
    background: #ff337d;
    color: #fff;
}
.btn-save-profile-gig{
    background: #0c202f;
    color: #fff;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    padding: 10px 45px;
    margin-top: 10px;
}
.btn-save-profile-gig:hover,
.btn-save-profile-gig:focus {
    background: #0c202f;
    color: #fff;
}
.btn-save-profile-gig .fa-calendar{
    font-size: 22px;
    margin-right: 10px;
}
.btn-see-skill-outline{
    background: #fff;
    border: 2px solid #000;
    color: #000;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    padding: 10px 45px;
    margin-left: 15px;
    margin-top: 10px;
}
.btn-see-skill{
    background: #000;
    color: #fff;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    padding: 10px 40px;
    margin-top: 10px;
}
.btn-see-skill:hover,
.btn-see-skill:focus{
    color:#fff;
}
.btn-see-profile-gig{
    background: #444de0;
    color: #fff;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    padding: 10px 45px;
    margin-top: 10px;
}
.btn-see-profile-gig:hover,
.btn-see-profile-gig:focus {
    background: #444de0;
    color: #fff;
}
.btn-submit-gig{
    background: #ff337d;
    color: #fff;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    padding: 8px 30px;
    margin-top: 10px;
}
.btn-submit-gig:hover,
.btn-submit-gig:focus {
    background: #ff337d;
    color: #fff;
}
.tutorial-box {
    position: relative;
}
.tutorial-tips {
    display: none;
    position: absolute;
    right: -260px;
    /*height: 260px;*/
    /*overflow-y: auto;*/
    z-index: 1;
    top: 0;
    width: 260px;
    min-height: 220px;
    border-radius: 6px;
    padding: 10px 20px;
    box-shadow: 0 3px 5px 0 rgba(135, 135, 135, 0.5);
    /*border: solid 1px #794fe8;*/
    background-color: #ffeeee;
}
.tutorial-box:hover .tutorial-tips {
    display: block;
}
.tutorial-para {
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000;
    margin-top: 15px;
    margin-bottom: 0px;
}
.tutorial-sub-para {
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #000;
}
.calling-rule {
    list-style-type: none;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding-inline-start: 0px;
    /*justify-content: center;*/
    /*align-items: top;*/
    margin-bottom: 20px;
}
.calling-rule-box {
    width: 50%;
    padding: 10px 25px;
}
.calling-rule-box .scope-sub-heading{
  margin-top: 0px;
  margin-bottom: 20px;
}
.calling-rule-box ul{
    margin-top: 15px;
    margin-bottom: 10px;
}
.calling-rule-box li{
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color:#000;
    margin-bottom: 20px;
}
.calling-rule-box img{
    width: 130px;
    margin-bottom: 15px;
}
.btn-internet-call{
    background-color: #2b1de6;
    color: #fff!important;
    padding: 8px 35px;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.btn-internet-call:hover,
.btn-internet-call:focus{
    background-color: #2b1de6;
    color: #fff;
}
.btn-normal-call{
    background-color: #fff;
    border: 1.5px solid #2b1de6;
    color: #2b1de6!important;
    padding: 8px 35px;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.btn-normal-call:hover,
.btn-normal-call:focus{
    background-color: #fff;
    border: 1.5px solid #2b1de6;
    color: #2b1de6;
}
.btn-call-schedule{
    background-color: #fff;
    border: 1.5px solid #2b1de6;
    color: #2b1de6!important;
    padding: 6px 8px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.btn-zoom-call{
    background-color: #2b1de6;
    color: #fff!important;
    padding: 6px 8px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.btn-call-schedule+.btn-zoom-call{
    margin-left: 10px;
}
.tutorial-link {
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000;
}
.tips-ul{
 padding-inline-start: 20px;
    margin-bottom: 10px;
}
.tips-ul li{
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #ff337d;
    margin-bottom: 10px;
}
.d-update-profile-body .tips-ul{
 margin-bottom: 20px;
}
.d-update-profile-body .tips-ul li{
    color: #000;
}
.d-update-profile-body .blank-dp{
    line-height: 40px;
}
.modal .tips-ul li{
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #000;
}
.tutorial-link a {
    color: #2b1de6;
    overflow-wrap: break-word;
    word-wrap: break-word;
}
.job-completed-jumboturn {
    margin-top: 0%;
    padding: 20px;
    margin-bottom: 10px;
}
.job-completed-jumboturn .scope-sub-heading {
    margin-top: 15px;
    font-size: 24px;
}
.job-completed-jumboturn img {
    margin-top: 50px;
    margin-bottom: 20px;
    width: 120px;
}
.btn-go-home {
    background-color: #000;
    color: #fff;
    padding: 10px 25px;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.btn-share-site {
    background-color: #fff;
    color: #000;
    border: 2px solid #000;
    font-size: 18px;
    padding: 10px 25px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.btn-go-home:hover,
.btn-go-home:focus {
    background-color: #fff;
    color: #000;
    border: 2px solid #000;
}
.btn-share-site:hover,
.btn-share-site:focus {
    background-color: #000;
    color: #fff;
}
.prefix {
    font-size: 24px!important;
}
.prefix-unit {
    position: absolute;
    display: block;
    left: 0px;
    top: 3px;
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    z-index: 9;
    padding: 5px 5px 5px 5px!important;
}
.i-will-input input {
    border: 1px solid #e3e3e3;
    padding-left: 60px;
    font-size: 24px!important;
}
.tutorial-tips:before {
    z-index: 100;
    content: ' ';
    height: 0;
    position: absolute;
    width: 0;
    left: -30px;
    border: 15px solid transparent;
    border-right-color: #d9d6ff;
}
.other-skill li {
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    padding: 8px 10px;
    border: 1px solid #a6abc1;
    background: #fff;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #a6abc1!important;
    cursor: pointer;
    text-align: center;
}
.other-skill li+li{
    margin-left: 15px;
}
.other-skill li.background-pink {
    border-radius: 4px;
    padding: 8px 10px;
    border: 1px solid #3f5be1!important;
    background: #fff;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #3f5be1!important;
    text-align: center;
    white-space: nowrap;
}
.other-skill-table button {
    margin-top: 0px;
    border-radius: 4px;
    padding: 8px 10px;
    width: 100%;
    border: 1px solid #3821d6;
    background: #fff;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #3821d6!important;
    margin-left: 0px;
    cursor: pointer;
    text-align: center;
}
.other-skill-table .badge {
    margin-left: 5px;
    padding: 5px 8px;
    background: #e323e1;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.other-skill-table button.background-pink {
    border-radius: 4px;
    padding: 8px 10px;
    border: 1px solid #5FC9B0!important;
    background: #5FC9B0;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #fff!important;
    text-align: center;
    white-space: nowrap;
    margin-bottom: 0px;
}
.other-skill-table button.background-white {
    border-bottom: 1px solid #3821d6;
}
.skill-table table>thead>tr {
    border: 1px solid #ddd;
}
.skill-table table>thead>tr>th {
    padding: 12px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}
.skill-table table>tbody>tr {
    border: 1px solid #ddd;
}
.skill-table table>tbody>tr>td {
    padding: 12px;
    vertical-align: middle;
    border-top: none;
    border-bottom: 1px solid #ddd;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.other-skill-table a:hover,
.other-skill-table a:focus {
    color: #ff337d;
}
.skill-table .table>tbody>tr>td .btn-link {
    padding: 0px 12px;
}
.service-heading-md {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 15px;
}
.service-para {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #8a8a98;
    font-size: 16px;
}
.category-p-heading {
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    border-bottom: 1px solid #e3e3e3;
    margin-top: 25px;
    padding-bottom: 2px;
}
.link-icon .fa-link {
    font-size: 22px;
    color: #ff337d;
}
.share-profile-modal .modal-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)!important;
}
.share-profile-modal .close {
    font-size: 34px;
}
.modal-heading {
    font-size: 22px;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    margin-bottom: 30px;
}
.login-form input {
    color: #000;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.login-form .form-group a {
    font-style: italic;
    margin-top: 5px;
    font-size: 16px;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.login-form .form-control {
    box-shadow: none;
    border-radius: 0px;
    border: none;
    padding-left: 0px;
    padding-top: 20px;
    padding-bottom: 0px;
    border-bottom: 1.2px solid #e8eef3;
}
.ui-sign-sub-heading {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000;
    font-size: 18px;
    margin-top: 0px;
    margin-bottom: 20px;
}
.log-in-button {
    margin-top: 20px;
    padding: 6px 20px;
    background-color: #ff337d;
    color: #fff;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.log-in-button:hover,
.log-in-button:focus {
    background-color: #ff337d;
    color: #fff;
}
.submit-blog .form-group{
   margin-bottom: 30px;
}
.unit {
    position: absolute;
    display: block;
    left: 0px;
    top: 15px;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    z-index: 9;
    padding: 0px 5px 5px 0px!important;
    border-right: 1px solid #dddddd;
}
.input-box {
    position: relative;
}
/*.input-box input{*/

/*    padding: 10px 0px 0px 40px!important;*/

/*}*/

.otp_input {
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 1px solid #000;
    width: 30px;
    text-align: center;
    padding: 5px;
    margin-left: 10px;
    margin-bottom: 15px;
}
.col-divider {
    margin-top: 70px;
    margin-bottom: 20px;
}
.otp-col-padding {
    padding: 0px 15px;
}
.otp-col-padding .btn-link {
    color: #2c3af4!important;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 18px;
    margin-top: 10px;
}
.d-table-responsive {
    max-width: 100%;
    margin-bottom: 15px;
    overflow-x: auto;
    overflow-y: auto;
    max-height: 700px;
}
/*.tableFixHead{*/
    /**/
/*}*/
.d-table-responsive thead th {
    position: sticky;
    top: 0;
    /*z-index: 1;*/
    background: #fff;
}
.d-table-responsive .checkbox {
    margin-top: 5px;
    margin-bottom: 0px;
}
.d-table-responsive .table>thead>tr>th,
.d-table-responsive .table>tbody>tr>td {
    padding: 6px 10px!important;
    vertical-align: middle;
}
.d-table-responsive.professional-list .table>thead>tr>th,
.d-table-responsive.professional-list .table>tbody>tr>td {
    padding: 6px 10px!important;
    vertical-align: top;
}
.d-table-responsive.service-supply .table>tbody>tr>td {
    vertical-align: top;
}
.d-table-responsive .table>thead {
    white-space: nowrap;
}
.d-table-responsive .table>tbody {
    white-space: nowrap;
}
.table.singal-job-table{
    margin-bottom: 10px;
}
.singal-job-table thead {
    border-bottom: none;
}
.singal-job-table thead>tr>th {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 16px;
    border: none;
}
.d-report-panel .d-table-responsive{
    margin-bottom: 0px;
}
.d-table-responsive .singal-job-table tbody>tr>td {
    padding: 0px 8px!important;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 16px;
}
.singal-job-table tbody>tr>td .media-heading {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
    margin-top: 10px;
}
.singal-job-table .media-sub-heading {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #ff337d;
}
.singal-job-table tbody>tr>td .dp-image {
    height: 40px!important;
    width: 40px!important;
    margin-top: 0px;
}
.singal-job-table tbody>tr>td .blank-dp{
    width: 40px!important;
    height: 40px!important;
    line-height: 27px;
}
.singal-job-table tbody>tr>td .blank-dp span{
    font-size: 14px!important;
}
#new_practice_area_add .modal-body {
    padding: 10px 15px;
}
#new_practice_area_add .modal-body .text-bold {
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    margin-top: 0px;
    margin-bottom: 5px;
}
.p-modal-heading {
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000;
    margin-top: 20px;
}
.update-product-box {
    min-height: 200px;
    padding: 20px;
    margin-bottom: 20px;
}
.p-added-head {
    border-bottom: 1px solid #e8eef3;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000;
}
.missed-call {
    margin-top: 15px;
    margin-bottom: 15px;
    color: #ff337d;
}
.missed-call span {
    padding: 6px 10px;
    border: 1px solid #ff337d;
    border-radius: 10px;
}
.p-added-head span {
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    margin-left: 30px;
}
.submit-button {
    border: 1.2px solid #1ed49c;
    color: #1ed49c;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    padding: 8px 20px;
}
.submit-button:hover,
.submit-button:focus {
    color: #1ed49c;
}
.t-and-c-haeding {
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000;
    margin-bottom: 30px;
}
.t-and-c-sub-haeding {
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000;
    line-height: 1.4em;
}
.t-and-c-indent {
    text-indent: -15px;
    margin-left: 15px;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.n-logo-container{
    padding: 10px 20px;
    margin-bottom: 15px;
}
.n-logo-container .navbar-logo{
    width: 100px;
}
.main-dashboard-container{
    display: flex;
    /* height: 100vh; */
    background: #FDFDFF;
    position: relative;
    gap: 20px;
}
.main-dashboard-container-2{
    /* display: flex; */
    background: #FDFDFF;
    position: relative;
    gap: 20px;
}
.main-dashboard-sidebar{
 background: #FFFFFF;
  box-shadow: 0px 10px 60px rgba(226, 236, 249, 0.5);
  width: 20%;
  position: fixed;
  top: 0;
  bottom: 0;
  padding: 10px 10px;
}
.navigation-container{
padding: 0 20px;
}
.s-link-category{
    margin-bottom: 20px;
}
.s-link-border{
    border-bottom: 1px solid #E6E6E6;
    padding-bottom: 5px;
}
.s-link-category li{
    margin-top: 10px;
    padding-bottom: 5px;
}
.s-link-category li>a{
    color: #9197B3;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    gap: 10px;
    align-items: center;
}
.s-link-category li>a:focus,
.s-link-category li>a:hover{
    color: #ff337d;
}
.s-link-category li.active>a{
    color: #ff337d;
}
.s-link-sub-category li>a img,
.s-link-category li>a img{
    width: 25px;
}
.s-link-sub-category{
    margin-left: 40px;
}
.s-link-sub-category li{
    margin-top: 10px;
    margin-bottom: 15px;
}
.s-link-sub-category li>a{
    color: #9197B3;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    gap: 10px;
    align-items: center;
}
.s-link-sub-category li>a:focus,
.s-link-sub-category li>a:hover{
    color: #ff337d;
}
.s-link-sub-category li>a .fa-check{
    color: #38CD8F;
}
.s-link-sub-category li>a .fa-circle{
    color: #F07575;
    font-size: 10px;
}
.btn-link-feed{
    padding: 8px 40px;
    font-size: 16px;
    border: 1px solid #506BF6;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #506BF6!important;
    margin-bottom: 5px;
    display: inline-block!important;
}
.breadcrumb.breadcrumb-ul{
    margin-bottom: 0;
}
.breadcrumb-ul li>a{
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 16px;
}
.breadcrumb-ul li>a:hover,
.breadcrumb-ul li>a:focus{
    color: #ff337d;
}
.posted-jobs-detail{
    display: flex;
    flex-wrap: wrap;
    /*width: 100%;*/
    background: #FFFFFF;
    box-shadow: 0 4px 10px rgba(197, 197, 197, 0.25);
    border-radius: 6px;
    margin: 10px 20px;
    border: 1px solid #E6E6E6;

}
.posted-jobs-m-container{
    width: 70%;
    border-right: 1px solid #E6E6E6;
}
.posted-jobs-m-side{
    width: 30%;
}
.posted-jobs-m-body{
    padding: 15px 25px;
    border-bottom: 1px solid #E6E6E6;
}
.main-dashboard-content-area{
    width: 80%;
    margin-left: 20%;
    padding: 10px 60px;
}
.common-new-d-gap{
    padding: 30px 0;
}
.s-link-sub-category li .dp-image{
    margin-top: 0px;
    border: 2px solid #FF337D;
}
.s-link-sub-category li .blank-dp{
    line-height: 40px!important;
}
.edit-setting>a{
    font-size: 14px;
    color: #9197B3;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.d-parent-margin-top {
    margin-top: 30px;
    /*padding-right: 0px;*/
}
.d-parent-common-top {
    margin-top: 40px;
}
.dashboard-header-box {
    box-sizing: border-box;
    box-shadow: 0 2px 5px 1px rgba(174, 170, 170, 0.5);
    background-color: #ffffff;
    border-radius: 4px;
    padding: 10px 15px;
    margin-bottom: 20px;
}
.new-dashboard-container {
    /*box-sizing: border-box;*/
    /*box-shadow: 0 2px 5px 1px rgba(174, 170, 170, 0.5);*/
    /*background-color: #ffffff;*/
    margin-top: 30px;
    /*border-radius: 4px;*/
    /*padding-top: 20px;*/
    /*padding-bottom: 30px;*/
}
.new-dashboard-container .d-update-profile{
    min-height: auto;
}
.d-update-profile.dashboard-banner{
    display: flex;
    flex-wrap: wrap;
    min-height: 230px;
}
.dashboard-banner-text{
    width:65%;
    padding-bottom: 20px;
}
.dashboard-banner-img{
    width: 35%;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffe9e9;
}
.dashboard-banner-img img{
    width:120px;
}
.dashboard-banner-img.expert-banner{
    position: relative;
}
.dashboard-banner-img.expert-banner img{
    width: 250px;
    position: absolute;
    bottom: 0px;
}
.dashboard-banner ul {
    padding-block-start: 10px;
}
.dashboard-banner ul>li {
    margin-left: 30px;
    margin-bottom: 15px;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.dashboard-banner ul>li:before {
    content: '';
    position: absolute;
    left: 0;
    margin-top: 5px;
    margin-left: 35px;
    width:20px;
    height: 20px;
    background: url('../images/yes-tick.png') no-repeat;
    background-size: 15px;
}
.tick-ul,
.star-ul {
    padding-block-start: 10px;
}
.tick-ul>li {
    margin-left: 30px;
    margin-bottom: 15px;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.tick-ul>li:before {
    content: '';
    position: absolute;
    left: 0;
    margin-top: 5px;
    margin-left: 45px;
    width:20px;
    height: 20px;
    background: url('../images/yes-tick.png') no-repeat;
    background-size: 15px;
}
.star-ul>li:before {
    content: '*';
    display: block;
    float: left;
    font-size: 28px;
    color: #ff337d;
    margin-left: -20px;
    margin-right: 5px;
}
.star-ul>li {
    margin-left: 25px;
    margin-bottom: 15px;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.signup-form .form-group .text-success {
    color: green
}

.btn-referral-code {
    background-color: #2b47fb;
    font-style: normal!important;
    color: #fff!important;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    margin-top: 10px;
}
.btn-referral-code:hover,
.btn-referral-code:focus {
    background-color: #2b47fb;
    color: #fff!important;
}
.d-common-padding {
    padding-right: 25px;
    padding-left: 25px;
}
.d-sub-heading {
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000;
    margin-top: 20px;
}
.d-sub-heading .btn-link {
    color: #2b1de6;
}
.d-report-panel {
    margin-top: 5px;
    margin-bottom: 20px;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0212249);
    background-color: #ffffff;
    position: relative;
    display: flex;
    flex-direction: column;
    /*flex: 33.3%;*/
    flex-basis: 33.3%;
}
.d-report-panel .xs-heading{
    font-size: 16px;
}
.d-report-panel-body{
    padding: 10px 15px;
    cursor: pointer;
}
.d-report-panel-footer{
    margin-top: auto;
    padding:0px 10px;
}
.d-report-panel-footer .btn-link{
    padding: 10px 0;
    color: #3760F2;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
}
.d-report-panel .text-link {
    color: #000;
    margin-top: 0px;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    margin-bottom: 0px;
}
.d-report-panel .heading-md {
    padding-bottom: 2px;
    margin-top: 0px;
    font-size: 16px;
}
.d-report-panel .btn-container-strip {
    background-color: #fbf6ef;
}
.report-stats-ul{
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}
.report-stats-ul li{
    font-size: 16px;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.report-stats-ul li+li{
    margin-left: 20px;
    color: #3570e1;
}
.report-stats-ul li img{
    width: 20px;
    vertical-align: text-bottom;
}
.d-report-panel .blank-dp{
    line-height: 40px!important;
}
.d-empty-space{
    display: flex;
    gap: 20px;
    width: 100%;
}
.popular-service-ul{
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin-top: 10px;
}
.popular-service-ul li{
    margin-top: 10px;
    margin-bottom: 10px;
}
.gig-label{
    padding: 8px 15px;
    border-radius: 25px;
    background: #E5EEFC;
    border: 1.2px solid #3B6CE9;
    color: #2B50D2;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.gig-label:hover,
.gig-label:focus{
    color: #2B50D2;
}
.btn-see-proposal {
    /*border: 1.2px solid #000;*/
    padding: 8px 25px;
    width: 100%;
    box-sizing: border-box;
    background-color: #2b1de6;
    color: #fff;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
}
.btn-edit-job {
    /*border: 1.2px solid #ff337d;*/
    padding: 8px 25px;
    width: 100%;
    box-sizing: border-box;
    background-color: #ff337d;
    color: #fff;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
}
.btn-see-doc {
    border: 1.2px solid #2b1de6;
    padding: 8px 25px;
    width: 100%;
    box-sizing: border-box;
    background-color: transparent;
    color: #2b1de6;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.btn-see-proposal:hover,
.btn-see-proposal:focus {
    background-color: #2b1de6;
    color: #fff;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
}
.btn-edit-job:hover,
.btn-edit-job:focus {
    background-color: #ff337d;
    color: #fff;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
}
.btn-see-doc:hover,
.btn-see-doc:focus {
    color: #fff;
    background: #2b1de6;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
}
.d-report-panel .text-number {
    margin-top: 20px;
    font-size: 24px;
}
.d-small-para {
    margin-top: 15px;
    margin-bottom: 20px;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #000;
}
.d-image-container img {
    margin-top: 30px;
    margin-bottom: 20px;
}
.d-btn-ui {
    padding: 10px 20px;
    width: 100%;
    max-width: 200px;
    box-sizing: border-box;
    background-color: #323232;
    color: #fff;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.d-btn-ui:hover,
.d-btn-ui:focus {
    background-color: #323232;
    color: #fff;
}
.d-sidebar-box {
    position: relative;
    box-shadow: 0 1px 5px 1px rgba(194, 189, 189, 0.5);
    background-color: #ffffff;
    border-radius: 4px;
    min-height: 200px;
    padding: 15px 10px;
    /*margin-left: 15px;*/
    margin-bottom: 20px;
    line-height: 120px;
}
.d-sidebar-profile-box {
    position: relative;
    box-shadow: 0 1px 5px 1px rgba(194, 189, 189, 0.5);
    background-color: #ffffff;
    border-radius: 4px;
    min-height: 200px;
    padding: 5px 10px;
    padding-bottom: 20px;
    /*margin-left: 15px;*/
    margin-bottom: 20px;
}
.d-sidebar-profile-box .blank-dp {
    line-height: 40px;
    margin-top: 15px!important;
}
.d-sidebar-box img {
    margin: auto;
}
.sidbox-btn {
    padding: 8px 20px;
    background-color: #323232;
    color: #fff;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    bottom: 15px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
.d-sidebar-box .dp-image,
.d-sidebar-profile-box .dp-image {
    width: 55px;
    height: 55px;
}
.d-sidebar-box .blank-dp {
    padding-top: 18px!important;
}
.d-sidebar-profile-box .table>thead>tr>th,
.d-sidebar-profile-box .table>tbody>tr>td {
    border: none;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #000;
    vertical-align: middle;
    padding:0px 8px;
    padding-top: 10px;
}
.professional-name {
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000;
    margin-top: 5px;
    margin-bottom: 5px;
}
.professional-bio {
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #868DA7;
    margin-top: 5px;
    margin-bottom: 10px;
}
.professional-rating {
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.professional-rating .fa-star {
    color: gold;
    margin-left: 2px;
}
.d-sidebar-profile-box .fa-star {
    color: gold;
    margin-left: 2px;
}
.d-sidebar-profile-box .table>tbody {
    border-top: 1px solid #e3e3e3;
    border-bottom: 1px solid #e3e3e3;
}
.btn-see-profile {
    padding: 8px 30px;
    background-color: #ff337d;
    color: #fff;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.btn-see-profile:hover,
.btn-see-profile:focus {
    color: #fff;
}
.d-sidebar-profile-heading{
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000;
}
.btn-see-service {
    padding: 6px 10px;
    border: 1.2px solid #0c202f;
    background-color: #fff;
    color: #000;
    font-size: 14px;
}
.label-default {
    background: #e323e1;
    color: #fff;
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    margin-left: 5px;
}
.btn-see-profile + .btn-see-service {
    margin-left: 10px;
}
.sidbox-btn:hover,
.sidbox-btn:focus {
    background-color: #323232;
    color: #fff;
}
.d-side-heading {
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000;
    text-align: center;
}
.d-earn-cash-text {
    color: #ff337d;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    margin-top: 20px;
    text-align: center;
}
#refer_and_earn .modal-content {
    border-radius: 4px;
}
#refer_and_earn .modal-body {
    padding: 10px 40px;
}
.d-modal-heading {
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #000;
    text-align: left;
}
.d-modal-para {
    font-size: 18px;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    margin-top: 25px;
    margin-bottom: 10px;
}
.invite-link-box{
    margin-bottom: 15px;
}
.invite-link-box .btn-default {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    border-radius: 0;
    font-size: 16px;
    border: 1px solid #e8eef3;
}
.d-guidelines-heading {
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000;
}
.btn-container-strip {
    padding-right: 15px;
    padding-left: 15px;
    height: 60px;
    line-height: 60px;
    background-color: #f7f7f7;
}
.d-button-container-box-left {
    display: flex;
    vertical-align: middle;
    justify-content: flex-start;
}
.d-button-container-box {
    display: flex;
    vertical-align: middle;
    justify-content: flex-end;
}
.btn-strip-box {
    /*flex-grow: 1;*/
    margin-left: 0px;
    margin-right: 15px;
    float: right;
}
.btn-strip-box .btn-link {
    color: #ff337d;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.button-hire {
    padding: 10px 20px;
    width: 100%;
    /*max-width: 150px;*/
    box-sizing: border-box;
    background-color: #323232;
    color: #fff;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.button-hire:hover,
.button-hire:focus {
    background-color: #323232;
    color: #fff;
}
.btn-see-more {
    padding: 10px 20px;
    width: 100%;
    /*max-width: 150px;*/
    box-sizing: border-box;
    background-color: #17C897;
    color: #fff;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.btn-see-more:hover,
.btn-see-more:focus {
    color: #fff;
}
.button-discuss {
    padding: 10px 20px;
    width: 100%;
    /*max-width: 150px;*/
    box-sizing: border-box;
    background-color: #3745ee;
    color: #fff;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.button-discuss:hover,
.button-discuss:focus {
    background-color: #3745ee;
    color: #fff;
}
.text-muted-time {
    color: #7d8082;
    font-size: 14px;
}
.cover-heading {
    margin-top: 10px;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.see-full-job {
    margin-top: -10px;
    margin-bottom: 15px;
}
.d-title-container {
    box-shadow: 0 2px 5px 0 rgba(223, 223, 223, 0.5);
    background-color: #ffffff;
    padding: 10px 30px 0px 30px;
    margin-bottom: 10px;
}
.d-tab-container {
    box-shadow: 0 2px 5px 0 rgba(223, 223, 223, 0.5);
    background-color: #ffffff;
    padding: 10px 20px 0px 20px;
    margin-bottom: 10px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}
.ul-upload-info{
    display: inline-flex;
    list-style: none;
    padding-inline-start: 0px;
    margin-bottom: 0px;
    align-items: center;
}
.ul-upload-info li+li{
    margin-left: 5px;
}
.d-tab-container ul li>a{
    color: #9395a7;
}
.order-completion-doc .doc-upload-processbar{
    position: relative!important;
    width: 100% !important;
    margin-top: 0px;
}
.ul-upload-info .doc-upload-processbar{
   position: absolute;
    margin-top: -40px;
    width:300px;
}
.ul-upload-info .doc-upload-processbar .progress_bar{
    margin-top: 5px;
}
.d-title-container .fa-long-arrow-left {
    color: #000;
    font-size: 24px;
    margin-left: 0px;
    margin-right: 15px;
}
.d-title-heading {
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000;
    margin-bottom: 20px;
}
.d-title-heading .btn-link {
    color: #ff337d;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.d-title-heading .date {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
}
.d-title-container .nav-tabs {
    border-bottom: none;
    margin-left: 20px;
}
.d-title-container .nav-tabs>li>a {
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000;
}
.d-title-container .nav-tabs>li.active>a,
.nav-tabs>li.active>a:focus,
.nav-tabs>li.active>a:hover {
    border: none;
    border-bottom: 4px solid #ff337d;
    color: #ff337d;
}
.d-title-container .nav>li>a:focus,
.d-title-container .nav>li>a:hover {
    background-color: transparent;
}
.nav-tabs>li>a:hover {
    border-color: transparent;
}
.d-update-profile-head .nav-tabs>li>a{
    color: #838892;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
}
.crate-gigs-tab{
    display: flex!important;
    align-items: center;
    justify-content: center;
    gap: 15px;
    text-align: left!important;
    margin-top: -5px;
}
.crate-gigs-tab img{
    width: 40px;
    vertical-align: bottom;
}
.disabled-text{
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.d-update-profile-head .nav-tabs>li.active>a{
    color: #302fe0;
    border-bottom:4px solid #302fe0!important;
}
.button-outline {
    padding: 10px 20px;
    background-color: #fff;
    border: 1.2px solid #ff337d;
    /*width: 100%;*/
    /*max-width: 150px;*/
    color: #ff337d;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
}
.button-outline:hover,
.button-outline:focus {
    background-color: #ff337d;
    color: #fff;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
}
.link-list li {
    margin-top: 10px;
}
.link-list li>a {
    color: #ff337d;
}
.c-img-container {
    display: flex;
    vertical-align: middle;
}
.c-img-box {
    flex-grow: 1;
    text-align: center;
    float: right;
}
.price-text {
    position: absolute;
    right: 0;
    margin-top: 10px;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #ff337d;
}
.singal-job-table .media {
    margin-top: 10px;
}
.user-new-dashboard-ui{
    margin-top: 40px;
    padding: 0px 30px;
}
.d-new-referral-card{
    background: url('../images/refral.png');
    background-repeat: no-repeat;
    background-position: 100% 100%;
    background-size: 65%;
}
.d-new-referral-card-txt{
    width: 100%;
}
.d-new-stats-card{
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0212249);
    min-height: 230px;
    position: relative;
    margin-bottom: 20px;
}
.d-new-stats-card-body{
    padding:10px 15px;
}
.d-new-stats-heading{
    margin-top: 10px;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000;
}
.d-new-stats-para{
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000;
    margin-bottom: 15px;
}
.d-new-stats-card-footer{
   position: absolute;
    bottom: 15px;
}
.d-new-stats-card-footer .btn-link{
    padding: 0px;
    color: #3760F2;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
}
.btn-get-referral-code{
    background: none;
    padding: 8px 20px;
    border: 2px solid #3760F2;
    color: #3760F2;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.btn-get-referral-code:hover,
.btn-get-referral-code:focus{
    color: #3760F2;
}
.d-new-stats-card .media-heading{
    font-size: 18px;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    margin-top: 10px;
    margin-left: 5px;
}
.d-new-stats-card .dp-image{
    margin-top: 0px;
}
.d-new-stats-card .blank-dp{
    height: 50px!important;
    width: 50px!important;
    line-height: 35px!important;
}
.stats-ul{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}
.stats-ul li{
    font-size: 18px;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    margin-bottom: 5px;
}
.stats-ul li>a{
    color: #3570E1;
}
.d-common-heading{
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000;
    margin-top: 30px;
}
.d-services-suggestion {
    max-height: 100%;
    margin-bottom: 20px;
    position: relative;
    margin-top: 10px;
}

.d-services-suggestion-img img{
    border-radius: 8px;
    object-fit: cover;
}
@media only screen and (max-device-width: 767px) {
    .d-empty-space{
        flex-direction: column;
        gap: 0;
    }
    .user-new-dashboard-ui{
        margin-top: 30px;
        padding: 0px 0px;
    }
    .d-new-stats-card{
        padding:10px 15px;
        min-height: 180px;
    }
    .bottom-navbar{
        height:55px;
        width: 100%;
        position: fixed;
        bottom: 0;
        background: #fff;
        z-index: 10;
        padding: 0px 20px;
        border-top: 1px solid #e8eef3;
    }
    .dashboard-banner-text{
        width:75%;
        padding-bottom: 10px;
    }
    .d-update-profile-head .nav-tabs>li>a{
        font-size: 12px;
        border-radius: 0px!important;
        margin-bottom: 0px!important;
    }
    .d-update-profile .embed-responsive{
        margin-top: 25px;
    }
    .dashboard-banner-img{
        width: 25%;
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffe9e9;
    }
    dashboard-banner-img img{
        width: 100px;
    }
    .calling-rule-box {
        width: 100%;
        padding: 20px 25px;
        border: none!important;
    }
    .app-img-banner{
       position: absolute;
        padding: 5px 10px;
        width:60%;
    }
    .app-img-banner .scope-heading{
        font-size: 14px;
        margin-top: 15px;
    }
    .app-img-banner .app-button{
        margin-top: 15px;
        text-align: left;
    }
    .app-img-banner .app-button li img{
        width:80px;
    }
    .bottom-nav-ul{
        list-style-type: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 55px;
        padding-inline-start: 0px;

    }
    .bottom-nav-ul li{
        display: flex;
        align-items: center;
        text-align: center;
    }
    .bottom-nav-ul li a{
        color: #979797;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
    font-weight: 500;
    }
    .bottom-nav-ul li a .fa{
        font-size: 20px;
    }
    .bottom-nav-ul li.active a{
        color:#000;
    }
    .see-more-tab .media-heading{
      font-family: 'Poppins', sans-serif;
    font-weight: 500;
        margin-top: 5px;
        font-size: 16px;
    }
    .see-more-tab .dp-image{
      height: 55px!important;
        width: 55px !important;
    }
    .browse-more-link.see-more-ul{
        margin-top: 20px;
    }
    .browse-more-link.see-more-ul li a{
        font-size: 16px;
    }
    .see-more-tab .blank-dp{
        line-height: 35px;
    }
    .d-report-panel .heading-md{
        font-size: 16px;
    }
    .d-report-panel .text-number{
        font-size: 20px;
    }
    .chat_message_wrapper.chat_message_right + .chat_message_wrapper.middle_chat_ui{
        margin-top: 15px;
    }
    .chat_message_wrapper.middle_chat_ui + .chat_message_wrapper.chat_message_right{
        margin-top: 15px;
    }
    .job-completed-jumboturn .scope-sub-heading {
        font-size: 18px;
    }
    .d-button-container-box.proposal-btn {
        justify-content: center;
    }
    .jumbotron-primary {
        background-color: #ffc2c2;
        width: 100%;
        margin: auto;
    }
    .service-heading-md {
        margin-top: 15px;
        font-size: 16px;
    }
    .job_title .left-offset {
        margin-left: 0;
    }
    .skill-heading {
        font-size: 16px;
    }
    .prefix-unit {
        font-size: 16px;
        top: 9px;
    }
    .i-will-input input {
        padding-left: 40px;
        font-size: 16px!important;
    }
    .add-skill {
        width: auto;
    }
    .add-skill .modal-body {
        padding: 10px 20px;
    }
    .d-sub-heading {
        font-size: 16px!important;
        font-family: 'Poppins', sans-serif;
    font-weight: 500;
        color: #000;
        line-height: 1.4em;
    }
    .d-report-panel {
        margin-bottom: 10px;
        width: 100%;
    }
    .d-report-panel-body{
        padding: 10px 10px;
    }
    .new-dashboard-container {
        padding-top: 0px;
        min-height: auto!important;
    }
    .singal-job-table thead>tr>th {
        font-family: 'Poppins', sans-serif;
    font-weight: 400;
        font-size: 14px;
        border: none;
    }
    .singal-job-table tbody>tr>td {
        padding: 8px 8px;
        font-family: 'Poppins', sans-serif;
    font-weight: 400;
        font-size: 14px;
    }
    .btn-see-proposal,
    .btn-edit-job,
    .btn-see-doc {
        font-size: 12px;
        padding: 8px 8px;
    }
    .singal-job-table tbody>tr>td .text-link {
        font-size: 12px;
    }
    .singal-job-table .media-sub-heading{
        font-size: 12px;
    }
    .singal-job-table .media .media-left {
        vertical-align: middle;
    }
    .singal-job-table tbody>tr>td .media-heading {
        margin-top: 0px;
        font-family: 'Poppins', sans-serif;
    font-weight: 500;
        font-size: 14px;
    }
    .singal-job-table tbody>tr>td .dp-image {
        margin-top: 0px;
        height: 30px!important;
        width: 30px!important;
    }
    .singal-job-table tbody>tr>td .blank-dp {
        line-height: 18px;
        height: 30px!important;
        width: 30px!important;
    }
    .singal-job-table tbody>tr>td .blank-dp span {
        font-size: 12px!important;
    }
    .d-common-padding {
        padding-right: 0px;
        padding-left: 0px;
    }
    .d-sidebar-box {
        margin-left: 0px;
    }
    .d-image-container img {
        width: 100%;
    }
    .d-title-heading {
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
    font-weight: 500;
        text-indent: -25px;
        margin-left: 35px;
        line-height: 1.4em;
    }
    .d-title-container {
        padding: 10px 10px 0px 10px;
    }
    .d-tab-container {
        padding: 10px 10px 0px 10px;
        margin-top: 10px;
    }
    .d-title-container .fa-long-arrow-left {
        margin-left: 20px;
        font-size: 16px;
    }
    .d-title-container .nav-tabs {
        margin-left: 0px;
    }
    .d-title-container .nav-tabs>li>a {
        font-size: 12px;
    }
    .d-title-container .nav-tabs>li {
        display: table-cell;
        /*width: 1%;*/
    }
    .cover-heading {
        margin-top: 0px;
        font-size: 14px;
    }
    .btn-strip-box {
        margin-right: 5px;
        margin-left: 5px;
    }
    .button-discuss {
        font-size: 12px;
        padding: 5px 10px;
    }
    .button-hire {
        font-size: 12px;
        padding: 5px 10px;
    }
    .btn-see-more {
        font-size: 12px;
        padding: 5px 10px;
    }
    .btn-strip-box .btn-link {
        font-size: 12px;
    }
    .text-muted-time {
        font-size: 12px;
    }
    .btn-container-strip {
        height: 50px;
        line-height: 45px;
        padding-right: 5px;
        padding-left: 5px;
    }
    .btn-strip-box {
        text-align: left;
    }
    .price-text {
        right: 10px;
        margin-top: 10px;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
    font-weight: 500;
        color: #ff337d;
    }
    #refer_and_earn .modal-body {
        padding: 10px 20px;
    }
    .d-modal-para {
        font-size: 16px;
        color: #000;
    }
    .button-outline {
        font-size: 12px;
        padding: 5px 10px;
    }
    .invite-link-box .btn-default {
        font-family: 'Poppins', sans-serif;
    font-weight: 500;
        font-size: 12px;
        border-radius: 0px;
        border: 1px solid #e8eef3;
    }
}
/*=====================End New dashboard ui===========================================================================*/

/*=======================New profile ui=================================*/

.profile-tutorial-container{
 background: #ffeeee;
 padding: 10px 15px;
 margin-bottom: 20px;
}
.profile-tutorial-heading{
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #2a64d3;
    margin-top: 10px;
    line-height: 1.4em;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}
.profile-tutorial-sub-heading{
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    line-height: 1.4em;
    color: #2a64d3;
}
.profile-tutorial-ul{
    margin-top: 20px;
}
.profile-tutorial-ul li{
    margin-bottom: 15px;
    color: #2a64d3;
    text-indent: -30px;
    margin-left: 30px;
}
.profile-tutorial-ul li img{
    margin-top: 0px;
    width:20px;
    margin-right: 10px;
    /*float: left;*/
}
.profile-tutorial-ul li>a{
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #2a64d3;
}
.expert-education-footer li+li{
    margin-left: 20px;
}
.expert-education-footer li{
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.expert-reviews-added-list{
    padding: 25px 15px;
    background: #f6f6f6;
    margin-bottom: 20px;
}
.added-review-para{
    font-size: 14px;
    color: #798194;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    margin-bottom: 0px;
}
.added-review-name{
    font-size: 16px;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    margin-bottom: 20px;
}
.gap-margin{
    margin-top: 15px;
}
.btn-add-proof{
    padding: 10px 30px;
    color: #fff;
    background: #6077f3;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
}
.btn-add-proof:hover,
.btn-add-proof:focus{
    color: #fff;
}
.add-work-footer{
    display: flex;
    align-items: center;
    margin-top: 30px;
}
.add-work-footer span{
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #000;
    margin-left: 15px;
    font-size: 16px;
}
.added-work-list{
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    /*justify-content: space-between;*/
}
.added-work-list li{
    width: 40%;
    font-size: 14px;
    color: #798194;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.added-work-list li+li{
    font-size: 14px;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    width: 60%;
}
.work-exp-input{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    /*margin-top: 20px;*/
}
.work-exp-input .input-field{
    width: 50%;
}
.text-suggetion-area{
    margin-left: 15px;
    padding-top: 10px;
}
.add-skill-ul{
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #e8eef3;
    width: 100%;
    margin-bottom: 20px;
}
.add-skill-ul.basic-detail-form .form-group{
    margin-bottom: 0px;
}
.add-skill-ul li{
    width: 30%;
    margin: 0px 10px;
}
.add-skill-ul .btn-add-more{
    margin-top: 0px;
    font-size: 18px;
}
.add-skill-input .table>tbody>tr>td{
    border: none;
}
.add-skill-input.table>thead>tr>th{
    border-bottom: none!important;
}
.added-skill-table .table>thead{
background: #e8eef3;
text-align: center;
}
.added-skill-table .table>thead>tr>th{
   font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    border: none;
    border-bottom: 1px solid #ddd;
    border-bottom-width: 1px;
    padding-left: 30px;
    padding-right: 30px;
}
.added-skill-table .table>tbody>tr>td{
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    border: none;
    border-bottom: 1px solid #ddd;
    vertical-align: middle; 
    padding-left: 30px;
    padding-right: 30px;
}
.edit-icon {
    display: flex; 
    align-items: center; 
}

.edit-icon i {
    margin-right: 5px;
}
.user-order-p-panel{
    box-shadow: 0 2px 5px 1px rgba(188, 185, 185, 0.5);
    border: 1px solid #d6d6d6;
    background-color: #fff;
    padding: 10px 25px;
    margin-bottom: 25px;
}
.user-order-p-panel .crated-gig-empty-place{
    margin-top: 15px;
}
.user-order-p-panel .crated-gig-empty-place li{
    margin-top: 10px;
    color: #3f55d2;
}
.order-posted-date{
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #6B7788;
}
.order-posted-heading{
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}
.order-posted-sub-heading{
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #3f55d2;
}
.order-posted-sub-heading-black{
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000;
}
.posted-success{
    margin-left: 10px;
    background: #C1EBE3;
    border-radius: 4px;
    padding: 8px 12px;
    color: #258A66;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
    position: absolute;
    right: 20px;
}
.inline-bubble{
    background: #E9F5EF;
    border-radius: 4px;
    padding: 8px 12px;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
}
.schedule-time-bubble{
    background: #F4F6FD;
    padding:30px 20px;
    border-radius: 6px;
}
.label-dark{
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #57636F;
}
.schedule-time{
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000;
}
.schedule-time .fa-calendar{
    font-size: 24px;
    color: #3864D3;
}
.feature-ul li{
    margin-bottom: 15px;
}
.feature-ul li>a{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #2C5FE1;
}
.feature-ul li>a{
    display: flex;
    gap: 10px;
    align-items: center;
}
.join-session-head{
    display: flex;
    align-items: center;
    gap: 40px;
}
.join-session-head li{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #000;
}
.join-session-head li .btn-link{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #2C5FE1;
}
.join-session-head li span{
    font-weight: 500;
    font-size: 14px;
    color: #71758A;
}
.pick-time-slot{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    margin: 0 auto;
}
.pick-time-slot li{
    /*width: 50%;*/
    margin-bottom: 15px;
}
.pick-time-slot li>a{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #64728A;
    display: inline-block;
    padding: 6px 12px;
    border-radius: 4px;
    border: 1.2px solid #ff337d;
    text-align: center;
}
.pick-time-slot li.active>a{
    background: #ff337d;
    color: #fff;
    padding: 6px 12px;
    border-radius: 4px;
}
.pick-time-slot li.active>a:hover,
.pick-time-slot li.active>a:focus{
    color: #fff
}
.draggble-box{
    position: absolute;
    z-index: 1;
    cursor: move;
    background: #FFFFFF;
    border: 1px solid #E6E6E6;
    box-shadow: 0px 4px 10px rgba(197, 197, 197, 0.25);
    border-radius: 6px;
    padding: 10px;
    bottom: 40px;
    right: 40px;
    min-width: 400px;
    min-height: 200px;

}
.video-holder-container{
    display: flex;
    gap: 10px;
    justify-content: space-between;
}
.video-holder{
   background: #C4C4C4;
    width: 50%;
    height: 150px;

}
.video-holder-footer{
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    align-items: center;
    gap: 10px;
    margin-bottom: 0;
}
.video-holder-footer li{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #000;
}
.video-holder-footer li .blank-dp{
    height: 40px!important;
    width: 40px!important;
    line-height: 25px;
}
.video-holder-footer li .blank-dp span{
    font-size: 14px!important;
}
.video-holder-footer li .btn-danger{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.basic-detail-form.interviewer-feedback-q{
    margin-top: 0;
}
.basic-detail-form.interviewer-feedback-q .form-group{
    margin-bottom: 5px;
}
.interviewer-feedback-q label{
    display: flex;
    align-items: center;
    gap: 10px;
}
.interviewer-feedback-q li>span{
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: #000;
    padding: 10px 0;
}
.complexity-bubble{
    padding: 6px 15px;
    background: #F8F3E2;
    border-radius: 4px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #000;
}
/*=======================New profile ui=================================*/
.create-gig-title{
    box-sizing: border-box;
    box-shadow: 0 2px 5px 1px rgba(174, 170, 170, 0.5);
    background-color: #ffffff;
    margin-bottom: 15px;
    padding:10px 25px;
    /*display: flex;*/
    /*align-items: center;*/
}
.d-update-gig-price{
    box-sizing: border-box;
    box-shadow: 0 2px 5px 1px rgba(174, 170, 170, 0.5);
    background-color: #ffffff;
    /*min-height: 300px;*/
    margin-bottom: 15px;
    padding:15px 25px;
    padding-bottom: 30px;

}
.d-update-gig-price textarea.form-control{
    box-shadow: none;
}
.d-update-profile-body .panel-text,
.d-update-gig-price .panel-text{
    margin-bottom: 5px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.d-update-gig-price .inlineRadio{
    margin-top: 0px;
}
.gig-price-table tbody>tr>td{
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.gig-price-table thead>tr>th{
    font-size: 18px;
    vertical-align:top;
}
.gig-price-table .inlineRadio{
    margin-top: 0px;
    display: flex;
}
.gig-price-table .inlineRadio label{
    margin-right: 5px!important;
}
.gig-process-tab{
    display: flex;
    align-items: center;
    /*justify-content: space-around;*/
    margin-bottom: 0px;
    padding: 10px 0px;
}
.gig-process-tab li {
    position: relative;
    width: 25%;
    background: #fff;
    z-index: 1;
}
.create-c-gig{
    margin-top: 25px;
}
.create-c-gig .form-group{
    margin-bottom: 25px;
}
.create-c-gig .heading-onboarding{
    font-size: 22px;
}
.gig-process-tab li>a{
    color: #9395a7;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.gig-process-tab li:after{
    content: " ";
    width: 100%;
    height: 1px;
    background: #c1c1c1;
    position: absolute;
    top: 12px;
    margin-left: 15px;
    margin-right: 5px;
}
.gig-process-tab li:nth-last-child(1):after{
    display: none;
}
.gig-process-tab li+li{
    margin-left: 20px;
    padding-left: 15px;
}
.gig-process-tab li.active>a{
    color:#2c3af4;
}
.gig-process-tab li.active.complete{
    color:#1dd59e;
}
/*.gig-process-tab li.active.complete:after{*/
    /*background: #1dd59e;*/
/*}*/

/*=====================New Profile ui===========================================================================*/
.d-update-profile-head{
    margin-top: 35px;
    margin-bottom: -15px;
    box-sizing: border-box;
    box-shadow: 0 2px 5px 1px rgba(174, 170, 170, 0.5);
    background-color: #ffffff;
    padding-top: 10px;
}
.d-update-profile-head .d-update-flex-body{
    display: flex;
}
.d-update-flex-box{
    flex-grow: 3;
}
.video-link{
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    margin-top: -15px;
    margin-bottom: 30px;
}
.video-link a{
    color: #1c2de3;
}
.d-update-profile-head .nav-tabs.nav-justified>li {
    display: table-cell;
    width: 1%;
}
.d-update-profile{
    box-sizing: border-box;
    /*box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0212249);*/
    box-shadow: 0 1px 9px #e8e8e8;
    background-color: #ffffff;
    min-height: 300px;
    margin-bottom: 20px;
    border-radius: 6px;
}

.d-update-profile-body{
    padding: 10px 25px;
    border-bottom: 1px solid #e8eef3;
}
.modal .d-update-profile{
    box-shadow: none;
}
.modal .d-update-profile-body{
    padding: 0px 0px;
    border-bottom: 0px solid #e8eef3;
}
.d-update-profile-body .message-item{
   margin-bottom: 10px;
}
.timeline-ui{
    display: flex;
    justify-content: center;
    align-items: center;
}
.timeline-ui .delivery-status{
   margin-top: 20px;
}
.d-update-profile-body.timeline-ui .message-item{
    margin-bottom: 30px;
}
.timeline-text{
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 18px;
    margin-top: 5px;
    margin-left: 10px;
}
.timeline-text a{
    color: #9197B3;
}
.timeline-text.active a{
    color: #ff337d;
}
.multi-skill-select{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;
    margin-top: 20px;
}
.multi-skill-select li{
    margin-top: 10px;
    margin-bottom: 10px;
}
.multi-skill-select li.active .skill-bubble{
    background: #ff337d;
    color: #fff;
}
.skill-bubble{
    padding: 10px 15px;
    background: #E7E7E7;
    color: #686868;
    border-radius: 25px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.skill-bubble:hover,
.skill-bubble:focus{
    color: #fff;
    background: #ff337d;
}

.d-profile-heading{
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color:#000;
    line-height: 1.3em;
}
.form-group .d-profile-heading{
    font-size: 16px;
}
.d-profile-sub-heading{
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color:#000;
    margin-bottom: 15px;
}
.d-para-dark{
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #57636F;
    margin-bottom: 15px;
}
.update-list li img{
    width:20px;
    margin-left: 0px;
    margin-right: 5px;
    vertical-align: middle;
}
.update-list li{
  margin-bottom: 5px;
}
.update-list.nav-tabs{
   border-bottom: none;
}
.update-list li>a{
   color:#000;
   font-size: 16px;
   font-family: 'Poppins', sans-serif;
    font-weight: 500;
   padding: 10px 0px!important;
}
.update-list.nav-tabs>li>a {
     margin-right: 0px;
     line-height: normal;
     border: 0px solid transparent;
     border-radius: 0px;
}
.update-list.nav-tabs li>a:hover{
    color:#252ee7!important;
    background-color: transparent!important;
}
.update-list.nav-tabs li.active>a{
   color: #252ee7!important;
}
.d-title-container .update-list.nav-tabs>li.active>a, .update-list.nav-tabs>li.active>a:focus, .update-list.nav-tabs>li.active>a:hover{
    border-bottom: none!important;
}
.d-update-profile .btn-save-review,
.d-update-profile .btn-save-profile{
  padding: 6px 40px;
}
.picky-date-time-calendar__body-container.m{
    height: 270px!important;
}
.flag-box{
    margin-bottom: 20px;
}
.flag-text{
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.flag-icon{
    font-size: 30px;
    float: left;
    margin-right: 15px;
}
.yellow-flag{
    padding:5px 10px;
    border: 2px solid #ecb60e;
    color: #b98c00;
    background-color: #fffbf1;
}
.orange-flag{
    padding:5px 10px;
    border: 2px solid #ec600e;
    color: #ec600e;
    background-color: #fff4eb;
}
.red-flag{
    padding:5px 10px;
    border: 2px solid #e12557;
    color: #e12556;
    background-color: #ffecef;
}
.black-flag{
    padding:5px 10px;
    border: 2px solid #000;
    color: #000;
    background-color: #fff;
}
.create-gig-tips-ul li{
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    margin-bottom: 25px;
    margin-top: 15px;
    vertical-align: center;
}
.create-gig-tips-ul li>img{
    float: left;
    width: 25px;
    margin-right: 5px;
}
.crated-gig-empty-place-heading{
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000;
    line-height: 1.4em;
    margin-top: 10px;
}
.crated-gig-empty-place li::before {
    content: "✓";
    color: rgb(57, 206, 130);
    font-size: 18px;
    margin: 5px;
}
.crated-gig-empty-place li{
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000;
    margin-top: 20px;
    margin-bottom: 15px;
    text-indent: -25px;
    margin-left: 25px;
}
.profile-submit{
    position: sticky;
    top: 80px;
    z-index: 100;
    padding:0 20px;
    margin: 10px;
    background: #e0efee;
    display: flex;
    justify-content: flex-end;
    box-shadow: none;
    border: 2px solid #41bfef;
}
.gig-list-ui{
    max-height: 350px;
    overflow: hidden;
    overflow-y: auto;
    padding: 10px 15px;
}
.c-gig-suggestion{
    border-bottom: 1px solid #e8eef3;
    margin-bottom: 15px;
}
.c-gig-suggestion .dp-image{
    margin-top: 0px;
    height: 50px!important;
    width: 50px!important;
}
.c-gig-suggestion .blank-dp{
    height: 50px!important;
    width: 50px!important;
    line-height: 35px!important;
}
.gig-suggestion-list{
    display: flex;
    justify-content: space-between;
    gap: 20px;
}
.about-gig{
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #6B6B6B;
}
.g-created-by{
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
}
.gig-suggestion-stats{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 5px;
}
.span-text-rating{
    color: #2D4ED5;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
}
.gig-suggestion-stats .span-text-rating{
    font-size: 14px;
}
.span-text-rating .fa-star{
    color: gold;
}
.span-text-price{
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #ff337d;
}
@media only screen and (max-device-width: 767px) {
    #c_container{
       margin-top: 0px!important;
    }

    .create-c-gig .heading-onboarding{
        font-size: 18px;
    }
}
/*=====================End New Profile ui===========================================================================*/

.dropdown-icon {
    position: absolute;
    top: 15px;
    right: -10px;
}
.dropdown-icon .fa{
    font-size: 16px;
}
.alert-custum {
    background-color: #B7BAEA;
    border-color: #B7BAEA;
    color: #63bebf;
}
hr.message-inner-separator {
    clear: both;
    margin-top: 10px;
    margin-bottom: 13px;
    border: 0;
    height: 1px;
    background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
    background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
    background-image: -ms-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
    background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
}
.text-success {
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #380258;
}
.consultant-panel {
    margin-bottom: 20px;
}
.consultant-name {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 0px;
}
.consultant-description {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #000;
    font-size: 14px;
}
.cunsultant-li li {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #000;
    font-size: 16px;
    margin-left: 5%;
    margin-right: 40px;
    margin-top: 10px;
    margin-bottom: 0px;
}
.cunsultant-li li span {
    margin-left: 0px;
    margin-right: 20px;
}
.consultant-dp {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    object-fit: cover;
    object-position: 50% 70%;
}
.catagory-list li {
    margin-top: 20px;
    margin-bottom: 10px;
    border-bottom: 1px solid #D7D9E6;
    padding-bottom: 5px;
}
.gig-category-sidebar{
    background: #f6f6f6;
    padding: 15px 10px;
}
.gig-category-sidebar .catagory-list li {
    border-bottom: 0px solid #D7D9E6;
}
.invoice-generate-tab{
    display: flex;
    margin-bottom: 0px;
    flex-wrap: nowrap;
    overflow-x: auto;
}
.invoice-generate-tab li+li{
   margin-left: 3%;
}
.btn-invoice-tab{
    padding: 8px 20px;
    border: 1.5px solid #0000FF;
    font-size: 16px;
    color: #0000FF;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.invoice-generate-tab li.active>.btn-invoice-tab{
    background: #0000FF;
    color: #fff;
}
.btn-invoice-tab:focus,
.btn-invoice-tab:hover{
   background: #0000FF;
    color: #fff;
}
.consultant-panel .blank-dp {
    height: 80px!important;
    width: 80px!important;
    padding-top: 22px !important;
}
.consultant-panel .blank-dp span {
    font-size: 24px!important;
}
.consultation-border-left {
    border-left: 1px solid #e8eef3;
    text-align: center;
}
.global-dp-container {
    width: 100%;
}
.select-inline-list li {
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 3%;
}
.select-inline-list li > a {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000;
    font-size: 18px;
}
.select-inline-list li > a small {
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #000;
}
.catagory-list li > a {
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000;
    margin-bottom: 20px;
}
.catagory-list li > a:hover,
.catagory-list li > a:focus{
    color: #ff337d;
}
.catagory-list li.active > a {
    color: #ff337d;
}
.enter-fee-table tbody > tr > td {
    border: none;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #000;
}
.ui-tooltip {
    position: relative;
    display: inline-block;
}
.tooltip-content {
    display: none;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.9);
    border-radius: 8px;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
    padding: 8px 10px;
    min-width: 220px;
    margin-top: 10px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}
.ui-tooltip .tooltiptext {
    visibility: hidden;
    width: auto;
    word-break: break-word;
    background-color: black;
    color: #fff;
    font-size: 14px;
    text-align: center;
    border-radius: 6px;
    padding: 10px 10px;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    margin-left: -40px;
    margin-top: 10px;
}

.ui-tooltip:hover .tooltiptext {
    visibility: visible;
}



/*.tooltip-content::before {*/

/*content: "";*/

/*border-bottom: 15px solid #000;*/

/*border-left: 10px solid transparent;*/

/*border-right: 10px solid transparent;*/

/*position: absolute;*/

/*top: -10px;*/

/*left: 30px;*/

/*}*/

.ui-tooltip:hover .tooltip-content {
    display: block;
}
.scope-tips {
    position: relative;
    display: inline-block;
    color: #2d3feb;
    cursor: pointer;
}
.scope-content {
    display: none;
    position: absolute;
    background-color: #fff;
    border-radius: 6px;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
    padding: 8px 10px;
    min-width: 300px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .1);
    z-index: 1;
}
.scope-tips:hover .scope-content {
    display: block;
}
.scope-content li {
    margin-bottom: 10px;
}
.price-info {
    position: relative;
    display: inline-block;
    color: #2d3feb;
    cursor: pointer;
}
.price-info-content {
    display: none;
    position: absolute;
    background-color: #fff;
    border-radius: 6px;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
    padding: 8px 10px;
    min-width: 200px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .1);
    z-index: 1;
}
.price-info:hover .price-info-content {
    display: block;
}
#client_review .form-group {
    margin-bottom: 30px;
}
#client_review textarea {
    margin-top: 0px;
    max-height: 100px;
    overflow: hidden;
    overflow-y: auto;
}
#brief_bio textarea {
    height: 250px;
    overflow: hidden;
    overflow-y: auto;
}
#brief_bio .form-control {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 16px;
}
.react-confirm-alert-overlay {
    background-color: rgba(0, 0, 0, 0.5) !important;
    z-index: 9999 !important;
}
.react-confirm-alert-body h1 {
    font-size: 24px !important;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.common-top-bottom-margin {
    margin-bottom: 0px;
    margin-top: 0px;
}
.common-top-bottom-margin .media-left img {
    margin-top: 20px;
    height: auto;
}
.common-top-bottom-margin .scope-sub-heading,
.common-top-bottom-margin .xs-para {
    margin-left: 15px;
    margin-bottom: 0px;
}
.common-top-bottom-margin .scope-sub-heading {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.4em;
}
.onboarding-container {
    margin-top: 20px;
    background-color: #e9f8f3;
    padding: 10px 15px;
}
.ui-sign-heading {
    font-size: 22px;
    margin-top: 0px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000;
}
.ui-sign-heading .btn-link {
    margin-top: 5px;
    color: #2d3feb;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 16px;
}
.modal-body .ui-sign-heading .btn-link {
    margin-top: 0px;
    color: #2d3feb;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 16px;
}
.modal-body .log-in-button {
    padding: 8px 25px;
    font-size: 16px;
}
.modal-body .form-group .btn-lg {
    margin-top: 10px!important;
    padding: 6px 15px;
    font-size: 16px;
}
.signup-section{
    display: flex;
    padding: 0 0;
    margin-bottom: 0px;
    min-height: 100vh;
}
.form-container{
    background: #fff;
    width: 70%;
    padding: 25px 40px;
}
.add-container{
    width: 30%;
    background: #F9FAFF;
}
.ui-sign-heading small {
    font-size: 16px;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.ui-onboard-heading {
    font-size: 28px;
    margin-top: 0px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000;
}
.general-onboarding .signup-form {
    margin-top: 0px;
}
.general-onboarding .signup-form .form-group {
    margin-bottom: 15px;
}
.hire-designer-button-full-pay {
    padding: 10px 15px;
    background: #17C897;
    color: #fff;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    width: 100%;
}
.hire-designer-button-full-pay:hover,
.hire-designer-button-full-pay:focus {
    background: #17C897;
    color: #fff;
}
.btn-make-payment {
    background-color: #e73392;
    color: #fff;
    font-size: 16px;
    padding: 10px 15px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    width: 100%;
}
.general-onboarding .signup-form .form-group select {
    color: #979797 !important;
}
.btn-make-payment:hover,
.btn-make-payment:focus {
    color: #fff;
}
.selected-professional-card {
    position: relative;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .1);
    transition: box-shadow 0.3s ease 0s;
    min-height: 260px;
    padding: 15px 25px;
}
.selected-professional-card .side-card-heading {
    text-align: left;
    font-size: 18px;
}
.selected-professional-card .dp-image {
    margin-top: 0px;
    height: 65px;
    width: 65px;
}
.selected-professional-card .profile-name {
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 18px;
}
.selected-professional-card .profile-description {
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 16px;
}
.selected-professional-card .text-bold {
    margin-bottom: 5px;
    margin-top: 10px;
    font-size: 18px;
}
.selected-professional-card .blank-dp {
    padding-top: 12px !important;
}
input,
select {
    color: #000 !important;
}
.react-datepicker__input-container > input {
    border: 1px solid #e8eef3;
    padding: 8px 10px;
    border-radius: 4px;
}
.affiliate-flex-form .react-datepicker__input-container > input{
    width: 300px;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box{
    width: 100% !important;
}
.react-datepicker__time-container{
    width: 90px!important;
}
.react-datepicker__current-month, .react-datepicker-time__header{
    font-size: 1.2em!important;
}
.alert-info {
    background-color: #436cf7;
    color: #fff;
    border: 1px solid #436cf7;
}
.btn-info {
    background-color: #000;
    border: 1px solid #000;
    outline: 0px;
}
.btn-info:hover,
.btn-info:focus {
    background-color: #000;
    border: 1px solid #000;
}
.alert-info .btn-info {
    background-color: #ff337d;
    border: 1px solid #ff337d;
    padding: 3px 10px;
}
.alert-info .btn-info:hover,
.alert-info .btn-info:focus {
    background-color: #ff337d;
    color: #fff;
    border: 1px solid #ff337d;
}
.alert-info .close:hover,
.alert-info .close:focus {
    color: #fff;
    opacity: 1;
}
.dialog-hide {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0;
}
.dialog-show {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: block;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0;
}
#snackbar {
    visibility: hidden;
    min-width: 250px;
    margin-left: -125px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    padding: 16px;
    position: fixed;
    z-index: 1;
    left: 50%;
    bottom: 30px;
    font-size: 17px;
}
#snackbar.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

.custom-snackbar {
    visibility: hidden;
    min-width: 250px;
    margin-left: -125px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    padding: 16px;
    position: fixed;
    z-index: 100;
    left: 50%;
    bottom: 30px;
    font-size: 17px;
}
.show-snackbar {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
}
@-webkit-keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }
    to {
        bottom: 30px;
        opacity: 1;
    }
}
@keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }
    to {
        bottom: 30px;
        opacity: 1;
    }
}
@-webkit-keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 1;
    }
    to {
        bottom: 0;
        opacity: 0;
    }
}
@keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 1;
    }
    to {
        bottom: 0;
        opacity: 0;
    }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: none;
    transition: background-color 5000s ease-in-out 0s;
}
.rc-pagination-item {
    border: none !important;
}
.rc-pagination > li {
    border: none;
}
.rc-pagination-item-active {
    background-color: #ff337d!important;
    color: #fff!important;
}
.rc-pagination-item-active a {
    color: #fff !important;
}
.rc-pagination-item.rc-pagination-item-active:hover a {
    color: #fff!important;
}
.rc-pagination-item:hover a {
    color: #ff337d!important;
}
.custom-icon-next {
    color: #000 !important;
}
.custom-icon-prev {
    color: #000 !important;
}
.MuiSvgIcon-root-83 {
    margin-top: -5px;
    font-size: 16px !important;
}
.css-es53b3-multiValue {
    background-color: #ff337d!important;
}
.css-vj8t7z {
    border: none !important;
}
.css-12jo7m5 {
    color: #fff !important;
}
.css-1hwfws3 {
    padding: 2px 0px !important;
}
.css-1alnv5e {
    color: #fff!important;
}
.header {
    /*padding-right: 20px;*/
    margin-top: 0px !important;
}
.page-header-border {
    border-bottom: 1px solid #e8eef3;
}
.login-page-header {
    padding-top: 15px;
    padding-bottom: 10px;
    padding-left: 0px;
    padding-right: 0px;
}
.login-page-header li > span {
    color: #7d8082;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 16px;
}
.login-header {
    font-size: 40px;
}
.inside-logo {
    width: 120px;
    padding-top: 15px;
    padding-right: 15px;
}
.login-page-header .glyphicon-user {
    font-size: 24px;
    color: #000;
}
.ui-widget.ui-widget-content {
    z-index: 10 !important;
}
.login-page-header .btn-login {
    box-shadow: none;
    border-radius: 0px;
}
.btn-login {
    background-color: #000;
    color: #fff;
    padding: 8px 20px;
    border-radius: 3px;
    font-size: 14px;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, .1);
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
}
.btn-login:hover,
.btn-login:focus {
    color: #fff;
    background-color: #ff337d;
    outline: 0px;
}
.btn-google-plus {
    background-color: #fff;
    color: #000;
    padding: 12px 15px;
    border: 1px solid #e8eef3;
    border-radius: 3px;
    font-size: 18px;
    margin-bottom: 20px;
    background: url('../images/google.png') no-repeat;
    background-position: 5% 55%;
    background-size: 25px;
    /*box-shadow: 0px 3px 3px rgba(0, 0, 0, .1);*/
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
}
.btn-pay {
    background-color: #ff337d;
    padding: 6px 20px;
    color: #fff !important;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.btn-pay:hover,
.btn-pay:focus {
    background-color: #ff337d;
    color: #fff;
}
.btn-sent {
    background-color: #213FD4;
    color: #fff;
    border: 1px solid #213FD4;
    padding: 5px 15px;
    border-radius: 3px;
    font-size: 14px;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, .1);
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
}
.btn-sent:hover,
.btn-sent:focus {
    color: #fff;
    background-color: #213FD4;
    outline: 0px;
}
optgroup {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.btn-submit {
    background-color: #ff337d;
    color: #fff;
    padding: 8px 35px;
    border-radius: 3px;
    font-size: 16px;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, .1);
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
}
.btn-submit:hover,
.btn-submit:focus {
    color: #fff;
    outline: 0px;
}
.custom-search-box {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
}
.custom-search-box-item {
    max-height: 600px;
    overflow-y: auto;
    background-color: #fff;
    width: 100%;
    z-index: 1000;
    position: absolute;
    box-shadow: 0 2px 5px 1px rgba(188, 185, 185, 0.5);
}
.custom-search-box-item .list-group-item a {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #000;
}
.searchbar {
    margin-bottom: auto;
    margin-top: 5px;
    height: 50px;
    background-color: #353b48;
    border-radius: 30px;
    padding: 5px;
}
.search_input {
    color: #fff!important;
    padding: 0 10px;
    border: 0;
    outline: 0;
    background: none;
    width: 90%;
    line-height: 40px;
    transition: width 0.4s linear;
}
/*.searchbar:hover > .search_input{*/

/*    padding: 0 10px;*/

/*    width: 250px;*/

/*    color:#fff;*/

/*    transition: width 0.4s linear;*/

/*}*/
.chat_sidebar .search_input{
   width: 80%;
}
.searchbar:hover > .search_icon {
    background: white;
    color: #e74c3c;
}
.display-milestone_table thead>tr>th {
    border: none;
}
.display-milestone_table tbody>tr>td {
    border: none;
}
.search_icon {
    height: 40px;
    width: 40px;
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
}
.btn-add {
    background-color: #000;
    color: #fff;
    padding: 6px 15px;
    border-radius: 4px;
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
}
.btn-add:hover,
.btn-add:focus {
    color: #fff;
    outline: 0px;
}
.btn-save {
    cursor: pointer;
    background-color: #ff337d;
    color: #fff;
    padding: 8px 25px;
    border-radius: 3px;
    font-size: 16px;
    /*box-shadow: 0px 3px 3px rgba(0, 0, 0, .1);*/
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
}
.btn-save:hover,
.btn-save:focus {
    color: #fff;
}
/* =========================Header section ======================================= */

/* =========================Body section ======================================= */

section {
    margin-bottom: 50px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
b {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.doc-name {
    color: #ff337d;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}
.expert-kyc{
    padding-inline-start: 10px;
}
.expert-kyc li{
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #000;
    padding-top: 10px;
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e8eef3;
}
.expert-kyc li>div{
    flex-grow: 3;
}
.expert-kyc li>div+div{
    flex-grow: 1;
    text-align: right;
    padding-right: 25px;
}
.modal .expert-kyc li>div+div{
    padding-right: 10px;
}
.expert-kyc li img{
    width: 40px;
    margin-right: 15px;
}
.expert-kyc li small{
    color: #000;
}
.text-prompt {
    color: #3C48FF;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.error {
    color: #ef0934;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.msg {
    background: #fefefe;
    color: #000;
    font-weight: bold;
    font-size: small;
    padding: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    padding-left: 16px;
    border-top: solid 3px #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
    -webkit-box-shadow: 0 10px 10px -5px rgba(0, 0, 0, .08);
    -moz-box-shadow: 0 10px 10px -5px rgba(0, 0, 0, .08);
    box-shadow: 0 10px 10px -5px rgba(0, 0, 0, .08);
}
.msg-success .glyphicon-ok {
    margin-right: 5px;
}
.msg-danger .glyphicon-remove {
    margin-right: 5px;
}
.msg-danger .glyphicon-remove {
    cursor: pointer;
}
.msg-danger {
    border-color: red;
}
.msg-success {
    border-color: green;
}
.msg-success-text {
    color: green;
}
.msg-danger-text {
    color: red;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
    background-color: transparent !important;
}
.text-muted {
    color: #7d8082;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

.small {
    font-size: 0.80em !important;
}

.text-muted label {
    color: #000;
    font-weight: 300;
}
.otp-box {
    text-align: center;
    margin-top: 20px;
}
.otp-box .btn-link {
    color: #3C48FF !important;
   font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.otp-box .btn-link:hover,
.otp-box .btn-link:focus{
    text-decoration: underline;
}
.otp-box .form-control {
    border: 1px solid #e8eef3 !important;
    padding: 6px 13px !important;
}
.xs-small-text {
    position: absolute;
    top: 30px;
    right: -50px;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.fa-exclamation-circle {
    color: #000;
    font-size: 20px;
}
.modal li .btn-bs-file img {
    width: 35px;
    margin-right: 25px;
}
.form-group .btn-bs-file {
    color: #585858;
    font-size: 18px;
    font-weight: 300;
    margin-top: 20px;
    margin-bottom: 0px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.form-group .btn-link {
    position: absolute;
    right: 0;
   font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #ff337d !important;
    top: 15px;
}
.form-group .btn-link.remove {
    color: #2f4dd3!important;
    margin-right: 50px;
}
.work-experience .form-group .form-control {
    border: 1px solid #e8eef3;
    box-shadow: none;
    border-radius: 0px;
}
.work-experience .form-group .btn-link{
  top: 5px;
  right: 10px;
}

.work-experience .table.table-responsive>tbody>tr>td {
    border: none;
    margin-left: 0px;
    padding: 8px;
    vertical-align: middle;
}
#Payment_detail .form-group {
    margin-bottom: 30px;
}
.nav-tabs .margin-left {
    margin-left: 5%;
}
#pay .btn-primary {
    background-color: #213FD4;
    border: 1px solid #213FD4;
}
#pay .btn-danger {
    background-color: #ff337d;
    border: 1px solid #ff337d;
    margin-right: 20px;
}
.work-experience .table > thead > tr > th {
    border-bottom: 1px solid #e8eef3;
    padding-left: 0px;
    padding-bottom: 0px;
    padding-right: 0px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #979797;
}
.work-experience .table > tbody > tr > td {
    border-bottom: 1px solid #e8eef3;
    padding-left: 0px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
    padding-right: 0px;
    color: #000;
}
.work-experience label {
    font-weight: 300;
    font-size: 16px;
    color: #000;
    margin-bottom: 0px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
#education .panel-text {
    margin-bottom: 0px;
}
.text-white {
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.text-dark {
    color: #000;
    line-height: 1.3em;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
form .text-dark {
    font-size: 16px;
}
.text-dark .glyphicon-user {
    font-size: 24px;
}
.heading-md {
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.text-number {
    color: #ff337d;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.signup-form {
    margin-top: 40px;
}
.signup-form.new-professional-signup{
    margin-top: 20px;
    padding: 20px 10px;
    background-color: #fff2f2;
}
.verify-otp-box{
    margin-top: 20px;
    padding: 20px 10px;
    background-color: #fff2f2;
    /*min-height: 200px;*/
}
.btn-verify-otp{
    background-color: #000;
    font-size: 16px;
    padding: 8px 25px;
    color:#fff;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.btn-resend-otp{
    background-color: #2b1de6;
    font-size: 16px;
    padding: 8px 25px;
    color:#fff;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.btn-resend-otp:hover,
.btn-resend-otp:focus{
    color: #fff;
}
.btn-verify-otp:hover,
.btn-verify-otp:focus{
    color: #fff;
}
.btn-verify-otp+.btn-resend-otp{
    margin-left: 10px;
}
.btn-new-signup{
    background-color: #000;
    font-size: 18px;
    padding: 10px 60px;
    color:#fff;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.btn-new-signup:hover,
.btn-new-signup:focus{
    background-color: #000;
    color:#fff;
}
.signup-form .blank-dp {
    padding-top: 12px !important;
}
.signup-form .form-group {
    margin-bottom: 20px;
}
.signup-form input {
    color: #000;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.description-box p {
    margin-top: 15px;
    margin-bottom: 0px;
    font-size: 16px;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.btn-nxt {
    background-color: #ff337d;
    padding: 10px 30px;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 18px;
}
.btn-prev {
    background-color: #2b47fb;
    padding: 10px 30px;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 18px;
}
.btn-nxt:hover,
.btn-nxt:focus {
    color: #fff;
}
.btn-prev:hover,
.btn-prev:focus {
    color: #fff;
}
.basic-detail-form{
    margin-top: 20px;
}
.basic-detail-form .form-group {
    margin-bottom: 25px;
}
.basic-detail-form.onboard-form .form-group{
    margin-bottom: 30px;
}
.basic-detail-form .form-control{
    box-shadow: none;
     font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.basic-detail-form .form-group label{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #25272E;
    font-size: 16px;
}
.basic-detail-form.onboard-form .form-group label{
     color: #444444;
}
.basic-detail-form.onboard-form .form-group{
    margin-bottom: 45px;
}
.basic-detail-form.onboard-form .checkbox{
    margin-bottom: 30px;
    margin-left: -10px;
}
.basic-detail-form.onboard-form .checkbox label{
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #000;
    font-size: 16px;
}
.basic-detail-form.onboard-form .listedRadio label .MuiFormControlLabel-label{
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #000;
}
.MuiRadio-root{
    color: #c7c7c7
}
.react-select-box>div:nth-of-type(1){
    border: 1px solid #ccc!important;
    padding: 3px 10px;
}
.basic-detail-form .form-group .btn-link{
 right: 10px;
  top: 30px;
}
.basic-detail-form .form-group img.btn-link{
    right: 10px;
    top: 40px;
}
.select-language-list{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}
.select-language-list li{
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    margin-bottom: 15px;
    color: #000;
}
.select-language-list li .inlineRadio{
    margin-top: 0px;
}
/*.select-language-list li:nth-last-of-type(2){*/
    /*margin-top: 25px;*/
/*}*/
.skills-category{
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    gap: 20px;
    /*justify-content: space-between;*/
    flex-wrap: wrap;
}
.skills-category li{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 23%;
    padding: 15px 10px;
    cursor: pointer;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(197, 197, 197, 0.25);
    border-radius: 6px;
    border: 1px solid #e0efee;
    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
    text-align: center;
}
.skills-category.shortlisted-interviewer li{
    background-color: #FBF6EF;
    border: none;
}
.icon-container{
    margin-bottom: 10px;
    height: 70px;
}
.skill-icon{
    width: 60px;
    height: 60px;
    object-fit: contain;
}
.skill-name{
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000;
}
.total-count{
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #7D839B;
}
.skills-category .btn-link{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #2b47fb;
}
.answer-container{
    border-radius: 6px;
    border: 1px solid #e0efee;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(197, 197, 197, 0.25);
    padding: 20px 30px;
    min-height: 400px;
}
.test-completed-container{
    border-radius: 6px;
    border: 1px solid #e0efee;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(197, 197, 197, 0.25);
    padding: 20px 30px;
    min-height: 400px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 20%;
}
.answer-ul{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.answer-label{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #000;
}
.timer-text{
    padding:6px 10px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 20px;
    color: #974A4F;
    background: #F8E7E7;
    margin: 5px 0px;
    border-radius: 4px;
    text-align: center;
}
.not-test-pass{
    padding:6px 10px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #974A4F;
    background: #F8E7E7;
    margin: 5px 0px;
    border-radius: 4px;
    text-align: center;
}
.test-passed{
    padding:10px 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #218460;
    background: #DAF2EC;
    margin: 25px 0px;
    border-radius: 4px;
    text-align: center;
}
.test-completed-container .not-test-pass{
    padding:10px 20px;
}
.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.btn-add-more{
    padding: 8px 25px;
    background: #6077f3;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
    margin-top: 10px;
}
.btn-add-more:hover,
.btn-add-more:focus{
    color: #fff;
}
.heading-onboarding {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 1.4em;
    color: #000;
}
.sub-heading-onboarding {
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #ed278c;
}
.description-box textarea {
    width: 100%;
    height: 100px;
    border: 1px solid #e8eef3;
    /* resize: none; */
    font-size: 16px;
    padding: 5px;
    overflow: hidden;
    overflow-y: auto;
}
.description-tips-p {
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.description-tips-list li {
    margin-top: 10px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #8c8c8c;
}
#count_message {
    position: absolute;
    right: 15px;
    margin-top: -20px
}
.panel-body h5 a {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.panel-body h5 a:hover {
    color: #ff337d;
}
#questions .form-control {
    box-shadow: none;
    border-radius: 0px;
    border: 1px solid #e8eef3;
    padding-left: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.inlineRadio {
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -o-flex-direction: row;
    flex-direction: row !important;
    margin-top: -20px;
}
.inlineRadio label {
    margin-left: 0px !important;
    margin-right: 70px !important;
}
.inlineRadio label span {
    font-size: 1em !important;
    line-height: 1.4em;
}
.listedRadio label span {
    font-size: 1em !important;
    line-height: 1.4em;
}
#questions select {
    border: 1px solid red;
    border-radius: 0px;
    border: 1px solid #e8eef3;
    width: 100%;
    padding-top: 0px !important;
    background-position: 98% 60%;
}
#questions label {
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 0px;
}
#questions .btn-upload-file {
    color: #ff337d;
}
#questions .col-xs-3 {
    padding: 0px;
}
#questions .checkbox label {
    margin-top: 0px;
}
.alert-dismissible {
    width: 90%;
    position: absolute;
    z-index: 100;
    top: 80px;
    left: 5%;
}
.alert-dismissible .btn-info {
    margin-top: -3px;
}
.signup-form .form-control {
    box-shadow: none;
    border-radius: 0px;
    border: none;
    background-color: transparent;
    padding-left: 0px;
    padding-top: 20px;
    padding-bottom: 0px;
    border-bottom: 1.2px solid #e8eef3;
}
.PhoneInput .PhoneInputCountryIcon--border {
    box-shadow: none;
    background-color: transparent;
}
.PhoneInput .PhoneInputCountry {
    padding-bottom: 5px;
}
.PhoneInput .PhoneInputCountryIcon {
    height: auto;
}
.PhoneInput input {
    border: none;
    border-left: 1.2px solid #e8eef3;
    padding-left: 10px;
}
.signup-form .form-group a {
    font-style: italic;
    margin-top: 5px;
    font-size: 16px;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.signup-form .form-group a:hover {
    color: #ff337d;
}
.signup-form .form-group select {
    color: #000 !important;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.login-form .form-group > input::-webkit-input-placeholder,
.signup-form .form-group > input::-webkit-input-placeholder {
    color: #979797 !important;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.signup-form .form-group > input:-moz-placeholder {
    color: #585858 !important;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.signup-form .form-group >::-moz-placeholder {
    color: #585858 !important;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.signup-form .form-group select {
    padding-top: 10px;
    border-bottom: 1.2px solid #e8eef3 !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url('../images/down.png') no-repeat;
    background-position: 100% 80%;
    background-size: 12px;
}
.onboarding-container .signup-form .form-group select {
    border-bottom: 0!important;
    border: 1px solid #e8eef3!important;
    background-color: #fff!important;
    background-position: 99% 55%;
    padding: 6px 12px!important;
    line-height: inherit!important;
}
.onboarding-container .form-group .btn-link {
    top: 6px;
    right: 10px;
}
.onboarding-container .signup-form .form-control {
    box-shadow: none;
    border-radius: 0px;
    border-bottom: 0!important;
    border: 1px solid #e8eef3!important;
    padding: 10px 16px!important;
}

.form-group input {
    background: #fff !important;
}
.btn-update {
    margin-top: 20px;
    background-color: #ff337d;
    color: #fff;
    border-radius: 3px !important;
    font-size: 14px;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, .1);
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    padding: 10px 25px;
    border-radius: 0px;
}
#profile_update_container .media img {
    width: 100px;
    height: 100px;
}
#profile_update_container .media .media-heading {
    margin-top: 40px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.btn-update:hover,
.btn-update:focus {
    color: #fff;
    background-color: #ff337d;
}
.update-profile {
    margin-top: 20px;
}
.update-profile label {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #ccc;
}
.update-profile .form-group {
    margin-bottom: 35px;
}
.update-profile .form-group .form-control {
    box-shadow: none;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 16px;
    border-radius: 0px;
}
.select-document {
    margin-top: 30px;
    margin-bottom: 50px;
}
.select-document .form-group .form-control {
    box-shadow: none;
    border-radius: 0px;
}
.select-document select {
    font-size: 16px;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    background: url('../images/down.png') no-repeat;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-position: 97% 50%;
}
.select-document .input-lg {
    line-height: 1.3333;
}
.document-table .table > thead > tr > th {
    color: #979797;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    border-bottom: 1px solid #e8eef3 !important;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
}
.document-table .table > tbody > tr > td {
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #000;
    padding-left: 0px;
    padding-right: 0px;
    vertical-align: middle;
    border: none;
    padding-top: 20px;
    padding-bottom: 10px;
}
.document-table .table > tbody > tr > td > p {
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.document-table .table > tbody > tr > td > p > a {
    color: #000;
}
.document-table .table > tbody > tr > td img {
    width: 25px;
    margin-top: -5px;
    margin-right: 10px;
    vertical-align: middle;
}
#court .form-control {
    box-shadow: none;
    border-radius: 0px;
}
.court-container {
    max-height: 250px;
    overflow: hidden;
    overflow-y: auto;
}
.scroll .job-history-table .table > tbody > tr {
    cursor: default!important;
}
.job-history-table .table > tbody > tr > td {
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #000;
    padding-left: 0px;
    padding-right: 0px;
    vertical-align: middle;
    border-top: 1px solid #e8eef3;
    padding-top: 15px;
    padding-bottom: 15px;
}
.job-history-table .table > thead > tr > th {
    color: #ccc;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    border-bottom: 1px solid #e8eef3 !important;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
}
.margin-left {
    margin-left: 5%;
}
.user-update-panel {
    border-radius: 6px !important;
    padding: 20px 30px;
    border-radius: 0px;
    margin-bottom: 25px;
    box-shadow: 0 2px 10px rgba(152, 152, 152, 0.5);
}
.user-update-panel .para {
    line-height: 1.4em;
}
.update-panel {
    min-height: 150px;
    border-radius: 0px;
    margin-top: 20px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(152, 152, 152, 0.5);
}
.user-update-panel .btn-login {
    background-color: #000;
}
.report-panel {
    margin-top: 5px;
    border-radius: 4px;
    height: 150px;
    padding: 10px 20px;
    box-shadow: 0 2px 5px 1px rgba(174, 170, 170, 0.5);
}
.report-panel .heading-md {
    padding-bottom: 2px;
}
.report-panel .panel-body {
    padding: 0px;
}
.report-panel .text-number {
    margin-top: 30px;
    font-size: 24px;
}
.top-offset {
    margin-top: 8%;
}

.heading-text {
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    padding-bottom: 5px;
    font-size: 14px;
    border-bottom: 1px solid #e8eef3;
}
.heading-text small {
    margin-left: 20px;
}
.job_title .nav-tabs {
    border-bottom: 1px solid #e8eef3;
    margin-top: 20px;
}
.job_title .nav-tabs .dropdown {
    position: relative;
    display: inline-block;
}
.job_title .nav-tabs .dropdown > a {
    color: #2d3feb;
}
.job_title .nav-tabs .dropdown-content {
    display: none;
    position: absolute;
    background-color: #fff;
    min-width: 400px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;
    right: 0;
}
.tips-dropdown {
    position: relative;
    display: inline-block;
}
.tips-content {
    display: none;
    position: absolute;
    background-color: #fff;
    min-width: 500px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;
    left: 0;
}
.tips-dropdown:hover .tips-content {
    display: block;
}
.tips-dropdown small {
    color: #2d3feb;
    font-size: 14px;
    margin-left: 10px;
    cursor: pointer;
}
.tips-content ol > li {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #000;
    font-size: 16px;
    line-height: 1.2em;
    margin-top: 10px;
    margin-bottom: 15px;
}
.tips-content.bio-tips ol > li{
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 14px;
}
.job_title .nav-tabs .dropdown:hover .dropdown-content {
    display: block;
}
.job_title .nav-tabs .dropdown-content ol > li {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    text-transform: capitalize !important;
    color: #000;
    font-size: 16px;
    line-height: 1.2em;
    margin-top: 10px;
    margin-bottom: 15px;
}
.user-dashboard-table .dropdown {
    position: relative;
    display: inline-block;
}
.user-dashboard-table .dropdown small {
    margin-left: 10px;
    color: #2d3feb;
    cursor: pointer;
}
.user-dashboard-table .dropdown-content {
    display: none;
    position: absolute;
    background-color: #fff;
    min-width: 500px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;
    left: 0;
}
.user-dashboard-table .dropdown:hover .dropdown-content {
    display: block;
}
.user-dashboard-table .dropdown-content ol > li {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #000;
    font-size: 16px;
    line-height: 1.2em;
    margin-top: 10px;
    margin-bottom: 15px;
}
.job_title .nav-tabs > li > a {
    color: #9395a7;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    padding: 2px 0px;
}
.job_title .nav-tabs li > a:hover,
.job_title .nav-tabs li > a:focus {
    background: none;
    color: #ff337d;
    border: 1px solid transparent;
    padding: 2px 0px;
}
.job_title .nav-tabs > li.active > a,
.job_title .nav-tabs > li.active > a:focus,
.job_title .nav-tabs > li.active > a:hover {
    border: none;
    padding: 2px 0px;
}
.job_title .nav-tabs > li.active > a,
.job_title .nav-tabs > li.active > a:focus,
.job_title .nav-tabs > li.active > a:hover {
    color: #ff337d;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.proposal-tab {
    margin-bottom: 20px;
}
/* =============================================================Job progress new style============== */

.ui-progress-job {
    margin-top: 40px;
}
.box-shadow {
    box-shadow: 0 2px 5px 0 rgba(223, 223, 223, 0.5);
    border: none!important;
}
.ui-progress-job .dropdown-menu {
    left: -120px !important;
}
.side-box-container {
    width: 100%;
    padding: 10px;
    box-shadow: 0 1px 5px 1px rgba(194, 189, 189, 0.5);
    background-color: #ffffff;
    margin-bottom: 20px;
    border-radius: 6px;
}
.add-more-btn-container {
    margin-bottom: 20px;
}
.col-sm-3 .side-box-container:nth-last-child(1) {
    min-height: 250px;
}
.information-li li {
    margin-bottom: 10px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.3em;
}
#how_to_use_wc .xs-para {
    font-size: 18px;
    line-height: 1.2em;
    margin-top: 10px;
}
#how_to_use_wc .btn-login {
    padding: 8px 25px;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
#how_to_use_wc .modal-body {
    padding: 5px 15px;
}
#how_to_use_wc .modal-footer {
    padding: 20px 15px;
}
.side-box-container .heading-text {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000;
    font-size: 16px;
}
.side-box-container .heading-text .btn-link{
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color:#ff337d;
    padding: 0px;
}
.delivery-time{
   font-size: 24px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
}
.request-payment-ul{
    display: flex;
    justify-content: space-between;
    padding:10px 20px;
    margin-bottom: 10px;
}
.request-payment-ul .btn-edit-delivery-time{
    margin-top: 0px;
    margin-bottom: 0px;
}
.request-payment-ul .para{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color:#000;
}
.request-payment-ul li{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color:#000;
    font-size: 16px;
    line-height: 1.6em;
}
.request-payment-ul li span{
    color:#ff337d;
}
.request-payment-ul li .form-control{
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.or-strike{
    display: flex;
    flex-direction: row;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 18px;
}
.or-strike:before,
.or-strike:after{
    content: "";
    flex: 1 1;
    border-bottom: 1.5px solid #e8eef3;
    margin: auto;
}
.or-strike:before {
    margin-right: 10px
}
.or-strike:after {
    margin-left: 10px
}
.delivery-status .para{
   margin-top: 5px;
}
.delivery-status .para span{
    color: #ff337d;
    float: right;
}
.btn-edit-delivery-time{
    background: none;
    border: 1.2px solid #2743d0;
    color: #2743d0;
    font-size: 16px;
    padding: 8px 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 20px;
}
.btn-edit-delivery-time:hover,
.btn-edit-delivery-time:focus{
    background: #2743d0;
    color: #fff;
}
.btn-edit-delivery-time+.btn-edit-delivery-time{
    background: #2743d0;
    border: 1.2px solid #2743d0;
    color: #fff;
}
.btn-after-success{
    border: 1.2px solid #23B794;
    color: #23B794;
    font-size: 16px;
    padding: 8px 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 20px;
}
.btn-after-success:hover,
.btn-after-success:focus{
    color: #23B794;
}
.delivery-time span{
    font-size: 18px;
}
.ui-img-container {
    margin-top: 20px;
    margin-bottom: 10px;
}
.ui-img-container img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    object-position: 50% 70%;
}
.ui-img-container .blank-dp {
    padding-top: 12px !important;
}
.ui-img-container .name {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 0px;
}
.ui-img-container .media-heading {
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000;
    margin-top: 5px;
    margin-bottom: 0px;
}
.ui-img-container .text-title {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #000;
    font-size: 14px;
    margin-top: 0px;
    margin-bottom: 20px;
}
.d-padding-r{
    padding-right: 0px;
}
.payment-table .table{
    margin-bottom: 10px;
}
.payment-table .table > thead > tr > th {
    padding: 5px 0px;
    border-bottom: 1px solid #e8eef3;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
}
.payment-table .table > tbody {
    border: none;
    border-bottom: 1px solid #e8eef3;
}
.payment-table .table > tbody > tr > td {
    padding: 5px 0px;
    border: none;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.payment-table .table > tbody > tr > td > b {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    margin-top: 20px;
}
.payment-table .table > tbody:nth-last-child(1) {
    border: none;
}
.payment-table .table .input-lg {
    border: 1px solid #888;
    width: 70%;
    height: 35px;
    font-size: 16px;
}
.payment-success {
    padding: 20px;
    background-color: #fff;
    margin-bottom: 20px;
}
.text-empty-space {
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
    margin-top: 25px;
    text-align: center;
}
.alert-default .media-body a {
    color: #ff337d;
}
.ui-white-box {
    box-sizing: border-box;
    padding: 20px;
    min-height: 600px;
    background-color: #fff;
    margin: auto;
    margin-bottom: 20px;
    border-radius: 6px;
}
.user-dashboard-white-box {
    padding: 5px 20px;
    min-height: 120px;
    background-color: #fff;
    margin: auto;
    margin-top: 40px;
    margin-bottom: -15px;
    border-radius: 6px;
}
.user-dashboard-white-box .text-empty-space {
    margin-top: 0px;
    margin-bottom: 30px;
}
.service_name {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
}
.user-dashboard-white-box .user-dashboard-table {
    margin-bottom: 0px !important;
}
.user-dashboard-white-box .user-dashboard-table .heading-md {
    font-size: 22px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    margin-bottom: 20px;
    margin-top: 10px;
}
.user-dashboard-table .heading-md {
    font-size: 22px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    margin-bottom: 20px;
    margin-top: 0px;
}
.logo .dropdown a div {
    position: absolute;
    height: 50px !important;
    width: 50px !important;
    padding-top: 12px !important;
    margin-top: -12px !important;
    margin-left: -10px !important;
}
.logo .dropdown a {
    cursor: default;
}
.feedback-box {
    background-color: #fff;
    padding: 20px 0px;
}
textarea {
    font-size: 16px;
   font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

.create-project-form {
    textarea {
        font-size: 12px !important;
        font-family: unset;
        font-weight: unset;
    }
}
.feedback-box .heading-text {
    text-align: center;
    font-size: 20px;
}
.ui-rating-field {
    padding: 20px 40px;
}
.ui-rating-field textarea {
    width: 100%;
    border: 1px solid #e8eef3;
    border-radius: 4px;
    /* resize: none; */
}
.ui-rating-field .para {
    margin-top: 25px;
    font-size: 14px;
}
.ui-rating-field .list-inline li {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #000;
    cursor: pointer;
    margin-left: 10px;
}
.ui-rating-field .list-inline .active {
    background-color: #ffe933;
    padding: 2px 10px;
    border-radius: 2px;
}
.ui-rating-field .btn-submit {
    margin-top: 30px;
    background-color: #000;
}
.ui-rating-field .checkbox {
    margin-bottom: 20px;
}
.document-table {
    padding: 10px 20px;
}
#blank_proposal {
    padding: 10px 40px;
}
#blank_proposal .xs-heading {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    margin-top: 8%;
}
#blank_proposal ol {
    margin-top: 30px;
}
#blank_proposal ol > li {
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    margin-top: 15px;
    color: #000;
}
#blank_proposal .para {
    font-size: 18px;
}
.media-left img {
    height: 50px;
    width: 50px;
    display: inline-block;
    object-fit: cover;
}
.alert-default .media-left img {
    height: 55px;
    width: 55px;
    object-fit: cover;
    object-position: 50% 70%;
}
.alert-default .pull-right h4 {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-weight: bold;
}
#profile_update_container .media-left .blank-dp {
    margin-top: 20px !important;
    height: 80px !important;
    width: 80px !important;
    padding-top: 20px !important;
    vertical-align: bottom;
}
#profile_update_container .media-left .blank-dp > span {
    font-size: 28px !important;
}
/* =============================================================Job progress new style============== */
.login-page-header .pull-right .btn-login {
    background-color: #ff337d;
    color: #fff !important;
    border-radius: 4px;
}
.pull-right .btn-login:nth-child(1) {
    border: 1px solid #ff337d;
    color: #ff337d;
}
.pull-right .btn-login:nth-child(1):hover,
.pull-right .btn-login:nth-child(1):focus {
    background-color: #ff337d;
    color: #fff;
    border: 1px solid #ff337d;
}
.text-container .list-inline a {
    cursor: default;
}
.dp-image {
    width: 80px;
    height: 80px;
    margin-left: 0px;
    margin-top: 20px;
    object-fit: cover;
}
.text-INR {
    font-size: 16px;
    text-align: center;
    color: #fff;
    line-height: 1.4em;
    margin-right: 0px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.text-INR small {
    font-size: 12px;
}
.input-group .btn-info {
    background-color: #ff337d;
    border: 1px solid #ff337d;
}
.input-group .btn-info .fa {
    color: #fff;
}
.cunsult-chat-box {
    overflow-y: auto;
    padding: 10px 20px;
}
.cunsult-chat-box .blank-dp {
    height: 40px !important;
    width: 40px !important;
    margin-top: 10px !important;
    padding-top: 8px !important;
}
.cunsult-chat-box .blank-dp > span {
    font-size: 16px !important;
}

.proposal-chat-head{
    display: flex;
    padding:10px 20px;
    background: #000;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}
.proposal-chat-head .table>tbody>tr>td{
    border: none;
    vertical-align: center!important;
}
.proposal-chat-head .blank-dp {
    height: 50px !important;
    width: 50px !important;
    line-height: 30px !important;
}
.chat_with_cunsultant .dp-image {
    width: 50px;
    height: 50px;
    margin-top: 0px;
}
.chat_with_cunsultant {
    margin-top: 20px;
}
.chat_with_cunsultant .btn-pink {
    background-color: #ff337d;
    color: #fff;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.chat_with_cunsultant .btn-pink:focus {
    outline: 0px;
}
.chat_with_cunsultant .btn-link img {
    margin-left: -10px;
    margin-bottom: -5px;
}
.chat_with_cunsultant .btn-link:focus {
    outline: 0px;
}
.chat_with_cunsultant textarea {
    /*margin-top: 10px;*/
    resize: none !important;
}
textarea.form-control {
    border: 1px solid #e3e3e3!important;
}
.chat_with_cunsultant textarea.form-control {
    box-shadow: none !important;
}
.cunsult-chat-box {
    overflow-y: auto;
    padding: 10px 20px;
}
.cunsult-chat-box .media-heading {
    margin-top: 20px;
    margin-bottom: 5px;
    font-size: 16px;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.cunsult-chat-box .media {
    border-bottom: 1px solid #e8eef3;
    padding-bottom: 10px;
    margin-top: 0px;
}
.uploaded_doc {
    background-color: transparent;
    padding: 6px 0px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 6px;
}
.uploaded_doc a {
    width: auto;
    padding: 6px 15px;
    border-radius: 10px;
}
.chat_with_cunsultant .btn-bs-file > img {
    margin-top: 15px;
    margin-right: 10px;
    margin-bottom: -35px;
}
.job_title .dropdown-menu li {
    z-index: 1000 !important;
}
.chat_with_cunsultant .list-inline {
    margin-top: 0px;
    margin-bottom: 0px;
}
.chat_with_cunsultant .list-inline li {
    font-size: 18px;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    margin-left: 5px;
}
.chat_with_cunsultant .list-inline small {
    font-size: 12px;
}
.chat_with_cunsultant .panel-body {
    padding: 0px;
}
.read_more .row {
    margin-top: 30px;
    margin-bottom: 40px;
}
.read_more .name {
    font-size: 14px;
    margin-top: 15px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000;
}
.read_more .time {
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #000;
    margin-left: 10px;
    margin-top: 30px;
}
.MESSAGES h5 a {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.read_more .list-inline {
    margin-top: 25px;
    margin-left: 10px;
}
.read_more .list-inline li > a {
    font-size: 16px;
    color: #fff !important;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.read_more .fa-star {
    font-size: 12px;
}
.read_more .fa-star-half-o {
    font-size: 12px;
    color: #fff !important;
}
#PROPOSALS .read_more .list-inline li {
    font-size: 18px;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    margin-left: 30px;
}
#PROPOSALS .read_more .list-inline small {
    font-size: 12px;
    color: #fff;
}
#PROPOSALS .read_more .fa-star {
    color: #fff;
    margin-right: 4px;
}
.read_more .panel-body {
    padding: 0px;
}
.read_more .dp-image {
    margin-left: 10px;
    margin-top: 5px;
}
.read_more .modal-body {
    padding: 0px;
}
.read_more .para {
    font-size: 14px;
}
.read_more ol > li {
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 20px;
}
.form-horizontal b {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.text-bold {
    margin-top: 20px;
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: bold;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.text-INR a {
    color: #fff;
    font-size: 12px;
}
.para {
    color: #000;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    line-height: 1.4em;
}
.para a {
    color: #000;
}
.text-container a {
    color: #ff337d;
}
.text-container a:hover {
    color: #ff337d;
}
.text-dark a {
    color: #000;
}
.text-dark a:hover {
    color: #ff337d;
}
.btn-pink {
    background-color: #fff;
    color: #ff337d;
    padding: 6px 40px;
    border: 1px solid #ff337d;
    border-radius: 4px;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, .1);
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
}
.btn-pink:hover,
.btn-pink:focus {
    background-color: #ff337d;
    color: #fff;
    outline: 0px;
}
.btn-mark-complete{
    background-color: #4524ff;
    color: #fff;
    padding: 6px 40px;
    /*border: 1px solid #ff337d;*/
    border-radius: 4px;
    /*box-shadow: 0px 3px 3px rgba(0, 0, 0, .1);*/
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
}
.btn-mark-complete:hover,
.btn-mark-complete:focus {
    background-color: #4524ff;
    color: #fff;
    outline: 0px;
}
.btn-c-milestone {
    background-color: #fff;
    color: #ff337d;
    display: block;
    width: 100%;
    padding: 5px 20px;
    border: 1px solid #ff337d;
    border-radius: 3px;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, .1);
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
    margin-bottom: 15px;
}
.btn-c-milestone:hover,
.btn-c-milestone:focus {
    background-color: #ff337d;
    color: #fff;
    outline: 0px;
}
#btn-container {
    border-left: 1px solid #e8eef3;
    margin-top: 10px;
}
.btn-margin {
    margin-top: 15px;
    margin-bottom: 15px;
}
#notification .alert {
    margin-bottom: 10px;
    padding: 15px 0px;
}
#notification .alert-default small {
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #000;
}
.Proposal-list .media-body .text-muted {
    width: 90%;
    color: #000;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.Proposal-list .alert-default .media-heading {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.Proposal-list .alert-default .para span {
    font-size: 14px;
}
.Proposal-list .alert-default .media-heading > span {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
}
.Proposal-list .dp-image {
    width: 55px;
    height: 55px;
    margin-top: 10px;
}
.Proposal-list .blank-dp {
    width: 55px!important;
    height: 55px!important;
    margin-left: 0px!important;
    padding-top: 12px !important;
    margin-top: 10px!important;
}
.alert-default {
    border-bottom: 1px solid #e8eef3;
    border-width: 0.1em;
    border-radius: 0px;
}
.alert-default .media-heading {
    margin-top: 0px;
    font-size: 18px;
    color: #000;
    margin-bottom: 0px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
#notification .alert-default .btn-login {
    margin-top: 20px;
    margin-right: 0px;
}
.alert-default .btn-login {
    margin-top: 20px;
    margin-right: 30px;
}
.alert-default .btn-login {
    background-color: transparent;
    border: 1px solid #213FD4;
    color: #213FD4;
}
.alert-default .btn-login:hover {
    background-color: #213FD4;
    color: #fff;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
}
.alert-default .btn-login:focus {
    outline: 0px;
}
.alert-default .media {
    margin-top: 10px;
}
.chat_area .time{
    /*margin-top: 3px;*/
    font-size: 12px;
    /*float: right;*/
}
.chat-quick-reply-btn-container{
    /*border: 1px solid red;*/
    display: flex;
    align-items: center;
    padding: 15px 20px;
    white-space: nowrap;
    overflow-x: auto;
}
.quick-btn-ul{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
}
.quick-btn-ul>li>a{
    border: 1.5px solid #416de2;
    border-radius: 25px;
    color: #416de2;
    padding: 5px 10px;
    font-size: 14px;
}
.time {
    color: #979797;
    font-size: 10px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.color-pink {
    color: #ff337d;
    margin-left: 10px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.pull-left li {
    padding-top: 2px;
}
.pull-left li > a {
    color: #000;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 36px;
}
.cunsult-chat-box::-webkit-scrollbar {
    width: 8px;
}
.cunsult-chat-box::-webkit-scrollbar-track {
    background: #f1f1f1;
}
.cunsult-chat-box::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
}
.cunsult-chat-box::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.scroll {
    height: 500px;
    overflow: hidden;
}
.scroll:hover {
    overflow: auto;
    overflow-x: hidden;
}
.scroll::-webkit-scrollbar {
    width: 8px;
}
/* Track */

.scroll::-webkit-scrollbar-track {
    background: #fff;
}
/* Handle */

.scroll::-webkit-scrollbar-thumb {
    background: #979797;
    border-radius: 8px;
}
.member_list::-webkit-scrollbar {
    width: 8px;
}
/* Track */

.member_list::-webkit-scrollbar-track {
    background: #f1f1f1;
}
/* Handle */

.member_list::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
}
/* Handle on hover */

.member_list::-webkit-scrollbar-thumb:hover {
    background: #555;
}
#SUGGESTIONS::-webkit-scrollbar {
    width: 8px;
}
#SUGGESTIONS::-webkit-scrollbar-track {
    background: #f1f1f1;
}
#SUGGESTIONS::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
}
#SUGGESTIONS::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.Proposal-list::-webkit-scrollbar {
    width: 8px;
}
.Proposal-list::-webkit-scrollbar-track {
    background: #f1f1f1;
}
.Proposal-list::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
}
/* Handle on hover */

.Proposal-list::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.chat-row {
    margin-bottom: 10px;
    /*border-bottom: 1px solid #e8eef3;*/
    margin-top: 0px;
    border-radius: 6px;
    /*background-color: #fff;*/
}
.job-history-row {
    margin-top: 28px;
}
.job_history {
    min-height: 600px;
    overflow: auto;
    padding: 0px;
    padding-top: 10px;
}
.job_history .nav-tab {
    border-bottom: 0px solid #e8eef3;
    margin-top: 15px;
    margin-left: 20px;
}
.job_history .nav-tab > li {
    margin-bottom: 20px;
}
.job_history .nav-tab > li > a {
    color: #000;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.job_history .nav-tab li > a:hover,
.job_history .nav-tab li > a:focus {
    background: none;
    color: #000;
}
.job_history .nav-tab > li.active > a,
.job_history .nav-tab > li.active > a:focus,
.job_history .nav-tab > li.active > a:hover {
    color: #ff337d;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.job_history .badge {
    background-color: transparent;
    color: #ff337d;
    border-radius: 4px;
    padding-top: 5px;
    padding-left: 10px;
    font-size: 16px;
    padding-right: 10px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
#JOB_REQUESTS .alert-default {
    border: 1px solid #e8eef3;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
    border-radius: 4px;
    margin-top: 10px;
    margin-bottom: 0px;
}
#JOB_REQUESTS .media-heading {
    font-size: 18px;
    margin-top: 0px;
}
#JOB_REQUESTS .media-body a,
#SENT_PROPOSAL .media-body a,
#JOBS_PROGRESS .media-body a {
    color: #ff337d;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
    margin-top: -10px;
}
.proposal-tab .nav-tabs {
    border-bottom: 0px solid #e8eef3;
    margin-left: 8%;
    margin-top: 10px;
}
.proposal-tab .nav-tabs > li > a {
    color: #000;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    padding: 0px 3px;
}
.proposal-tab .nav-tabs li > a:hover,
.proposal-tab .nav-tabs li > a:focus {
    border: 1px solid transparent;
    padding: 0px 3px;
    background-color: transparent;
}
.proposal-tab .nav-tabs > li.active > a,
.proposal-tab .nav-tabs > li.active > a:focus,
.proposal-tab .nav-tabs > li.active > a:hover {
    border: none;
    padding: 0px 3px;
    background-color: transparent;
}
.proposal-tab .nav-tabs > li.active > a,
.proposal-tab .nav-tabs > li.active > a:focus,
.proposal-tab .nav-tabs > li.active > a:hover {
    color: #ff337d;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    padding: 1px 3px;
}
#dashboard_chat_message .heading-md a {
    color: #000;
}
.alert-default .pull-right > .btn-sent {
    margin-right: 30px;
    margin-top: 50px;
}
.MESSAGES a {
    color: #000;
}
.MESSAGES .media-body a {
    color: #ff337d;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
    /*margin-top: -10px;*/
}
.MESSAGES .media-body a.btn-primary{
    color: #fff;
}
.MESSAGES .media-body .text-muted {
    width: 90%;
    color: #000;
    font-size: 14px;
}
.MESSAGES .media-heading {
    margin-top: 14px;
}
.MESSAGES .media-left img {
    height: 40px;
    width: 40px;
    object-fit: cover;
    object-position: 50% 70%;
    margin-top: 10px;
}
.MESSAGES .heading-md small {
    color: #000;
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.MESSAGES .para {
    color: #000;
    font-size: 14px;
    width: 90%;
}
.MESSAGES .time {
    color: #979797;
    margin-left: 5px;
    font-size: 12px;
}
.MESSAGES .alert {
    padding: 5px 0px;
    margin-bottom: 0px;
}
.MESSAGES .chat_with_cunsultant .media-heading {
    margin-top: 10px;
    margin-bottom: 5px;
}
.chat_with_cunsultant .heading-md {
    font-size: 16px !important;
    text-align: right;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.heading-text .date {
    font-size: 14px;
}
.date {
    font-size: 12px;
    color: #979797;
    text-align: left;
}
.user-dashboard-table {
    margin-top: 0px;
    margin-bottom: 60px;
}
.btn-sent-proposal {
    background-color: #fff;
    color: #ff337d;
    padding: 5px 15px;
    border: 1px solid #ff337d;
    border-radius: 3px;
    font-size: 14px;
    margin-right: 30px;
    margin-top: 10px;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, .1);
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
}
.btn-not-interested {
    background-color: #fff;
    color: #213FD4;
    padding: 5px 15px;
    border-radius: 3px;
    font-size: 14px;
    margin-top: 10px;
    border: 1px solid #213FD4;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, .1);
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
}
.btn-sent-proposal:hover,
.btn-sent-proposal:focus {
    background-color: #ff337d;
    color: #fff;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
}
.btn-not-interested:hover,
.btn-not-interested:focus {
    background-color: #213FD4;
    color: #fff;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
}
.table.past-interview>thead>tr>th{
    font-size: 14px;
    border-bottom: 1px solid #E6E6E6;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #64728A;
    padding: 10px 8px;
}
.table.past-interview>tbody>tr>td{
    font-size: 14px;
    border: none;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000;
    padding: 15px 8px;
}
.past-interview.table-striped>tbody>tr:nth-of-type(odd){
    background-color: #F7FAFC;
}
.past-interview .btn-link{
    color: #2C5FE1;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.user-dashboard-table .table > thead > tr > th {
    font-size: 16px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 2px;
    color: #64728A;
    border-bottom: 1px solid #e8eef3;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.user-dashboard-table .table > tbody > tr > td {
    border: none;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 15px;
    padding-bottom: 5px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 16px;
}
.user-dashboard-table .table.job-progress > tbody > tr > td {
    vertical-align: top;
}
.profile-approval-modal .table > tbody > tr > td{
    border: none;
    font-size: 16px;
    padding: 20px 12px;
}
.profile-approval-modal .table > tbody > tr > td img{
    width:40px;
}
.user-dashboard-table .table > tbody > tr > td > a {
    font-size: 16px;
    color: #000;
}
.user-dashboard-table .table > tbody > tr > td > a:hover,
.user-dashboard-table .table > tbody > tr > td > a:focus {
    color: #ff337d;
}
.user-dashboard-table .table > tbody > tr > td > a .fa-circle {
    font-size: 10px;
    color: #000;
}
.user-dashboard-table .dropdown .fa-circle {
    color: #3C48FF;
    font-size: 6px;
    margin-right: 5px !important;
}
.consultation-table .table > tbody > tr > td {
    border-bottom: 1px solid #e8eef3;
    padding-top: 20px;
    padding-bottom: 15px;
}
.select-slot .media-left img {
    height: 70px;
    width: 70px;
    margin-top: 0px;
}
.select-slot .media-heading {
    margin-top: 0px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000;
}
.select-slot ul li {
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 16px;
}
.select-slot .blank-dp {
    padding-top: 12px !important;
}
.slot-table {
    margin-bottom: 30px;
}
.slot-table .table > tbody > tr > td {
    vertical-align: middle;
    border: none;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
}
.slot-table .table > tbody > tr > td > a {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #000;
    font-size: 16px;
}
.slot-table .table > tbody > tr > td.active {
    background-color: #2d3feb;
    cursor: pointer;
    color: #fff;
}
.ui-white-box .slot-table .table > tbody > tr > td b {
    position: absolute;
    margin-top: -15px;
}
.slot-table .table > tbody > tr > td b {
    position: absolute;
    margin-top: 5px;
}
.slot-table .table > tbody > tr > td input {
    margin-left: 15px;
}
.slot-table .table > tbody > tr > td.active a {
    cursor: pointer;
    color: #fff;
}
li .dropdown a .fa-circle {
    margin-left: 4px;
    color: #9395a7;
    font-size: 8px;
}
.dialog-top-margin {
    margin-top: 8%;
}
.user-dashboard-table .table tr > td .dropdown .dropdown-menu {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px;
    right: 0;
    left: -120px;
}
.job_detail ul {
    margin-left: -20px;
}
.job_detail ul > li {
    color: #000;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

.accept-detail .text-gray {
    color: #979797;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 10px;
}
.text-gray span {
    font-size: 16px;
}
.job_detail .media-heading {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    margin-top: 5px;
}
#custumize_prize .col-xs-4 label {
    margin-top: 10px;
}
.modal .panel-text {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    margin-bottom: 5px;
}
.modal .panel {
    margin-top: 0px !important;
    margin-bottom: 10px;
}
.accept-detail .dialog-top-margin {
    margin-top: 3%;
    width: 80%;
}
.see_proposal .modal-content {
    padding: 10px 20px;
}
.see_proposal .dialog-top-margin {
    margin-top: 3%;
    width: 80%;
}
.see_proposal .text-pink {
    color: #ff337d;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
    margin-top: 30px;
}
.text-blue {
    color: #0000FF;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 16px;
}
.see_proposal .modal-body .panel-body {
    padding: 15px;
}
.modal-footer {
    border: none;
}
.see_proposal .text-muted {
    font-size: 14px;
}
.modal-header {
    border-bottom: 1px solid #e8eef3;
}
#profile_picture .modal-header {
    border-bottom: 1px solid #e8eef3;
}
.modal-title {
    color: #000;
    font-size: 18px;
    /*padding-left: 20px;*/
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.close {
    opacity: 1;
}
.modal .signup-form .form-group {
    margin-bottom: 15px;
}
.modal-body .signup-form {
    margin-top: 10px;
}
#basic_details .btn-login {
    padding: 6px 15px;
}
#education .btn-login {
    padding: 6px 15px;
}
#education .btn-edit {
    margin-left: 0px;
    color: blue;
    font-size: 18px;
}
.btn-delete {
    /* margin-left: 15px; */
    color: #ff337d;
    font-size: 18px;
}
.btn-delete:hover,
.btn-delete:focus{
  color: #ff337d;
}
#business_details .btn-login {
    padding: 6px 15px;
}
#business_details li {
    color: #979797;
}
#business_details li span {
    color: #000;
}
#profile_picture .btn-login {
    padding: 6px 15px;
}
#profile_picture .modal-footer {
    border: none;
    text-align: center;
}
#working-address .modal-dialog {
    margin-top: 8%;
}
#working-address .btn-login {
    padding: 6px 15px;
}
#working-address .modal-body,
#working-address .modal-footer {
    padding: 10px 40px;
}
#working-address label {
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
}
#working-address .modal-header {
    border-bottom: 1px solid #e8eef3;
}
#brief_bio .modal-header {
    border-bottom: 1px solid #e8eef3;
}
#brief_bio .panel-text {
    margin-bottom: 0px;
}
textarea {
    /* resize: none; */
}
.ui-white-box .panel-text {
    margin-bottom: 5px;
}
.panel-default ol {
    margin-left: -25px;
}
.panel-default ol > li {
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    margin-bottom: 15px;
}
.panel-default ol > li > div {
   font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
#brief_bio textarea {
    border: 1px solid #e8eef3;
    padding: 10px;
}
#brief_bio .modal-footer {
    padding: 10px 40px;
}
#brief_bio .btn-login {
    padding: 6px 15px;
}
#brief_bio .modal-body {
    padding: 10px 40px;
}
#consultation_description textarea {
    border: 1px solid #e8eef3;
    padding: 10px;
}
#consultation_description .modal-footer {
    padding: 10px 20px;
}
#consultation_description .btn-login {
    padding: 6px 15px;
}
#consultation_description .modal-body {
    padding: 10px 20px;
}
#job_description textarea {
    border: 1px solid #e8eef3;
    padding: 10px;
}
#job_description .modal-footer {
    padding: 10px 20px;
}
#job_description .btn-login {
    padding: 6px 15px;
}
#job_description .modal-body {
    padding: 10px 20px;
}
.modal-footer .btn-link .fa-paperclip {
    margin-top: 20px;
    margin-right: 5px;
    vertical-align: bottom;
    font-size: 26px;
    color: #ff337d;
}
.modal-footer .btn-link {
    margin-top: -20px;
    font-size: 16px;
    color: #ff337d;
}
.modal-footer .btn-link:hover,
.modal-footer .btn-link:focus {
    text-decoration: none;
    color: #ff337d;
}
.btn-upload-file .fa-paperclip {
    margin-top: 20px;
    margin-right: 5px;
    vertical-align: bottom;
    font-size: 26px;
    color: #ff337d;
}
.btn-upload-file {
    font-size: 16px;
    color: #ff337d;
}
.btn-upload-file:hover,
.btn-upload-file:focus {
    text-decoration: none;
    cursor: pointer;
    color: #ff337d;
}
#Payment_detail .modal-footer {
    padding: 10px 40px;
    text-align: center !important;
}
#Payment_detail .btn-login {
    padding: 6px 15px;
}
#Payment_detail .modal-body {
    padding: 10px 40px;
}
#document_proof .modal-header {
    border-bottom: 1px solid #e8eef3;
}
#document_proof .modal-footer {
    text-align: center;
}
#document_proof .btn-login {
    padding: 6px 15px;
}
#document_proof .modal-body {
    padding: 10px 10px;
}
#document_proof .pull-right {
    margin-right: 30px;
}
#document_proof ol > li {
    color: #000;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 30px;
}
#document_proof small {
    color: #000;
}
.modal-body .btn-link {
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.modal-body .btn-link:hover,
.modal-body .btn-link:focus {
    text-decoration: none;
    outline: 0px;
    color: #ff337d;
}
#practice_areas .modal-header {
    border-bottom: 1px solid #e8eef3;
}
#practice_areas .modal-body,
#practice_areas .modal-footer {
    padding: 10px 40px;
}
#practice_areas .btn-login {
    padding: 6px 15px;
}
#practice_areas .modal-footer {
    text-align: center;
}
.background-white {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #000;
    padding: 5px;
    cursor: pointer;
    border-radius: 25px;
    margin-left: 15px;
    margin-top: 10px;
    margin-bottom: 5px;
}
.background-pink {
    background-color: #2d3feb;
    color: #fff !important;
    padding: 5px;
    font-size: 14px;
    border-radius: 25px;
    margin-top: 10px;
    margin-left: 15px;
    margin-bottom: 10px;
    cursor: pointer;
}
.background-pink span {
    padding: 0px 10px;
}
.background-pink span {
    padding: 0px 10px;
}
#consultation_fee .modal-body,
#consultation_fee .modal-footer {
    padding: 10px 20px;
}
#consultation_fee .btn-login {
    padding: 6px 15px;
}
#consultation_fee .modal-footer {
    text-align: left;
}
#consultation_fee .btn-link {
    font-size: 18px;
}
#consultation_fee label {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 12px;
    font-weight: bold;
}
#consultation_fee .table .btn-link {
    font-size: 12px;
}
#consultation_fee .table > tbody > tr > td {
    border: none;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
    vertical-align: middle;
}
#consultation_fee .table > tbody > tr {
    border-top: 1px solid #e8eef3;
    border-left: 1px solid #e8eef3;
    border-bottom: 1px solid #e8eef3;
    border-right: 1px solid #e8eef3;
}
.panel-text {
    color: #000;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    margin-bottom: 0px;
    margin-top: 20px;
}
.modal .panel {
    box-shadow: none;
}
.modal .panel .panel-body{
    word-break: break-word;
}
.logo b {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    margin-left: 0px;
}
.logo ul li .blank-dp {
    height: 45px !important;
    width: 45px !important;
    margin-left: -10px !important;
    margin-top: -10px !important;
    position: absolute !important;
}
.logo {
    padding: 21px;
    height: 66.5px;
}
.logo .name {
    left: 65px;
    position: absolute;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    white-space: nowrap;
}
.price .form-control {
    border: 1px solid #888;
    box-shadow: none;
}
.price textarea {
    border: 1px solid #888 !important;
    border-radius: 4px;
    padding: 10px 10px;
    /* resize: none; */
    width: 100%;
    color: #000;
}
.price .table tbody>tr>td {
    border: none;
    padding: 5px 8px;
}
.price .table thead>tr>th {
    border: none;
    padding: 5px 8px;
}
.price .para {
    font-size: 12px;
    color: #7d7d7d;
    margin-top: 15px;
}
.text-note {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #ff337d;
}
.star-note{
    color: #ff337d;
}
.price label {
    font-size: 16px;
    margin-top: 5px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.price .form-group {
    margin-bottom: 25px;
}
.accept-detail .panel-text {
    margin-bottom: 2px;
}
.accept-detail .text-muted {
    margin-top: -15px;
    margin-bottom: 50px;
    font-size: 14px;
}
.btn-bs-file {
    position: relative;
}
.btn-bs-file input[type="file"] {
    position: absolute;
    filter: alpha(opacity=0);
    opacity: 0;
    width: 0;
    height: 0;
    outline: none;
    cursor: inherit;
}
.image-size {
    width: 100px;
    height: 100px;
    border: 2px solid #ecb33c;
    object-fit: cover;
    object-position: 50% 20%;
}
.signup-form .blank-dp,
.basic-detail-form .blank-dp{
    width: 100px!important;
    height: 100px!important;
    line-height: 80px;
    margin: 0px 0px!important;
}
.signup-form .blank-dp>span,
.basic-detail-form .blank-dp>span{
   font-size: 30px!important;
}
.col-sm-offset-3 ol {
    margin-top: 20px;
}
.col-sm-offset-3 ol > li {
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 10px;
}
.panel {
    border: 1px solid #e8eef3;
}
.hire-summary .table thead > tr > th {
    border: none;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000;
}
.hire-summary .input-group {
    z-index: 1;
}
.hire-summary .table tbody > tr > td {
    border: none;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #000;
    vertical-align: middle;
}
.hire-summary .table .form-control {
    padding: 10px 10px;
}
.hire-summary .blank-dp {
    padding-top: 12px !important;
}
.checkbox a {
    color: #000;
}
.checkbox label {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #000;
    font-size: 14px;
    margin-top: -10px;
    margin-left: -10px;
}
.hire-summary .input-group {
    margin-top: -15px;
}
.modal .hire-summary .input-group {
    margin-top: 0px;
}
.sm-heading a {
    color: #000;
}
.hire-summary .btn-primary {
    background-color: #000;
    border: 1px solid #000;
}
#PAYMENTS .modal-body {
    padding: 10px 40px;
}
#PAYMENTS .modal-footer {
    text-align: center;
    padding: 20px 40px;
}
#MILESTONES .modal-dialog {
    width: 70%;
}
#MILESTONES .modal-footer {
    text-align: center;
    padding: 20px;
}
#MILESTONES .hire-summary .table tbody > tr > td {
    vertical-align: top;
}
#MILESTONES .hire-summary .input-group {
    margin-top: 0px;
}
#MILESTONES .btn-login {
    padding: 6px 15px;
    font-size: 16px;
}
#insufficient_fund ol li {
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    margin-bottom: 10px;
}
#insufficient_fund .modal-body {
    padding: 0px 30px;
}
#insufficient_fund img {
    margin-top: 15px;
    width: 80px!important;
}
.hire-summary .form-control {
    padding: 0px 5px;
}
.price-ul li {
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    margin-top: 10px;
}
.price-ul li>span+span {
    margin-left: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.hire-summary .text-dark {
    margin-bottom: 0px;
}
.hire-summary .panel {
    margin-bottom: 25px;
}
.hire-summary .dp-image {
    margin-top: 0px;
}
.hire-summary .panel-body {
    padding: 10px;
    padding-bottom: 10px;
}
.hire-summary .panel-body .para {
    text-align: left;
}
.hire-summary .dp-image {
    width: 70px;
    height: 70px;
}
.hire-summary .fa-star {
    margin-left: 5px;
    color: gold;
}
.hire-summary .fa-star-half-o {
    margin-left: 5px;
    color: gold;
}
.hire-summary .list-inline {
    margin-top: 0px;
    margin-left: 15px;
}
.hire-summary .list-inline li {
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
}
.hire-summary .list-inline li > a {
    color: #000;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.hire-summary .list-inline li > a small {
    margin-left: 20px;
    color: #000;
}
.hire-summary .text-bold {
    margin-top: 0px;
    margin-bottom: 5px;
}
.text-title {
    color: #979797;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 0px;
}
#contract_detail .panel {
    border-radius: 0px;
    border: none;
    box-shadow: none;
}
#contract_detail .panel-body {
    padding-bottom: 10px;
    padding-top: 10px;
}
.text-title-para {
    color: #000;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.css-1rtrksz {
    padding: 0px !important;
}
.css-10nd86i {
    border: 1.2px solid #e8eef3 !important;
}
#business_details .signup-form .css-10nd86i {
    border: none !important;
    border-bottom: 1.2px solid #e8eef3 !important;
}
.css-2o5izw {
    box-shadow: none !important;
    border: none !important;
    border-radius: 0px !important;
}
.css-2o5izw:hover {
    background-color: transparent !important;
    border-color: transparent !important;
}
.css-2o5izw:focus {
    background-color: transparent !important;
    border: none !important;
    border-color: transparent !important;
    outline: 0px !important;
}
.css-1aya2g8 {
    background-color: transparent !important;
    border: none !important;
    outline: 0px;
}
.css-1aya2g8:focus {
    outline: none !important;
    border: none !important;
    background-color: transparent !important;
    border-radius: 0px !important;
}
.css-xwjg1b {
    background-color: #ff337d !important;
    color: #fff !important;
}
.css-1alnv5e:hover {
    background-color: #ff337d !important;
    color: #fff !important;
    cursor: pointer;
}
.css-10odw4q {
    color: #fff !important;
}
/* =========================Body section ======================================= */

/* =========================check box ======================================= */

.checkbox label:after,
.radio label:after {
    content: '';
    display: table;
    clear: both;
}
.accept-detail .checkbox label,
.radio label {
    padding-left: 0px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #000;
}
.checkbox .cr,
.radio .cr {
    position: relative;
    display: inline-block;
    border: 1px solid #a9a9a9;
    border-radius: .25em;
    width: 1.3em;
    height: 1.3em;
    float: left;
    margin-right: .5em;
}
.radio .cr {
    border-radius: 50%;
}
.checkbox .cr .cr-icon,
.radio .cr .cr-icon {
    position: absolute;
    font-size: .8em;
    line-height: 0;
    top: 50%;
    left: 20%;
    color: green;
}
.radio .cr .cr-icon {
    margin-left: 0.04em;
}
.checkbox label input[type="checkbox"],
.radio label input[type="radio"] {
    display: none;
}
.checkbox label input[type="checkbox"] + .cr > .cr-icon,
.radio label input[type="radio"] + .cr > .cr-icon {
    transform: scale(3) rotateZ(-20deg);
    opacity: 0;
    transition: all .3s ease-in;
}
.checkbox label input[type="checkbox"]:checked + .cr > .cr-icon,
.radio label input[type="radio"]:checked + .cr > .cr-icon {
    transform: scale(1) rotateZ(0deg);
    opacity: 1;
}
.checkbox label input[type="checkbox"]:disabled + .cr,
.radio label input[type="radio"]:disabled + .cr {
    opacity: .5;
}
.signup-form .dropdown-submenu {
    position: relative;
    margin: 8px 5px;
}
.signup-form .dropdown button {
    text-align: left;
    color: #000;
}
.signup-form .dropdown button {
    padding: 12px 5px;
    font-size: 16px;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    border: 1px solid #e8eef3 !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url('../images/down.png') no-repeat;
    background-position: 99% 55%;
    background-size: 12px;
}
.signup-form .dropdown button.btn-link {
    background: none;
    padding: 3px 10px;
    font-weight: 400;
    color: #333;
    border: none!important;
}
.signup-form .dropdown button.btn-link:hover,
.signup-form .dropdown button.btn-link:focus {
    text-decoration: none;
}
.signup-form .dropdown button.selected_services {
    white-space: normal;
    background-color: #fff;
}
.signup-form .dropdown li>a:hover {
    margin-left: 0px;
}
.signup-form .dropdown .btn-default {
    border: none;
    border-radius: 0px;
}
.signup-form .dropdown .btn-default:hover,
.signup-form .dropdown .btn-default:focus {
    background-color: #fff;
}
.signup-form .dropdown-submenu > .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border: 1px solid #e8eef3;
}
.signup-form .dropdown .dropdown-menu > li > a:hover,
.signup-form .dropdown .dropdown-menu > li > a:focus {
    background-color: #ccc;
}
.signup-form .dropdown-submenu:hover > .dropdown-menu {
    display: block;
}
.signup-form .dropdown-submenu > a:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #ccc;
    margin-top: 5px;
    margin-right: -10px;
}
.signup-form .dropdown-submenu:hover > a:after {
    border-left-color: #000;
}
.signup-form .dropdown-submenu.pull-left {
    float: none;
}
.signup-form .dropdown-submenu.pull-left > .dropdown-menu {
    left: -100%;
    margin-left: 10px;
    -webkit-border-radius: 6px 0 6px 6px;
    -moz-border-radius: 6px 0 6px 6px;
    border-radius: 6px 0 6px 6px;
}
#wallmessages .pull-right .price {
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
    margin-top: 5px;
    margin-right: 40px;
}
#wallmessages .pull-right {
    margin-top: 45px;
    margin-right: 30px;
}
#wallmessages .btn-login {
    padding: 2px 10px;
    background-color: #08988b;
}
.message-item {
    margin-bottom: 25px;
    margin-left: 40px;
    position: relative;
}
.message-item .message-inner {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 10px;
    position: relative;
}
.message-item .message-inner:before {
    border-right: 10px solid #ddd;
    border-style: solid;
    border-width: 10px;
    color: rgba(0, 0, 0, 0);
    content: "";
    display: block;
    height: 0;
    position: absolute;
    left: -20px;
    top: 6px;
    width: 0;
}
.message-item .message-inner:after {
    border-right: 10px solid #fff;
    border-style: solid;
    border-width: 10px;
    color: rgba(0, 0, 0, 0);
    content: "";
    display: block;
    height: 0;
    position: absolute;
    left: -18px;
    top: 6px;
    width: 0;
}
.message-item:before {
    background: #e8eef3;
    border-radius: 0px;
    bottom: -30px;
    /* box-shadow: 0 0 3px rgba(0,0,0,0.2); */
    content: "";
    height: 100%;
    left: -29px;
    position: absolute;
    width: 1.2px;
}
.message-item:after {
    background: #ff337d;
    border: 2px solid #ff337d;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    content: "";
    height: 15px;
    left: -36px;
    position: absolute;
    top: 10px;
    width: 15px;
}
.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}
.message-item .message-head {
    border-bottom: 1px solid #eee;
    margin-bottom: 8px;
    padding-bottom: 8px;
}
.message-item .message-head .avatar {
    margin-right: 20px;
}
.message-item .message-head .user-detail {
    overflow: hidden;
}
.message-item .message-head .user-detail h5 {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
}
.message-item .message-head .post-meta {
    float: left;
    padding: 0 15px 0 0;
}
.message-item .message-head .post-meta > div {
    color: #333;
    font-weight: bold;
    text-align: right;
}
.post-meta > div {
    color: #777;
    font-size: 12px;
    line-height: 22px;
}
.message-item .message-head .post-meta > div {
    color: #333;
    font-weight: bold;
    text-align: right;
}
.post-meta > div {
    color: #777;
    font-size: 12px;
    line-height: 22px;
}
#wallmessages img {
    width: 40px;
    height: 40px;
}
ul.timeline {
    list-style-type: none;
    position: relative;
}
ul.timeline:before {
    content: ' ';
    background: #000;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 3px;
    height: 87%;
    z-index: 400;
}
ul.timeline > li {
    margin: 40px 0;
    padding-left: 20px;
}
ul.timeline > li:before {
    content: ' ';
    background: white;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 2px solid #000;
    left: 15px;
    width: 30px;
    height: 30px;
    z-index: 400;
    margin-bottom: 40px;
}
ul.timeline .active:before {
    background-color: #ff337d;
    border: none;
}
.text-timeline {
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    position: absolute;
    margin-left: -130px;
    font-size: 16px;
    top: 10px;
}
.timeline > li .pull-right {
    margin-right: 50px;
}
.timeline-panel {
    padding: 10px 0px;
    border-radius: 4px;
}
.timeline-panel p {
    color: #000;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    margin-bottom: 0px;
}
.timeline-date {
    padding: 10px 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    background-color: #fff;
    margin-bottom: 30px;
}
.timeline-date p {
    color: #979797;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    margin-bottom: 0px;
}
.timeline-date .btn-link {
    color: #213FD4;
    text-decoration: none;
}
.timeline-date .btn-link:hover {
    text-decoration: none;
}
.timeline-date .active {
    color: #13887a;
}
.timeline-date .price {
    margin-top: 10px;
    margin-bottom: 10px;
}
#wallmessages .message-item:nth-last-child(1)::before {
    height: 50%;
    top: 30px;
}
.timeline-text-pink {
    color: #ff337d;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 12px;
    margin-top: 5px;
}
#track_status .bs-wizard {
    width: 80%;
    margin: auto;
    margin-top: 30px;
}
#accordion .panel {
    background-color: #F2F4F6;
    margin-top: 0px;
    border-radius: 0px;
    border-bottom: 0px;
}
#accordion .glyphicon {
    margin-right: 10px;
}
#accordion .panel-collapse {
    background-color: #fff;
}
#accordion ul {
    margin-bottom: 0px;
    margin-left: 5px;
    margin-right: 5px;
}
#accordion .panel-title .fa-angle-right {
    font-size: 18px;
    color: #000;
    margin-right: 5px;
}
#accordion .panel-title {
    padding: 15px 10px;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #000;
}
#accordion .panel:first-child {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}
#accordion .panel:last-child {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom: 1px solid #e8eef3;
}
.add-more {
    background-color: #f8e71c;
    border-radius: 50%;
    padding: 12px 21px;
    box-shadow: 0 0 40px rgba(0, 0, 0, .1);
}
.plus-sign {
    color: #000;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    text-align: center;
}
.add-more input {
    visibility: hidden;
}
/* =========================================Track======================================== */

/* =========================check box ======================================= */

/* =================================Chat-section====================================== */

#custom-search-input {
    /*border-right: 1px solid #e8eef3;*/
    /*border-left: 1px solid #e8eef3;*/
    /*border-bottom: 1px solid #e8eef3;*/
    /*border-top: 1px solid #e8eef3;*/
    height: 55px;
    margin-top: 0px;
    margin-bottom: -20px;
}
#custom-search-input button {
    position: absolute;
    top: 10px;
    left: 10px;
}
#custom-search-input button.btn-link {
    color: #000;
    font-size: 16px;
    text-decoration: none;
}
#custom-search-input .dropdown-menu {
    width: 100px;
    background-color: #000;
    border: 1px solid #000;
    top: 45px !important;
}
#custom-search-input .dropdown-menu li > a {
    color: #fff;
}
#custom-search-input .dropdown-menu li > a:hover,
#custom-search-input .dropdown-menu li > a:focus {
    background-color: transparent !important;
    color: #fff !important;
}
.member_list .chat-body {
    margin-left: 50px;
    margin-top: 0px;
}
.member_list .text-muted {
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.member_list ul > li {
    padding-top: 10px;
}
.member_list ul > li:hover {
    background-color: rgba(0, 0, 0, 0.1);
}
.member_list .active {
    background-color: rgba(0, 0, 0, 0.1);
    border-left: 2px solid #000;
    color: #fff;
}
.top_nav {
    overflow: visible;
}
.member_list .contact_sec {
    margin-top: -10px;
    margin-left: 5px;
}
.member_list li {
    padding: 0px 6px;
}
.member_list ul {
    border-bottom: 1px solid #e8eef3;
    background-color: #fff;
    margin-bottom: 0px;
}
.member_list ul .blank-dp {
    line-height: 28px !important;
    height: 40px !important;
    width: 40px !important;
}
.member_list ul .blank-dp span{
    font-size: 16px!important;
}
.chat-img img {
    height: 60px;
    width: 60px;
}
.member_list li {
    border-bottom: 1px solid #e8eef3;
    padding: 6px;
}
.chat_user_avatar img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    object-position: 0% 0%;
    border: 2px solid #ecb33c;
}
.chat_box .chat_message_wrapper .chat_user_avatar .blank-dp {
    line-height: 25px !important;
    height: 40px!important;
    width: 40px!important;
}
.chat_box .chat_message_wrapper .chat_user_avatar .blank-dp span {
    font-size: 13px!important;
}
.member_list li:last-child {
    border-bottom: none;
}
.notification-error {
    width: 70%;
    background-color: #fbf2e4;
    padding: 10px 15px;
    margin: 10px auto;
    text-align: left;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
    border-radius:  25px;
}
.member_list {
    /*border-right: 1px solid #e8eef3;*/
    border-top: 1px solid #e8eef3;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: auto;
}
.member_list_margin {
    width: 100%;
}
.chat_box.chat_box_colors_a .chat_message_wrapper ul.chat_message > li {
    word-break: break-word;
}
.new_message_head {
    padding: 5px 10px;
    width: 100%;
    background-color: #fff;
}
.new_message_head .table {
    margin-bottom: 0px;
    margin-top: 0px;
}
.new_message_head .table>tbody>tr>td .blank-dp {
    height: 40px!important;
    width: 40px!important;
    line-height: 25px;
}
.new_message_head .table>tbody>tr>td .blank-dp span {
    font-size: 14px!important;
}
.new_message_head .table>tbody>tr>td .time {
    margin: 0px;
}
.new_message_head .table>tbody>tr>td .media-heading {
    margin-top: 10px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.new_message_head .table>tbody>tr>td .service-name{
    color:#ff337d;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.new_message_head .table>tbody>tr>td .media img {
    border: 2px solid #ecb33c;
}
.new_message_head .table>tbody>tr>td .btn-link{
  color:#ff337d;
    font-size: 16px;
}
.new_message_head .table>tbody>tr>td {
    vertical-align: middle;
    border: none;
    padding: 0px;
}
.new_message_head:after {
    content: '';
    height: 1px;
    background-color: #e8eef3;
    position: absolute;
    top: 55px;
    left: 0;
    bottom: 0;
    width: 100%;
}
.new_message_head .table>tbody>tr>td.call-btn-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 10px;
}
.new_message_head .pull-left li > a {
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    margin-left: 10px;
}

.message_section {
    border-top: 1px solid #e8eef3;
    border-left: 0px solid #e8eef3;
    border-right: 1px solid #e8eef3;
    border-bottom: 1px solid #e8eef3;
}
.group-media{
    margin-top: 10px;
}
.group-media .media-heading{
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000;
}
.group-media .text-bio{
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #888888;
}
.group-media .media-heading .btn-link{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #888888;
}
.group-media .blank-dp{
    height: 40px!important;
    width: 40px!important;
    line-height: 25px;
}
.group-media .blank-dp span{
    font-size: 14px!important;
}
.chat_sidebar {
    background: #fff;
    border: 1px solid #e8eef3;
}
.chat_area {
    margin-top: 5px;
    float: left;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
}
.chat_area li {
    padding: 14px 14px 0;
}
.chat_area li .chat-img1 img {
    height: 40px;
    width: 40px;
}
.chat_area .chat-body1 {
    margin-left: 0px;
}
.chat_area .panel {
    border: none;
    border-radius: 0px;
    box-shadow: none;
}
.chat_area .panel-body {
    padding: 10px 10px
}
.chat_area li:last-child {
    padding-bottom: 10px;
}
.message_write {
    background: #fff;
    max-width: 100%;
    padding: 0px 0px;
}
.message_write:after {
    content: '';
    height: 1px;
    background-color: #e8eef3;
    position: absolute;
    top: 0px;
    left: 0;
    bottom: 0;
    width: 100%;
}
.message_write:before {
    content: '';
    height: 0px;
    background-color: #e8eef3;
    position: absolute;
    top: 80px;
    left: 0;
    bottom: 0;
    width: 100%;
}
.message_write .clearfix {
    border-bottom: 1px solid #e8eef3;
    padding: 0px;
}

.send-message-box{
    border-top: 1px solid #e8eef3;
    border-bottom: 0px solid #e8eef3;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    background: #fff;
    /*bottom: -60px;*/
}
.text-box{
  width:86%;
}
.text-box textarea{
    border: 1px solid #e8eef3;
    width: 100%;
    border-radius: 35px;
    padding:10px 15px;
    max-height: 120px;
    margin-top: 5px;
    overflow-y: auto;
}
.attach-box{
    width:7%;
}
.attach-box img{
    width: 25px;
    cursor: pointer;
}
.send-button-box{
    width:7%;
}
.send-button-box img{
    width: 35px;
}
.chat_bottom {
    float: left;
    width: 100%;
    padding: 0px 15px;
    border-top: 1px solid #e8eef3;
    vertical-align: middle;
}
.chat_bottom ul {
    margin-bottom: 0px;
}
.chat_bottom ul li {
    margin: 5px;
}
.chat_bottom ul li img {
    width: 25px;
}
.chat_box {
    padding: 5px !important;
}
.chat_bottom .btn-link {
    margin-right: 10px;
}
.chat_bottom label {
    color: #979797;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.chat_bottom .pull-right a {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000;
    font-size: 14px;
    margin-left: 30px;
}
.upload_btn {
    color: #777777;
}

.sub_menu_ > li a,
.sub_menu_ > li {
    float: left;
    width: 100%;
}
.member_list li:hover {
    color: #fff;
    cursor: pointer;
}
.primary-font {
    color: #000;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.primary-font span {
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.header_sec .time {
    margin-right: 5px;
    margin-top: 2px;
}
/* =================================Chat-section====================================== */


/* ========================================Footer section========================= */
.blizzcraft-footer{
position: relative;
}
.footer {
    position: absolute;
    width: 100%;
    right: 0;
    left: 0;
    padding-left: 10px;
    padding-right: 10px;
    background-color: transparent;
}
.footer .row {
    border-top: 1px solid #e8eef3;
    padding-top: 10px
}
.footer h5,
.footer a {
    color: #97989a;
    margin-right: 0px;
    margin-left: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.footer a:hover,
.footer a:focus {
    color: #ff337d
}
/* ========================================Footer section========================= */

/* ========================================Dashboard section========================= */

/*Main CSS*/

.dashboard-icon {
    display: none;
}
.dashboard-icon-show {
    display: block;
}
/*Login Signup Page*/

a:focus,
a:hover,
a {
    outline: none;
    text-decoration: none;
}
.navi li,
.navi ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.navi li {
    margin-bottom: 7px;
}
.logo ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.header-top i {
    font-size: 18px;
}
/*Login Signup Page*/

/*Home Page*/

#navigation {
    background: #fff;
    height: 100%;
    position: fixed;
}
.navigation-width {
    width: 5%;
}
.display-full-wiidth {
    width: 100%;
    margin-left: 0px;
}
#navigation {
    padding: 0;
}
#navigation .logo {
    padding-bottom: 2px;
}
#navigation .logo .dropdown {
    padding: 19.5px;
}
.display-table {
    display: table;
    padding: 0;
    height: 100%;
    width: 100%;
}
.display-table-row {
    display: table-row;
    height: 100%;
}
.display-table-cell {
    float: none;
    height: 100%;
}
#v-align .navbar-default {
    padding: 0px 21px;
}
#v-align {
    position: fixed;
    z-index: 2;
}
#dashboard-name {
    position: fixed;
    z-index: 2;
}
.v-align {
    vertical-align: top;
    /* -webkit-transition: all 0.5s ease; */
    /* -moz-transition: all 0.5s ease; */
    /* -o-transition: all 0.5s ease; */
    /* transition: all 0.5s ease; */
}
.logo img {
    width: 65px;
    height: 65px;
    padding: 10px;
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
    object-position: 50% 25%;
}
.logo a {
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #000;
    margin-left: 60px;
}
.logo .dropdown-menu {
    left: 50px;
    top: 70px;
    border: none;
}
.logo .dropdown-menu > li > a {
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #000;
    padding: 8px 50px;
    margin-left: 0px;
}
.header-top {
    margin: 0;
    padding-top: 2px;
}
.header-top img {
    max-width: 48px !important;
    width: 100%;
}
.add-project {
    background: #fff;
    border: 1.2px solid #ff337d;
    color: #ff337d;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
    padding: 8px 20px;
    position: relative;
    border-radius: 4px;
}
.add-project:hover {
    background-color: #ff337d;
    color: #fff;
}
.add-project:hover .color-pink {
    color: #fff;
}
.header-rightside ul > li {
    margin-right: 30px;
}
.header-rightside .nav > li > a:focus,
.header-rightside .nav > li > a:hover {
    background: none;
    text-decoration: none;
}
.add-project::before {
    /* background: rgba(0, 0, 0, 0) url("../images/plus.png") no-repeat scroll 0 0; */
    content: "";
    height: 12px;
    left: 17px;
    position: absolute;
    top: 12px;
    width: 12px;
}
.add-project:hover {
    color: #ffffff;
}
.header-top i {
    color: #0e1a35;
}
.header-icon {
    position: relative;
}
.navi {
    margin-top: 20px;
}
.navi i {
    font-size: 20px;
}
.label.label-primary {
    border-radius: 50%;
    font-size: 10px;
    left: 5px;
    position: absolute;
    top: -2px;
}
.header-icon .label {
    border: 2px solid #ffffff;
    font-weight: 500;
    padding: 3px 5px;
    text-align: center;
}
.header-top li {
    display: inline-block;
    text-align: center;
}
.header-top .dropdown-toggle {
    color: #0e1a35;
}
.header-top .dropdown-menu {
    border: medium none;
    left: -85px;
    padding: 17px;
}
.view {
    background: #5584ff none repeat scroll 0 0;
    border-radius: 100px;
    color: #ffffff;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    margin-top: 10px;
    padding: 10px 15px;
}
.navbar-content > span {
    font-size: 13px;
    font-weight: 700;
}
.img-responsive {
    width: 100%;
}
#navigation {
    /* -webkit-transition: all 0.5s ease; */
    /* -moz-transition: all 0.5s ease; */
    /* -o-transition: all 0.5s ease; */
    /* transition: all 0.5s ease; */
    z-index: 100
}
#header {
    background: #ffffff none repeat scroll 0 0;
    /* box-shadow: 0 1px 12px rgba(0, 0, 0, 0.04); */
    display: inline-block !important;
    /* border-bottom:1px solid #cbcbcb; */
    padding: 25px;
    transition: all 0.5s ease 0s;
    width: 100%;
    /* -webkit-transition: all 0.5s ease; */
    /* -moz-transition: all 0.5s ease; */
    /* -o-transition: all 0.5s ease; */
    /* transition: all 0.5s ease; */
}
.label-primary {
    background: #ff337d;
    margin-left: 5px;
    margin-top: -7px;
}
.header-icon .fa-bell {
    font-size: 24px;
}
.navi a {
    border-bottom: 0px solid #0d172e;
    border-top: 0px solid #0d172e;
    color: #6d6d6d;
    display: block;
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    padding: 10px 10px;
    /* margin-left:30px; */
    text-decoration: none;
}
.navi i {
    margin-right: 15px;
    color: #5584ff;
}
.navi img {
    width: 15px;
    margin-right: 20px;
    margin-top: -5px;
}
.navi .active {
    background-color: #fff;
}
.navi li > a {
    margin-left: 10px;
}
.navi .active a {
    background: transparent;
    color: #ff337d;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 12px;
    border-left: 0px solid #5584ff;
}
.navi a:hover {
    background: transparent;
    color: #ff337d;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 12px;
    border-left: 0px solid #5584ff;
    display: block;
}
.left-margin {
    margin-left: 20px;
}
.left-text {
    margin-left: 200px;
}
.left-text-margin {
    margin-left: 150px;
}
#infom_text p {
    margin-left: -30px;
}
.info-text {
    font-size: 26px;
    color: #000;
    margin-top: 4px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.hi-text {
    font-size: 26px;
    color: #000;
    margin-top: 4px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
#infom_text .first-name {
    font-size: 26px;
    color: #000;
    margin-top: 4px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.navbar-default {
    background-color: #ffffff;
    border-color: #ffffff;
}
.navbar-toggle {
    border: none;
}
.header .navbar-default .navbar-toggle:focus,
.header .navbar-default .navbar-toggle:hover {
    background-color: rgba(0, 0, 0, 0);
}
.header .navbar-default .navbar-toggle .icon-bar {
    background-color: #0e1a35;
}
.collapse-aerrow {
    position: absolute;
    bottom: 20px;
    right: 0;
}
.ultop-margin {
    margin-top: 20px;
}
.collapse-aerrow .fa-angle-double-right {
    color: #fff;
    cursor: pointer;
    font-size: 22px;
}
.collapse-aerrow .fa-angle-double-left {
    color: #fff;
    cursor: pointer;
    font-size: 22px;
}
.circle-logo {
    margin: 0 auto;
    max-width: 30px !important;
    text-align: center;
}
.hidden-xs {
    /* -webkit-transition: all 0.5s ease; */
    /* -moz-transition: all 0.5s ease; */
    /* -o-transition: all 0.5s ease; */
    /* transition: all 0.5s ease; */
}
.user-dashboard {
    padding: 0 20px;
}
.user-dashboard h1 {
    color: #0e1a35;
    font-size: 30px;
    font-weight: 500;
    margin: 0;
    padding: 21px 0;
}
.sales {
    background: #ffffff none repeat scroll 0 0;
    border: 1px solid #d4d9e3;
    display: inline-block;
    padding: 15px;
    width: 100%;
}
.sales button {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: 1px solid #dadee7;
    border-radius: 100px;
    font-size: 15px;
    letter-spacing: 0.5px;
    padding-right: 32px;
    color: #0e1a35;
}
.sales button::before {
    content: "";
    font-family: FontAwesome;
    position: absolute;
    right: 12px;
    top: 11px;
}
.sales .btn-group {
    float: right;
}
.sales h2 {
    color: #8492af;
    float: left;
    font-size: 21px;
    font-weight: 600;
    margin: 0;
    padding: 9px 0 0;
}
.btn.btn-secondary.btn-lg.dropdown-toggle > span {
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.5px;
}
.sales .dropdown-menu {
    margin: 0px;
    padding: 0px;
    border: 0px;
    border-radius: 8px;
    width: 100%;
    color: #0e1a35;
}
.sales .btn-group.open .dropdown-toggle,
.btn.active,
.btn:active {
    box-shadow: none;
}
.sales .dropdown-menu > a {
    color: #0e1a35;
    display: inline-block;
    font-weight: 800;
    padding: 9px 0;
    text-align: center;
    width: 100%;
}
#my-cool-chart svg {
    width: 100%;
}
.sales .dropdown-menu > a:hover {
    color: #5584FF;
}
.shield-buttons {
    display: none;
}
.global-top-margin {
    margin-top: 50px;
    width: 100% !important;
}
.suggestion-left-box {
    border-left: 1px solid #e8eef3;
    margin-top: -100px;
    padding-top: 50px;
}
.proposal-left-box {
    margin-top: 0px;
}
.suggestion-left-box .btn-margin {
    margin-top: 0px;
}
.btn-border-left {
    border-left: 1px solid #e8eef3;
}
.signup-form .description-tips-p {
    margin-top: -30px;
}
.onboarding-box {
    margin-top: 15px;
    justify-content: start;
}
.onboarding-box .btn-strip-box {
    width: 100%;
}
.new_message_head .btn-expert-chat{
    background: #2d3feb;
    padding: 6px 7px;
    margin-top: 4px;
    width:auto;
    display: inline-block;
    font-size: 12px;
}
.wiz-assured{
    width:125px;
    margin-top: 4px;
}
.message_write img{
    margin-left: -20px;
}
.error-box{
    position: absolute;
    width:100%;
    margin: auto;
    margin-top: -50px;
}
.freebies-strip{
    width: 100%;
    background: #618fe9;
    padding:10px 20px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
}
.strip-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.strip-container-text{
    display: flex;
    align-items: center;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
}
.strip-container-text img{
    float: left;
    width: 35px;
    margin-right: 10px;
}
.btn-freebies-app{
    padding: 6px 15px;
    background: #ffffff;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
    width: 100px;
}
.btn-freebies-app+.btn-freebies-app{
    margin-left: 10px;
}
.btn-freebies-app+.close{
    color: #fff;
    margin-left: 10px;
}
.freebies-chat-banner{
    display: none;
}
@media only screen and (max-device-width: 767px) {
    .new_message_head .table>tbody>tr>td .service-name{
        font-size: 12px;
    }
    .signup-section{
        display: flex;
        flex-direction: column;
    }
    .form-container{
        background: #fff;
        width: 100%;
        padding: 20px 20px;
    }
    .add-container{
        width: 100%;
        background: #fff;
    }
    .d-update-profile-head .nav-tabs.nav-justified>li{
        width: 0%;
    }
    .freebies-modal .modal-dialog {
       margin-top: 50%;
        margin-left: 0px;
        margin-right: 0px;
        border-radius: 8px;
    }
    .freebies-modal .modal-dialog .modal-content{
       background: transparent;
       box-shadow: none;
        border: none;
    }
    .freebies-chat-banner{
        display: block;
        position: fixed;
        background: #5763e7;
        padding:10px 20px;
        color: #fff;
        margin-top: 55px;
        width: 100%;
        z-index: 1;
    }
    .is-freebies{
        padding-top: 160px;
    }
    .freebies-ul{
        padding-inline-start: 10px;
    }
    .freebies-ul li{
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
    font-weight: 500;
        color: #fff;
        margin-bottom: 6px;
    }
    .freebies-banner-heading{
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
    font-weight: 500;
        color: #fff;
        margin-top: 0px;
    }
    .freebies-chat-banner-modal{
        position: relative;
    }
    .modal-body.freebies-chat-banner-modal{
        padding: 0px;
    }
    .freebies-chat-banner-modal .freebies-banner-heading{
        font-size: 14px;
    }
    .freebies-chat-banner-modal .freebies-ul li{
        font-size: 12px;
        margin-bottom: 4px;
    }
   .freebies-chat-banner-modal button.close{
       color: #fff;
    }
   .freebies-chat-banner-modal .btn-link{
      color: #fff;
      font-size: 16px;
      font-family: 'Poppins', sans-serif;
    font-weight: 500;
       margin-top: 15px;
       padding: 0px;
   }
    .btn-app-download{
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
    font-weight: 500;
        color: #000;
        padding: 5px 25px;
        background: #fff;
        border: 1.5px solid #000;
    }
    .freebies-chat-banner-modal img{
        width: 100%;
    }
    .freebies-modal-banner{
        position: absolute;
        top: 0;
        padding: 10px 15px;
        margin-top: 30px;
        width: 60%;
    }
    .freebies-modal-banner .btn-app-download{
        font-size: 14px;
        padding: 5px 25px;
    }

    .modal .signup-form .form-group {
        margin-bottom: 15px;
    }
    .d-update-profile-head .d-update-flex-body{
        display: block;
    }
    .d-update-flex-box{
        flex-grow: 4;
    }
    .d-update-flex-box img{
        width: 90px!important;
    }
    .info-container{
        right: 0px;
    }
    .btn-mark-complete{
        font-size: 14px;
    }
    .chat-quick-reply-btn-container{
        padding: 10px 15px;
    }
    .quick-btn-ul{
        margin-bottom: 5px;
    }
    .quick-btn-ul>li>a{
        font-size: 14px;
    }
    .signup-form input{
        font-size: 14px;
    }
    .chat_box .chat_message_wrapper{
        padding-left: 10px;
    }
    .chat_box .chat_message_wrapper ul.chat_message{
        margin: 0px 0px 5px;
    }
    .chat_message_wrapper.middle_chat_ui+.chat_message_wrapper.sender-party{
        padding-top: 10px;
    }
    .chat_message_wrapper.sender-party+.chat_message_wrapper.middle_chat_ui{
        padding-top: 10px;
    }
    .chat_box .chat_message_wrapper ul.chat_message > li .para{
        line-height: 1.4em;
        font-size: 14px;
    }
    .new_message_head .table>tbody>tr>td .blank-dp{
        width:30px!important;
        height: 30px!important;
        line-height: 15px;
    }
    .new_message_head .table>tbody>tr>td .blank-dp span{
        font-size: 12px!important;
    }
    .new_message_head .table>tbody>tr>td .media img{
        width:30px!important;
        height: 30px!important;
    }
    .new_message_head .table>tbody>tr>td .media-heading{
        margin-top: 2px;
        font-size: 14px;
    }
    .chat_user_avatar img {
        width: 30px;
        height: 30px;
        object-fit: cover;
        object-position: 0% 0%;
        border: 2px solid #ecb33c;
    }
    .chat_box .chat_message_wrapper .chat_user_avatar .blank-dp {
        line-height: 15px !important;
        height: 30px!important;
        width: 30px!important;
    }
    .sendar-name{
        margin-bottom: 3px;
        font-size: 14px;
        font-family: Helvetica, Arial, sans-serif;
    }
    .text-box{
        width:80%;
    }
    .send-message-box{
        position: absolute;
        width: 100%;
        background: #fff;
        /*bottom: -60px;*/
        padding: 0px 10px;
    }
    .text-box textarea{
        margin-top: 5px;
        margin-bottom: 0px;
        padding: 5px 15px;
        width: 100%;
        max-height: 120px;
        overflow: auto;
        box-sizing: border-box;
        /* resize: none; */
    }
    .attach-box{
        width:10%;
    }
    .send-button-box{
        width:10%;
    }
    .send-button-box img{
        margin-top: -5px;
        margin-left: -5px;
        width: 30px;
    }
    .member_list {
        max-height: 600px;
        overflow: hidden;
        overflow-y: scroll;
        margin-bottom: 10px;
    }
    .chat_area .time{
        font-size: 10px;
    }
    .request-payment-ul li{
        font-size: 14px;
        line-height: 1.4em;
    }
    #custom-search-input{
        background: #fff;
        top:0;
        position: sticky;
    }
    .chat_sidebar{
        margin-top: 0px;
    }
    .notification-error {
        width: 100%!important;
        font-size: 14px;
    }
    .message_section {
        border-left: 1px solid #e8eef3;
    }
    .wiz-assured{
        width:110px!important;
        margin-top: 5px;
    }
    #fc_frame{
        display: none!important;
    }
    .message_write img {
        width: 30px!important;
        margin-top: 5px!important;
        margin-left: -10px;
    }
    .chat-row{
        margin-bottom: 0px;
    }
    .chat_area{
        margin-top: 55px;
    }
    .chat_area {
        overflow-y: auto;
    }
    textarea{
        font-size: 14px!important;
    }
    .chat_box{
        padding: 0px!important;
    }
    .chat-top-margin{
        margin-top: 15px;
    }
    .new_message_head{
        position: fixed;
        z-index: 1000;
        padding: 2px 8px;
        left: 0;
        right: 0;
    }

    .new_message_head .btn-link img{
        margin-top: 5px;
        /*width: 28px!important;*/
    }
    .btn-link.schedule-icon{
        margin: 0px 10px!important;
    }
    .new_message_head .btn-link.schedule-icon{
        width: 18px!important;
    }
    .new_message_head .table>tbody>tr>td .btn-link{
        font-size: 20px;
        padding: 5px 8px;
    }
    .new_message_head .table>tbody>tr>td .btn-link+.btn-link{
        margin-left: 10px;
    }
    .new_message_head .table>tbody>tr>td.call-btn-box{
        display: flex;
        margin: 8px 5px;
    }
    .btn-zoom-call,.btn-call-schedule{
        padding: 5px 6px;
        font-size: 12px;
    }
    .btn-call-schedule+.btn-zoom-call{
        margin-left: 5px;
    }
    .g-chat-container{
        position: fixed;
        width: 100%;
        left: 0;
        right: 0;
    }
    .new_message_head .btn-expert-chat{
        padding: 7px 5px;
        margin-top: 5px;
        font-size: 12px;
    }
    .new_message_head:after{
        top:53px
    }
    .uploaded_doc a {
        width: auto;
        padding: 6px 6px;
        border-radius: 10px;
        font-size: 12px;
        white-space: nowrap;
    }
    .onboarding-box {
        display: block!important;
    }
    .onboarding-box .btn-strip-box {
        flex-grow: unset;
        margin-left: 0px;
        margin-right: 0px;
        float: none;
    }
    #MILESTONES .modal-dialog {
        width: auto;
    }
    .media-top img {
        width: 30px!important;
    }
    .slot-table .table > tbody > tr > td {
        display: table-row;
        margin-bottom: 20px;
    }
    .slot-table .table > tbody > tr > td .react-datepicker-wrapper {
        margin-bottom: 10px;
    }
    .slot-table .table > tbody > tr > td b {
        margin-top: 5px;
    }
    .modal-body .log-in-button {
        padding: 10px 15px;
        font-size: 16px;
    }
    .btn-referral-code {
        padding: 6px 15px!important;
        font-size: 14px;
    }
    .btn-nxt,
    .btn-prev {
        padding: 8px 20px;
        font-size: 16px;
    }
    .onboarding-container {
        margin-top: 10px;
        padding: 10px 0px;
    }
    #open_dropdown {
        margin-bottom: 15px!important;
    }
    .update-panel {
        margin-top: 20px;
        padding-left: 0px;
        padding-right: 0px;
        border-radius: 0px;
        box-shadow: 0 2px 10px rgba(152, 152, 152, 0.5);
    }
    .update-panel .para {
        font-size: 14px;
    }
    .signup-form .description-tips-p {
        margin-top: 0px;
    }
    .doc-upload {
        float: left!important;
    }
    .navbar-inverse {
        padding: 0px 0px;
    }
    form .text-dark {
        margin-top: 10px!important;
    }
    .twPc-bg {
        display: none;
    }
    .heading-onboarding {
        font-family: 'Poppins', sans-serif;
    font-weight: 500;
        font-size: 16px;
    }
    .sub-heading-onboarding {
        font-size: 16px;
    }
    .footer {
        /*position: relative;*/
        padding-left: 5px;
        padding-right: 5px;
        background-color: transparent;
    }
    .footer h5,
    .footer a {
        font-size: 15px;
        text-align: left;
    }
    .footer .text-right {
        text-align: left;
    }
    .Proposal-list .alert-default .pull-right {
        float: right !important;
    }
    .chat_with_cunsultant .twPc-bg {
        display: none;
    }
    .chat_with_cunsultant .panel-body {
        padding: 5px 0px 5px 5px;
    }
    .cunsult-chat-box{
        padding: 10px 5px;
    }
    .inner-tab-nav {
        padding: 0px;
    }
    .alert-dismissible {
        width: 90% !important;
        left: 25px;
        right: auto;
        top: 50px;
        font-size: 12px;
    }
    .alert-dismissible .btn-info {
        margin-top: 10px;
    }
    .update-profile .form-group {
        margin-bottom: 10px;
    }
    .user-dashboard-table .heading-md {
        font-size: 18px;
        margin-top: 10px;
    }
    .navbar-inverse .navbar-logo {
        width: 80px;
        margin-top: 0px;
    }
    .text-container a {
        font-size: 12px;
    }
    .text-INR.pull-right {
        float: none !important;
    }
    .text-INR {
        margin-top: 10px;
        font-size: 14px;
        text-align: left;
    }
    .btn-make-payment {
        margin-top: 15px;
    }
    .btn-border-left {
        border-left: none;
    }
    .consultation-border-left {
        border: none;
    }
    .consultant-dp {
        height: 55px;
        width: 55px;
    }
    .consultant-panel .blank-dp {
        height: 55px!important;
        width: 55px!important;
        padding-top: 12px!important;
    }
    .consultant-panel .blank-dp span {
        font-size: 16px!important;
    }
    .consultant-name {
        font-size: 12px;
    }
    .cunsultant-li li {
        font-size: 14px;
        margin-bottom: 0px;
        margin-right: 5px;
    }
    .cunsultant-li li span {
        margin-left: 10px;
        margin-right: 10px;
    }
    .btn-pay {
        padding: 6px 15px;
        font-size: 14px;
    }
    .consultant-panel {
        margin-bottom: 20px;
    }
    .consultant-panel .para {
        line-height: 1.2em;
        font-size: 12px;
    }
    .suggestion .row {
        margin-top: 0px;
        margin-bottom: 0px;
    }
    .suggestion .panel-body {
        padding: 5px;
    }
    .suggestion-left-box {
        border-left: 0px solid #e8eef3;
        margin-top: 0px;
        padding-top: 0px;
    }
    .suggestion .list-inline li {
        font-size: 16px;
    }
    .suggestion .list-inline li > a {
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
    font-weight: 400;
    }
    .suggestion .list-inline li > a small {
        font-size: 12px;
        font-family: 'Poppins', sans-serif;
    font-weight: 400;
    }
    .suggestion .list-inline {
        margin-left: 5px;
    }
    .service_name {
        font-size: 14px;
    }
    .text-container .para {
        font-size: 14px;
        overflow-wrap: break-word;
        word-wrap: break-word;
    }
    .progress_bar {
        width: 100% !important;
    }
    .user-dashboard-table .table > thead > tr > th {
        font-size: 12px;
    }
    .user-dashboard-table .table > tbody > tr > td {
        line-height: 1.2em;
        font-size: 12px;
    }
    .user-dashboard-table .table > tbody > tr > td > a {
        font-size: 12px;
    }
    .user-dashboard-table .table > tbody > tr > td .btn-filter-apply {
        font-size: 12px;
        margin-left: 0px;
        padding: 5px;
    }
    .user-dashboard-white-box .user-dashboard-table .heading-md {
        font-size: 16px;
    }
    .user-update-panel {
        padding: 0px 0px;
    }
    .user-update-panel .para {
        line-height: 1.2em;
        font-size: 14px;
    }
    #blank_proposal .para {
        font-size: 14px;
        line-height: 1.3em;
    }
    #blank_proposal .xs-heading {
        font-size: 16px;
    }
    #blank_proposal ol {
        margin-top: 10px;
    }
    #blank_proposal ol > li {
        margin-top: 5px;
        font-size: 14px;
    }
    .global-top-margin {
        margin-top: 30px;
        margin-left: -10px;
        margin-right: -10px;
    }
    .global-top-margin.global-top-margin-history{
        margin-top: 0px;
    }
    .signup-form .form-group {
        margin-bottom: 10px;
    }
    .general-onboarding .signup-form {
        margin-top: 0px;
    }
    .selected-professional-card {
        position: relative;
        box-shadow: 0 2px 10px rgba(0, 0, 0, .1);
        transition: box-shadow 0.3s ease 0s;
        min-height: 180px;
        padding: 5px 15px;
        margin-top: 20px;
    }
    .selected-professional-card .profile-name {
        font-size: 18px;
    }
    .selected-professional-card .para {
        font-size: 14px !important;
    }
    .selected-professional-card .text-bold {
        margin-top: 0px;
    }
    .selected-professional-card .media {
        margin-bottom: 10px;
    }
    .side-card-heading {
        line-height: 1em;
    }
    .ui-sign-heading {
        font-size: 16px;
        margin-top: 20px;
        margin-bottom: 15px;
    }
    .signup-form.new-professional-signup .para{
       font-size: 12px;
    }
    .ui-sign-heading .btn-link {
        margin-top: -10px;
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
    font-weight: 400;
    }
    .inside-logo {
        width: 100px;
        /*margin-top: -10px;*/
    }
    .margin-left .ui-sign-heading {
        margin-top: 0px;
        font-size: 22px;
    }
    .login-page-header {
        padding-bottom: 0px;
    }
    .signup-form {
        margin-top: 10px;
    }
    .header {
        padding-right: 0px;
    }
    .text-dark {
        font-size: 16px;
    }
    .signup-form .dropdown-submenu > .dropdown-menu {
        position: relative!important;
        top: 0;
        left: 0;
        width: 100%!important;
        box-shadow: none!important;
    }
    .login-logo img {
        margin: 0 auto;
    }
    #navigation {
        margin-top: 75px;
    }
    .login-details .nav-tabs > li {
        text-align: center;
        width: 50%;
    }
    .login-signup .login-inner h1 {
        font-size: 26px;
        margin-bottom: 0;
        margin-top: 10px;
    }
    .login-inner .login-form input {
        font-size: 15px;
        max-width: 100%;
        padding: 15px 45px;
    }
    .login-inner .form-details {
        padding: 25px;
    }
    .login-inner .login-form label {
        margin-bottom: 20px;
        width: 100%;
    }
    .login-inner .form-btn {
        margin: 0;
        max-width: 180px;
    }
    .user-dashboard-white-box .tab-content .tab-pane {
        padding: 0px;
    }
    .user-dashboard-white-box #PROGRESS_JOB {
        margin-top: 0px !important;
    }
    .user-dashboard-white-box {
        margin-top: 30px;
    }
    .heading-text.text-dark {
        font-size: 18px!important;
        margin-top: 10px!important;
    }
    .heading-text {
        font-size: 12px !important;
        margin-bottom: 0px;
    }
    .nav.navbar-nav.navbar-right .blank-dp {
        margin-left: 0px!important;
    }
    .hire-summary .list-inline {
        margin-left: 25px;
    }
    .hire-summary .dp-image {
        height: 45px;
        width: 45px;
    }
    .hire-summary .blank-dp {
        height: 45px!important;
        width: 45px!important;
        padding-top: 10px!important;
    }
    .chat_with_cunsultant .btn-link img {
        width: 25px !important;
    }
    .chat_with_cunsultant .btn-link.btn-call img {
        width: 25px !important;
    }
    .chat_with_cunsultant .btn-bs-file > img {
        width: 20px !important;
    }
    .chat_with_cunsultant .chat_area{
        margin-top: 0px;
    }
    .no-padding {
        padding: 0px!important;
    }
    .chat_with_cunsultant .heading-md {
        font-family: 'Poppins', sans-serif;
    font-weight: 400;
        font-size: 10px !important;
        text-align: left;
    }
    .MESSAGES h5 a {
        font-family: 'Poppins', sans-serif;
    font-weight: 400;
    }
    /*#progress_list_case{*/
    /*	padding: 5px;*/
    /*}*/
    .ui-white-box {
        padding: 1px 10px;
        border-radius: 0px;
        min-height: 650px;
    }
    .common-top-bottom-margin .media-left img {
        width: 30px !important;
    }
    .common-top-bottom-margin .scope-sub-heading,
    .common-top-bottom-margin .xs-para {
        text-align: left;
        line-height: 1.2em;
        font-size: 14px;
    }
    .side-box-container {
        margin-top: 20px;
        margin-bottom: 10px;
    }
    .side-box-container .heading-text {
        font-size: 18px!important;
    }
    .col-sm-3 .side-box-container:nth-last-child(1) {
        min-height: 200px;
    }
    .job_title .pull-right {
        float: none !important;
    }
    .ui-progress-job {
        margin-top: 20px;
    }
    .nav-tabs .margin-left {
        margin-left: 4%;
    }
    .job_title .nav-tabs > li.active > a,
    .job_title .nav-tabs > li.active > a:focus,
    .job_title .nav-tabs > li.active > a:hover {
        font-size: 12px;
    }
    .job_title .nav-tabs > li > a {
        font-size: 12px;
    }
    #navigation .navi a {
        font-size: 12px;
        padding: 20px;
        text-align: center;
    }
    #navigation .navi i {
        margin-right: 0px;
    }
    #navigation .navi a:hover,
    #navigation .navi .active a {
        border-left: none;
        display: block;
        padding-left: 20px;
    }
    header .header-top img {
        max-width: 38px !important;
    }
    .v-align header {
        padding: 12px 15px;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
    }
    header .header-top li {
        padding-left: 13px;
        padding-right: 6px;
    }
    .navbar-default .navbar-toggle {
        border-color: rgba(0, 0, 0, 0);
    }
    #header .navbar-header .navbar-toggle {
        float: left;
        margin: 0;
        padding: 0;
        top: 12px;
    }
    .navbar-header .app-button li img{
      width: 65px;
      margin-top: 15px;
    }
    .user-dashboard .sales h2 {
        color: #8492af;
        float: left;
        font-size: 14px;
        font-weight: 600;
        margin: 0;
        padding: 13px 0 0;
    }
    .user-dashboard .btn.btn-secondary.btn-lg.dropdown-toggle > span {
        font-size: 11px;
    }
    .user-dashboard .sales button {
        font-size: 11px;
        padding-right: 23px;
    }
    .user-dashboard .sales h2 {
        font-size: 12px;
    }
    .gutter {
        padding: 0;
    }
}
@media only screen and (max-device-width: 992px) {
    header .header-top li {
        padding-left: 20px !important;
        padding-right: 0;
    }
    header .logo img {
        max-width: 125px !important;
    }
}
@media only screen and (min-device-width: 767px) and (max-device-width: 998px) {
    .user-dashboard .header-top {
        padding-top: 5px;
    }
    .user-dashboard .header-rightside {
        display: inline-block;
        float: left;
        width: 100%;
    }
    .user-dashboard .header-rightside .header-top img {
        max-width: 41px !important;
    }
    .user-dashboard .sales button {
        font-size: 10px;
    }
    .user-dashboard .btn.btn-secondary.btn-lg.dropdown-toggle > span {
        font-size: 12px;
    }
    .user-dashboard .sales h2 {
        font-size: 15px;
    }
}
@media only screen and (min-device-width: 998px) and (max-device-width: 1350px) {
    #navigation .logo img {
        width: 65px;
        height: 65px;
    }
}
/* ========================================Media query========================= */

@media screen and (max-width: 767px) {
    .center-modal .modal-dialog {
        position: relative;
        width: 100%;
        margin: auto;
        top: 65px;
        left: 0;
        transform: none!important;
    }
    .share-profile-modal .modal-dialog {
        width: 100%;
        margin: auto;
        top: 55%;
    }
    .center-modal .modal-dialog .ui-sign-heading {
        font-size: 18px;
    }
    .col-divider {
        margin-top: 10px;
        margin-bottom: 0px;
    }
    .ui-sign-sub-heading {
        margin-top: 10px;
    }
    .otp-col-padding {
        margin-bottom: 20px;
    }
    .navbar-inverse .navbar-nav > li {
        padding: 0px 10px 0px 10px;
    }
    .dropdown-icon {
        right: 100px;
    }
    .navbar-inverse .nav > li > a {
        padding: 10px 10px;
    }
    .navbar-inverse .navbar-nav > li > a {
        font-family: 'Poppins', sans-serif;
    font-weight: 400;
    }
    .carousel-width {
        width: auto;
    }
    .header-rightside ul > li {
        margin-right: 10px;
    }
    .top-offset {
        margin-top: 18%;
    }
    #v-align {
        position: fixed;
        z-index: 100;
    }
}
/* ======================================SIDE MENU ================================================ */

.navi ul:not(collapsed) .arrow:before,
.navi li:not(collapsed) .arrow:before {
    font-family: FontAwesome;
    content: "\f107";
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
    vertical-align: middle;
    font-size: 14px;
    float: right;
}
.sub-menu li {
    margin-left: 20px;
    margin-bottom: 5px;
}
.sub-menu li > a {
    font-size: 80%;
    padding: 5px 20px;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.sub-menu li > a:hover {
    font-size: 80%;
}
.sub-menu .active a {
    font-size: 80%;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
li .badge {
    background-color: transparent;
    color: #ff337d;
    border-radius: 4px;
    font-size: 13px;
    margin-top: -4px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.sub-menu li .badge {
    background-color: transparent;
    color: #ff337d;
    border-radius: 4px;
    font-size: 13px;
    margin-top: -4px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.progress_bar {
    position: relative;
    height: 10px;
    width: 100%;
    border-radius: 50px;
    background-color: #efefef;
    border: 1px solid #efefef;
    margin: auto;
    margin-top: 15px;
}
.bar-count{
    margin-top: 10px;
}
.filler {
    background: #65fbc6;
    height: 100%;
    border-radius: inherit;
    transition: width .2s ease-in;
}
@media (min-width: 768px) and (max-width: 1024px) {
    .btn-see-skill{
        padding: 8px 15px;
    }
    .btn-see-skill-outline{
        padding: 8px 15px;
        margin-left: 10px;
    }
    .d-empty-space{
        flex-direction: column;
    }
}
@media screen and (max-width: 767px) {
    .progress_bar{
        margin-top: 5px;
    }
    .bar-count{
        margin-top: 0px;
    }
    .btn-see-skill{
        padding: 8px 15px;
        width: 100%;
        font-size: 14px;
        display: block;
    }
    .btn-see-skill-outline{
        padding: 8px 15px;
        margin-left: 0px;
        font-size: 14px;
        margin-top: 10px;
        width: 100%;
        display: block;
    }
    .dashboard-banner-text{
        width: 100%;
    }
    .dashboard-banner-img{
        width: 30%
    }
    .d-parent-margin-top{
        margin-top: 10px;
    }
    .dashboard-banner ul>li{
        font-size: 14px;
    }
    .dashboard-banner-text .para{
        font-size: 14px;
    }
    .dashboard-banner-text .d-profile-heading{
        font-size: 16px;
        line-height: 1.4em;
    }
}

@media only screen and (max-width: 400px) {
    .freebies-modal-banner{
        position: absolute;
        top: 0;
        padding: 10px 15px;
        margin-top: 10px;
        width: 60%;
    }
    .freebies-chat-banner-modal .btn-link{
        color: #fff;
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
    font-weight: 500;
        margin-top: 10px;
        padding: 0px;
    }
    .freebies-modal-banner .btn-app-download{
        font-size: 12px;
        padding: 5px 20px;
    }
}
/* ======================================SIDE MENU ================================================ */
.circular-progress-baar{
    width: 70px;
    height: 70px;
    margin: 0px auto;
}
.circular-progress-baar .dp-image{
    margin-top: 0;
    height: 60px;
    width: 60px;
}
.circular-progress-baar .blank-dp{
    height: 60px!important;
    width: 60px!important;
    line-height: 45px!important;
}
.text-percentage{
    color: #ff337d;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.text-percentage + .xs-heading{
    margin-top: 0px;
}


.form-group input:disabled {
    background: #f2f2f2 !important;
}

.form-group select:disabled {
    background: #f2f2f2 !important;
}

.project-search-field, .professional-serach-field {
    padding: 10px 15px;
    border-radius: 20px;
    width: 100%;
    margin-bottom: 2em;
}