@font-face {
    font-family: EinaBold;
    src: url(../font/EinaBold.woff) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap!important;
}
@font-face {
    font-family: SofiaSemiBold;
    src: url(../font/Sofia-Pro-Semi-Bold.woff) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap!important;
}
@font-face {
    font-family: SofiaLight;
    src: url(../font/Sofia-Pro-Light.woff) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap!important;
}
@font-face {
    font-family: SofiaRegular;
    src: url(../font/Sofia-Pro-Regular.woff) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap!important;
}
@font-face {
    font-family: SofiaMedium;
    src: url(../font/Sofia-Pro-Medium.woff) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap!important;
}
html {
    height: 100%;
    box-sizing: border-box;
}
*,
*:before,
*:after {
    box-sizing: inherit;
}
body {
    position: relative;
    margin: 0;
    font-family: 'Helvetica', 'Arial', 'sans-serif', open sans regular;
    min-height: 100%;
    width:100%;
}
*:focus {
    outline: none !important;
}
/* ========================================Header========================= */

.lazy-loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.navbar-default {
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 0px;
    background-color: #fff;
}
.navbar-top-margin {
    margin-top: 50px;
}
.top-nav-header {
    position: fixed;
    width: 100%;
    background-color: #fff;
    z-index: 1000;
    top: 0px;
}
.top-nav-header .list-inline li {
    padding: 10px 15px;
}
.top-nav-header .social li a {
    text-transform: uppercase;
}
.top-nav-header .list-inline li {
    font-size: 16px;
}
.top-nav-header .list-inline li a {
    color: #000;
    font-size: 14px;
    font-family: SofiaRegular;
}
.top-nav-header .list-inline li a:hover,
.top-nav-header .list-inline li a:focus {
    color: #ff337d;
}
.nav-shadow {
    /*box-shadow: 0 0 40px  rgba(0, 0, 0, .1);*/
    border-top: 1.2px solid #e8eef3 !important;
    border-bottom: 1.2px solid #e8eef3 !important;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > .active > a:hover {
    background-color: transparent;
    color: #000;
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:focus,
.navbar-default .navbar-nav > .open > a:hover {
    background-color: transparent;
    color: #000;
}
.navbar-default .navbar-right > li {
    padding-left: 0px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
}
.navbar-default .navbar-right > li .dp-image {
    height: 45px;
    width: 45px;
    margin-top: -12px;
    border: 2px solid #ffe933;
}
.navbar-default .navbar-right .dropdown {
    padding-bottom: 0px;
    /*border:1px solid red;*/
}
.navbar-default .navbar-right > li .blank-dp {
    line-height: 30px;
    height: 45px!important;
    width: 45px!important;
    margin-top: -12px!important;
}
.navbar-default .navbar-right > li .blank-dp span {
    font-size: 16px!important;
    font-family: SofiaRegular!important;
}
.navbar-default .navbar-nav > li > a {
    color: #000;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-family: EinaBold;
    font-size: 12px;
}
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
    color: #ff337d!important;
}
.navbar-default .navbar-nav > li > a.login-button:hover,
.navbar-default .navbar-nav > li > a.login-button:focus {
    color: #fff!important;
}
.navbar-btn {
    background-color: #000;
    border: 1.2px solid #000;
    padding: 10px 15px!important;
    border-radius: 0px;
    font-size: 14px;
    color: #fff!important;
    font-family: SofiaSemiBold;
    letter-spacing: 1px;
    /*margin-left: 20px;*/
    margin-top: 5px;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
}
.navbar-btn:hover,
.navbar-btn:focus {
    background-color: #fff;
    color: #000;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
}
.signup-expert-button {
    border: 1.2px solid #ff337d!important;
    background-color: transparent!important;
    color: #ff337d!important;
    /*margin-right: 15px!important;*/
}
.header-search-bar {
    margin-top: 10px;
    padding: 2px 20px;
  }
  .header-search-bar .input-group {
    margin-bottom:0;
  }
.header-search-bar .input-group .form-control.input-lg {
    height: 40px;
  }
.header-search-bar .input-group .btn-header-search {
    padding: 8px 10px;
  }
.btn-header-search {
    background: #ff337d;
    padding: 10px 15px;
    color: #fff;
    font-size: 16px;
  }
.whatsapp-link img {
    width: 20px;
    margin-right: 5px;
}
.navbar-brand.whatsapp-link img {
    width: 25px;
    margin-top: -5px;
}
.navbar-default .navbar-nav > li > a.signup-expert-button:hover,
.navbar-default .navbar-nav > li > a.signup-expert-button:focus {
    border: 1.2px solid #ff337d;
    background-color: #ff337d!important;
    color: #fff!important;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
}
/*=============================================Header Dropdown======================*/

/*=============================================Progress bar======================*/

.bs-wizard {
    margin-top: 20px;
    margin-bottom: 20px;
}
.bs-wizard {
    border-bottom: solid 0px #e0e0e0;
    padding: 0 0 10px 0;
}
.bs-wizard > .bs-wizard-step {
    padding: 0;
    position: relative;
}
.bs-wizard > .bs-wizard-step .bs-wizard-stepnum {
    color: #979797;
    font-size: 16px;
    margin-bottom: 5px;
}
.bs-wizard > .bs-wizard-step.complete .bs-wizard-stepnum {
    color: #ff337d;
    margin-bottom: 5px;
    font-family: EinaBold
}
.bs-wizard > .bs-wizard-step > .bs-wizard-dot {
    position: absolute;
    width: 30px;
    height: 30px;
    display: block;
    background: #ffdfdf;
    top: 45px;
    left: 50%;
    margin-top: -15px;
    margin-left: -15px;
    border-radius: 50%;
}
.bs-wizard > .bs-wizard-step.complete > .bs-wizard-dot {
    position: absolute;
    width: 30px;
    height: 30px;
    display: block;
    background: #ff337d;
    top: 45px;
    left: 50%;
    margin-top: -15px;
    margin-left: -15px;
    border-radius: 50%;
}
.bs-wizard > .bs-wizard-step > .bs-wizard-dot:after {
    content: ' ';
    width: 14px;
    height: 14px;
    background: #ffdfdf;
    border-radius: 50px;
    position: absolute;
    top: 8px;
    left: 8px;
}
.bs-wizard > .bs-wizard-step.complete > .bs-wizard-dot:after {
    content: ' ';
    width: 14px;
    height: 14px;
    background: #ff337d;
    border-radius: 50px;
    position: absolute;
    top: 8px;
    left: 8px;
}
.bs-wizard > .bs-wizard-step > .progress {
    position: relative;
    border-radius: 0px;
    height: 5px;
    box-shadow: none;
    margin: 20px 0;
    background: #2d3fd0;
}
.bs-wizard > .bs-wizard-step > .progress > .progress-bar {
    width: 0px;
    box-shadow: none;
    background: #ff337d;
}
.bs-wizard > .bs-wizard-step.complete > .progress > .progress-bar {
    width: 100%;
}
.bs-wizard > .bs-wizard-step.active > .progress > .progress-bar {
    width: 50%;
}
.bs-wizard > .bs-wizard-step:first-child.active > .progress > .progress-bar {
    width: 0%;
}
.bs-wizard > .bs-wizard-step:last-child.active > .progress > .progress-bar {
    width: 100%;
}
.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot {
    background-color: #f5f5f5;
}
.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot:after {
    opacity: 0;
}
.bs-wizard > .bs-wizard-step:first-child > .progress {
    left: 50%;
    width: 50%;
}
.bs-wizard > .bs-wizard-step:last-child > .progress {
    width: 50%;
}
.bs-wizard > .bs-wizard-step.disabled a.bs-wizard-dot {
    pointer-events: none;
}
/*=============================================Progress bar======================*/

/*============================================Testimonials============================*/

.valid-session-ui {
    margin-top: 1%;
}
.valid-session-ui .text-dark {
    font-size: 16px;
}
.valid-session-ui .dp-image {
    margin-top: 0px;
}
.xs-dp-img{
    padding-right: 0px!important;
}
.xs-dp-img .blank-dp {
    margin-top: 5px!important;
    height: 35px!important;
    width: 35px!important;
    line-height: 20px;
}
.xs-dp-img .blank-dp span {
    font-size: 14px!important;
}
.xs-dp-img .dp-image {
    margin-top: 5px;
    width: 40px;
    height: 40px;
    border: 2px solid #ffe933;
}
.valid-session-ui .blank-dp {
    padding-top: 15px!important;
}
.valid-session-ui .xs-heading {
    margin-top: 10px!important;
}
.valid-session-ui .list-card {
    text-align: center;
    margin-bottom: 20px;
}
.valid-session-ui .media-body .para {
    margin-top: 10px;
}
.play-button {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 60px;
    width: 100px;
    background-color: #ff337d;
    border-radius: 5px;
}
.play-button:after {
    content: "";
    display: block;
    position: absolute;
    top: 16.5px;
    left: 40px;
    margin: 0 auto;
    border-style: solid;
    border-width: 12.5px 0 12.5px 20px;
    border-color: transparent transparent transparent rgba(255, 255, 255, 1);
}
.close-video::before {
    content: '✖';
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 20px;
    padding: 20px;
    z-index: 1;
    line-height: .7;
    display: block;
    color: #fff;
}
.custom-file-upload input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 10px;
    min-height: 10px;
    /*font-size: 100px;*/
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: red;
    display: block;
    cursor: pointer;
    border: 1px solid red;
}
.doc-upload-processbar{
    position: absolute;
    margin-top:-65px;
    width:95%;
}
.doc-upload-processbar .progress_bar{
    margin-top: 5px;
}
.testimonials-section {
    margin-top: 20px;
}
.help-center-section {
    margin-top: 80px!important;
    margin-bottom: 10px!important;
}
.help-center-section .jumbotron-box {
    background-color: #ffdbe4;
    padding: 50px;
    border-radius: 6px;
}
.page-contant .jumbotron-box {
    /*background-color: #cffcd8;*/
    padding: 10px;
    border-radius: 6px;
}
.explore-product-section{
    background-color: #ffdbe4;
    padding:15px 50px;
    border-radius: 6px;
}

.explore-product-section ul {
  padding-block-start: 10px;
}
.explore-product-section ul>li {
    margin-left: 30px;
    margin-bottom: 15px;
    font-size: 16px;
    font-family: SofiaMedium;
}
.explore-product-section ul>li:before {
    content: '';
    position: absolute;
    left: 0;
    margin-top: 5px;
    margin-left: 20px;
    width:20px;
    height: 20px;
    background: url('../images/yes-tick.png') no-repeat;
    background-size: 15px;
}
.covid-first-section{
    height: 200px;
    background: url('../images/covid-banner.png') no-repeat;
    background-position: center;
    background-size: cover;
}
.our-brand-section{
    background-color: #fff;
}
.our-brand-logo{
    text-align: center;
}
.our-brand-logo {
    list-style-type: none;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding-inline-start: 0px;
    justify-content: center;
    align-items: center;
}
.our-brand-logo-wrapper {
    width: 25%;
}
.our-brand-logo-wrapper img{
    width: 150px;
}
.msme-strip{
    background-color: #FEE8C4;
    border-radius: 0px;
    border-color: #FEE8C4;
    z-index: 1100;
    position: absolute;
    margin-top:-115px;
    right: 0;
    left: 0;
    padding: 15px 20px;
    /*height: 50px;*/
    width: 100%;
}
.msme-strip .text-success a{
    color:#ff337d;
}
.msme-strip .text-success{
    font-family: SofiaRegular;
    color: #000!important;
    font-size: 16px;
}
.covid-app-section{
    position: relative;
    /*padding: 10px;*/
}
.covid-text-box{
    left: 30px;
    position: absolute;
}

.blog-page-add .app-button li img{
    margin-top: 20px;
  width:120px;
}
.covid-app-section .scope-heading{
    margin-top: 60px;
}
.help-center-h1 {
    font-family: EinaBold;
    font-size: 32px;
}
.help-center-h2 {
    font-family: SofiaMedium;
    font-size: 22px;
    line-height: 1.4em;
}
.help-inline-tab {
    list-style: none;
    width: 100%;
    margin: 0;
    padding: 15px 30px;
    display: flex;
    justify-content: space-between;
    background-color: #fcf0df;
    border-radius: 6px;
}
.help-inline-tab li {
    width: 100%;
    text-align: center;
}
.help-inline-tab li>a {
    font-size: 20px;
    font-family: SofiaMedium;
    color: #000;
    padding: 10px 100px;
    text-align: center;
    width: 100%;
}
.help-inline-tab li.active>a {
    color: #fff;
    background-color: #2b1de6;
    border-radius: 6px;
}
.help-inline-tab li>a:hover,
.help-inline-tab li>a:focus {
    color: #ff337d;
}
.help-inline-tab li.active>a:hover,
.help-inline-tab li.active>a:focus {
    color: #fff;
}
.help-faq .panel-title {
    font-family: EinaBold!important;
}
.help-faq-heading {
    font-family: EinaBold;
    font-size: 20px;
    color: #000;
    margin-top: 20px;
}
.help-faq-note {
    font-family: EinaBold;
    font-size: 18px;
    color: #000;
    margin-top: 20px;
    margin-bottom: 0px;
}
.help-faq-para {
    font-family: SofiaLight;
    font-size: 18px;
    color: #000;
    margin-top: 10px;
    margin-bottom: 15px;
}
.help-faq-para a {
    color: #ff337d;
}
.covid-help-ul{
    display: flex;
    width:100%;
    justify-content: space-between;
}
.covid-help-ul li{
    font-family: SofiaMedium;
    font-size: 18px;
    margin:20px 0px;
}
.covid-help-ul img{
    width:40px;
    margin-bottom: 5px;
}

.btn-book-now{
    margin-top: 10px;
    background-color: #ff337d;
    color: #fff;
    font-family: SofiaMedium;
    font-size: 18px;
    padding: 8px 50px;
}
.btn-book-now:hover,
.btn-book-now:focus{
    color:#fff;
}
.help-ul li {
    font-family: SofiaLight;
    font-size: 16px;
    margin-top: 10px;
}
.article-table-heading {
    font-size: 18px;
    font-family: EinaBold;
    color: #000;
    line-height: 1.4em;
}
.article-list {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-inline-start: 10px;
}
.article-list li {
    margin-top: 15px;
}
.article-list a {
    font-size: 16px;
    color: #696969;
    font-family: SofiaRegular;
}
.article-list a:hover,
.article-list a:focus {
    color: #ff337d;
}
.article-text-h1 {
    font-family: EinaBold;
    font-size: 36px;
    color: #000;
    margin-bottom: 25px;
}
.article-text-h2 {
    font-family: SofiaRegular;
    font-size: 24px;
    color: #000;
    line-height: 1.4em;
    margin-bottom: 25px;
}
.article-text-h3 {
    font-family: EinaBold;
    font-size: 26px;
    color: #000;
    line-height: 1.4em;
    margin-top: 20px;
    margin-bottom: 15px;
}
.article-ul li {
    font-size: 18px;
    margin-top: 10px;
    font-family: SofiaLight;
}
.article-ul li a {
    color: #ff337d;
}
.article-para {
    font-size: 18px;
    font-family: SofiaLight;
    color: #000;
    margin-bottom: 15px;
}
.article-para a {
    color: #ff337d;
}
.tesimonial-text-box {
    position: absolute;
    margin-top: 5%;
}
.customer-review-section{
    background: #e35c96;
    padding: 50px 0px;
}
.professional-social-ui.customer-review-section{
    background: #ededff;
}
.affiliate-reviews-section{
    background: #f2e9ff;
    padding-top: 15px;
    padding-bottom: 35px;
}
.customer-review-container{
    background: #fff;
    padding:10px 30px 20px;
    border-radius: 14px;
    box-shadow: 0 3px 6px 0 rgba(181, 181, 181, 0.5);
}
.customer-review-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: normal;
    width: 100%;
    margin-top: 2%;
}
.customer-review-wrapper li{
    width: 30%;
     margin: 20px auto;
    min-height: 340px;
    display: flex;
    flex-direction: column;
    padding: 15px 15px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 3px 6px 0 rgba(181, 181, 181, 0.5);
}
.r-author-detail{
    margin-top: auto;
}
.r-author-detail .text-name{
    margin-top: 0px;
    margin-bottom: 5px;
}
.btn-load-more{
    background: #fff;
    border: 2px solid #000;
    padding: 12px 30px;
    color: #000;
    font-size: 16px;
    font-family: SofiaMedium;
    margin-top: 10px;
}
.testimonials-h1 {
    font-size: 36px;
    font-family: EinaBold;
    line-height: 1.4em;
    color: #000;
    margin-top: 30px;
    margin-bottom: 30px;
}
.testimonials-h2 {
    font-size: 32px;
    font-family: EinaBold;
    text-align: center;
    line-height: 1.4em;
    color: #000;
    margin-top: 30px;
    margin-bottom: 70px;
}
.testimonials-h3 {
    font-size: 28px;
    font-family: EinaBold;
    line-height: 1.4em;
    color: #000;
    margin-top: 30px;
}
.testimonials-text-name {
    margin-top: 25px;
    font-size: 22px;
    font-family: EinaBold;
    color: #000;
    line-height: 1.3em;
    text-align: left;
}
.testimonials-user-name {
    font-size: 20px;
    font-family: EinaBold;
    line-height: 1.2em;
    color: #000;
    margin-bottom: 5px;
}
.sample-video .testimonials-user-name{
    margin-bottom: 20px;
}
.user-bio {
    font-family: SofiaRegular;
    font-size: 18px;
    color: #000;
    margin-bottom: 20px;
}
.testimonials-text-name span {
    font-size: 18px;
    font-family: SofiaRegular;
}
.brand-add-slide {
    margin-top: 10px;
    height: 100%;
    /*border: 1px solid red;*/
}
.up-to-offer {
    font-size: 16px;
    font-family: SofiaRegular;
    color: #ff337d;
}
.brand-add-slide img {
    max-width: 100%;
    max-height: 100%;
    /*object-fit: cover;*/
}
.offer-brand-table {
    border-collapse: separate;
    border-spacing: 0 1em;
}
.offer-brand-card .home-industry__icon img {
    width: 90px;
}
.offer-brand-card .home-industry__icon {
    height: 100px;
    line-height: 100px;
    margin-top: 25px;
}
.embed-responsive {
    position: relative;
}
.embed-responsive img {
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}
.embed-responsive .video-play {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.embed-responsive .video-play .fa-play-circle {
    font-size: 78px
}
.embed-responsive .video-play:hover .fa-play-circle {
    color: #ff337d;
}
.video-testimonial-block {
    position: relative;
    width: auto;
    height: 350px;
    overflow: hidden;
    margin-bottom: 30px;
}
.video-testimonial-block .video-thumbnail {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    background-size: cover;
    top: 0;
    left: 0;
}
.video-testimonial-block .video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 330px;
    border: none;
}
.video-testimonial-block .video-play {
    cursor: pointer;
    z-index: 2;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.video-testimonial-block .video-play .fa-play-circle {
    font-size: 78px
}
.video-testimonial-block .video-play:hover .fa-play-circle {
    color: #ff337d;
}
.video-testimonial-block img {
    width: 100%;
}
.ui-testimonial-container {
    display: block;
    text-align: center;
    margin-bottom: 60px;
}
.user-blank-dp {
    height: 60px;
    width: 60px;
    line-height: 60px;
    border-radius: 50%;
    margin: auto;
}
.user-blank-dp span {
    font-size: 24px;
    color: #fff;
    text-transform: uppercase;
}
.testimonials-para {
    margin-top: 20px;
    margin-bottom: 15px;
    font-size: 16px;
    font-family: SofiaLight;
    font-style: italic;
    color: #000
}
.testimonials-name {
    font-size: 18px;
    font-family: SofiaMedium;
    color: #000;
    margin-bottom: 0px;
}
.testimonials-name::before {
    content: '';
    position: absolute;
    margin-top: 10px;
    margin-left: -30px;
    height: 2px;
    width: 20px;
    background: #0c202f;
}
.testimonials-title {
    font-family: SofiaLight;
    font-size: 16px;
    color: #000;
}
/*============================================Testimonials============================*/

/*======================================Brand Service Ui======================*/

.checklist-box span p {
    margin-top: 10px;
    line-height: 1.5em;
    font-family: SofiaRegular!important;
}
.checklist-box span p strong {
    font-family: EinaBold!important;
}
.brand-doc-dropzone {
    border: 1.5px solid #979797;
    height: auto;
    text-align: center;
    padding: 20px;
}
.dropzone-area .fa-cloud-upload {
    font-size: 36px;
}
.dropzone-area {
    border: 2px dashed #979797;
    padding: 10px 20px;
    width: 100%;
    color: #ff337d;
    cursor: pointer;
}
.btn-continue {
    background-color: #26ba9c;
    color: #fff;
    padding: 8px 25px;
    font-size: 14px;
    font-family: SofiaMedium;
}
.btn-skip {
    background-color: #000;
    color: #fff;
    padding: 8px 25px;
    font-size: 14px;
    font-family: SofiaMedium;
}
.btn-continue:hover,
.btn-skip:hover {
    color: #fff;
}
/*======================================Brand Service Ui======================*/

/*====================================Designer new product====================*/

.image-gallery-image {
    height: 350px;
    line-height: 320px;
    overflow: hidden;
}
.image-gallery.fullscreen-modal {
    z-index: 1033!important;
}
.image-gallery-slide img {
    max-width: 100%;
    height: 100%;
    object-fit: contain;
    margin: auto;
}
.image-gallery-slide-wrapper {
    height: 100%;
    max-width: 100%;
    background-color: #f7f7f7;
    /*max-height: 410px;*/
    /*overflow: hidden;*/
}
.fullscreen .image-gallery-image {
    height: 100vh!important;
    padding: 60px;
    /*overflow-y: hidden;*/
}
.image-gallery-content.fullscreen {
    background: #f7f7f7!important;
}
.fullscreen-modal .image-gallery-fullscreen-button {
    bottom: 50px;
}
.designer-prfile-img {
    border: 2px solid #ecb33c;
    height: 90px!important;
    width: 90px!important;
    object-fit: cover;
    padding: 1px;
    object-position: 50% 50%!important;
    vertical-align: middle;
}
.designer-media .blank-dp {
    height: 90px!important;
    width: 90px!important;
    line-height: 80px;
}
.designer-media .blank-dp>span{
    font-size: 26px!important;
}
.d-name-text {
    font-size: 18px;
    font-family: EinaBold;
    color: #000;
    margin-top: 5px;
    margin-bottom: 5px;
}
.d-bio-text {
    font-size: 16px;
    font-family: SofiaRegular;
    color: #ff337d;
    margin-bottom: 3px;
}
.d-about-text {
    margin-top: 5px;
    font-family: SofiaRegular;
    font-size: 16px;
    color: #000;
}
.d-about-text .fa-star {
    color: gold;
    margin-left: 2px;
}
.d-about-text>span+span {
    margin-left: 20px;
}
.btn-share-product {
    background-color: #d3e3ff;
    padding: 5px 10px;
    color: #193bdd;
    font-family: SofiaMedium;
    font-size: 16px;
    border: 1.3px solid #193bdd;
    margin-top: 5px;
}
.btn-share-product:hover,
.btn-share-product:focus {
    color: #193bdd;
}
.btn-ask-portfolio{
    background-color: #fff;
    padding: 10px 20px;
    border: 2px solid #193bdd;
    color: #193bdd;
    font-family: SofiaMedium;
    font-size: 16px;
}
.btn-ask-portfolio:hover,
.btn-ask-portfolio:focus {
    color: #193bdd;
}
.btn-share-product+.btn-share-product{
    margin-left: 10px;
    color: #0e6e3b;
    border: 1.3px solid #0e6e3b;
    background: #d3ffe7;
}
.sample-container {
    height: 600px;
    overflow: hidden;
}
.d-designer-heading {
    font-size: 20px;
    font-family: EinaBold;
    color: #000;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 1.4em;
}
.external-link {
    font-size: 18px;
    font-family: SofiaLight;
    margin-top: 15px;
}
.external-link a {
    color: #ff337d;
    overflow-wrap: break-word;
    word-wrap: break-word;
}
.gig-panel{
    box-shadow: 0 2px 10px rgba(0, 0, 0, .1);
    /*padding: 10px;*/
}
.designer-media-panel .d-name-text {
    margin-bottom: 8px;
}
.designer-media-panel .d-bio-text {
    margin-bottom: 0px;
}
.listing-panel .designer-media-panel .blank-dp {
    margin-top: 0px!important;
    height: 75px!important;
    width: 75px!important;
    line-height: 35px;
}
.summary-card {
    position: relative;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .1);
    transition: box-shadow 0.3s ease 0s;
    min-height: 200px;
    padding: 10px 20px;
    box-sizing: border-box;
    margin-top: 20px;
}
.summary-heading {
    font-size: 18px;
    font-family: EinaBold;
    color: #000;
    margin-top: 15px;
    margin-bottom: 10px;
}
.price-compair-card {
    border: solid 1px #e3e3e3;
    background-color: #ffffff;
    height: 100%;
    min-height: 200px;
    box-sizing: border-box;
    margin-bottom: 20px;
}
.price-compair-card-body {
    padding: 10px 20px;
}
.price-compair-card-body .product-increase{
    border-bottom: 1px solid #e8eef3;
}
.price-compair-card .nav-tabs.nav-justified a {
    background-color: #e8e8e8;
    border: none;
    border-radius: 0px;
    color: #000;
    font-family: EinaBold;
    font-size: 16px;
}
.price-compair-card .nav-tabs.nav-justified>.active>a {
    background-color: #3f4fe0;
    color: #fff;
    border-radius: 0px;
}
.price-compair-card .nav-tabs.nav-justified>.active>a,
.price-compair-card .nav-tabs.nav-justified>.active>a:focus,
.price-compair-card .nav-tabs.nav-justified>.active>a:hover {
    border: none;
}
.page-contant .price-compair-card .tab-pane {
    margin-top: 0px;
}
.d-inline-text {
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-family: EinaBold;
    color: #000;
}
.price-compair-card-body h4 {
    font-size: 14px;
    color: #ff337d;
}
.price-compair-card-body strong {
    font-weight: 400!important;
}
.price-compair-card-body ul,
.price-compair-card-body ol {
    list-style-type: none;
    /*-webkit-column-count: 2;*/
    /*-moz-column-count: 2;*/
    /*column-count: 2;*/
    padding-inline-start: 0px;
    margin-top: 15px;
}
.price-compair-card-body ul li,
.price-compair-card-body ol li {
    font-size: 14px;
    font-family: SofiaRegular;
    text-indent: -25px;
    margin-left: 20px;
    margin-top: 8px;
    word-break: break-word;
}
.price-compair-card-body .cp-ul li{
    font-size: 14px;
    font-family: SofiaLight;
    text-indent: -20px;
    margin-left: 25px;
    margin-bottom: 10px;
}
.price-compair-card-body .cp-ul li:before{
    content: '●' !important;
    color: #ff337d;
    font-size: 12px;
    margin-right: 10px;

}
.gig-assurance-ul{
    list-style-type: none;
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    -webkit-padding-start: 0;
    padding-inline-start: 0;
}
.gig-assurance-ul li{
    width: 25%;
    font-family: SofiaMedium;
    font-size: 16px;
    border-radius: 6px;
    box-shadow: 0 2px 2px 0 rgba(205, 205, 205, 0.5);
    border: 1px solid #dbdbdb;
    padding: 10px;
    margin: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.gig-assurance-ul .service-grid-cards-icon{
  margin: 5px 5px;
    height: 35px;
    text-align: left;
    line-height: 35px;
}
.gig-assurance-ul li img{
    max-width: 30px;
}
.gig-assurance-ul .service-grid-cards-heading{
    font-size: 16px;
    font-family: SofiaMedium;
}
.dashboard-assuarance .assurance-ul li {
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 16px;
    text-indent: -20px;
    font-family: SofiaRegular;
    margin-left: 20px;
}
.dashboard-assuarance .assurance-ul li:before {
    content: '✓';
    color: #39ce82;
    font-size: 16px;
    margin: 5px;
}
.payment-protection li {
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 16px;
    text-indent: -30px;
    font-family: SofiaMedium;
    margin-left: 25px;
}
.payment-protection li:before {
    content: 'X';
    color: red;
    font-size: 16px;
    font-family: EinaBold;
    margin: 10px;
}
.price-compair-card-body ul li:before,
.price-compair-card-body ol li:before {
    content: '✓';
    color: #39ce82;
    font-size: 16px;
    margin: 5px;
}
.price-compair-card-body li:empty {
    display: none;
}
.text-timer {
    font-size: 14px;
    color: #929292;
    font-family: SofiaLight;
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
}
.text-timer img {
    width: 20px;
    margin-right: 10px;
}
.btn-pay-installment {
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #EA367F;
    color: #fff;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
    font-family: EinaBold;
    width: 100%;
    display: block;
}
.btn-pay-installment:hover,
.btn-pay-installment:focus {
    color: #fff;
}
.btn-pay-full {
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #EA367F;
    text-align: center;
    color: #fff;
    font-size: 16px;
    padding: 10px 20px;
    font-family: SofiaRegular;
    width: 100%;
    display: block;
}
.btn-pay-full:hover,
.btn-pay-full:focus {
    color: #fff;
}
.btn-expert-chat {
    background-color: #2c47f2;
    color: #fff;
    font-size: 16px;
    padding: 10px 20px;
    font-family: SofiaRegular;
    width: 100%;
    display: block;
    margin-top: 15px;
    margin-bottom: 15px;
}
.btn-expert-chat:hover,
.btn-expert-chat:focus {
    background-color: #2c47f2;
    color: #fff;
}
.btn-expert-chat-outline {
    background-color: #fff;
    border: 1.5px solid #2c47f2;
    color: #2c47f2;
    font-size: 16px;
    padding: 10px 20px;
    font-family: SofiaRegular;
    width: 100%;
    display: block;
    margin-top: 15px;
    margin-bottom: 15px;
}
.btn-expert-chat-outline:hover,
.btn-expert-chat-outline:focus {
    background-color: #fff;
    color: #2c47f2;
}
.card-button-box{
    display: flex;
    justify-content: space-between;
}
.btn-share-expert-chat {
    background-color: #444DEC;
    /*border: 2px solid #533fe8;*/
    color: #fff;
    font-size: 16px;
    padding: 10px 20px;
    font-family: EinaBold;
    width: 100%;
    /*display: block;*/
    margin-top: 10px;
    margin-bottom: 15px;
}
.btn-share-expert-chat:hover,
.btn-share-expert-chat:focus {
    background-color: #533fe8;
    color: #fff;
}
 .btn-share-expert-chat .fa{
    margin-right: 5px;
}
.btn-share-expert-chat+.btn-share-expert-chat .fa{
    font-size: 18px;
    vertical-align: middle;
}
.btn-share-expert-chat+.btn-share-expert-chat{
 background-color: #19b899;
 margin-left: 20px;
    /*float: right;*/

}
.btn-share-expert-chat+.btn-share-expert-chat:hover,
.btn-share-expert-chat+.btn-share-expert-chat:focus {
    background-color: #19b899;
    color: #fff;
}
.btn-share-offer{
    background: #ff337d;
    padding: 8px 15px;
    color: #fff!important;
    font-size: 14px;
    font-family: SofiaRegular;
    margin-top: 10px;
    margin-bottom: 10px;
    text-decoration: none!important;

}
.btn-share-offer:hover,
.btn-share-offer:focus{
    background: #ff337d;
    color: #fff!important;
}
.assurance-card {
    padding: 15px 20px;
    border: solid 1px #e3e3e3;
    background-color: #ffffff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .1);
    height: 100%;
    min-height: 200px;
    box-sizing: border-box;
    margin-bottom: 20px;
}
.assurance-heading {
    font-size: 18px;
    font-family: EinaBold;
    color: #000;
    text-align: center;
    margin-bottom: 15px;
}
.assurance-ul li {
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 16px;
    font-family: SofiaMedium;
    margin-left: 0px;
}
.assurance-ul li>img {
    width: 35px;
    vertical-align: middle;
    margin: 10px;
}
/*====================================Home page service Grid===================*/

.service-grid-cards {
    list-style-type: none;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding-inline-start: 0px;
    /*justify-content: center;*/
}
.service-grid-cards-wrapper {
    width: 24%;
    margin: 5px;
    height: 20rem;
    padding: 10px 10px;
    position: relative;
    border: 1px solid #e8eef3;
    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
}
.service-grid-cards-icon {
    text-align: center;
    margin: 5px auto;
    height: 60px;
    line-height: 60px;
    display: flex;
    justify-content: center;
}
.service-grid-cards-icon:after {
    display: none;
    content: "";
    position: absolute;
    top: 85px;
    right: 50%;
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
    padding: 0 0 8px;
    width: 58px;
    border-bottom: 2px solid #c5c6c9;
    -webkit-transition: border-color 0.2s ease-in-out, padding 0.2s ease-in-out;
    -o-transition: border-color 0.2s ease-in-out, padding 0.2s ease-in-out;
    transition: border-color 0.2s ease-in-out, padding 0.2s ease-in-out;
}
.service-grid-cards-icon:hover:after {
    padding: 0 50px 8px;
    border-bottom: 2px solid #ff337d;
    -webkit-transition: border-color 0.2s ease-in-out, padding 0.2s ease-in-out;
    -o-transition: border-color 0.2s ease-in-out, padding 0.2s ease-in-out;
    transition: border-color 0.2s ease-in-out, padding 0.2s ease-in-out;
}
.service-grid-cards-icon img {
    width: 60px;
}
.service-grid-cards-wrapper>a:hover .service-grid-cards-heading {
    color: #ff337d;
}
.service-grid-cards-heading {
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    color: #000;
    font-weight: 500;
    margin-top: 10px;
}
.service-grid-cards-wrapper:hover {
    /*box-shadow: 0 2px 4px 0 rgba(60, 64, 67, .3), 0 6px 11px 5px rgba(60, 64, 67, .15);*/
    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
}
/* .service-grid-cards-icon:hover img {
    content: url(hoverIcon);
} */
.browse-service-list {
    padding-inline-start: 0px;
}
.browse-service-list li>a {
    font-size: 20px;
    font-family: SofiaMedium;
    color: #000;
    position: relative;
    display: block;
    padding: 15px 15px;
    margin-bottom: -1px;
    /*background-color: #fff;*/
    border-bottom: 1px solid #e8eef3;
}
.browse-service-list a:hover,
.browse-service-list a:focus {
    background-color: #e8eef3;
    color: #ff337d;
}
.browse-service-list a:after {
    content: '>';
    font-family: FontAwesome;
    float: right;
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
}
@media screen and (max-width: 767px) {
    .testimonials-h3 {
        font-size: 20px;
        margin-top: 20px;
    }
    .customer-review-section{
        padding: 20px 0px;
    }
    .customer-review-container{
        padding: 10px 15px 20px;
    }
    .customer-review-wrapper li{
        width: 100%;
        margin: 10px auto;
        min-height: 320px;
    }
    .customer-review-wrapper .expert-dp{
      height: 50px!important;
      width: 50px!important;
    }
    .customer-review-container .testimonials-h3{
        text-align: center;
    }
    .btn-load-more{
        font-size: 14px;
        padding: 8px 20px;
    }
    .service-grid-cards{
        margin-top: 10px;
    }
    .our-brand-logo-wrapper {
        width: 33%;
    }
    .our-brand-logo-wrapper img{
        width: 110px;
    }
    .xs-dp-img{
        margin-right: 10px;
    }
    .btn-share-expert-chat+.btn-share-expert-chat{
        margin-left: 10px;
    }
    .btn-share-expert-chat{
        padding: 10px 15px;
    }
    .service-grid-cards-wrapper {
        width: 30%;
        margin: 5px;
        /*margin-top: 15px;*/
        padding: 0px 5px;
        border:1px solid #e8eef3;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .service-grid-cards-icon {
        margin: 0px 10px;
        height: 45px;
        line-height: 45px;
    }
    .service-grid-cards-icon img {
        width: 35px;
    }
    .service-grid-cards-icon:after {
        top: 70px;
    }
    .browse-service-list li>a {
        font-size: 16px;
    }
    .service-grid-cards-heading {
        font-size: 12px;
        margin-top: 0px;
    }

}
/*====================================Home page service Grid===================*/

/*============================Community Css====================================*/
.community-section{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: #fafafa;
    /*background: #F9F7FE;*/
    padding:10px 0px;
}
.community-tag-tab{
    height: 70px;
    display: flex;
    align-items: center;
    background: #fff;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 6px;
}
.hash-tag-tab-ul{
    /*display: flex;*/
    /*width: 100%;*/
    /*flex-wrap: nowrap;*/
    /*justify-content: center;*/
    /*overflow-x: scroll;*/
    /*white-space: nowrap;*/
    /*-webkit-padding-start: 0;*/
    padding-inline-start: 0;
    list-style: none;
    margin-bottom: 0px;
}
.hash-tag-tab-ul li{
    margin-top: 10px;
}
.hash-tag-tab-ul li>a{
    /*align-items: center;*/
    /*border-radius: 16px;*/
    /*color: #5e5e5e;*/
    /*text-decoration: none;*/
    /*text-transform: capitalize;*/
    /*transition: all .1s ease;*/
    display: inline-flex;
    padding: .3rem 1.25rem .3rem .3rem;
    font-family: SofiaLight;
    font-size: 16px;
}
.post-type{
    margin-top: 5px;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1.5px solid #d6d7d8;
}
.edit-post{
    position: absolute;
    right: 40px;
}
.edit-post .dropdown>a{
    color: #000;
    font-size: 6px;
}
.edit-post .dropdown-menu a{
    font-family: SofiaLight!important;
}
.edit-post .dropdown-menu{
    min-width: auto!important;
}
.edit-post .dropdown>a .fa{
    margin-left: 2px;
}
.post-type-bubble{
    text-align: center;
    border-radius: 16px;
    color: #ff337d;
    border: 1.5px solid #ff337d;
    text-decoration: none;
    text-transform: capitalize;
    padding: 5px 10px;
    font-family: SofiaLight;
    font-size: 14px;
}
.select-post-category-ul{
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    justify-content: flex-start;
    /*overflow-x: scroll;*/
    white-space: nowrap;
    -webkit-padding-start: 0;
    padding-inline-start: 0;
    list-style: none;
}
.select-post-category-ul li+li{
    margin: 0px 15px;
}
 .btn-select-type{
     padding: 5px 30px;
     border-radius: 20px;
     border: 1.5px solid #ff337d;
     color: #000;
     font-size: 16px;
     font-family: SofiaRegular;
 }
 .btn-select-type:hover,
 .btn-select-type:focus{
     background: #ff337d;
     color: #fff;
 }
 .active .btn-select-type{
     background: #ff337d;
     color: #fff;
 }
.liked {
    color: #ff008a;
    animation-name: heart-like;
    animation-duration: 250ms;
    animation-iteration-count: 1;
    animation-timing-function: linear;
}

.unliked {
    animation-name: heart-unlike;
    animation-duration: 250ms;
    animation-iteration-count: 1;
    animation-timing-function: linear;
}

@keyframes heart-like {
    0% { transform: scale(1); -webkit-transform: scale(1); -moz-transform: scale(1); -ms-transform: scale(1); -o-transform: scale(1); }
    50% { transform: scale(1.3); -webkit-transform: scale(1.3); -moz-transform: scale(1.3); -ms-transform: scale(1.3); -o-transform: scale(1.3); }
    100% { transform: scale(1); -webkit-transform: scale(1); -moz-transform: scale(1); -ms-transform: scale(1); -o-transform: scale(1); }
}

@keyframes heart-unlike {
    0% { transform: scale(1); -webkit-transform: scale(1); -moz-transform: scale(1); -ms-transform: scale(1); -o-transform: scale(1); }
    50% { transform: scale(.7); -webkit-transform: scale(.7); -moz-transform: scale(.7); -ms-transform: scale(.7); -o-transform: scale(.7); }
    100% { transform: scale(1); -webkit-transform: scale(1); -moz-transform: scale(1); -ms-transform: scale(1); -o-transform: scale(1); }
}
.hash-bubble{
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #fea82f;
    text-align: center;
    margin-right: 10px;
    color: #fff;
}
.community-side-card{
    box-sizing: border-box;
    min-height: 100px;
    padding: 10px 10px;
    background: #fff;
    margin-top: 10px;
    margin-bottom: 15px;
    border-radius: 4px;
    /*border: 1px solid #e8eef3;*/
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.08);
    /*transition: box-shadow .3s ease-in-out, transform .3s ease-in-out;*/
    /*box-shadow: 0 0.0625rem 0.0875rem 0 rgba(0,0,0,0.08);*/
}
.community-side-heading{
    font-size: 18px;
    font-family: EinaBold;
    color:#000;
    border-bottom: 1.5px solid #e8eef3;
    padding-bottom: 5px;
}
.community-side-card .app-button li img {
    width: 110px;
}
.community-side-card .scope-sub-heading{
    font-size: 16px;
}
.btn-write-post{
    background: #fff;
    border: 1.5px solid #ff337d;
    padding: 6px 20px;
    color: #ff337d;
    font-family: SofiaRegular;
    font-size: 16px;
    border-radius: 25px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.community-side-card .btn-link{
    color: #767e94;
    font-size: 14px;
    font-family: SofiaLight;
}
.btn-write-post:hover,
.btn-write-post:focus{
    color: #fff;
    background: #ff337d;
}
.btn-attachment{
    color: #000;
    font-size: 24px;
}
.btn-attachment+.btn-attachment{
    margin-left: 10px;
}
.posted-by-name{
    font-family: EinaBold;
    color: #000;
    font-size: 15px;
}
.posted-by-bio{
    font-family: Sofialight;
    color: #464646;
    margin-bottom: 0px;
    font-size: 13px;
}
.posted-time{
    color: #828282;
    margin-bottom: 0px;
    font-size: 11px;
}
.posted-by-img{
    width: 50px!important;
    height: 50px!important;
    border-radius: 50%;
    object-fit: cover;
    object-position: 0% 0%;
    /*border: 2px solid #d57712;*/
}
.community-question{
    font-size: 16px;
    font-family: Helvetica, serif;
    line-height: 1.4em;
    color: #000;
    margin-top: 10px;
}
.community-side-card .slider-crousel{
    background: #e8eef3;
    height: 350px;
    line-height: 350px;
    width:100%;
    border-radius: 4px;
    display: flex;
    align-items: center;
}
.modal-body .community-side-card .slider-crousel{
    height: 200px;
}
.community-side-card .slider-crousel img{
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
}
.post-img-container{
    background: #e8eef3;
    height: 350px;
    line-height: 350px;
    width:100%;
    border-radius: 4px;
    display: flex;
    align-items: center;
}
.post-img-container .img-responsive{
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
}
.link-preview{
    display: flex;
    width: 100%;
    margin-top: 10px;
    border: 1px solid #e8eef3;
    box-shadow: 0 0.0625rem 0.1875rem 0 rgba(0,0,0,0.08);
    /*align-items: center;*/
}
.link-preview-img{
    width: 30%;
    /*padding: 10px;*/
}
.link-preview-data{
    width: 70%;
    margin: 10px;
    position: relative;
}
.link-meta-title{
    font-size: 16px;
    font-family: EinaBold;
    margin-top: 0px;
    margin-bottom: 10px;
    color: #000;
}
.link-meta-desc{
   font-size: 14px;
   color: #000;
   font-family: SofiaRegular;
    margin-bottom: 5px;
}
.domain-link{
    color: #97989a;
    font-size: 12px;
    font-family: SofiaRegular;
}
.community-question a{
    color: #000;
}
.community-question a:hover,
.community-question a:focus{
    color: #ff337d;
}
.hash-tag{
    color: #ff337d!important;
}
.tagged-user{
    color: #2468EC!important;
}
.card-footer-ul{
    margin-top: 15px;
    border-bottom: 1.5px solid #d6d7d8;
    padding-bottom: 10px;
}
.card-footer-ul li+li{
    margin-left: 12px;
}
.card-footer-ul .fa{
    font-size: 18px;
}
.card-footer-ul .fa-heart{
    color: #ff337d;
}
.card-footer-ul li>a{
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    color: #2E2E2E;
    font-size: 14px;
    font-family: SofiaMedium;
}
.card-footer-ul.sub-footer-ul{
    padding-bottom: 5px;
}
.card-footer-ul.sub-footer-ul li>a{
   font-size: 12px;
    font-family: SofiaMedium;
}
.sub-footer-ul li>a:hover,
.sub-footer-ul li>a:focus{
    color: #ff337d;
}
.card-footer-ul li>a img{
  width: 20px;
  margin-right: 5px;
}

.card-footer-media{
    margin-top: 5px;
    margin-bottom: 5px;
}
.comment-bubble{
  background: #fff3f3;
  padding: 5px 10px;
  border-radius: 8px;
}
.comment-bubble.reply-bubble{
   background: #fff8f1;
}
.card-footer-media .blank-dp{
    height: 40px!important;
    width: 40px!important;
    line-height: 30px!important;
}
.card-footer-media .blank-dp span{
    font-size: 16px!important;
}
.card-footer-media .posted-by-name{
    font-size: 15px;
    margin-top: 5px;
}
.tagging-user-img{
    float: left;
    margin-right: 10px;
}
.tagging-user-img .blank-dp{
    height: 40px!important;
    width: 40px!important;
    line-height: 30px!important;
}
.tagging-user-img .blank-dp span{
    font-size: 16px!important;
}
.tagging-user-list-box{
    /*position: relative;*/
}
.tagging-user-list{
    background: #fff;
    padding:10px 20px;
    margin:0px auto;
    max-height: 200px;
    overflow: hidden;
    overflow-y: auto;
    position: absolute;
    width: 95%;
    left: 0;
    right: 0;
    z-index: 1;
    border: 1px solid #d6d7d8;
    border-radius: 6px;
}
.tagging-user-list li{
    /*margin-bottom: 5px;*/
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e8eef3;
    padding: 5px 0px;
}
.tagging-user-list li>a{
    font-family: SofiaLight;
    font-size: 16px;
    color: #000;
    vertical-align: middle;
}
.tagging-user-list li>a:hover,
.tagging-user-list li>a:focus{
    color: #ff337d;
}
.form-control.post-text-box{
    width: 100%;
   border: none!important;
    box-shadow: none!important;
    max-height: 200px;
    padding: 6px 5px;
}
textarea.post-text-box::placeholder{
    font-size: 20px;
}
textarea.post-text-box::-webkit-input-placeholder {
    font-size: 20px;
}
textarea.post-text-box:-moz-placeholder {
    font-size: 20px;
}
.community-comment-box{
    border: 1px solid #e8eef3;
    border-radius: 16px;
    width: 100%;
    max-height: 150px;
    padding: 5px 10px;
    margin-top: 5px;
}
.comment-text{
    font-size: 14px;
    font-family: Helvetica, serif;
    color: #000;
}
.comment-box-media{
    margin-top: 10px;
}
.comment-box-media .blank-dp{
    height: 40px!important;
    width: 40px!important;
    line-height: 30px!important;
}
.comment-box-media .blank-dp span{
    font-size: 16px!important;
}
.commented-by-img{
    width: 40px!important;
    height: 40px!important;
    border-radius: 50%;
    object-fit: cover;
    object-position: 0% 0%;
    /*border: 2px solid #d57712;*/
}
.reply-on-commente-img{
    width: 30px!important;
    height: 30px!important;
    border-radius: 50%;
    object-fit: cover;
    object-position: 0% 0%;
}
.reply-media .blank-dp{
    height: 30px!important;
    width: 30px!important;
    line-height: 20px!important;
}
.reply-media .blank-dp span{
    font-size: 14px!important;
}
.posted-img-preview-box{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.posted-img-preview{
    width: 21%;
    margin: 15px 10px;
    margin-top: 0px;
    position: relative;
}
.posted-img-preview img{
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}
.img-delete-btn{
    height: 25px;
    width:25px;
    text-align: center;
    background: #000;
    opacity: 0.5;
    border-radius: 50%;
    position: absolute;
    margin: 5px;
    right: 0;
    font-size: 18px;
    color: #fff;
    box-shadow: 0 3px 4px 0 rgba(197, 196, 196, 0.5);
    cursor: pointer;
}
@media screen and (max-width: 767px) {
    .community-question{
        font-size: 14px;
    }
    .card-footer-media .posted-by-name{
        font-size: 14px;
    }
    .comment-text{
        font-size: 12px;
    }
    .card-footer-ul li>a img{
        width: 15px;
    }
    .card-footer-ul li>a{
        font-size: 12px;
    }
    .card-footer-ul .btn-link{
        font-size: 12px;
    }
    .card-footer-ul li+li{
        margin-left: 5px;
    }
    .card-footer-ul.sub-footer-ul li>a{
        font-size: 12px;
        font-family: SofiaLight;
    }
    .community-side-card .slider-crousel,
    .post-img-container{
        height: 200px;
    }
}
/*============================End Community Css====================================*/





/*=====================================Responsive media=====================*/

@media screen and (max-width: 767px) {
    .bs-wizard {
        margin-top: 20px;
    }
    .bs-wizard > .bs-wizard-step .bs-wizard-stepnum {
        color: #979797;
        font-size: 14px;
        margin-bottom: 5px;
    }
    .fullscreen-modal .image-gallery-fullscreen-button {
        bottom: 70px!important;
    }
    .d-about-text>span+span {
        margin-left: 10px;
    }
    .image-gallery-image {
        height: 220px;
        line-height: 220px;
        overflow: hidden;
    }
    .fullscreen .image-gallery-image {
        height: 100%;
    }
    .designer-media .blank-dp {
        height: 55px!important;
        width: 55px!important;
        line-height: 45px;
    }
    .media.designer-media .media-left {
        vertical-align: middle;
    }
    .d-inline-text{
        font-size: 14px;
    }
    .price-compair-card-body ul li:before, .price-compair-card-body ol li:before{
        font-size: 14px;
    }
    .price-compair-card-body ul li,
    .price-compair-card-body ol li {
        font-size: 14px;
        font-family: SofiaRegular;
        text-indent: -25px;
        margin-left: 20px;
        margin-top: 8px;
    }
    .assurance-heading{
        font-size: 16px;
    }
    .assurance-ul li {
        margin-top: 0px;
        margin-bottom: 5px;
        font-size: 14px;
        font-family: SofiaMedium;
        margin-left: 0px;
    }
    .assurance-ul li>img {
        width: 22px;
        vertical-align: middle;
        margin: 5px;
    }
    .dashboard-assuarance .assurance-ul li{
        font-size: 14px;
    }
    .designer-prfile-img {
        width: 55px!important;
        height: 55px!important;
    }
    .listing-panel .designer-media-panel .blank-dp {
        margin-top: 0px!important;
        height: 55px!important;
        width: 55px!important;
        line-height: 30px;
    }
    .designer-media-panel .media-left {
        vertical-align: middle;
    }
    .d-designer-heading {
        font-size: 16px;
        margin-bottom: 15px;
    }
    .external-link {
        font-size: 16px;
        margin-top: 10px;
    }
    .price-compair-card .nav-tabs.nav-justified>li {
        display: table-cell;
        width: 1%;
    }
    .price-compair-card .nav-tabs.nav-justified a {
        font-family: SofiaRegular;
        font-size: 14px;
    }
    .btn-share-product {
        font-size: 12px;
        padding: 3px 10px;
    }
    .d-name-text {
        font-size: 16px;
    }
    .d-bio-text {
        font-size: 14px;
    }
    .d-about-text{
        font-size: 14px;
        margin-bottom: 0px;
    }
}
/*====================================Designer new product====================*/

.our-team-main-container {
    position: absolute;
    margin-top: 1850px;
}
.gallery_product {
    margin-bottom: -5px;
    padding: 5px;
    padding-left: 0px;
}
.hover-container {
    position: relative;
    width: 100%;
}
.hover-container:after {
    content: '';
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background: -moz-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.4) 100%);
    background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.4) 100%);
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.4) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1);
}
.team-m-image {
    display: block;
    width: 100%;
    height: auto;
    -webkit-filter: grayscale(100%);
    /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}
.team-m-image-overlay {
    position: absolute;
    bottom: 0;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.5);
    color: #f1f1f1;
    width: 100%;
    height: 30%;
    transition: .5s ease;
    opacity: 0;
    color: white;
    font-size: 15px;
    padding: 20px;
    text-align: center;
    z-index: 100;
}
.middle-text-image {
    position: absolute;
    bottom: 0;
    right: 10px;
}
.middle-text-image .name-text {
    text-align: right;
    font-size: 16px;
    color: #fff;
    font-family: SofiaMedium;
}
.our-team-header {
    font-size: 52px;
    margin-top: 30px;
    margin-bottom: 30px;
    color: #000;
    font-family: EinaBold;
}
.our-team-sub-header {
    font-size: 40px;
    margin-top: 30px;
    margin-bottom: 30px;
    color: #000;
    font-family: EinaBold;
}
.hover-container:hover .team-m-image-overlay {
    opacity: 1;
}
.hover-container:hover .team-m-image {
    -webkit-filter: none;
    filter: none;
}
.carousel .slide {
    background: #fff!important;
}
.carousel .thumb {
    width: 200px!important;
}

.mega-dropdown {
    position: static !important;
}
.mega-dropdown-menu {
    padding: 20px 0px;
    width: auto!important;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .1);
    -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, .1);
}
.secondary-nav .mega-dropdown-menu {
    width: auto!important;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}
.mega-dropdown-menu .browse-more li {
    margin-top: 5px;
    margin-bottom: 5px;
}
.mega-dropdown-menu .xs-heading {
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 16px;
    font-family: EinaBold;
}
.secondary-nav .mega-dropdown-menu li>a:hover,
.secondary-nav .mega-dropdown-menu li>a:focus {
    color: #ff337d;
    margin-left: 0px;
}
.navbar-nav > li > .dropdown-menu {
    background-color: #fff;
    border: none !important;
}
.navbar-nav>li> .dropdown-menu.d-sub-menu li{
    border-bottom: 1.5px solid #e8eef3;
    padding: 5px 0px;
}
.navbar-nav>li> .dropdown-menu.d-sub-menu li:nth-last-child(1){
    border: none;
}
.navbar-nav>li> .dropdown-menu.d-sub-menu li>a {
    font-size: 14px;
}
.navbar-nav>li> .dropdown-menu li>a {
    color: #000;
    font-family: SofiaLight;
    font-size: 16px;
    /*-webkit-transition: all .5s ease;*/
    /*transition: all .5s ease*/
}
.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover {
    background-color: #fff !important;
    color: #ff337d !important;
    /*margin-left: 6px;*/
    /*-webkit-transition: all .5s ease;*/
    /*transition: all .5s ease*/
}
.navbar-default .dropdown-menu {
    min-width: 350px;
}
.navbar-default .dropdown-submenu {
    position: relative;
}
.navbar-default .dropdown-submenu > .dropdown-menu {
    max-height: 300px;
    overflow-y: scroll;
    background-color: #fff;
    border: none !important;
    width: 100%;
    top: 0;
    left: 100%;
    margin-top: -6px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
}
.navbar-default .dropdown-submenu > a:after {
    border-color: transparent transparent transparent #000;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    content: " ";
    display: block;
    float: right;
    height: 0;
    margin-right: -10px;
    margin-top: 6px;
    width: 0;
}
.navbar-default .dropdown-submenu:hover > a:after {
    border-left-color: #000;
}
.navbar-default .dropdown-menu > li > a:hover,
.dropdown-menu > .active > a:hover {
    text-decoration: none;
}
.secondary-nav {
    border-bottom: 1.2px solid #e8eef3 !important;
    top: 65px;
    margin-bottom: 0px;
}
.secondary-nav .dropdown-menu.mega-dropdown-menu {
    min-width: auto!important;
    padding: 0px 10px 30px 30px;
}
.secondary-nav .dropdown-menu {
    min-width: 100%!important;
}
.secondary-nav .dropdown-menu li {
    margin-bottom: 5px;
}
.secondary-nav .navbar-nav>li>a {
    font-family: EinaBold;
}
.nav-justify-content-center {
    width: 100%;
    text-align: center;
}
.nav-justify-content-center>li {
    padding-left: 0px;
    padding-right: 0px;
    float: none;
    display: inline-block;
}
@media (min-width: 768px) {
    ul.nav li:hover > ul.dropdown-menu {
        display: block;
    }
}
/*=============================================Header Dropdown======================*/
.main-header .navbar-brand{
    margin-left: 5px!important;
    padding: 10px 10px!important;
}
.header-logo {
    width: 120px;
    margin-top: 0px;
}
.gig-header-logo {
    width: 130px;
    margin-top: 5px;
}
/*.wiz-work .heading-sm{*/

/*font-size: 24px;*/

/*}*/

.lock-verified-icon {
    margin-left: 0px;
    margin-right: 15px;
    vertical-align: top;
}
.brief-heading {
    font-size: 20px;
    font-family: EinaBold;
    margin-top: 20px;
}
.brief-note {
    margin-top: 15px;
    font-size: 16px;
    font-family: SofiaLight;
    margin-left: 30px;
    text-indent: -35px;
}
.login-button {
    background-color: #fff;
    color: #ff337d !important;
    border: 1.2px solid #ff337d;
    padding: 12px 20px !important;
    margin-top: 0px;
    text-align: center;
    font-size: 14px !important;
    font-family: SofiaSemiBold !important;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
}
.login-button:hover,
.login-button:focus {
    background-color: #ff337d !important;
    color: #fff !important;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
}
.btn-done-mark {
    background-color: #000;
    padding: 12px 25px;
    color: #fff;
    font-size: 16px;
    font-family: SofiaLight;
}
.btn-done-mark:hover,
.btn-done-mark:focus {
    color: #fff;
}
/* ========================================Header End========================= */

/* ========================================Body========================= */

/* ========================================Learning center========================= */

.general-header {
    height: 60px;
    border-bottom: 1px solid #e8eef3;
}
.side-btn-container {
    margin-top: -80px;
}
.owl-stage {
    padding-left: 0px !important;
}
.ui-lg-heading {
    font-family: EinaBold;
    font-size: 4em;
    margin-bottom: 30px;
    color: #000;
}
.home-learning-center-section {
    background-color: #cff0f8;
    height: 250px;
}
.home-learning-bottom-section {
    background-color: #cff0f8;
    height: 280px;
    margin-bottom: 20px;
    text-align: center;
}
.learning-bottom-heding {
    font-family: EinaBold;
    font-size: 26px;
    line-height: 1.3em;
    margin-top: 30px;
    margin-bottom: 20px;
    text-align: center;
}
.box-small-heding {
    font-family: SofiaSemiBold;
    text-transform: uppercase;
    font-size: 16px;
    color: #000;
    margin-top: 30px;
    margin-bottom: 15px;
}
.more-learning-btn {
    border: 1.2px solid #ff1e9b;
    padding: 10px 15px;
    color: #ff1e9b;
    font-family: SofiaLight;
    border-radius: 4px;
    font-size: 18px;
}
.more-learning-btn:hover,
.more-learning-btn:focus {
    background-color: #ff1e9b;
    color: #fff;
}
.box-para {
    font-size: 16px;
    font-family: SofiaMedium;
    line-height: 1.5em;
    margin-bottom: 30px;
}
.toc-box{
    padding: 30px 20px;
    margin: 20px 10px;
    border: 1px solid #ccc;
    background: #fafafa
}
.home-learning-center-heading {
    font-family: EinaBold;
    font-size: 36px;
    line-height: 1.2em;
    margin-top: 20px;
    text-align: center;
}
.home-learning-center-heading.background-line {
    position: relative;
    z-index: 1;
}
.home-learning-center-heading.background-line:before {
    border-top: 1px solid #000;
    border-top-width: thin;
    content: "";
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: -1;
}
.home-learning-center-heading.background-line span p {
    display: inline;
}
.home-learning-center-heading.background-line span {
    background: #fff;
    padding: 0 15px;
}
.learning-ui-box {
    position: relative;
    height: 380px;
    border-radius: 4px;
    border: 1px solid #e8eef3;
    text-align: center;
    padding: 10px;
    margin-bottom: 40px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, .1);
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
}
.learning-ui-box:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, .1);
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
}
.learning-ui-box .category-blog-icon-img{
   height: 150px;
    width:100%;
}
.learning-ui-box .category-blog-icon-img img{
    width: 100%;
    height: 100%;
}
.learning-sidebar-box {
    min-height: 250px;
    background-color: #fde8e8;
    padding: 10px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
}
.category-blog-box {
    max-height: 100%;
    height: 400px;
    margin-bottom: 50px;
    position: relative;
    padding: 10px;
    border: 1px solid #fff;
    box-shadow: 0 0 1.5rem rgba(0,0,0,.1);
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
}
.category-blog-box.product-box{
    height: 240px;
    margin-bottom: 30px;
}
.category-blog-box.popular-service-ui{
    height: 280px;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-left: 5px;
}
.category-blog-box.popular-service-ui .category-heading{
    font-size: 16px;
}
.category-blog-box.popular-service-ui:hover .category-heading,
.category-blog-box.popular-service-ui:focus .category-heading{
    color:#ff337d;
}
.popular-service-img{
    height: 200px;
    width:100%;
    position: relative;
    overflow: hidden;
}
.popular-service-img img {
    object-fit: cover;
    height: 200px;
    width: 100%;
    -webkit-transition: opacity .3s ease-in-out;
    -o-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out;
    position: absolute;
    top: 0;

}
.product-box-service-img {
    height: 160px;
    text-align: center;
    width:100%;
    position: relative;
    overflow: hidden;
}
.product-box-service-img img {
    width: 100%;
    max-height: 100%;
    display: block;
}
.category-blog-box.product-box:hover .category-heading{
    color: #ff337d;
}
.category-blog-box.product-box .category-heading{
    font-size: 16px;
}
.category-blog-box-img {
    max-height: 200px;
    position: relative;
    overflow: hidden;
}
.category-blog-box-img img {
    width: 100%;
    height: auto;
}
.free-service-box-img {
    height: 280px;
    text-align: center;
    width:100%;
    position: relative;
    /*overflow: hidden;*/
}
.free-service-box-img img {
    width: 100%;
    max-height: 100%;
    display: block;
}
.category-heading {
    font-size: 18px;
    color: #000;
    line-height: 1.4em;
    font-family: EinaBold;
    margin-top: 10px;
}
.category-heading:hover {
    color: #ff337d;
}
.category-read-more-box {
    position: absolute;
    /*position: relative;*/
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px 10px;
    /*padding: 0px 0px;*/
}
.category-read-more-box .read-more-link .fa-long-arrow-right{
    vertical-align: middle;
}
.category-read-more-box .read-more-link{
    text-align: left;
    color:#ff337d;
    font-size: 16px;
    font-family: EinaBold;
    margin-bottom: 10px;
}
.learning-sidebar-heading {
    font-size: 18px;
    font-family: EinaBold;
    color: #000;
    line-height: 1.4em;
}
.learning-sidebar-para {
    margin-top: 15px;
    font-size: 16px;
    color: #000;
    line-height: 1.5em;
    font-family: SofiaLight;
}
.learning-ui-box-heading {
    font-size: 18px;
    font-family: EinaBold;
    color: #000;
    line-height: 1.4em;
    margin-top: 20px;
    margin-bottom: 15px;
}
.blog-time {
    /*position: absolute;*/
    position: relative;
    /*bottom: 0;*/
    /*left: 0;*/
    width: 100%;
    /*padding: 10px 10px;*/
    padding: 0px 0px;
}
.blog-date {
    margin-top: 15px;
    font-size: 14px;
    color: #979797;
    font-family: SofiaLight;
}
.blog-date span+span{
    margin-left: 10px;
}
.blog-author {
    float: right;
    margin-top: 20px;
    font-size: 14px;
    color: #979797;
    font-family: SofiaLight;
}
.btn-learning-add {
    margin-top: 25px;
    background-color: #ff1e9b;
    font-size: 16px;
    color: #fff;
    padding: 6px 15px;
    font-family: SofiaMedium;
}
.btn-learning-add:hover,
.btn-learning-add:focus {
    color: #fff;
}
.short-description {
    font-size: 16px;
    text-align: left;
    font-family: SofiaLight;
    line-height: 1.4em;
    color: #000;
    margin-top: 10px;
    margin-bottom: 15px;
}
.learning-ui-box a {
    color: #ff337d;
    font-family: SofiaRegular;
    font-size: 16px;
}
.ask-question-heading {
    font-size: 32px;
    font-family: EinaBold;
    color: #000;
}
.ask-question-sub-heading {
    font-size: 18px;
    font-family: SofiaLight;
    line-height: 1.4em;
    margin-bottom: 20px;
}
.learning-ui-box-sticky {
    position: sticky;
    top: 60px;
}
.ui-sticky-side-block {
    position: sticky;
    top: 100px;
}
.ui-sticky-sidebar-block {
    position: sticky;
    top: 20px;
}
.ui-sticky-side-block .d-accordion .panel-collapse .panel-body a {
    color: #000;
}
.ui-sticky-side-block .d-accordion .panel {
    margin-bottom: 5px;
}
.ui-sticky-side-block .d-accordion .panel-title {
    font-family: SofiaLight;
    font-size: 16px;
}
.ui-sticky-side-block .d-accordion .panel-title .fa-angle-down {
    margin-top: -2px;
    font-size: 20px;
}
.ui-h2 {
    font-family: EinaBold;
    font-size: 18px;
    margin-bottom: 30px;
    margin-top: 10px;
    color: #000;
}
.ul-category {
    margin-bottom: 30px;
}
.ul-category li {
    margin-bottom: 15px;
    border-bottom: 1px solid #e8eef3;
}
.ul-category li > a {
    color: #000;
    font-size: 14px;
    font-family: SofiaLight;
    text-transform: lowercase;
}
.ul-category li.active a {
    color: #ff337d;
}
.ul-category li > a:focus,
.ul-category li > a:hover {
    background-color: #fff !important;
    color: #ff337d !important;
    margin-left: 6px;
    -webkit-transition: all .5s ease;
    transition: all .5s ease
}
.api-question-answer b {
    font-family: EinaBold!important;
    font-size: 18px;
}
.api-question-answer img {
    width: 100%;
    height: auto;
}
.api-question-answer table {
    width: 100%;
}
.api-question-answer table>tbody>tr>td {
    padding: 8px;
    border: 1px solid #e8eef3;
    font-family: SofiaLight;
}
.api-question-answer table>tbody>tr>td span {
    font-size: 16px;
}
.category-detail-block {
    margin-top: 10px;
    margin-bottom: 30px;
}
.category-detail-block img {
    width: 100%;
    height: auto;
}
.blog-detail-heading {
    font-size: 32px;
    font-family: EinaBold;
    margin-top: 20px;
    margin-bottom: 20px;
}
.social-share-box {
    border-bottom: 1px solid #e8eef3;
    margin-top: 30px;
    padding-top: 10px;
    margin-bottom: 0px;
}
.social-share-box .heading-sm {
    font-size: 22px;
}
.author-block {
    /*border-top: 1px solid #e8eef3;*/
    padding-top: 10px;
    margin-bottom: 20px;
}
.author-block .blank-dp {
    padding-top: 15px!important;
}
.author-block .dp-image{
    margin-top: 5px;
    border: 2px solid #ecb33c;
}
.author-block .media-heading {
    font-size: 18px;
    margin-top: 15px;
    font-family: SofiaSemiBold;
    color: #000;
}
strong {
    font-family: EinaBold!important;
}
.api-question-answer b span {
    font-family: EinaBold!important;
}
.api-question-answer span {
    font-family: Arial;
    font-size: 18px;
}
.api-question-answer span td {
    font-family: Arial;
    font-size: 16px;
}
.api-question-answer p {
    line-height: 1.5em;
    margin-top: 10px;
    margin-bottom: 20px;
    font-family: Arial;
    /*text-align: justify;*/
}
.text-question {
    font-family: EinaBold;
    font-size: 20px;
    line-height: 1.5em;
    margin-bottom: 10px;
}
.text-answer {
    font-family: SofiaLight;
    font-size: 16px;
    line-height: 1.5em;
    margin-top: 10px;
    color: #000;
    margin-bottom: 20px;
}
.breadcrumb li a {
    text-transform: capitalize;
    color: #000;
    font-size: 16px;
}
.breadcrumb {
    background-color: #fff;
    padding-inline-start: 0px;
}
.breadcrumb li:nth-last-child(1) a {
    color: #ff337d;
}
.agreed-t-and-c-h1 {
    font-size: 20px;
    font-family: EinaBold;
    color: #000;
    line-height: 1.4em;
    margin-bottom: 10px;
}
.agreed-t-and-c-p {
    font-size: 18px;
    font-family: SofiaLight;
    color: #000;
    line-height: 1.4em;
    margin-bottom: 15px;
}
.agreed-t-and-c-h2 {
    font-size: 20px;
    font-family: EinaBold;
    line-height: 1.4em;
    margin-top: 20px;
    margin-bottom: 20px;
}
.agreed-t-and-c-h3 {
    font-size: 18px;
    font-family: EinaBold;
    line-height: 1.4em;
    margin-top: 30px;
    margin-bottom: 0px;
}
.agreed-t-and-c-h4 {
    font-size: 16px;
    font-family: EinaBold;
    line-height: 1.4em;
    margin-top: 10px;
    margin-bottom: 0px;
}
@media screen and (max-width: 767px) {
    .learning-ui-box{
        height: auto;
    }
    .learning-ui-box .category-read-more-box{
        position: relative;
    }
    .learning-ui-box .category-blog-icon-img{
        height: auto;
        width:100%;
    }
    .home-learning-center-heading.background-line:before {
        display: none;
    }
    .home-learning-center-heading.background-line span {
        background: #fff;
        padding: 0 0px;
    }
    .home-learning-center-heading.background-line span p{
        display: block;
    }
    .breadcrumb {
        margin-top: 20px;
        padding: 8px 0px;
        white-space: nowrap;
        width:100%;
        overflow-x: auto;
    }
    .home-learning-bottom-section {
        height: auto;
        padding: 30px 20px;
    }
    .category-detail-block {
        margin-top: 20px;
    }
    .category-blog-box {
        margin-bottom: 30px;
        height: 360px;
    }
    .home-learning-center-heading {
        margin-top: 20px;
        font-size: 20px;
    }
    .breadcrumb li a{
        font-size: 12px;
    }
    .category-blog-box.product-box{
        height: 145px;
    }
    .product-box-service-img {
        height: 80px;
        overflow: hidden;
    }
    .category-blog-box.popular-service-ui{
        height: 170px;
        margin-bottom: 10px;
        margin-top: 10px;
    }
    .category-blog-box.popular-service-ui .category-heading{
        font-size: 14px;
    }
    .popular-service-img{
        height: 100px;
        width:100%;
        position: relative;
        overflow: hidden;
    }
    .popular-service-img img {
        object-fit: cover;
        height: 100px;
        width: 100%;
        -webkit-transition: opacity .3s ease-in-out;
        -o-transition: opacity .3s ease-in-out;
        transition: opacity .3s ease-in-out;
        position: absolute;
        top: 0;

    }
    .social-share-box .heading-sm {
        font-size: 18px;
    }
    .social-share-box {
        padding-bottom: 10px;
    }
    .category-blog-box.product-box .category-heading{
        font-size: 12px;
    }
    .category-heading {
        font-size: 14px;
    }
    .learning-bottom-heding {
        font-size: 20px;
        margin-top: 0px;
    }
    .blog-detail-heading {
        font-size: 18px;
        margin-top: 15px;
        margin-bottom: 10px;
    }
    .blog-date {
        margin-top: 20px;
    }
    .api-question-answer span {
        font-size: 14px;
    }
    .api-question-answer table>tbody>tr>td span {
        font-size: 12px;
    }
    .api-question-answer h3 {
        font-size: 20px;
    }
    .box-para {
        margin-bottom: 20px;
    }
    .video-testimonial-block {
        height: 230px;
        overflow: auto;
    }
    .video-testimonial-block .video iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 230px;
        border: none;
    }
    .testimonials-user-name {
        font-size: 16px;
        margin-bottom: 5px;
    }
    .user-bio {
        margin-bottom: 20px;
        font-size: 12px;
    }
    .ui-testimonial-container {
        margin-bottom: 30px;
    }
    .tesimonial-text-box {
        margin-top: 3%;
    }
    .testimonials-h1 {
        font-size: 22px;
        line-height: 1.4em;
    }
    .testimonials-h2 {
        margin-top: 10px;
        margin-bottom: 30px;
        font-size: 22px;
    }
    .testimonials-para {
        font-size: 16px;
        margin-bottom: 10px;
        margin-bottom: 10px;
    }
    .testimonials-text-name {
        font-size: 12px;
        font-family: EinaBold;
        line-height: 1.2em;
        margin-top: 0px;
    }
    .testimonials-text-name span {
        font-size: 12px;
        font-family: SofiaRegular;
    }
    .carousel .thumb {
        width: 100px!important;
    }
    .testimonials-section {
        margin-top: 80px!important;
    }
    .offer-brand-card .home-industry__icon img {
        width: 70px;
    }
    .up-to-offer {
        font-size: 14px;
    }
    .offer-brand-table tbody>tr>td img {
        width: 70px;
    }
    .explore-product-section{
        background-color: transparent;
        padding: 0px 0px;
        border-radius: 6px;
        position: relative;
    }
    .explore-product-card-image{
        position: absolute;
        z-index: -1;
        left: 0;
        right: 0;
        margin: 10px;
    }
    .explore-product-card{
        background-color: #fff;
        border-radius: 6px;
        padding: 15px;
        position: relative;
        box-shadow: 0 12px 20px 0 rgba(0,0,0,.12);
        z-index: 1;
        margin-top: 130px;
    }
    .explore-product-section ul>li{
        font-size: 14px;
        margin-bottom: 10px;
    }
    .explore-product-section ul>li:before{
        margin-top: 3px;
    }
    .help-center-section .jumbotron-box {
        padding: 15px 10px;
        border-radius: 6px;
    }
    .covid-first-section{
        height: auto;
        background: #cffcd8;
        border-radius: 4px;
    }
    .covid-app-section{
        height: auto;
        background: #ffe1e5;
        margin: 10px;
        margin-top: 30px;
        padding-top: 0px!important;
        border-radius: 4px;
    }
    .covid-app-section .scope-heading{
        margin-top: 10px;
    }
    .quote-panel{
        padding: 0px!important;
    }
    .covid-app-section .help-center-h1{
        margin-top: 0px;
    }
    .covid-text-box{
        left: 0px;
        position: relative;
        padding: 20px;
    }
    .covid-help-ul{
        display: block;
    }
    .covid-help-ul li{
        font-size: 16px;
        width:50%;
        /*margin: 10px 0px;*/
    }
   .covid-help-ul .icon-box{
       height: 50px;
       line-height: 50px;
    }
    .covid-help-ul img{
        width:35px;
    }
    .covid-first-section .help-center-h2 {
        font-size: 16px;
    }
    .explore-product-section .help-center-h1{
        margin-top: 0px;
        margin-bottom: 0px;
    }
    .explore-product-section ul>li:before{
        background-size: 10px;
    }
    .explore-product-section ul>li{
        margin-left: 20px;
    }
    .help-center-h1 {
        line-height: 1.4em;
        font-size: 20px;
    }
    .help-center-h2 {
        font-family: SofiaRegular;
        font-size: 18px;
    }
    .help-inline-tab {
        padding: 15px 0px;
    }
    .help-inline-tab li {
        width: 100%;
        text-align: center;
    }
    .help-inline-tab li>a {
        font-size: 16px;
        padding: 10px 15px;
        text-align: center;
        width: 100%;
    }
    .help-faq .panel-title {
        font-family: EinaBold!important;
    }
    .help-faq-heading {
        font-size: 18px;
        margin-top: 20px;
    }
    .help-faq-note {
        font-size: 16px;
        color: #000;
        margin-top: 20px;
        margin-bottom: 0px;
    }
    .help-faq-para {
        font-size: 16px;
        margin-top: 10px;
        margin-bottom: 15px;
    }
    .article-table-heading {
        font-size: 16px;
    }
    .article-text-h1 {
        font-size: 22px;
        color: #000;
        margin-top: 10px;
        margin-bottom: 25px;
    }
    .article-text-h2 {
        font-family: SofiaRegular;
        font-size: 18px;
        color: #000;
        margin-bottom: 25px;
    }
    .article-text-h3 {
        font-size: 18px;
        color: #000;
        margin-top: 20px;
        margin-bottom: 25px;
    }
    .article-ul li {
        font-size: 18px;
        margin-top: 10px;
    }
    .article-ul li a {
        color: #ff337d;
    }
    .article-para {
        font-size: 18px;
        font-family: SofiaLight;
        color: #000;
        margin-bottom: 15px;
    }
    .article-para a {
        color: #ff337d;
    }
    .agreed-t-and-c-h1 {
        font-size: 20px;
    }
    .agreed-t-and-c-h2 {
        font-size: 18px;
    }
    .our-brand-section{
        background-color: #fff;
        padding: 10px;
    }
    .learning-ui-box-sticky {
        position: relative;
        top: 20px;
    }
}
/* ========================================End learning center========================= */

/*===========================Stats ui=============================*/

.main_counter_area {
    border-top: 1px solid #e8eef3;
    border-bottom: 1px solid #e8eef3;
}
.p-y-2 {
    padding-top: 28px;
    padding-bottom: 28px;
}
.m-b-1 {
    margin-bottom: 18px;
}
.main_counter_area .main_counter_content .single_counter {
    background: #fff;
    color: #000;
}
.main_counter_area .main_counter_content .single_counter i {
    font-size: 36px;
}

.single_counter img {
    width: 110px;
}
.single_counter p {
    font-size: 20px;
    font-family: EinaBold;
}
.statistic-counter {
    font-size: 24px!important;
    font-family: EinaBold;
    color: #000;
    margin-top: 15px;
    margin-bottom: 0px;
}
/*===========================Stats ui=============================*/

.ui-first-section {
    height: 540px;
    background-image: linear-gradient(to left, rgba(253, 160, 209, 0.5), #b81253);
}
.add-page-section {
    height: 545px;
    background-image: linear-gradient(to left, rgba(160, 160, 253, 0.5), #2512b8);
}
.add-page-section .banner-text-heading {
    margin-top: 40px;
    font-size: 32px;
}
.add-page-section .top-margin {
    margin-top: 30px;
}
.book-service-box {
    margin-top: 20px;
    background-color: #fff;
    padding: 15px 20px;
}
.text-service-price {
    font-family: EinaBold;
    font-size: 20px;
    margin-top: 0px;
    color: #000;
}
.text-installment-price {
    font-family: SofiaLight;
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 20px;
    color: #000;
}
.btn-book-service {
    padding: 12px 30px;
    background-color: #3acc9e;
    font-size: 18px;
    font-family: SofiaMedium;
    color: #fff;
}
.btn-book-service:hover,
.btn-book-service:focus {
    color: #fff;
}
.for-brand-section{
    display: flex;
    padding: 0px 10px;
    align-items: center;
}
.for-brand-section li{
    margin: 5px auto;
}
.for-brand-section li img {
    width: 12vw;
    /*-webkit-filter: grayscale(100%);*/
    /*filter: grayscale(100%);*/
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
}
.affiliate-home-section.help-center-section{
    margin-top: 60px!important;
}
.affiliate-home-section.for-expert-section{
    background: #FFF6ED;
    padding: 40px 0px 40px 0px;
}
.affiliate-home-section.for-expert-section .text-black-heading{
    line-height: 1.3em;
    margin-bottom: 25px;
    margin-top: 15px;
    font-size: 52px;
}
.for-expert-banner-img{
   /*margin-left: 20%;*/
    border-radius: 100% 6px 0 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.for-expert-banner-img img{
    max-height: 450px;
    object-fit: cover;
}
.for-expert-services{
    display: flex;
    /*justify-content: space-between;*/
    align-items: center;
    margin-top: 5%;
}
.banner-text-sub-para{
    font-size: 22px;
    font-family: SofiaRegular;
    color: #3e51e0;
    margin-bottom: 15px;
}
.for-expert-img {
    position: absolute;
    height: 400px;
    right: 0;
    width: 100%;
}
.for-expert-img img {
    position: absolute;
    width: 320px;
    top: 0px;
    right: 0px;
    z-index: 1;
}
.for-expert-img .banner-rating {
    bottom: 0px;
    left: 50px;
}
.for-expert-img .banner-rating .blockquote {
    margin-left: -170px;
    color: #fff;
}
.ui-first-section .lg-white-heading {
    margin-top: 20px;
    font-size: 40px;
}
.ui-first-section .top-margin {
    margin-top: 3%;
}
.ui-first-section .text-white-para {
    font-size: 22px;
    font-family: EinaBold;
    margin-top: 30px;
    margin-bottom: 30px;
}
.list-how-it-work > li {
    font-size: 22px;
    color: #000;
    font-family: SofiaLight;
    line-height: 1.5em;
    margin-top: 15px;
    margin-bottom: 25px;
}
.ui-how-it-work {
    height: 300px;
    background-color: #002c28;
}
.work-tab {
    margin-top: -5px;
    background-color: #002c28;
}
.work-tab .nav-tabs > li > a {
    font-size: 18px;
    color: #fff;
    font-family: SofiaLight;
}
.work-tab .nav-tabs.nav-justified > .active a {
    font-size: 18px;
    color: #fff;
    font-family: SofiaLight;
}
.work-tab .nav-tabs.nav-justified > .active > a,
.work-tab .nav-tabs.nav-justified > .active > a:focus,
.work-tab .nav-tabs.nav-justified > .active > a:hover {
    border: none;
    border-bottom-color: transparent;
}
.nav-tabs.nav-justified > li > a {
    border-bottom: 1px solid transparent;
}
.work-tab .nav-tabs > li.active {
    border: none;
    border-bottom: 5px solid #ff337d;
}
.work-tab .nav-tabs > li.active > a,
.work-tab .nav-tabs > li.active > a:focus,
.work-tab .nav-tabs > li.active > a:hover {
    background-color: transparent;
}
.work-tab .nav > li > a:focus,
.work-tab .nav > li > a:hover {
    border: 1px solid transparent;
    background-color: transparent;
}
.ui-first-section .white-line {
    margin-left: 0px;
    margin-bottom: 10px;
}
/*===================================================All services================*/

.designer-profile-card {
    border: solid 1px #DAE2E8;
    border-radius: 8px;
    margin-top: 10px;
    margin-bottom: 35px;
    /*height: 360px;*/
    height: 340px;
    position: relative;
    /*box-shadow: 0 2px 8px 0 rgba(0,0,0,0.08);*/
    transition: box-shadow .3s ease-in-out, transform .3s ease-in-out;
}
.designer-profile-card:hover{
    cursor: pointer;
    transition: box-shadow .3s ease-in-out, transform .3s ease-in-out;
    box-shadow: 0 2px 20px 0 rgba(0,0,0,0.16);
}
.gap-xs{
    padding: 0px 10px;
}
.giglist-container{
    padding: 0px 30px;
}
.common-card{
    height: 340px;
}
.designer-profile-card .react-player{
    height: 100% !important;
    width: 100% !important;
}
.professional-badge-with-name {
    /*text-align: center;*/
    font-family: SofiaRegular;
    /*font-size: 14px;*/
    z-index: 1;
    /*margin: 10px;*/
    vertical-align: middle;
}
.professional-badge-with-name .badge-pro,
.professional-badge-with-name .badge-super-pro,
.professional-badge-with-name .badge-recommended {
    border: none;
    padding: 5px 12px;
}
.professional-badge {
    position: absolute;
    right: 10px;
    margin-top: -20px;
    line-height: normal;
    font-family: SofiaRegular;
    font-size: 14px;
    z-index: 1;
}
.agency-badge{
    border: 2px solid #000;
    border-radius: 25px;
    padding: 3px 20px;
    background-color: #fff;
    color: #000;
}
.badge-gigzoe-preferred {
    border: 3px solid #fff;
    border-radius: 25px;
    padding: 5px 12px;
    background-color: #E82DA7;
    color: #fff;
}
.badge-pro {
    border: 3px solid #fff;
    border-radius: 25px;
    padding: 5px 12px;
    background-color: #B42DE2;
    color: #fff;
}
.badge-super-pro {
    border: 3px solid #fff;
    border-radius: 25px;
    padding: 5px 12px;
    background-color: #3463DF;
    color: #fff;
}
.badge-recommended {
    border: 3px solid #fff;
    border-radius: 25px;
    padding: 5px 12px;
    background-color: #DFAF3F;
    color: #fff;
}
.professional-badge-with-name{
    font-size: 12px;
}
.professional-badge-with-name .minimul-badge{
    margin-top: 10px;
    margin-bottom: 5px;
}
.professional-badge-with-name .minimul-badge .badge-gigzoe-new,
.professional-badge-with-name .minimul-badge .badge-gigzoe-preferred-text,
.professional-badge-with-name .minimul-badge .badge-pro-text,
.professional-badge-with-name .minimul-badge .badge-super-pro-text,
.professional-badge-with-name .minimul-badge .badge-recommended-text{
    border-radius: 25px;
    padding: 6px 10px;
}
.minimul-badge{
    margin-top: 5px;
    margin-bottom: 10px;
}
.badge-gigzoe-product{
    color: #d81bb1;
    border: 1.4px solid #d81bb1;
    background: #fff1f1;
    border-radius: 4px;
    padding:2px 6px;
    font-family: SofiaRegular;
    font-size: 12px;
}
.badge-gigzoe-new{
    /*color: #0000FF;*/
    /*border: 1.4px solid #0000FF;*/
    /*background: #f1fffa;*/
    /*border-radius: 4px;*/
    padding:2px 6px;
    /*font-family: SofiaRegular;*/
    /*font-size: 14px;*/
}
.badge-gigzoe-preferred-text{
    color: #d81bb1;
    border: 1.4px solid #d81bb1;
    background: #fff1f1;
    border-radius: 4px;
    padding:2px 6px;
    font-family: SofiaRegular;
    font-size: 14px;
}
.badge-pro-text{
    color: #982ee5;
    border: 1.3px solid #982ee5;
    background: #f1eaff;
    border-radius: 4px;
    padding:2px 6px;
    font-family: SofiaRegular;
    font-size: 14px;
}
.badge-super-pro-text{
    color: #087f69;
    border: 1.3px solid #087f69;
    background: #f1fffa;
    border-radius: 4px;
    padding:2px 6px;
    font-family: SofiaRegular;
    font-size: 14px;
}
.badge-recommended-text{
    color: #d57712;
    border: 1.3px solid #d57712;
    background: #f7f3ce;
    border-radius: 4px;
    padding:2px 6px;
    font-family: SofiaRegular;
    font-size: 14px;
}
.designer-profile-card .slider-crousel {
    height: 200px;
    line-height: 180px;
    position: relative;
    overflow: hidden;
    margin-bottom: 0px;
}
.image-gallery-slide {
    background: none!important;
}
.designer-profile-card .slider-crousel video {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}
.designer-profile-card .slider-crousel{
    border-bottom: 1px solid #DAE2E8;
    background: #f7f7f7;
}
.designer-profile-card .slider-crousel img {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    max-width: 100%!important;
    max-height: 100%!important;
    margin: auto;
    display: block!important;
    object-fit: scale-down;
}
.designer-profile-card .owl-theme .owl-nav{
    margin-top: -20px!important;
}
 .prev-slide{
     display: none;
    height: 30px;
     background-color: #fff;
     border-radius: 50%;
     width: 30px;
     line-height: 30px;
     cursor: pointer;
     -webkit-transition: right .2s;
     -o-transition: right .2s;
     transition: right .2s;
     box-shadow: 3px 3px 10px rgba(0,0,0,.25);
}
 .designer-profile-card:hover .prev-slide{
     display: block;
     -webkit-transition: right .2s;
     -o-transition: right .2s;
     transition: right .2s;
 }
.designer-profile-card:hover .hire-designer-button{
 color:#ff337d;
}
.designer-profile-card:hover .fa-long-arrow-right{
    color:#ff337d;
}
.owl-carousel .owl-prev.disabled,
.owl-carousel .owl-next.disabled{
    pointer-events: none;
    opacity: 0.2;
}

.designer-profile-card .fa-angle-left {
    position: absolute;
    left: 5px;
    top: 80px;
}
/*.designer-profile-card .fa {*/
    /*color: #000;*/
/*}*/
.designer-profile-card .fa-angle-right {
    position: absolute;
    right: 5px!important;
    top: 80px;
}
.designer-profile-card .fa-angle-left:hover,
.designer-profile-card .fa-angle-left:focus {
    color: #000;
}
.designer-profile-card .fa-angle-right:hover,
.designer-profile-card .fa-angle-right:focus {
    color: #000;
}
.profile-card-body {
    margin-top: 10px;
    padding: 5px 10px;
}
.profile-card-body .media .media-left {
    vertical-align: top;
}
.profile-card-body .blank-dp {
    line-height: 28px;
    width: 40px!important;
    height: 40px!important;
}
.profile-card-body .blank-dp span {
    font-size: 16px!important;
}
.span-text {
    color: #2f58e2;
    font-family: SofiaMedium;
    font-size: 15px;
}
.profile-card-body .list-inline li.span-text img{
    width: 15px;
    margin-top: -5px;
}
.designer-name .span-text{
    font-size: 18px;
}
.profile-card-footer {
    text-align: center;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
}
.profile-card-body .fa-star {
    color: gold;
    margin-left: 2px;
    font-size: 14px;
}
.profile-card-body .list-inline {
    padding: 0px 0px;
}
.how-it-work .message-item:nth-last-child(1)::before {
    height: 0px!important;
}
.hire-designer-button {
    padding: 8px 10px;
    background: #1dd59e;
    text-align: left;
    color: #fff;
    font-size: 16px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    font-family: SofiaMedium;
    width: 100%;
}
.hire-designer-button .fa-long-arrow-right {
    color: #000;
    vertical-align: middle;
}
.hire-designer-button:hover,
.hire-designer-button:focus {
    background: #1dd59e;
    color: #fff;
}
.designer-profile-card .hire-designer-button{
    background: transparent;
    color:#000;
    border-top: 1px solid #DAE2E8;
}
.designer-profile-card .list-inline{
    margin-top: 10px;
    margin-bottom: 0px;
    display: flex;
    justify-content: space-between;
}
.designer-dp {
    /*border: 2px solid #ecb33c;*/
    height: 40px!important;
    width: 40px!important;
    object-fit: cover;
    padding: 1px;
    object-position: 0% 0%!important;
    vertical-align: middle;
}
.designer-name {
    font-size: 16px;
    font-family: EinaBold;
    color: #000;
    margin-top: 0px;
    margin-bottom: 5px;
    display: initial;
}
.designer-bio {
    font-size: 14px;
    font-family: SofiaRegular;
    color: #000;
}
.designer-city{
    font-size: 14px;
    font-family: SofiaLight;
    color: #000;
    margin-bottom: 5px;
}
.designer-language {
    font-size: 16px;
    font-family: SofiaLight;
    color: #000;
    /*margin-bottom: 5px;*/
    /*margin-top: -5px;*/
}
.designer-language .label-info {
    background-color: #f93c93;
    padding: 5px;
    font-size: 12px;
}
.designer-brief {
    font-size: 16px;
    font-family: SofiaLight;
    color: #000;
    margin-top: 0px;
    line-height: 1.4em;
}
.service-head-box {
    /*background-color: #ffdfdf;*/
    /*min-height: 250px;*/
    margin-bottom: 0px;
    padding: 10px;
}
.see-all{
    font-size: 16px;
    font-family: EinaBold;
    margin-bottom: 10px;
}
.see-all .btn-link{
    padding: 0px;
    color: #000;
}
.see-all .btn-link:hover,
.see-all .btn-link:focus{
    color:#ff337d;
}
/*.service-head-box .banner-text-heading {*/
    /*!*margin-bottom: 10px;*!*/
    /*color: #fff;*/
/*}*/
.service-head-box .text-white-para{
    color:#000;
}
.service-head-box .owl-theme .owl-nav.disabled + .owl-dots {
    position: absolute;
    bottom: 10px;
    left: 10px;
}
.service-head-box .owl-carousel {
    box-shadow: 0 3px 4px 0 rgba(197, 196, 196, 0.5);
    border-radius: 6px;
}
.service-head-box.new-category-service .owl-carousel{
    box-shadow: none!important;
    border-radius: 0px!important;
}
.service-head-box .label-warning {
    vertical-align: middle;
    padding: 12px 20px;
    border-radius: 25px;
    background: #F2EB59;
    font-size: 16px;
    font-family: SofiaLight;
    color: #000;
    margin-left: 5px;
}
.service-head-box .label-warning .fa-star {
    color: #255DEA;
}
.customer-review-container .rating-ui-label,
.reviews-box-ui .rating-ui-label {
    vertical-align: middle;
    padding: 12px 25px;
    border-radius: 25px;
    background: #F2EB59;
    font-size: 16px;
    font-family: SofiaLight;
    color: #000;
}
.rating-assured-label{
    vertical-align: middle;
    padding: 10px 20px;
    border-radius: 25px;
    background: #f5cf0a;
    font-size: 14px;
    font-family: SofiaRegular;
    color: #000;
}
.rating-assured-label+.rating-assured-label{
    margin-left: 10px;
}
.customer-review-container .rating-ui-label .fa-star,
.reviews-box-ui .rating-ui-label .fa-star {
    color: #255DEA;
}
.reviews-box-ui .btn-link {
    color: #ff337d;
    font-size: 16px;
    font-family: SofiaMedium;
}
.service-card {
    height: 250px;
    border: solid 1px #d2d8e4;
    border-radius: 6px;
    padding: 10px 15px;
    text-align: center;
    box-shadow: 0 3px 4px 0 rgba(197, 196, 196, 0.5);
}
.service-card .text-bold {
    line-height: normal;
    font-family: EinaBold;
}
.service-card .message-item{
  margin-bottom: 10px;
}
.service-card .message-item .para{
    font-size: 16px;
    line-height: 1.3em;
    margin-top: 10px;
    font-family: SofiaRegular;
}
.standard-service-section {
    height: 200px;
    /*background-image: linear-gradient(to left, #8baff5, #2c1fcd);*/
    background-color: #E0FFF3;
    padding: 0px 20px;
}
.standard-service-section .text-white-para {
    font-family: SofiaMedium;
}
.curated-packages{
    min-height: 250px;
    background-color: #E0FFF3;
    padding: 0px 30px;
}
.curated-packages-nav-box{
    padding: 0px 20px;
    margin-top: -35px;
}
.sub-curated-packages-nav-box{
    padding: 0px 0px;
    top: 0;
    position: sticky;
    background-color: #fff;
    z-index: 100;
}
.sub-curated-packages-nav{
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    justify-content: center;
    overflow-x:scroll;
    white-space: nowrap;
    -webkit-padding-start: 0;
    padding-inline-start: 0;
    margin-bottom: 20px;

}
.sub-curated-packages-nav li{
    padding:10px 5px!important;
    text-align: center;
    margin: 0px 10px;
}
.sub-curated-packages-nav li>a{
    -webkit-box-align: center;
    align-items: center;
    background: #fff;
    border-radius: 20px;
    box-shadow: 3px 3px 10px rgba(0,0,0,.25);
    color: #5e5e5e;
    text-decoration: none;
    text-transform: capitalize;
    transition: .1s all ease;
    display: inline-flex;
    padding: .30rem;
    padding-right: 1.25rem;
    font-family: EinaBold;
    font-size: 14px;
}
.sub-curated-packages-nav li>a img{
    width:35px;
    margin-right: 5px;
}
.sub-curated-packages-nav-box .nav-tabs>li.active{
    color: #021c5e!important;
}
.sub-curated-packages-nav-box .nav-tabs>li.active img{
    background-color: transparent;
    border-radius: 50%;
}
.sub-curated-packages-nav-box .nav-tabs>li.active>a,
.sub-curated-packages-nav-box .nav-tabs>li.active>a:focus,
.sub-curated-packages-nav-box .nav-tabs>li.active>a:hover{
    border:2px solid #ff337d!important;
    border-bottom: none;
    background: #fff;
    box-shadow: none;
    color:#ff337d;
}
.nav.sub-curated-packages-nav{
    border:none;
}
.nav.sub-curated-packages-nav>li>a:focus,
.nav.sub-curated-packages-nav>li>a:hover{
    background: #e8e8e8;
    box-shadow: none;
    color:#ff337d;
}
.curated-packages-nav{
    padding: 20px 10px;
    box-shadow: 0 12px 20px 0 rgba(0,0,0,.12);
    border-radius: 8px;
    background: #fff;

}
.curated-packages-nav li>a{
    color:#000;
    font-family: EinaBold;
    font-size: 16px;
}
.curated-packages-nav-box .nav-tabs.nav-justified>.active>a,
.curated-packages-nav-box .nav-tabs.nav-justified>.active>a:focus,
.curated-packages-nav-box .nav-tabs.nav-justified>.active>a:hover{
   border: none;
    color:#1735e8;
}
.suggested-services .slider-crousel {
    height: 110px;
    overflow: hidden;
}
.suggested-services .slider-crousel img {
    max-width: 100%!important;
    max-height: 100% !important;
}
.standard-service-strip {
    height: 70px;
    line-height: 70px;
    background-color: #ffebeb;
    padding: 0px 30px;
}
.how-it-work-box {
    margin-top: 10px;
    height: 400px;
    background-color: #fff9e5;
    padding: 30px 60px;
    border-radius: 6px;
}
.how-it-work-box .para {
    font-family: SofiaMedium;
    margin-top: 5px;
}
.how-it-work-box .message-item {
    margin-bottom: 15px;
}
.how-it-work-box .assurance-ul li {
    margin-top: 25px;
    color: #000;
}
.d-button-container-box-center {
    width: 100%;
    display: flex;
    vertical-align: middle;
    justify-content: center;
}
.standard-service-box {
    padding: 0px 130px;
}
.standard-service-box img {
    width: 100px;
}
.modal-image .img-thumbnail {
    margin-bottom: 20px;
}
.add-crowsel {
    height: 250px;
    /*line-height: 250px;*/
    border-radius: 6px;
    /*background-image: linear-gradient(to left, #6464a2, #3236e4);*/
    background-color: #E0FFF3;
    padding: 0px 20px;
}
/*=========================Gig page banner===================================*/
.gig-banner-container{
    height: 230px;
    background-color: #fff5eb;
    padding: 0px 20px;
}
.gig-banner-side-box{
    position: relative;
    display: flex;
    height: 230px;
}
.gig-banner-quote{
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: right;
    width: 60%;
}
.gig-banner-img{
    position: absolute;
    bottom: 0;
    right: 0;
    /*border: 1px solid red;*/
}
.gig-banner-img img{
    width: 160px;
}
@media screen and (max-width: 767px) {
    .gig-banner-container{
        height: 150px;
        background-color: #fff5eb;
        padding: 0px 0px;
    }
    .blockquote{
        font-size: 11px;
    }
    .for-brand-section li img{
        width: 30vw!important;
    }
    .gig-banner-side-box{
        height: 150px;
    }
    .gig-banner-img img{
        width: 100px;
    }
    .gig-banner-img{
        right: -10px;
    }
}
/*=========================End Gig page banner===================================*/
.new-category-service .add-crowsel{
    border-radius: 0px;
    height: 200px;
}
.new-category-service .service-card{
    box-shadow: none;
    border-radius: 0px;
}
.assurance-banner-ul {
    padding-block-start: 0px;
}
.assurance-banner-ul>li {
    margin-left: 25px;
    margin-bottom: 10px;
    font-size: 16px;
    color:#000;
    font-family: SofiaRegular;
}
.assurance-banner-ul>li:before {
    content: '';
    position: absolute;
    left: 0;
    margin-top: 5px;
    margin-left: 20px;
    width:20px;
    height: 20px;
    background: url('../images/yes-tick.png') no-repeat;
    background-size: 12px;
}
.app-download-popup ul {
    padding-block-start: 10px;
}
.app-download-popup ul>li {
    margin-left: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    color:#000;
    font-family: SofiaMedium;
}
.app-download-popup .scope-sub-heading{
    line-height: 1.4em;
    font-size: 20px;
}

.add-crowsel .text-white-para {
    font-size: 18px;
    margin-bottom: 0px;
}
.brand-logo {
    width: 130px;
}
.add-crowsel img {
    position: absolute;
    width: 350px!important;
    top: 10px;
    bottom: 0;
}
.add-crowsel .banner-text-heading img{
    display: inline-block!important;
    position: relative!important;
    width:160px!important;
    top: 0px!important;
    margin-left: 10px;
    margin-right: 10px;
}
.second-slide-add {
    display: flex;
    height: 250px;
    line-height: 250px;
    border-radius: 6px;
    border: solid 1px #d2d8e4;
    padding: 0px 0px;
}
.affiliate-slide{
    background-image: linear-gradient(to left, #6464a2, #3236e4);
    padding: 10px 20px;
}
.affiliate-slide .banner-text-heading{
    margin-top: 0px;
}
.reward-text-box {
    padding: 15px 20px;
    border-radius: 6px;
    border: solid 1px #979797;
    background-color: #191919;
    width: 40%;
}
.reward-text-box .banner-text-heading {
    font-size: 26px;
}
.offer-brand-box {
    padding: 30px 10px;
    border-radius: 6px;
    background-color: #fff;
    width: 60%;
}
.offer-brand-box .table>tbody>tr>td img {
    width: 60px;
    margin: auto;
}
.offer-brand-box .table>tbody>tr>td {
    padding: 20px;
    text-align: center;
    border: none;
}
.package-section {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px;
}
.package-card {
    position: relative;
    height: 480px;
    border-radius: 6px;
    border: solid 1px #eaeaea;
    margin-bottom: 30px;
}
.package-card-header {
    background-color: #41d2d2;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}
.package-card.standard-new-service {
    border: none;
    height: 410px;
}
.q-increase{
    font-size: 14px;
    font-family: SofiaRegular;
    color: #000;
}
.product-increase{
   border-top: 1px solid #eaeaea;
   padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.product-increase .input-group{
    width:120px;
    margin-left: 10px;
}
.product-increase .input-number{
    height: 28px;
    display:flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e8eef3;
}
.quantity-left-minus{
    background: #fff;
    color:#979797;
    font-size: 12px;
    margin-right: 0px!important;
}
.quantity-right-plus{
    background: #fff;
    color:#979797;
    font-size: 12px;
    margin-left: 0px!important;
}
.quantity-left-minus:hover, .quantity-left-minus:focus,
.quantity-right-plus:hover, .quantity-right-plus:focus{
  color:#000;
}
.standard-new-service .package-card-header {
    height: 50px;
}
.standard-new-service .package-card-header {
    background-color: #533fe8;
}
.standard-new-service .package-head-name {
    color: #fff;
}
.standard-new-service .package-card-body {
    background-color: #fff1e8;
}
.standard-new-service .profile-card-footer {
    background-color: #fff1e8;
}
.package-card-body-box {
    background-color: #fff1e8;
    padding-bottom: 5px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}
.package-head-name {
    margin: 10px;
    line-height: 1.3em;
    font-size: 16px;
    font-family: EinaBold;
    color: #000;
    text-align: center;
}
.package-name {
    text-align: center;
    font-family: SofiaRegular;
    font-size: 18px;
    color: #4d5872;
    border-bottom: 1px solid #eaeaea;
    padding: 10px;
}
.package-card-body {
    padding: 10px 10px;
}
.package-card-body .btn-link {
    color: #ff337d;
    font-size: 16px;
}
.package-card-body .btn-link:hover,
.package-card-body .btn-link:focus {
    color: #ff337d;
}
.package-text {
    height: 200px;
    overflow-y: scroll;
}
.package-text::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}
.package-text::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
.package-card-body .xs-heading {
    margin-top: 0px;
}
.package-card-body ol,
.package-card-body ul {
    list-style-type: none;
    padding-inline-start: 0px;
}
.package-card-body ul li {
    margin-bottom: 8px;
    font-size: 14px;
    font-family: SofiaLight;
    color: #000;
    text-indent: -10px;
    margin-left: 20px;
}
.package-card.standard-new-service .package-card-body ul li{
    font-size: 16px;
}
.package-card-body li::before {
    content: '✓';
    color: green;
    font-weight: bold;
    display: inline-block;
    font-size: 14px;
    margin-right: 10px;
}
.package-card-body ul li:nth-child(n+4) {
    display: none;
}
.reviews-list li:nth-child(n+6) {
    display: none;
}
.read-more-link {
    cursor: pointer;
    color: #f00;
}
.text-price {
    color: #ff337d;
    font-size: 18px;
    font-family: EinaBold;
}
.actual-price {
    font-size: 14px;
    font-family: SofiaLight;
    color: #979797;
    margin-right: 6px;
}
.save-price {
    font-size: 14px;
    font-family: SofiaLight;
    color: #0000FF;
}
.inclusive-price {
    font-size: 14px;
    font-family: SofiaLight;
    color: #ff337d;
    margin-left: 6px;
}
.package-card .hire-designer-button {
    font-size: 16px;
    padding: 8px 12px;
    text-align: center;
}
.listing-panel .designer-profile-card .list-inline {
    margin-left: 0px;
}
.forum-section {
    margin-bottom: 50px;
}
.forum-block {
    background-color: #ffefef;
    padding: 20px 10px;
    margin-top: 40px;
    text-align: center;
}
.question-label {
    font-family: EinaBold;
    font-size: 18px;
    color: #000;
}
.forum-block textarea {
    padding: 10px 20px;
    width: 100%;
    border: 1px solid #fff;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #000;
    font-family: SofiaRegular;
}
.forum-panel .panel {
    box-shadow: 0 3px 5px 0 rgba(145, 145, 145, 0.5);
    background-color: #ebfafb;
    padding-bottom: 10px;
    margin-bottom: 30px;
}
.forum-panel .panel .para {
    margin-top: 15px;
    margin-bottom: 20px;
}
.forum-panel .panel .para-bold {
    font-family: SofiaMedium;
    color: #000;
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 20px;
}
.forum-panel .blank-dp{
    line-height: 45px!important;
}
.inline-button-container {
    display: flex;
    vertical-align: middle;
}
.service-label {
    border: 1px solid #1609e8;
    color: #1609e8;
    font-size: 14px;
    font-family: SofiaLight;
    padding: 5px;
    border-radius: 4px;
}
.forum-title {
    font-size: 18px;
    font-family: EinaBold;
    color: #281bf4;
    margin-top: 15px;
}
.forum-title-time {
    font-size: 14px;
    color: #000;
    font-family: SofiaLight;
}
.forum-h2 {
    font-family: EinaBold;
    font-size: 20px;
    color: #000;
    margin-bottom: 20px;
}
.forum-panel .media {
    margin-top: 0px;
}
.forum-panel .name {
    font-size: 18px;
    font-family: EinaBold;
    color: #000;
}
.text-bio {
    font-size: 16px;
    font-family: SofiaRegular;
    color: #000;
}
.forum-category {
    margin-bottom: 20px;
}
.forum-category li {
    margin-top: 10px;
}
.forum-category li>a {
    color: #000;
    font-size: 16px;
    font-family: SofiaLight;
}
.forum-category li>a:hover {
    color: #ff337d;
    text-decoration: underline;
}
.btn-submit-question {
    background-color: #ff337d;
    padding: 10px 30px;
    color: #fff;
    font-family: SofiaMedium;
    font-size: 18px;
}
.btn-submit-question:hover,
.btn-submit-question:focus {
    background-color: #ff337d;
    color: #fff;
}
.btn-see-answer {
    background-color: #05616b;
    padding: 10px 20px;
    color: #fff;
    font-size: 16px;
    font-family: SofiaLight;
}
.btn-see-answer:hover,
.btn-see-answer:focus {
    background-color: #05616b;
    color: #fff;
}
.btn-see-answer-outline {
    background-color: #fff;
    padding: 10px 20px;
    border: 1.2px solid #05616b;
    color: #05616b;
    font-size: 16px;
    font-family: SofiaLight;
}
.btn-see-answer-outline:hover,
.btn-see-answer-outline:focus {
    background-color: #05616b;
    color: #fff;
}
.forum-panel .panel .btn-link {
    color: #05616b;
}
.service-related-tab>li>a {
    font-size: 1.4em;
    color: #000;
    font-family: SofiaMedium;
}
.nav-tabs.nav-justified>.active>a,
.nav-tabs.nav-justified>.active>a:focus,
.nav-tabs.nav-justified>.active>a:hover {
    border: none;
    border-bottom: 4px solid #ff337d;
}
.title-xs-heading {
    font-size: 18px;
    font-family: SofiaRegular;
}
.ui-md-heading {
    color: #000;
    font-size: 20px;
    font-family: EinaBold;
    margin-top: 0px;
    margin-bottom: 30px;
}
.filter-city .form-group {
    margin-bottom: 10px !important;
}
.filter-strip {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    background-color: #000;
    padding: 0px 20px;
    z-index: 100;
    height: 60px;
    line-height: 60px;
    margin-top: 0px !important;
    margin-bottom: 0px;
}
/*.filter-form-strip {*/

/*    margin-top: 15px;*/

/*}*/

.filter-form-strip .form-control {
    background-color: #313131 !important;
    border: none;
    box-shadow: none;
    outline: 0px;
}
.filter-form-strip .form-group select {
    padding-top: 5px;
    font-size: 16px;
    font-family: SofiaLight;
    color: #fff !important;
    border: none !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url('../images/arrow-down-sign-to-white.png') no-repeat;
    background-position: 98% 50%;
    background-size: 12px;
}
.filter-form-strip .form-group {
    margin-bottom: 0px;
    margin-top: 12px;
}
.filter-form-strip .list-inline li {
    font-size: 16px;
    color: #fff;
    font-family: SofiaLight;
}
.filter-form-strip .side-card-btn {
    padding: 5px 10px;
}
.sidebar-card-review {
    padding: 15px 15px;
    margin-top: 30px;
}
.sidebar-card-review .dp-image {
    margin-left: 10px;
    margin-top: -10px;
    width: 50px;
    height: 50px;
    display: none;
}
.sidebar-card-review img {
    float: right;
}
.public-review-name {
    font-size: 16px;
    font-family: SofiaSemiBold;
    color: #000;
    text-align: right;
    margin-bottom: 30px;
}
.public-review-name:before {
    content: '';
    position: absolute;
    width: 15px;
    height: 1.5px;
    background-color: #000;
    margin-left: -25px;
    margin-top: 12px;
}
.public-review-para {
    font-size: 16px;
    font-family: SofiaLight;
    font-style: italic;
    color: #000;
    line-height: 1.5em;
    margin-top: 20px;
}
.sidebar-card {
    position: relative;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .1);
    transition: box-shadow 0.3s ease 0s;
    min-height: 170px;
    padding: 20px 15px;
    margin-top: 20px;
    margin-bottom: 10px;
}
.sidebar-card ul > li {
    font-family: SofiaLight;
    margin-bottom: 5px;
    line-height: 1.2em;
    color: #000;
    font-size: 16px;
}
.include-tips-dropdown {
    display: none;
    position: relative;
}
.what-includes-content {
    display: none;
    position: absolute;
    min-width: 320px;
    background-color: #fff;
    text-align: left;
    z-index: 1;
    left: -50px;
}
.include-tips-dropdown:hover .what-includes-content {
    display: block;
}
.what-includes-content .sidebar-card {
    margin-top: 0px;
}
.text-link {
    font-size: 16px;
    color: #2d3feb;
    font-family: SofiaLight;
    margin-top: 20px;
    margin-bottom: -10px;
}
.whats-include ul {
    list-style: none;
    padding-inline-start: 20px;
}
.whats-include ul li {
    font-size: 16px;
    text-indent: -10px;
    margin-left: 10px;
    font-family: SofiaLight;
    margin-bottom: 10px;
    word-break: break-word;
}
.whats-include ul li::before {
    content: '✓';
    color: green;
    font-weight: bold;
    display: inline-block;
    font-size: 14px;
    margin-right: 10px;
}
.text-link a {
    color: #2d3feb;
}
.side-card-heading {
    font-size: 18px;
    text-align: center;
    font-family: EinaBold;
    line-height: 1.4em;
    color: #000;
    margin-bottom: 15px;
}
.side-card-list {
    margin-top: 20px;
    margin-bottom: 20px;
}
.side-card-list li {
    font-size: 16px;
    font-family: SofiaLight;
    line-height: 1.5em;
    color: #000;
    margin-bottom: 10px;
}
.sidebar-card .side-card-btn-box {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    margin: auto;
}
.side-card-btn {
    background-color: #3e32f1;
    color: #fff;
    font-family: SofiaLight;
    font-size: 16px;
    padding: 8px 15px;
}
.side-card-btn:hover,
.side-card-btn:focus {
    color: #fff;
}
.on-card-it-word {
    margin-top: 25px;
}
.on-card-it-word p {
    color: #000;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 25px;
    font-family: SofiaRegular;
}
.ui-x-lg-heading {
    font-size: 46px;
    margin-top: 0px;
    color: #000;
    font-family: EinaBold;
    line-height: 1.4em;
}
.btn-talk {
    background-color: #000;
    color: #fff;
    font-size: 18px;
    font-family: SofiaLight;
    padding: 12px 20px;
}
.popular-services-box {
    position: relative;
    border: 1px solid #e8eef3;
    padding: 10px 20px;
    height: 180px;
    margin-bottom: 50px;
}
.popular-services-box-overlay {
    background-color: #000;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 0;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
}
.popular-services-box:hover .popular-services-box-overlay {
    opacity: 1;
    height: 100%;
}
.text-service-box {
    margin-top: 10px;
    text-align: center;
    padding: 5px 20px;
}
.popular-services-box p {
    color: #000;
    font-size: 20px;
    position: absolute;
    top: 40%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}
.btn-talk:hover,
.btn-talk:focus {
    background-color: #000;
    color: #fff;
}
.btn-free-proposal {
    background-color: #000;
    color: #fff;
    border: 1.2px solid #fff;
    font-size: 1em;
    font-family: SofiaLight;
    padding: 8px 10px;
    display: block;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}
.btn-free-proposal:hover,
.btn-free-proposal:focus {
    color: #fff;
}
.home-industry-cards {
    /*background: url('../images/back.jpg') no-repeat;*/
    /*background-size: cover;*/
    /*background-position: center center;*/
    /*background-size: 100%;*/
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}
.background-lie-image {
    width: 100%;
    position: absolute;
    right: 0;
    left: 0;
}
.industy-card-wrapper {
    border-radius: 8px;
    background: #fff;
    border: 1px solid #e8eef3;
    width: 30%;
    margin-left: 0px;
    margin-right: 3%;
    margin-top: 15px;
    padding: 5px 10px;
    position: relative;
    transition: box-shadow .3s ease-in-out, transform .3s ease-in-out;
}
.home-industry__icon {
    text-align: center;
    margin: 10px auto;
    height: 60px;
    line-height: 60px;
}
.home-industry__icon img {
    width: 50px;
}
.industy-card-wrapper .media-heading {
    padding: 5px;
    margin-bottom: 10px;
    border-bottom: 1px dashed #e8eef3;
}
.home-industry__label {
    font-size: 15px;
    text-align: left;
    font-family: SofiaLight;
    color: #000;
}
.industy-card-wrapper:hover {
    box-shadow: 0 2px 4px 0 rgba(60, 64, 67, .3), 0 6px 11px 5px rgba(60, 64, 67, .15);
    transition: box-shadow .3s ease-in-out, transform .3s ease-in-out;
}
.value-props-wrapper {
    border-radius: 8px;
    background: #fff;
    border: 1px solid #e8eef3;
    width: 23%;
    margin-left: 0px;
    margin-right: 2%;
    margin-top: 15px;
    padding: 35px 10px;
    position: relative;
    transition: box-shadow .3s ease-in-out, transform .3s ease-in-out;
}
.value-props-wrapper .para{
    text-align: center;
    margin-top: 25px;
}
.value-props-wrapper:hover {
    box-shadow: 0 2px 4px 0 rgba(60, 64, 67, .3), 0 6px 11px 5px rgba(60, 64, 67, .15);
    transition: box-shadow .3s ease-in-out, transform .3s ease-in-out;
}

.service-form-container {
    background-color: #fff;
    margin-top: 50px;
    padding-top: 20px;
    padding-bottom: 30px;
}
.service-form-container .signup-form {
    margin-top: 0px;
}
.service-form-container .signup-form .form-group {
    margin-bottom: 10px;
}
.service-form-container .btn-book-consultation {
    border-radius: 0px;
    padding: 10px 25px;
}
.business-box {
    position: relative;
    background-color: #043d30;
    padding: 30px 20px;
    height: 180px;
    margin-bottom: 50px;
    border-radius: 6px;
}
.text-business {
    text-align: center;
    color: #fff;
    font-size: 20px;
    height: 50px;
    font-family: SofiaRegular;
    margin-bottom: 10px;
}
.hire-list li {
    color: #000;
    line-height: 1.5em;
    font-size: 20px;
    font-family: SofiaLight;
    margin-top: 10px;
    margin-bottom: 15px;
}
.hire-list li::before {
    content: '';
    position: absolute;
    left: -20px;
    margin-top: 14px;
    height: 2px;
    width: 20px;
    background-color: #000;
}
.business-box .service-text {
    font-size: 14px;
    color: #fff;
}
.business-box .service-text::before {
    background-color: #fff;
    left: 35px;
    margin-top: 8px;
}
.firm-container {
    margin-top: 30px;
    padding: 0px 40px;
    height: 250px;
    background-color: #cef7e6;
}
.press-release-box {
    display: flex;
    vertical-align: middle;
}
.press-release-logo {
    flex-grow: 1;
    text-align: center;
}
.listing-panel .press-release-logo img {
    width: 70px!important;
}
.listing-panel .press-release-logo:nth-of-type(1) img {
    width: 130px!important;
}
.press-release-logo img {
    height: auto;
    /*-webkit-filter: grayscale(100%);*/
    /*filter: grayscale(100%);*/
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
}
.common-margin-bottom {
    margin-bottom: 10px !important;
    padding-bottom: 10px !important;
}
.ui-feature .media-left img {
    height: 80px;
    width: 80px;
}
.lawyer-profile-img {
    border-radius: 50%;
    object-fit: cover;
    object-position: 50% 10% !important;
}
.ui-feature .media-heading {
    margin-top: 15px;
    font-family: SofiaSemiBold;
    color: #000;
    font-size: 18px;
}
.ui-feature .media-heading label {
    margin-left: 10px;
}
.ui-feature .media-heading label .fa-star {
    color: gold;
    margin-left: 2px;
    font-size: 12px;
}
.ui-feature .media {
    margin-bottom: 30px;
}
.media-sub-para {
    color: #000;
    font-size: 16px;
    line-height: 1.6em;
    font-family: SofiaLight;
}
.imageBottomRightContainer {
    position: relative;
}
.imageBottomRight {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 300px;
    width: 400px;
}
.imageBottomRight img {
    position: absolute;
    max-width: 360px;
    bottom: 0;
    right: 0px;
    z-index: 1;
}
.ui-section-product-3 .imageBottomRight img {
    max-width: 60%;
}
.add-banner {
    background-color: #ffe6e6;
    height: 370px!important;
}
.add-banner .imageBottomRight img {
    position: absolute;
    max-width: 210px;
    bottom: 70px;
    right: 30px;
    z-index: 1;
}
.banner-rating {
    position: absolute;
    bottom: 90px;
    left: -20px;
}
.banner-rating .blockquote {
    font-size: 18px;
    font-style: italic;
    color: #000;
    margin-left: -70px;
}
.banner-rating .fa-star {
    color: gold;
    font-size: 24px;
    margin-left: 0px;
    margin-right: 2px;
}
.banner-rating .text-white {
    font-size: 22px;
    color: #000;
    font-family: SofiaRegular;
}
.add-banner .text-white-para {
    font-size: 22px;
}
.add-offer-box {
    min-height: 280px;
    margin-left: 0px;
    margin-right: 5%;
    border-radius: 4px;
    border: 1px solid #e8eef3;
    position: relative;
    padding: 30px 20px;
    margin-bottom: 40px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, .1);
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
}
.text-bold .label-info {
    position: absolute;
    padding: 3px 15px;
    font-size: 14px;
    color: #fff;
    background-color: #351AE7;
    font-family: SofiaMedium;
    margin-left: 48%;
    border-radius: 4px;
}
.add-offer-box .label-info {
    padding: 8px 25px;
    background-color: #FDD0D3;
    font-family: SofiaMedium;
}
.add-offer-box .label-info {
    font-size: 16px;
    /*color:#fff;*/
}
.add-offer-box .label-success {
    font-size: 16px;
    font-family: Sofialight;
    /*color:#fff;*/
}
.add-offer-box .label-primary {
    font-size: 16px;
    font-family: Sofialight;
    /*color:#fff;*/
}
.add-offer-box .label-success {
    padding: 8px 25px;
    background-color: #C2F1F8;
    font-family: SofiaMedium;
    margin-left: 0px;
}
.add-offer-box .label-primary {
    padding: 8px 25px;
    background-color: #FEEA8C;
    font-family: SofiaMedium;
}
.add-offer-box .label-temp {
    padding: 8px 25px;
    background-color: #D1CAFC;
    font-family: SofiaMedium;
}
.add-offer-box .label-temp {
    font-size: 16px;
    /*color:#fff;*/
}
.add-offer-box:hover {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}
.item .add-offer-box:hover {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}
.offer-heading {
    font-size: 20px;
    font-family: SofiaSemiBold;
    line-height: 1.6em;
    margin-top: 25px;
}
.offer-heading span {
    font-family: SofiaSemiBold;
    color: #ff1e9b;
}
.offer-btn {
    position: relative;
    /*bottom: 30px;*/
    /*left:18%;*/
    text-align: center;
    vertical-align: bottom;
}
.offer-btn-accounting {
    position: absolute;
    bottom: 30px;
    text-align: center;
    vertical-align: bottom;
}
.btn-offer-add {
    position: absolute;
    background-color: #000;
    padding: 8px 30px;
    color: #fff;
    font-size: 16px;
    font-family: SofiaRegular;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    margin-top: 5px;
}
.btn-offer-add:hover,
.btn-offer-add:focus {
    background-color: #000;
    color: #fff;
}
.offer-text {
    font-family: SofiaMedium;
    font-size: 16px;
}

.legal-filter-section {
    /*background-color: #28328c;*/
    margin-top: 0px;
    box-sizing: border-box;
}
.legal-filter-section .assurance-list {
    margin-top: 20px;
}
.legal-filter-section .assurance-list li {
    color: #fff;
}
.legal-filter-container {
    background: url('../images/banner.svg') top no-repeat;
    min-height: 530px!important;
    background-attachment: fixed;
    /*background-position: center;*/
    /*background-size: cover;*/
    background-position-y: 110px;
}
.legal-filter-container select {
    border-radius: 4px;
    padding-top: 0px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url('../images/down.png') no-repeat;
    background-color: #fff;
    background-position: 98% 55%;
    background-size: 14px;
}
.legal-filter-container select.form-control {
    border-radius: 6px;
}
.legal-filter-h1 {
    color: #fff;
    font-size: 30px;
    font-family: EinaBold;
    margin-top: 70px;
    margin-bottom: 30px;
}
.legal-filter-h2 {
    text-align: center;
    color: #fff;
    font-size: 22px;
    font-family: SofiaMedium;
    margin-bottom: 0px;
}
.btn-select-filter {
    padding: 10px 20px;
    font-size: 18px;
    background: #ff337d;
    color: #fff;
    font-family: SofiaRegular;
}
.btn-select-filter:hover,
.btn-select-filter:focus {
    background: #ff337d;
    color: #fff;
}
.sticky-block {
    position: sticky;
    top: 60px;
}
.text-service-heading {
    color: #000;
    font-size: 34px;
    font-family: EinaBold;
    line-height: 1.6em;
    margin-top: 30px;
    margin-bottom: 15px;
}
.text-service-sub-heading {
    color: #000;
    font-size: 16px;
    font-family: SofiaRegular;
    line-height: 1.6em;
    margin-top: 10px;
    margin-bottom: 10px;
}
.service-ui-box {
    border-top: 1px solid #e8eef3;
    border-bottom: 1px solid #e8eef3;
    margin-top: 30px;
    margin-bottom: 0px;
    min-height: 350px;
    box-sizing: border-box;
}
.ui-service-work-container {
    min-height: 550px;
    border-left: 1px solid #e8eef3;
    padding-left: 50px;
    padding-bottom: 60px;
}
.ui-icon-container {
    height: 90px;
    width: 90px;
    margin-bottom: 15px;
    /*border:1px solid red;*/
}
.ui-icon-container img {
    max-height: 100%;
    max-width: 100%;
}
.ui-service-work-container .media {
    margin-top: 70px;
}
.ui-service-work-container .media-left img {
    height: auto;
    width: auto;
    margin-right: 30px;
}
.ui-service-work-container .media-heading {
    font-size: 22px;
    font-family: SofiaMedium;
    color: #000;
}
.service-ui-box .heading-sm {
    margin-top: 70px;
}
.xs-small-heading {
    font-size: 22px;
    font-family: SofiaSemiBold;
    color: #000;
    margin-top: 70px;
}
.xs-small-para {
    font-size: 18px;
    font-family: SofiaLight;
    line-height: 1.6em;
    color: #000;
    margin-top: 10px;
    margin-bottom: 15px;
}
.ui-service-work-container .text-name {
    margin-top: 70px;
}
.d-accordion .panel-heading {
    height: auto !important;
    background-color: #fff !important;
    padding: 0px 0px;
}
.d-accordion h3{
    margin-top: 0px;
}
.d-accordion .panel {
    border-radius: 0px;
    border: none;
    border-bottom: 1px solid #e8eef3;
    box-shadow: none;
    margin-bottom: 30px;
}
.d-accordion .panel-title {
    font-size: 18px;
    color: #000;
    margin-bottom: 10px;
    font-family: EinaBold;
}
.d-accordion .panel-title .fa-angle-down {
    margin-top: 3px;
    font-size: 24px;
}
.d-accordion .panel-collapse .panel-body {
    padding: 15px 0px;
}
.d-accordion .panel-collapse .panel-body a {
    color: #ff337d;
}
.d-accordion .table > thead > tr > th {
    border: 1px solid #e8eef3;
    color: #000;
    font-size: 18px;
    vertical-align: top;
    font-family: SofiaSemiBold;
}
/*.d-accordion .table > tbody > tr > td:nth-of-type(1) {*/

/*    font-family: SofiaSemiBold;*/

/*}*/

.d-accordion .table > tbody > tr > td {
    border: 1px solid #e8eef3;
    color: #000;
    font-size: 16px;
    font-family: SofiaLight;
}
.i-row {
    padding-top: 40px;
    padding-bottom: 40px;
}
.i-row-odd {
    background-color: #ffffff;
}
.i-row-even {
    background-color: #f7f7f7;
}
.panel-collapse .para {
    font-size: 18px;
}
.section-title {
    margin-top: 0;
    margin-bottom: 0.6em;
    font-weight: 500;
}
.section-title .fa {
    margin-right: 5px;
    color: #6f5499;
}
.i-accordion .panel-heading,
.d-accordion .panel-heading,
.accordion-2a .panel-heading,
.accordion-2b .panel-heading,
.accordion-3 .panel-heading {
    cursor: pointer;
}
.d-accordion .text-bold {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: normal;
    color: #000;
    font-family: SofiaRegular;
}
.d-accordion li {
    color: #000;
    font-size: 16px;
    line-height: 1.5em;
    font-family: SofiaLight;
    margin-bottom: 10px;
}

.product-link li {
    margin-top: 0px;
    margin-bottom: 15px;
}
.product-link li>a{
    font-size: 16px;
    color: #000;
    font-family: SofiaRegular;
}
.product-link li>a>span{
    text-transform: capitalize;
}
.product-link li>a:hover,
.product-link li>a:focus{
    color: #ff337d;
}
.browse-more-padding {
    background-color: #d2f4e7;
    padding: 20px 20px;
    margin: 10px 10px;
}
.browse-more-padding .heading-sm {
    margin-bottom: 10px;
}
.browse-more-heading {
    font-family: EinaBold;
    font-size: 18px;
    color: #000;
    margin-top: 20px;
    margin-bottom: 20px;
}
.browse-more-link li {
    margin-top: 10px;
    margin-bottom: 10px;
}
.browse-more-link li a {
    color: #000;
    font-family: SofiaRegular;
    text-transform: capitalize;
    font-size: 16px;
    -webkit-transition: all .5s ease;
    transition: all .5s ease
}
.browse-more-link li a.btn-link {
    text-align: left;
    padding: 0px;
    color: #2b47fb;
}
.browse-more-link li a.btn-link:hover {
    color: #2b47fb;
}
.browse-more-link li a:hover,
.browse-more-link li a:focus {
    color: #ff337d;
    /*margin-left: 6px;*/
    text-decoration: underline;
    -webkit-transition: all .5s ease;
    transition: all .5s ease
}
.browse-more li {
    margin-top: 20px;
    margin-bottom: 10px;
}
.browse-more li a {
    color: #000;
    font-family: SofiaLight;
    font-size: 16px;
    -webkit-transition: all .5s ease;
    transition: all .5s ease
}
.browse-more li a:hover,
.browse-more li a:focus {
    color: #ff337d!important;
    margin-left: 0px;
    -webkit-transition: all .5s ease;
    transition: all .5s ease
}
.ui-feature .xs-para {
    text-align: left;
}
.ui-scroll-box-parent {
    overflow: hidden;
}
/*.ui-scroll-box{*/

/*height: 400px;*/

/*overflow: auto;*/

/*}*/

/*.ui-scroll-box::-webkit-scrollbar {*/

/*display: none;*/

/*}*/

.text-bold-heading {
    font-family: SofiaSemiBold;
    font-size: 20px;
    color: #000;
    margin-top: 20px;
    margin-bottom: 10px;
}
.bullet-list {
    margin-top: 15px;
}
.bullet-list li {
    font-size: 20px;
    margin-bottom: 15px;
}
.signup-h4-heading {
    font-size: 18px;
    font-family: EinaBold;
    text-transform: uppercase;
    line-height: 1.6em;
    color: #000;
}
@media screen and (max-width: 767px) {
    .brand-logo {
        width: 100px;
    }
    .designer-profile-card .slider-crousel img {
        object-fit: cover;
    }
    .designer-media{
        margin-top: 15px!important;
    }
    .professional-badge-with-name .minimul-badge{
        margin-top: 5px;
    }
    .package-card-body ul li{
        font-size: 14px;
    }
    .m-media{
        padding:10px 0px 5px 0px;
        box-shadow: 3px 3px 10px rgba(0,0,0,.25);
        margin-bottom: 15px;
    }
    .m-media .designer-brief{
        font-family: SofiaRegular;
        font-size: 15px;
        margin-bottom: 5px;
        line-height: 1.3em;
    }
    .m-media .designer-name{
        font-size: 14px;
        margin-bottom: 2px;
    }
    .designer-name{
        font-size: 14px;
        margin-bottom: 2px;
    }
    .m-media .designer-bio{
        font-size: 14px;
        margin-bottom: 3px;
    }
    .designer-city{
        font-size: 12px;
        margin-bottom: 2px;
        color: #6b6b6b;
        font-family: SofiaRegular;
        display: flex;
    }
    .designer-city span+span{
        margin-left: 10px;
    }
    .giglist-container{
      padding-left: 0px;
      padding-right: 0px;
    }
    .m-media .slider-crousel{
        height: 70px;
        overflow: hidden;
    }
    .m-media .slider-crousel img{
        max-width: 100%!important;
        /*max-height: 100%!important;*/
        /*margin: auto;*/
        display: block!important;
        object-fit: contain;

    }
    .m-media .fa-star {
        color: gold;
        margin-left: 2px;
        font-size: 12px;
    }
    .m-media .list-inline .pull-right{
        float:none!important;
    }
    .m-media .list-inline .span-text{
        font-size: 14px;
        width: 33.3%;
    }
    .m-media .list-inline .span-text:nth-child(1)+li{
        text-align: center;
    }
    .m-media .list-inline .span-text:nth-last-child(2)+li{
        text-align: right;
    }
    .profile-card-body .list-inline li.span-text img{
        width: 20px;
        margin-top: -5px;
    }
    .m-media .profile-card-body{
        margin-top: 0px;
        padding: 0px 0px;
    }
    .m-media .profile-card-body .media .media-left{
        vertical-align: top;
    }
    .m-media .designer-name{
        margin-top: 0px;
        white-space: nowrap;
    }
    .span-text{
        font-size: 14px;
    }
    .designer-profile-card .hire-designer-button{
        font-size: 14px;
    }
    .designer-name .pull-right{
        display: flex;
    }
    .m-media .list-inline{
        padding-inline-start: 0px;
        margin-bottom: 5px;
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
    }
    /*.m-media .m-rating-ui .list-inline{*/
        /*justify-content: normal!important;*/
    /*}*/
    .m-media .professional-badge{
        margin-top: -15px;
        font-size: 10px;
        right:6px;
    }
    .m-media .profile-card-body .blank-dp {
        line-height: 18px;
        width: 35px !important;
        height: 35px !important;
    }
    .m-media .designer-dp{
        height: 35px!important;
        width: 35px!important;
    }
    .profile-card-body .blank-dp span{
       font-size: 16px!important;
    }
    .m-media .badge-pro{
        padding: 5px 20px;
    }
    .m-media .badge-super-pro, .m-media .badge-recommended{
        padding: 5px 10px;
    }
    .m-media-footer{
        border-top: 1px solid #e9e9e9;
        padding:5px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .m-media-footer .service-price{
        font-family: EinaBold;
    }
    .m-media-footer .hire-designer-button{
        width:auto;
        padding: 3px 10px;
        border-radius: 6px!important;
        font-size: 14px;
        background: transparent;
        color: #000;
        font-family: EinaBold;
    }
    .m-view-signup-box{
       /*position: fixed!important;*/
        top: 60px !important;
        height: 100%;
    }
    .m-view-signup{
        max-height: 650px;
        overflow: auto;
    }
    .minimul-badge{
        margin-top: 0px;
    }
    .minimul-badge .badge-gigzoe-new,
    .minimul-badge .badge-gigzoe-preferred-text,
    .minimul-badge .badge-pro-text,
    .minimul-badge .badge-super-pro-text,
    .minimul-badge .badge-recommended-text{
        font-size: 12px;
        padding: 2px 4px;
    }
    .service-price{
        color:#ff337d;
        font-family: SofiaRegular;
        vertical-align: middle;
    }
    .standard-service-section {
        padding: 10px;
        height: auto;
    }
    .service-card {
        height: auto;
        line-height: 0px;
        padding: 20px 10px;
        text-align: center;
        box-shadow: 0 3px 4px 0 rgba(197, 196, 196, 0.5);
    }
    .standard-service-strip {
        padding: 0px 0px;
    }
    .how-it-work-box {
        margin-top: 10px;
        height: auto;
        background-color: #fff9e5;
        padding: 10px 20px;
        margin-bottom: 20px;
    }
    .how-it-work-box .assurance-ul li{
        margin-top: 10px;
        font-size: 12px;
    }
    .how-it-work-box .para {
        font-family: SofiaMedium;
        font-size: 12px;
        margin-top: 10px;
    }
    .how-it-work .message-item,
    .how-it-work-box .message-item{
        margin-bottom: 5px;
        margin-left: 30px;
    }
    .how-it-work .para{
        font-size: 14px;
        line-height: 1.4em;
        margin-top: 10px;
    }
    .customer-review-container .rating-ui-label,
    .reviews-box-ui .rating-ui-label {
        font-size: 14px;
        padding: 10px 10px;
    }
    .suggested-services .slider-crousel {
        height: 100px;
    }
    .standard-service-box {
        padding: 0px 20px;
    }
    .standard-service-box img {
        width: 70px!important;
    }
    .standard-service-strip .standard-service-box:nth-last-of-type(1) img {
        width: 50px!important;
    }
    .service-card .side-card-heading {
        margin-top: 0px;
        font-size: 16px;
        line-height: 1.3em;
    }
    .gap-xs{
        padding: 0px 0px;
    }
    .designer-profile-card {
        height: 260px;
        position: relative;
        margin-bottom: 25px;
    }
    .designer-profile-card.common-card{
        height: 260px;
    }
    .designer-profile-card .slider-crousel {
        height: 130px;
    }
    .designer-profile-card .slider-crousel .embed-responsive-16by9{
        padding-bottom: 130px;
    }
    .p-y-2 {
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .statistic-counter {
        font-size: 20px!important;
        font-family: EinaBold;
        color: #000;
    }
    .single_counter img {
        width: 80px;
    }
    .single_counter p {
        font-size: 16px;
        font-family: EinaBold;
    }
    .legal-filter-container {
        background: none;
        background-color: #255fef;
        min-height: 350px!important;
    }
    .legal-filter-section .assurance-list {
        margin-top: 10px;
    }
    .legal-filter-section .assurance-list li {
        font-size: 8px;
    }
    .legal-filter-h1 {
        margin-top: 40px;
        font-size: 18px;
    }
    .btn-select-filter {
        font-size: 16px;
        padding: 8px 30px;
    }
    .legal-filter-h2 {
        font-size: 16px;
    }
    .sidebar-card {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .filter-form-strip .form-group {
        margin-top: 5px;
    }
    .industy-card-wrapper {
        width: 47%;
        height: auto;
    }
    .value-props-wrapper {
        border-radius: 8px;
        background: #fff;
        border: 1px solid #e8eef3;
        width: 48%;
        margin-left: 0px;
        margin-right: 2%;
        margin-top: 15px;
        padding: 25px 10px;
        position: relative;
        transition: box-shadow .3s ease-in-out, transform .3s ease-in-out;
    }
    .value-props-wrapper .para{
        text-align: center;
        margin-top: 20px;
        font-size: 12px;
    }
    .home-industry__label {
        position: relative;
        font-size: 12px;
        bottom: 5px;
    }
    .offer-heading {
        font-size: 16px;
    }
    .add-offer-box {
        height: auto;
    }
    .offer-btn-accounting {
        position: relative;
        left: 0px;
        text-align: center;
        bottom: 0px;
    }
    .offer-btn {
        position: relative;
        text-align: center;
        left: 0px;
        bottom: 0px;
    }
    .ui-x-lg-heading {
        font-size: 30px;
    }
    .text-service-heading {
        font-size: 20px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .text-bold-heading {
        font-size: 16px;
        margin-top: 0px;
    }
    .xs-small-para {
        font-size: 16px;
        line-height: 1.2em;
    }
    .d-accordion .panel-title {
        font-size: 14px;
        margin-bottom: 5px;
    }
    .d-accordion .text-bold {
        font-size: 16px;
        font-family: SofiaRegular;
    }
    .d-accordion .panel-title .fa-angle-down {
        margin-top: 0px;
    }
    .d-accordion li {
        line-height: 1.2em;
        font-size: 14px;
    }
    .panel-collapse .para {
        font-size: 14px;
    }
    .ui-service-work-container .media .media-body .xs-para {
        text-align: left !important;
    }
    .hire-list li {
        color: #000;
        line-height: 1.6em;
        font-size: 16px;
        font-family: SofiaLight;
        margin-top: 10px;
        margin-bottom: 15px;
    }
    .hire-list li::before {
        margin-top: 8px;
    }
    .ui-feature .col-xs-offset-1.left-offset-1 {
        margin-left: 10%;
    }
}
/*===================================================All services================*/

.owl-theme .item {
    background: none !important;
    height: auto !important;
    width: 100%;
}
.small-blank-line {
    width: 30px;
    height: 3px;
    background-color: #000;
    margin-bottom: 20px;
}
.lg-white-heading {
    font-size: 42px;
    color: #fff;
    font-family: EinaBold;
    margin-top: 45px;
    margin-bottom: 30px;
}
.banner-text-heading {
    font-size: 24px;
    color: #000;
    font-family: EinaBold;
    line-height: 1.4em;
    margin-top: 15px;
    margin-bottom: 10px;
}
.banner-text-sub-heading{
    font-size: 16px;
    color: #000;
    font-family: EinaBold;
    line-height: 1.4em;
    margin-top: 25px;
    margin-bottom: 5px;
}
.banner-text-para{
    font-size: 16px;
    font-family: SofiaRegular;
    color: #000;
    line-height: 1.4em;
}
.banner-section .text-white-para {
    font-size: 18px;
    margin-top: 10px;
}
.add-banner .btn-job-post {
    background-color: #ff1e9b;
    color: #fff;
    font-family: SofiaMedium;
    margin-left: 0px;
    margin-right: 10px!important;
    padding: 12px 15px;
    font-size: 18px;
}
.add-banner .banner-text-heading {
    margin-top: 30px;
    margin-bottom: 0px;
}
.pink-heading {
    font-size: 24px;
    color: #ff337d;
    line-height: 1.5em;
    font-family: EinaBold;
    margin-top: 45px;
    margin-bottom: 20px;
}
.pink-heading a {
    color: #ff337d;
}
.pink-heading a:hover {
    text-decoration: none;
}
.pink-heading::before {
    content: '';
    width: 25px;
    height: 2px;
    background-color: #ff337d;
    position: absolute;
    left: -20px;
    margin-top: 20px;
}
.col-mokeup {
    background-color: #020d2a;
    height: 600px;
    padding: 20px 10px;
    margin-bottom: 170px;
}
.col-mokeup .list-inline li > a {
    color: #ff337d;
    font-size: 22px;
    padding-top: 5px;
    font-family: SofiaMedium;
}
.col-mokeup .list-inline li > a:hover,
.col-mokeup .list-inline li > a:focus {
    text-decoration: none;
}
.col-mokeup .list-inline li::before {
    content: '';
    width: 20px;
    height: 2px;
    background-color: #ff337d;
    position: absolute;
    margin-left: 2px;
    margin-top: -7px;
}
.col-mokeup img {
    margin-top: 10px;
}
.app-button li img {
    width: 150px;
}
.col-mokeup .lg-white-heading {
    margin-top: 40px;
    margin-bottom: 10px;
}
.profile-section {
    margin-top: 60px !important;
    /*background-image: linear-gradient(right, #343e5f, #272f49, #342240);*/
    /*background-image: -webkit-linear-gradient(right, #343e5f, #272f49, #342240);*/
    /*background-image: -moz-linear-gradient(right, #343e5f, #272f49, #342240);*/
    padding: 10px 20px;
}
.profile-section .profile-name {
    color: #000;
}
.profile-section .panel {
    box-shadow: 0 2px 10px rgba(152, 152, 152, 0.5);
}
.profile-section .fa-star {
    font-size: 14px;
    color: gold;
    margin-left: 2px;
}
.professional-image .blank-dp {
    height: 150px !important;
    width: 150px !important;
    padding-top: 40px !important;
}
.professional-image .blank-dp > span {
    font-size: 42px !important;
}
b {
    font-family: EinaBold;
}
.chield-category {
    padding-inline-start: 0px;
}
.chield-category li::before {
    content: "\2022";
    color: #ff337d;
    font-weight: bold;
    display: inline-block;
    font-size: 16px;
    left: 10px;
    position: absolute;
}
.chield-category li {
    color: #000;
    font-family: SofiaRegular;
    font-size: 16px;
    margin-bottom: 10px;
    margin-left: 15px;
}
.practice-area-section {
    margin-top: 0px;
}
.practice-area-section .col-sm-4 {
    height: 150px;
    margin-bottom: 30px;
}
.practice-area-section .panel {
    border: 1px solid #e8eef3;
    box-shadow: 0 0 40px rgba(0, 0, 0, .1);
}
.court-section {
    margin-top: 25px;
}
.court-section .panel {
    border: 1px solid #e8eef3;
    box-shadow: 0 0 40px rgba(0, 0, 0, .1);
}
.court-section .list-inline > li {
    font-size: 18px;
    width: 33%;
    font-family: SofiaLight;
    padding-left: 70px;
    margin-bottom: 40px;
}
.client-section .panel {
    border: 1px solid #e8eef3;
    box-shadow: 0 0 40px rgba(0, 0, 0, .1);
}
.client-section {
    margin-top: 25px;
}
.client-logo {
    width: 150px;
    margin: auto;
}
.invite-section .panel-body {
    min-height: 200px;
}
.left-offset {
    margin-left: 20px;
}
.public-profile-box .cp-body-box{
    padding: 0px 25px;
}
.public-profile-box .d-accordion .table > tbody > tr > td {
    border: none;
    font-size: 16px;
    font-family: SofiaLight;
    color: #000;
    vertical-align: middle;
}
.public-profile-box .tab-content {
    margin-top: 20px;
}
.slab-table .table>thead>tr>th {
    font-family: EinaBold;
    font-size: 18px;
}
.public-profile-box .btn-hire {
    background-color: #3a40e7;
    padding: 6px 15px;
    font-size: 14px;
    color: #fff !important;
}
.public-profile-box{
    min-height: 600px!important;
    padding-bottom: 10px;
    background-color: #fff;
    margin-bottom: 20px;
}
.public-profile-box .tab-pane{
    margin-top: 10px!important;
}
.chield-categor-box-p{
    list-style-type: none;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding-inline-start: 20px;
}
.chield-categor-box-c {
    width: 50%;
    margin-bottom: 15px;
    padding: 10px 10px;
    position: relative;
}
.public-profile-box .nav-tabs {
    margin-bottom: 10px;
    border-bottom: 1px solid #e8eef3;
}
.public-profile-box .nav-tabs > li.active > a,
.public-profile-box .nav-tabs > li.active > a:focus,
.public-profile-box .nav-tabs > li.active > a:hover {
    border: none;
    color: #3a40e7;
    font-family: SofiaSemiBold;
    font-size: 16px;
}
.public-profile-box .nav-tabs > li > a:hover {
    border-color: transparent;
}
.public-profile-box .nav > li > a:focus,
.public-profile-box .nav > li > a:hover {
    background-color: transparent;
}
.public-profile-box .nav-tabs > li > a {
    font-size: 16px;
    font-family: SofiaSemiBold;
    color: #979797;
}
.public-profile-box .nav > li {
    margin-left: 0px;
    margin-right: 10%;
}
.public-profile-box .nav > li > a {
    padding: 10px 0px;
}
.public-profile-box .panel .panel {
    box-shadow: none !important;
}
.profile-box-shadow {
    box-shadow: 0 2px 10px rgba(152, 152, 152, 0.5)!important;
}
#expertise_areas_skills .panel {
    border: none;
}
#expertise_areas_skills .panel .panel-body {
    padding: 15px 0px;
}
#client_reviews .panel {
    border: none;
}
.profile-img {
    height: 150px;
    width: 150px;
    margin: auto;
    margin-top: 10px;
    object-fit: cover;
    object-position: 53% 0;
}
.top-margin {
    margin-top: 50px;
}
.ui-work-container {
    padding-top: 50px;
    padding-bottom: 50px;
    margin-bottom: 50px;
}
.ui-reviews-container {
    min-height: 550px;
    border-left: 1px solid #e8eef3;
    padding-left: 50px;
}
.ui-reviews-container .heading-sm {
    margin-bottom: 10px;
}
.ui-reviews-container .xs-para {
    margin-bottom: 40px;
}
.ui-reviews-container::after {
    content: '';
    width: 80px;
    height: 3px;
    position: absolute;
    left: -30px;
    margin-top: 50px;
    background-color: #000;
}
.ui-work-container .text-black-sub-heading {
    margin-top: 100px;
}
.profile-name {
    color: #fff;
    font-size: 22px;
    font-family: SofiaSemiBold;
    margin-top: 10px;
    margin-bottom: 5px;
}
.profile-name .label-success {
    font-size: 12px;
    margin-left: 10px;
}
.label-success {
    font-size: 12px;
    margin-left: 15px;
}
.profile-name .label-warning {
    font-size: 12px;
    margin-left: 10px;
}
.label-warning {
    font-size: 12px;
    margin-left: 15px;
}
small {
    color: #fff;
    font-size: 12px;
    font-family: SofiaLight;
}
.xs-heading {
    color: #000;
    font-size: 18px;
    font-family: EinaBold;
    margin-top: 15px;
    margin-bottom: 10px;
}
.text-para-reviews {
    color: #000;
    font-family: SofiaLight;
    line-height: 1.4em;
    font-size: 18px;
    margin-top: 15px;
}
.text-para {
    color: #000;
    font-family: SofiaLight;
    line-height: 1.6em;
    font-size: 18px;
    margin-top: 15px;
}
.text-muted {
    color: #000;
    font-family: SofiaLight;
    font-size: 20px;
    margin-top: 15px;
}
.text-sub-muted {
    color: #000;
    font-family: SofiaLight;
    font-size: 20px;
    margin-top: 15px;
    text-indent: -35px;
    margin-left: 20px;
}
.text-sub-indent-muted {
    color: #000;
    font-family: SofiaLight;
    font-size: 20px;
    margin-top: 15px;
    text-indent: -50px;
    margin-left: 30px;
}
.text-white {
    color: #fff;
    font-family: EinaBold;
}
.text-small {
    color: #000;
    font-size: 16px;
    font-family: SofiaRegular;
}
.fa-map-marker {
    font-size: 18px;
}
.fa-graduation-cap {
    font-size: 16px;
}
.text-indent {
    text-indent: -11px;
    margin-left: 15px;
}
.profile-section .list-unstyled {
    margin-top: 30px;
    padding: 0px;
}
.profile-section .list-unstyled > li {
    border-left: 4px solid #fff;
    padding-left: 10px;
    padding-top: 0px;
    padding-bottom: 0px;
}
.profile-section .list-unstyled > li span {
    color: #000;
    font-size: 22px;
    font-family: EinaBold;
}
.profile-section .list-unstyled > li small {
    margin-left: 30px;
    font-size: 14px;
}
.para.show-read-more{
    overflow-wrap: break-word;
    word-wrap: break-word;
}
.show-read-more .more-text {
    display: none;
}
.show-read-more a {
    color: #ff337d!important;
}
.basic-bio .panel {
    padding: 0px 20px;
    border: 1px solid #e8eef3;
    box-shadow: none;
}
.basic-bio .panel-body {
    padding-bottom: 30px;
}
.basic-bio .list-unstyled > li label {
    margin-left: 50px;
    margin-top: 5px;
}
.basic-bio .list-unstyled > li .fa {
    color: gold;
    margin-left: 5px;
    font-size: 18px;
}
.basic-bio .list-unstyled > li {
    margin-top: 10px;
    margin-bottom: 15px;
}
.basic-bio .list-unstyled > li > b {
    color: #000;
    font-family: EinaBold;
    font-size: 16px;
}
.basic-bio .list-unstyled > li > span {
    color: #ff337d;
    font-family: EinaBold;
    font-size: 16px;
    position: absolute;
    width: 40px;
    margin-top: -3px;
}
.basic-bio .list-unstyled > li > small {
    color: #000;
    margin-left: 55px;
    font-size: 18px;
    font-family: SofiaRegular;
}
.practice-area-logo {
    margin: auto;
    width: 70px;
}
.center-text {
    font-size: 18px;
    font-family: SofiaLight;
    color: #000;
    width: 50%;
    line-height: 1.2em;
    text-align: center;
    margin: auto;
    margin-top: 15px;
}
.xs-text-name {
    color: #000;
    font-family: SofiaSemiBold;
    text-align: center;
    margin-top: 15px;
    line-height: 1.8em;
    font-size: 18px;
}
.xs-text-name a {
    color: #000;
}
.xs-text-name a:hover {
    color: #ff337d;
}
.text-name {
    font-size: 18px;
    color: #000;
    font-family: EinaBold;
    margin-top: 20px;
    margin-bottom: -10px;
}
.text-product-name{
    font-size: 14px;
    color: #757c8e;
    font-family: EinaBold;
    margin-top: 15px;
    margin-bottom: -5px;
}
.brand-assurance .s-how-it-work .text-name {
    margin-bottom: -10px;
}
.text-name .fa-star {
    color: gold;
    font-size: 14px;
    margin-left: 5px;
}
.text-name label {
    margin-left: 10px;
}
.text-invite {
    color: #000;
    font-family: EinaBold;
    margin-top: 70px;
}
.btn-invite {
    background-color: #2b47fb;
    padding: 8px 20px;
    color: #fff;
    font-size: 16px;
    font-family: SofiaRegular;
    border-radius: 4px;
    margin-top: 20px;
}
.btn-invite:hover,
.btn-invite:focus {
    background-color: #2b47fb;
    color: #fff;
}
.btn-share-profile {
    border: 1.2px solid #2b47fb;
    color: #2b47fb;
    border-radius: 4px;
    padding: 8px 20px;
    font-family: SofiaRegular;
    font-size: 16px;
    margin-top: 20px;
}
.btn-share-profile:hover,
.btn-share-profile:focus {
    background-color: #2b47fb;
    color: #fff;
}
.btn-book-consultation {
    background-color: #000;
    padding: 12px 25px;
    color: #fff;
    border: 1.5px solid #000;
    text-align: center;
    font-size: 20px;
    font-family: SofiaRegular;
    outline: 0px;
    border-radius: 4px;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
}
.btn-book-consultation:hover,
.btn-book-consultation:focus {
    background-color: #000;
    color: #fff;
    outline: 0px;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
}
.text-white-para {
    font-size: 18px;
    font-family: SofiaLight;
    color: #fff;
    line-height: 1.4em;
    margin-top: 10px;
}
.text-white-para b{
    font-family: EinaBold;
}
.ui-padding {
    padding: 0px 30px;
}
.logged-in {
    margin-bottom: 25px;
}
@media screen and (max-width: 767px) {
    .logged-in {
        margin-bottom: 0px;
    }
    .profile-name .label-success {
        margin-top: 10px!important;
    }
    .panel .label-success {
        float: right;
        margin-top: 5px;
    }
    .text-product-name{
        font-size: 14px;
    }
    .panel .label-warning {
        float: right;
        margin-top: 0px;
    }
    .press-release-box .press-release-logo:nth-of-type(1) img {
        width: 140px!important;
    }
    .press-release-box .press-release-logo:nth-of-type(2) img {
        width: 25px!important;
    }
    .press-release-box .press-release-logo:nth-of-type(3) img {
        width: 50px!important;
    }
    .press-release-box .press-release-logo:nth-of-type(4) img {
        width: 50px!important;
    }
    .press-release-box .press-release-logo:nth-of-type(5) img {
        width: 50px!important;
    }
    .press-release-box .press-release-logo:nth-of-type(6) img {
        width: 50px!important;
    }
    .press-release-box .press-release-logo:nth-of-type(7) img {
        width: 80px!important;
    }
    .navbar-top-margin {
        margin-top: 0px;
    }
    .public-profile-box .d-accordion .table > tbody > tr > td {
        padding: 6px;
        font-size: 11px;
        font-family: SofiaRegular!important;
    }
    .public-profile-box .profile-box-shadow {
        box-shadow: none!important;
    }
    .public-profile-box .btn-hire {
        font-size: 11px;
        padding: 6px 8px;
    }
    /*.public-profile-box .btn-hire {*/
    /*    padding: 6px 6px;*/
    /*    font-size: 12px;*/
    /*}*/
    .panel-body.table-responsive {
        border: none;
    }
    .center-text {
        font-size: 14px;
        width: 100%;
    }
    .practice-area-logo {
        width: 40px;
    }
    .practice-area-section .col-sm-4 {
        height: 100px;
        margin-bottom: 10px;
    }
    .public-profile-box .panel {
        border: none;
    }
    .text-name {
        font-size: 16px;
        margin-top: 8px;
    }
    .text-name .fa-star {
        color: gold;
        font-size: 12px;
        margin-left: 5px;
    }
    .text-para {
        font-size: 14px;
    }
    .public-profile-box .top-margin {
        margin-top: 20px;
    }
    .public-profile-box .panel-body {
        padding: 0px 8px;
    }
    .public-profile-box .nav-tabs {
        margin-bottom: 10px;
    }
    .public-profile-box .nav-tabs {
        height: 50px;
        line-height: 50px;
        border-top: 1px solid #e8eef3;
        border-bottom: 1px solid #e8eef3;
    }
    .public-profile-box .nav-tabs > li.active > a,
    .public-profile-box .nav-tabs > li.active > a:focus,
    .public-profile-box .nav-tabs > li.active > a:hover {
        border-bottom: none;
    }
    .chield-categor-box-p{
        padding-inline-start: 0px;
    }
    .chield-categor-box-c{
        width:100%;
        padding: 0px 10px;
    }
    .public-profile-box .tab-content{
        margin-top: 0px!important;
    }
    .public-profile-box .nav-tabs > li > a {
        white-space: nowrap;
        font-size: 14px;
    }
    .public-profile-box .scope-content {
        right: -75px
    }
    .profile-section {
        margin-top: 90px!important;
        /*background-image: linear-gradient(right, #343e5f, #272f49, #342240);*/
        /*background-image: -webkit-linear-gradient(right, #343e5f, #272f49, #342240);*/
        /*background-image: -moz-linear-gradient(right, #343e5f, #272f49, #342240);*/
        padding: 0px 0px;
        text-align: center;
        margin-bottom: 0px!important;
    }
    .profile-img {
        height: 80px;
        width: 80px;
    }
    .professional-image .blank-dp {
        height: 80px!important;
        width: 80px!important;
        padding-top: 15px!important;
    }
    .profile-section .panel-body {
        padding: 10px;
    }
    .profile-section .basic-bio {
        text-align: left;
    }
    .profile-section .text-para {
        font-size: 16px;
        line-height: 1.3em;
    }
    .court-section .list-inline > li {
        font-size: 14px;
        width: 45%;
        font-family: SofiaLight;
        padding-left: 0px;
        margin-bottom: 20px;
        margin-top: 20px;
        margin-left: 4%;
    }
    .center-text {
        margin-top: 10px;
        margin-bottom: 50px;
    }
    .btn-invite {
        margin-top: 0px;
        margin-bottom: 10px;
    }
    .profile-section .text-right {
        text-align: center;
    }
    .basic-bio .list-unstyled {
        border-right: 0px solid #e8eef3;
        width: 90%
    }
    .profile-section .list-unstyled {
        margin-top: 0px;
    }
    .basic-bio .list-unstyled > li {
        margin-top: 0px;
    }
}
.heading-center {
    margin-top: 70px;
}
.x-lg-heading {
    color: #000;
    font-size: 52px;
    font-family: EinaBold;
    /*margin-top: 6%;*/
    line-height: 1.4em;
}
.our-mission-section {
    padding-top: 10px;
    padding-bottom: 70px;
    margin-bottom: 30px;
}
.our-mission-container {
    height: 550px;
    background: url('../images/growing-plant.webp') no-repeat;
    background-position: center;
    background-size: cover;
}
.our-profile-section {
    padding-top: 20px;
    padding-bottom: 70px;
    margin-bottom: 30px;
}
.our-profile-section .text-dark-para {
    margin-bottom: 2%;
}
.text-white-heading {
    font-size: 46px;
    font-family: EinaBold;
    color: #fff;
    margin-top: 70px;
    margin-bottom: 50px;
}
.text-sub-heading {
    font-size: 42px;
    font-family: EinaBold;
    line-height: 1.6em;
    color: #fff;
}
.our-mission-container .text-sub-heading {
    font-size: 40px;
    margin-top: 20px;
    font-family: EinaBold;
    line-height: 1.4em!important;
    color: #000;
}
.white-line {
    width: 60px;
    height: 3px;
    background-color: #fff;
    margin-bottom: 25px;
    margin-left: 20px;
}
.black-line {
    width: 100px;
    height: 3px;
    background-color: #000;
}
.black-line-straight {
    width: 3px;
    height: 40px;
    background-color: #000;
}
.white-line-straight {
    margin-top: 70px;
    margin-bottom: 80px;
    width: 3px;
    height: 40px;
    background-color: #000;
}
mark {
    background-color: #cbf7e4;
    font-family: SofiaRegular;
}
.our-value-section {
    padding-top: 50px;
    padding-bottom: 50px;
    margin-bottom: 30px;
}
.contact-section {
    padding: 0px 40px;
    margin-top: 30px;
    margin-bottom: 30px;
    background: #cbffed;
    height: 190px;
    position: relative;
}
.contact-section .scope-heading{
    line-height: 1.3em;
    margin-bottom: 10px;
}

.contact-section .banner-image-box{
    position: absolute;
    top:-82px;
    display: inline-flex;
}
.contact-section .m-banner-box{
    align-self: flex-end;
}
.contact-section .banner-image{
    width: 200px;
}
.blockquote{
    font-family: SofiaRegular;
}
.name-box .fa-star{
    color: gold;
    margin-left: 2px;
}
.btn-talk-sales{
    padding: 10px 30px;
    background: #08ad78;
    color:#fff;
    font-family: SofiaRegular;
    font-size: 18px;
    margin-top: 10px;
}
.btn-talk-sales:hover,
.btn-talk-sales:focus{
    color:#fff;
}
.btn-for-business{
    padding: 8px 30px;
    background: #ff337d;
    color:#fff;
    font-family: SofiaRegular;
    font-size: 18px;
    margin-top: 15px;
}
.btn-for-business:hover,
.btn-for-business:focus{
    color:#fff;
}
.for-business-banner{
    height: 600px;
    background-color: #09133a!important;
}
.affiliate-step-box{
    padding: 20px;
}
.affiliate-step-box .scope-sub-heading{
    margin-bottom: 25px;
}
.affiliate-step-box .step-label{
   font-family: EinaBold;
   font-size: 24px;
    color: #cb8383;
}
.affiliate-home-section.professional-social-ui{
    margin-top: 10%;
}
.affiliate-home-section.professional-social-ui .media-heading {
    font-size: 20px;
    font-family: EinaBold;
    margin-top: 5px;
}
.affiliate-home-section .text-black-sub-heading{
   margin-top: 10px;
}
.affiliate-home-section .banner-text-para{
    font-size: 22px;
    line-height: 1.4em;
}
.affiliate-home-section .btn-for-business{
    padding: 10px 60px;
    font-size: 20px;
}
.affiliate-service-wrappre{
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
}
.affliate-s-list{
    width: 50%;
}
.affliate-s-list ul{
    padding-inline-start: 20px;
}
.affiliate-service-wrappre ul>li{
    font-size: 16px;
    font-family: SofiaRegular;
    margin-bottom: 10px;
}
.grow-bussiness{
    background-color: #ffeded;
    padding: 30px 20px;
}
.expert-sample-container{
    padding: 50px;
    background: #eff5f3;
}
.bottom-button-container{
    display: flex;
    align-items: center;
    justify-content: center;
}
.hr-line{
    height: 1.5px;
    background: #000;
    width: 55%;
    /*margin:auto 30px auto auto;*/
}
.bottom-button-container .btn-for-business{
    margin-top: 0px;
    padding: 8px 45px;
}
.for-business-banner .text-white-para{
   font-size: 20px;
}
.m-center-btn{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    align-items: center;
}
.m-center-btn .btn-for-business{
    font-size: 18px;
    padding: 10px 40px;
    width: 100%;
    vertical-align: middle;
}
.m-center-btn .btn-for-business .fa{
    vertical-align: middle;
    font-size: 20px;
    margin-right: 5px;
}
.m-center-btn .btn-for-business+.btn-for-business{
    background: #38cc90;
    margin-left: 15px;
}
.m-center-btn .btn-for-business+.btn-for-business+.btn-for-business{
    border:1.5px solid #38cc90;
    background: transparent;
}
.client-img{
    height: 120px!important;
    width: 120px!important;
    margin: 15px 20px;
}
.c-reviews-para{
    font-size: 22px;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    color: #000;
    line-height: 1.4em;
    margin-bottom: 15px;
}
.c-reviews-name{
    font-size: 18px;
    font-family: EinaBold;
    color: #000;
    line-height: 1.4em;
    margin-bottom: 2px;
}
/*.c-reviews-name::before{*/
    /*content: " ";*/
    /*position: absolute;*/
    /*height: 1.5px;*/
    /*width: 25px;*/
    /*background-color: #000;*/
    /*margin-left: -35px;*/
    /*margin-top: 12px;*/
/*}*/
.c-reviews-about{
    font-size: 16px;
    font-family: SofiaLight;
    color: #000;
    line-height: 1.4em;
}
.video-section-ui.for-business-section .text-black-sub-heading{
    font-size: 26px;
    line-height: 1.4em;
    margin-bottom: 15px;
}
.video-section-ui.for-business-section .top-offset-2{
    margin-top: 5%;
}
.talk-to-sales-section{
    /*background-color: #fff6ef;*/
    /*min-height: 100vh;*/
    margin-top: 50px;
    margin-bottom: 50px;
}
.msme-products {
    list-style-type: none;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding-inline-start: 0px;
    /*justify-content: center;*/
}
.msme-products-wrapper {
    width: 48%;
    margin: 5px;
    /*border: 1px solid red;*/
    padding: 5px 5px;
    position: relative;
    /*border: 1px solid #e8eef3;*/
    transition: box-shadow .3s ease-in-out, transform .3s ease-in-out;
}
.msme-products-wrapper .service-grid-cards-icon{
    text-align: left;
    margin: 5px 5px;
    height: 50px;
    line-height: 50px;
}
.msme-products-wrapper .service-grid-cards-icon img{
    width: 45px;
}
.msme-products-wrapper .service-grid-cards-heading{
   font-family: EinaBold;
}
.talk-to-sales-section .assurance-ul li>img{
   width: 25px;
}
.talk-to-sales-section .assurance-ul li{
  font-size: 18px;
}
.talk-to-sales-form{
    box-shadow: 0 2px 8px 0 rgba(0,69,191,.16);
    padding: 15px 15px 35px 15px;
    background-color: #fff6ef;
    border-radius: 6px;
}
.talk-to-sales-form .price .form-group{
    margin-top: 15px;
}
.service-section-box {
    background-color: #020d2a !important;
    min-height: 370px;
    padding: 15px 20px;
}
.service-section-box .text-white {
    font-size: 22px;
    line-height: 1.4em;
    font-family: SofiaRegular;
    margin-bottom: 40px;
}
.service-section-box .dropdown .btn-default {
    background-color: #fff;
    padding: 10px 10px;
    border-radius: 4px !important;
    width: 70%;
    margin: auto;
    color: #000;
    font-size: 16px;
    margin-bottom: 20px;
}
.service-section-box .dropdown .btn-default:hover,
.service-section-box .dropdown .btn-default:focus {
    background-color: #fff !important;
}
.service-section-box .dropdown button {
    background-position: 96% 60% !important;
}
.contact-box {
    min-height: 460px;
    background-color: #ff9595;
    padding: 25px 15px;
}
.contact-box .text-black-heading {
    color: #fff;
}
.contact-form .form-group {
    margin-bottom: 30px;
}
.contact-form .form-group .form-control {
    box-shadow: none;
    border-radius: 0px;
    background-color: transparent;
    border: none;
    padding-left: 0px;
    padding-top: 20px;
    padding-bottom: 0px;
    border-bottom: 1.5px solid #fff;
}
.contact-form .form-group input {
    color: #fff;
    font-family: SofiaLight;
    font-size: 18px;
}
.contact-form .form-group > input::-webkit-input-placeholder {
    color: #fff !important;
    font-size: 18px;
    font-family: SofiaLight;
}
.contact-form .form-group > input:-moz-placeholder {
    color: #fff !important;
    font-size: 18px;
    font-family: SofiaLight;
}
.contact-form .form-group >::-moz-placeholder {
    color: #fff !important;
    font-size: 18px;
    font-family: SofiaLight;
}
.btn-enter {
    background-color: #000;
    border-radius: 4px;
    padding: 10px 20px;
    color: #fff;
    font-size: 16px;
    font-family: SofiaMedium;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
}
.btn-enter:hover,
.btn-enter:focus {
    background-color: #000;
    color: #fff;
}
.text-black-heading {
    color: #000;
    font-size: 52px;
    font-family: EinaBold;
    margin-top: 30px;
    margin-bottom: 70px;
    line-height: 1.2em;
}
.text-black-sub-heading {
    color: #000;
    font-size: 42px;
    font-family: EinaBold;
    line-height: 1.4em;
    margin-top: 30px;
    margin-bottom: 0px;
}
.professional-social-ui {
    margin-bottom: 5%;
}
.professional-social-ui .media-heading {
    font-size: 18px;
    font-family: EinaBold;
    color: #000;
    /*margin-top: 10px;*/
}
.professional-social-ui .media-image {
    height: auto;
    width: 40px;
}
.our-partner-trust{
    background: #fff9f4;
    padding: 50px;
}
.professional-social-ui .text-black-sub-heading {
    color: #000;
    font-size: 30px;
    font-family: EinaBold;
    line-height: 1.3em;
    margin-bottom: 0px;
}
.video-section-ui .x-lg-heading {
    font-size: 42px;
}
.video-section-ui {
    margin-top: 7%;
}
.top-offset-2{
    margin-top: 10%;
}
.video-section-ui .text-black-sub-heading {
    color: #000;
    font-size: 30px;
    font-family: EinaBold;
    margin-top: 0px;
    line-height: 1.3em;
}
.video-section-ui .text-normal-para {
    font-size: 20px;
}
.video-section-ui .media-heading {
    font-size: 16px;
    font-family: EinaBold;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.video-section-ui .media {
    margin-top: 20px;
}
.video-section-ui .media-image {
    height: auto;
    width: 50px;
    /*-moz-transform: scaleX(-1);*/
    /*-o-transform: scaleX(-1);*/
    /*-webkit-transform: scaleX(-1);*/
    /*transform: scaleX(-1);*/
    /*filter: FlipH;*/
    /*-ms-filter: "FlipH";*/
}
.video-section-ui .media .para {
    color: #ff337d;
    font-family: SofiaSemiBold;
}
.video-section-ui blockquote {
    margin-top: 30px;
    margin-bottom: 10px;
}
.quote {
    margin-top: 0px;
    font-size: 18px;
    font-style: normal;
    color: #495179;
    font-family: SofiaLight;
}
.dot-pink {
    color: #ff337d;
}
.text-dark-para {
    color: #000;
    font-family: SofiaLight;
    font-size: 18px;
    margin-top: 15px;
    line-height: 1.9em;
    margin-bottom: 8%;
}
.vr-line {
    height: 60px;
    margin-top: 70px;
    margin-right: -60px;
    width: 3px;
    background-color: #000;
}
.bold {
    /*font-size: 18px;*/
    font-family: EinaBold;
    font-weight: bold;
}
/* ===============================================WE LOVE SECTION===================================================== */

.we-love-section {
    padding-top: 50px;
    padding-bottom: 0px;
}
.we-love-section .text-black-heading {
    margin-top: 0px;
}
.line-container {
    min-height: 2850px;
    border-left: 1px solid #f2f2f2;
    width: 100%;
}
.square-shape-one {
    height: 30px;
    width: 30px;
    background-color: #f3ec51;
    position: absolute;
    left: 0px;
    margin-top: 70px;
}
.square-shape-two {
    height: 30px;
    width: 30px;
    background-color: #f78fa6;
    position: absolute;
    left: 0px;
    margin-top: 70px;
}
.square-shape-four {
    height: 30px;
    width: 30px;
    background-color: #b2f7c5;
    position: absolute;
    left: 0px;
    margin-top: 550px;
    z-index: 1;
}
.square-shape-five {
    height: 30px;
    width: 30px;
    background-color: #8db5f3;
    position: absolute;
    left: 0px;
    margin-top: 550px;
}
.square-shape-six {
    height: 30px;
    width: 30px;
    background-color: #000;
    position: absolute;
    right: -15px;
    margin-top: 1050px;
}
.square-shape-seven {
    height: 30px;
    width: 30px;
    background-color: #8db5f3;
    position: absolute;
    left: 0px;
    margin-top: 1750px;
}
.square-shape-eight {
    height: 30px;
    width: 30px;
    background-color: #b2f7c5;
    position: absolute;
    right: -15px;
    margin-top: 1850px;
}
.square-shape-nine {
    height: 30px;
    width: 30px;
    background-color: #b2f7c5;
    position: absolute;
    left: 0px;
    margin-top: 3020px;
}
.square-shape-ten {
    height: 30px;
    width: 30px;
    background-color: #8db5f3;
    position: absolute;
    right: -15px;
    margin-top: 2750px;
}
.square-shape-11 {
    height: 30px;
    width: 30px;
    background-color: #f78fa6;
    position: absolute;
    left: 0px;
    margin-top: 3020px;
}
.box-one {
    width: 200%;
    position: absolute;
    margin-top: 170px;
    margin-left: -20px;
    z-index: 1;
}
.box-one>p {
    font-size: 52px;
    width: 140%;
    position: absolute;
    color: #000;
    font-family: EinaBold;
    margin-left: 20px;
    margin-top: -28px;
}
.box-one img {
    width: 100%;
    position: absolute;
    left: 20px;
    margin-top: 60px;
}
.box-two {
    position: absolute;
    margin-top: 170px;
    width: 120%;
    margin-left: -100px;
    z-index: 1;
}
.box-two>p {
    font-size: 52px;
    width: 140%;
    position: absolute;
    color: #000;
    font-family: EinaBold;
    margin-left: 100px;
    margin-top: -28px;
}
.box-two img {
    width: 140%;
    margin-top: 60px;
    margin-left: 100px;
}
.box-three {
    width: 200%;
    position: absolute;
    margin-top: 670px;
    margin-left: -20px;
    z-index: 1;
}
.box-three>p {
    font-size: 52px;
    width: 140%;
    position: absolute;
    color: #000;
    font-family: EinaBold;
    margin-left: 20px;
    margin-top: -28px;
}
.box-three img {
    width: 90%;
    position: absolute;
    left: 20px;
    margin-top: 60px;
}
.box-four {
    width: 100%;
    position: absolute;
    margin-top: 670px;
    margin-left: -20px;
    z-index: 1;
}
.box-four>p{
    font-size: 52px;
    width: 140%;
    position: absolute;
    color: #000;
    font-family: EinaBold;
    margin-left: 20px;
    margin-top: -28px;
}
.box-four img {
    width: 160%;
    position: absolute;
    left: 20px;
    margin-top: 60px;
}
.box-five {
    position: absolute;
    width: 100%;
    margin-top: 950px;
    left: 16px;
    z-index: 1;
}
.box-five img {
    position: absolute;
    left: 40%;
    margin-top: 0px;
    width: 170px;
}
.box-six {
    position: absolute;
    width: 150%;
    margin-top: 1100px;
    left: 16px;
    z-index: 1;
}
.box-six h2 {
    font-size: 52px;
    position: absolute;
    width: 140%;
    margin-top: 30px;
    color: #000;
    left: 0px;
    font-family: EinaBold;
}
.img-box-one {
    width: 200%;
    display: none;
    position: absolute;
    margin-top: 1850px;
    margin-left: -20px;
    z-index: 1;
}
.img-box-one img {
    position: absolute;
    left: 20px;
    margin-top: 0px;
    width: 50%;
}
.img-box-two {
    width: 200%;
    display: none;
    position: absolute;
    margin-top: 2150px;
    margin-left: -20px;
    z-index: 1;
}
.img-box-two img {
    position: absolute;
    left: 20px;
    margin-top: 0px;
    width: 50%;
}
.img-box-three {
    width: 200%;
    display: none;
    position: absolute;
    margin-top: 1700px;
    margin-left: -20px;
    z-index: 1;
}
.img-box-three img {
    position: absolute;
    left: -155px;
    margin-top: 0px;
    width: 50%;
}
.img-box-four {
    width: 200%;
    display: none;
    position: absolute;
    margin-top: 1930px;
    margin-left: -20px;
    z-index: 1;
}
.img-box-four img {
    position: absolute;
    left: 20px;
    margin-top: 0px;
    width: 50%;
}
.line-text-box-one {
    position: absolute;
    width: 100%;
    height: 200px;
    margin-top: 1300px;
    left: 20px;
    z-index: 1;
}
.line-text-box-two {
    position: absolute;
    width: 100%;
    height: 200px;
    margin-top: 1550px;
    left: 20px;
    z-index: 1;
}
.line-text-box-three {
    position: absolute;
    width: 100%;
    height: 200px;
    margin-top: 1950px;
    left: 20px;
    z-index: 1;
}
.line-text-box-one .black-line,
.line-text-box-two .black-line,
.line-text-box-three .black-line {
    width: 20%;
}
.line-text-box-one h3,
.line-text-box-two h3,
.line-text-box-three h3 {
    color: #000;
    font-family: EinaBold;
}
.styled-list {
    margin-top: 15px;
}
.styled-list li {
    color: #000;
    font-family: SofiaLight;
    font-size: 18px;
    line-height: 1.5em;
    margin-bottom: 20px;
}
.styled-list li a {
    color: #ff337d;
}
.service-section-box .dropdown-menu {
    margin-left: 90px;
}
.cp-professional {
    background-color: #fff;
    min-height: 200px;
    margin-bottom: 15px;
}
.borderless-table {
    margin-top: 10px;
}
.borderless-table>thead>th {
    font-size: 16px;
    font-family: SofiaSemiBold;
}
.borderless-table>tbody>tr>td {
    font-size: 16px;
    font-family: SofiaLight;
}
.cp-how-it-work {
    background-color: #fff;
    padding: 50px 15px;
    margin-bottom: 20px;
}
.cp-body-box .image-gallery-thumbnail .image-gallery-thumbnail-inner {
    height: 60px;
    overflow: hidden;
    line-height: 60px;
}
.image-gallery-thumbnail .image-gallery-thumbnail-inner img{
    max-width: 100%;
    height: 100%;
    object-fit: contain;
    margin: auto;
}
.cp-body {
    padding: 10px;
}
.cp-body .text-name {
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 0px;
}
.cp-body .text-product-name{
    margin-bottom: 5px;
    margin-top: 0px;
}
.cp-body .text-para-reviews {
    font-size: 16px;
    margin-top: 0px;
}
.cp-body .reviews-list li:nth-child(n+6) {
    display: none;
}
.cp-body .btn-link {
    color: #ff337d;
}
.cp-body-box {
    padding: 10px 25px;
}
.cp-ul{
    margin-top: 10px;
}
.cp-ul li{
    margin-bottom: 10px;
    font-size: 16px;
    font-family: SofiaLight;
    color: #ff337d;

}
.cp-lg-head {
    font-size: 24px;
    font-family: EinaBold;
    border-bottom: 1px solid #e8eef3;
    padding: 10px;
    line-height: 1.4em;
}
.cp-head {
    font-size: 18px;
    font-family: EinaBold;
    border-bottom: 1px solid #e8eef3;
    padding: 10px;
}
.cp-professional .profile-img {
    width: 100px;
    height: 100px;
}
.cp-professional .blank-dp {
    width: 100px!important;
    height: 100px!important;
    line-height: 100px!important;
    padding-top: 0px!important;
    margin-top: 10px;
}
.cp-professional .fa-star {
    font-size: 14px;
    color: gold;
    margin-left: 2px;
}
.skill-ul {
    padding: 0px 10px;
}
.skill-ul li {
    border-radius: 25px;
    border: 1.5px solid #4524ff;
    padding: 5px 10px;
    font-size: 12px;
    margin: 5px;
    text-align: center;
}
.text-info-title {
    font-size: 16px;
    color: #a1a1a1;
    font-family: SofiaRegular;
    margin-bottom: 0px;
}
.link-list li>a{
    color:#2b1de6;
    overflow-wrap: break-word;
    word-wrap: break-word;
}
.c-about-product {
    font-size: 16px;
    font-family: EinaBold;
    color: #000;
    margin-bottom: 20px;
}
.c-product-fee-breack-up{
    margin-top: 10px;
    margin-bottom: 15px;
    display: flex;
}
.c-product-fee-breack-up span{
    font-size: 16px;
    font-family: EinaBold;
    color: #000;
    width: 35%;
}
.c-product-fee-breack-up span+span{
    color: #283fe1;
}
.m-profile-box .profile-name {
    color: #000;
}
.m-profile-box .profile-img {
    border: 1.5px solid #ecb33c;
    display: inline-block;
}
.m-profile-box .profile-name {
    font-size: 16px;
    font-family: EinaBold;
}
.bio-caption {
    font-size: 16px;
    font-family: EinaBold;
    color: #ff337d;
}
.location {
    color: #000;
    font-family: SofiaRegular;
    font-size: 16px;
}
/*.professional-social-ui .media-heading {*/
    /*font-size: 16px;*/
/*}*/

.statistic-box {
    margin-bottom: 0px;
    border-top: 1px solid #e8eef3;
    text-align: left;
    padding: 10px;
}
.statistic-box .table>tbody>tr>td {
    border: 0;
    color: #545f6f;
    font-size: 16px;
    font-family: SofiaLight;
}
.statistic-box .table {
    margin-bottom: 10px;
}
.statistic-box .table>tbody>tr>td .stat-text {
    color: #000;
    font-family: SofiaMedium;
    font-size: 14px;
}
.statistic-head {
    font-size: 16px;
    font-family: EinaBold;
    color: #000;
    margin-top: 10px;
    margin-bottom: 15px;
}
/* =========================================Media query we love section================ */

@media screen and (max-width: 767px) {
    .cp-body-box .d-button-container-box-center{
      display:block
    }
    .affiliate-home-section.help-center-section{
        margin-top: 50px!important;
    }
    .affiliate-home-section .text-black-sub-heading{
        font-size: 22px;
    }
    .affiliate-home-section .banner-text-para{
        font-size: 16px;
    }
    .affiliate-home-section .btn-for-business{
        padding: 8px 30px;
        font-size: 16px;
    }
    .affiliate-home-section.professional-social-ui .media-heading{
        font-size: 16px;
    }
    .affiliate-home-section .img-responsive.banner-img{
        margin-bottom: 10px;
    }
    .affiliate-home-section.professional-social-ui{
        margin-top: 12%;
    }
    .affiliate-home-section.for-expert-section{
        padding: 20px 0px 20px 0px;
    }
    .affiliate-home-section.for-expert-section .text-black-heading{
        text-align: left;
        font-size: 28px;
    }
    .for-expert-services{
        display: flex;
        flex-direction: column-reverse;
    }
    .affiliate-home-section.for-expert-section .for-expert-services{
        display: block;
        flex-direction: unset;
    }
    .expert-banner-border-img{
        margin-top: 10%;
    }
    .for-brand-section{
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .for-brand-section li{
        width: 50%;
        text-align: center;
        margin: 10px 0px;
    }
    .banner-text-sub-para{
        font-size: 16px;
        margin-bottom: 15px;
    }
    .affiliate-step-box{
        padding: 10px 10px;
    }
    .affiliate-step-box .step-label{
        font-size: 18px;
        margin-top: 0px;
    }
    .affiliate-service-wrappre{
        margin-top: 20px;
    }
    .affliate-s-list{
        width: 100%;
    }
    .affiliate-service-wrappre ul>li{
        font-size: 14px;
        margin-bottom: 5px;
    }
    .cp-body-box .btn-strip-box{
        float:none;
    }
    .our-partner-trust{
        background: #fff9f4;
        padding: 20px 10px;
    }
    .cp-lg-head {
        font-size: 18px;
    }
    .cp-how-it-work{
        padding: 20px 10px;
    }
    .c-about-product {
        margin-bottom: 15px;
    }
    .m-profile-box .profile-img {
        border: 1.5px solid #ecb33c;
        display: inline-block;
    }
    .m-profile-box .profile-name {
        font-size: 16px;
        font-family: EinaBold;
    }
    .bio-caption {
        font-size: 14px;
        font-family: EinaBold;
        color: #ff337d;
    }
    .custom-file-upload .doc-upload-processbar{
        position: absolute;
        margin-top:-60px;
        width:95%;
    }
    .location {
        color: #000;
        font-family: SofiaRegular;
        font-size: 12px;
    }
    .professional-social-ui .media-heading {
        font-size: 16px;
    }
    .cp-professional .statistic-box{
        margin-top: 0px;
    }
    .statistic-box {
        margin-top: 20px;
        margin-bottom: 0px;
        border-top: 1px solid #e8eef3;
        /*border-bottom: 1px solid #e8eef3;*/
        text-align: left;
        padding-bottom: 10px;
    }
    .statistic-box .table>tbody>tr>td {
        border: 0;
        color: #545f6f;
        font-size: 12px;
        font-family: SofiaLight;
    }
    .statistic-box .table {
        margin-bottom: 10px;
    }
    .statistic-box .table>tbody>tr>td div {
        font-family: SofiaRegular;
        font-size: 14px;
    }
    .statistic-head {
        font-size: 14px;
        font-family: EinaBold;
        color: #000;
        margin-top: 10px;
        margin-bottom: 15px;
    }
    .professional-social-ui .text-normal-para {
        margin-top: 15px;
        font-size: 18px;
    }
    .video-section-ui {
        margin-top: 5%;
        margin-bottom: 40px!important;
    }
    /*.video-section-ui .left-offset-1{*/
    /*    margin-left: 0px;*/
    /*}*/
    .video-section-ui .top-margin {
        margin-top: 5%;
    }
    .video-section-ui .x-lg-heading {
        font-size: 20px;
        text-align: center;
    }
    .professional-social-ui .text-black-sub-heading {
        color: #000;
        font-size: 22px;
        font-family: EinaBold;
        line-height: 1.4em;
        margin-top: 3%;
        margin-bottom: 0px;
    }
    .video-section-ui .text-black-sub-heading {
        color: #000;
        font-size: 22px;
        font-family: EinaBold;
        line-height: 1.4em;
        margin-top: 0%;
        margin-bottom: 0px;
    }
    .video-section-ui .media-image{
        width:40px;
    }
    .video-section-ui .text-black-sub-heading.text-right {
        text-align: left;
    }
    .video-section-ui .media-heading{
        font-size: 14px;
    }
    .video-section-ui .media .para{
        font-size: 14px;
    }
    .video-section-ui .text-normal-para {
        font-size: 16px;
        line-height: 1.5em;
    }
    .video-section-ui .text-normal-para.text-right {
        text-align: left;
    }
    .video-section-ui .media {
        margin-bottom: 20px;
    }
    .quote {
        font-size: 14px;
        margin-top: 0px;
    }
    .service-section-box .dropdown .btn-default {
        background-color: #fff;
        padding: 10px 10px;
        border-radius: 4px !important;
        width: 100%;
        margin: auto;
        color: #000;
        font-size: 16px;
        margin-bottom: 20px;
    }
    .small-screen-margin {
        margin-left: -30px;
    }
    .button-center {
        text-align: center;
    }
    .service-section-box {
        height: auto;
        padding-bottom: 50px;
    }
    .service-section-box .dropdown-menu {
        width: 100%;
        margin-left: 0px;
    }
    .line-container {
        min-height: 2600px;
        border-left: 1px solid #f2f2f2;
        width: 100%;
    }
    .our-team-main-container {
        margin-top: 1650px;
        margin-left: -40px;
    }
    .hover-container {
        z-index: 1;
    }
    .team-m-image {
        -webkit-filter: none;
        filter: none;
    }
    .middle-text-image .name-text {
        font-size: 12px;
    }
    .team-m-image-overlay {
        opacity: 1;
    }
    .our-team-sub-header {
        font-size: 24px;
    }
    .our-team-header {
        font-size: 28px;
    }
    .box-one>p,
    .box-two>p,
    .box-three>p,
    .box-four>p {
        font-size: 24px;
    }
    .box-one img,
    .box-two img,
    .box-three img,
    .box-four img {
        margin-top: 10px;
    }
    .square-shape-four,
    .square-shape-five {
        margin-top: 350px;
    }
    .box-three,
    .box-four {
        margin-top: 470px;
    }
    .square-shape-six {
        margin-top: 650px;
    }
    .square-shape-seven {
        margin-top: 1530px;
    }
    .square-shape-eight {
        margin-top: 1630px;
    }
    .square-shape-nine,
    .square-shape-ten,
    .square-shape-11 {
        margin-top: 2500px;
    }
    .box-six {
        margin-top: 750px;
    }
    .box-six>h2 {
        font-size: 32px;
        width: 300%;
    }
    .line-text-box-one {
        margin-top: 900px;
        height: auto;
        width: 180%;
    }
    .line-text-box-one h3,
    .line-text-box-two h3,
    .line-text-box-three h3 {
        font-size: 18px;
    }
    .line-text-box-one p,
    .line-text-box-two p,
    .line-text-box-three p {
        line-height: 1.6em;
        width: 140%;
    }
    .line-text-box-two {
        margin-top: 1150px;
        height: auto;
        width: 180%;
    }
    .line-text-box-two p {
        padding-right: 20px;
    }
    .line-text-box-one .black-line,
    .line-text-box-two .black-line,
    .line-text-box-three .black-line {
        width: 30%;
    }
    .line-text-box-three {
        margin-top: 1350px;
        height: auto;
        width: 180%;
    }
    .box-one img {
        width: 120%;
    }
    .box-two img {
        width: 170%;
    }
    .box-three img {
        width: 120%;
    }
    .box-four img {
        width: 210%;
    }
    .heading-sub-md{
        font-size: 24px;
    }
    .text-muted{
        font-size: 16px;
    }
    .text-sub-muted{
        font-size: 16px;
    }
    .text-sub-muted{
        text-indent: -35px;
        margin-left: 40px;
    }
    .text-sub-indent-muted{
        text-indent: 0px;
        margin-left: 0px;
        font-size: 20px;
    }
}
/* =========================================Media query we love section================ */

/* ===============================================END OF WE LOVE SECTION============================================== */

/* ==========================================INDEX PAGE=================================================== */

/* ==========================================Hover section=================================================== */

.s-how-it-work {
    text-align: center;
}
.s-how-it-work img {
    width: 50px;
}
.s-how-it-work .text-name {
    font-size: 18px;
    font-family: EinaBold;
    color: #000;
    margin-top: 10px;
    margin-bottom: 20px;
}
.s-how-it-work .text-para-reviews {
    font-size: 16px;
    line-height: 1.3em;
}
.work-box-padding {
    padding: 0px 20px;
    text-align: center;
}
.work-icon-box {
    height: 60px;
    line-height: 60px;
}
.step-label{
    margin-top: 20px;
}
.step-label span{
    padding: 8px 12px;
    background: #cefaf5;
    font-family: EinaBold;
    font-size: 14px;
    border-radius: 8px;
    text-align: center;
}
.work-box-padding .scope-sub-heading {
    line-height: 1.5em;
}
.hero-client-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 70px;
    line-height: 65px;
    background-color: #fff;
    width: 100%;
    z-index: 1;
}
.hero-client-container .service-text:before {
    margin-top: 8px;
}
.tagline-strip {
    width: 100%;
}
.reviews-box {
    height: auto;
    /*overflow: hidden;*/
    /*overflow-y: scroll;*/
}
.service-client-container {
    height: 45px;
    left: 0;
    right: 0;
    background-color: #fff;
    width: 100%;
    z-index: 1;
}
.service-client-container .ui-new-list li {
    font-size: 15px;
    color: #000;
    font-family: SofiaMedium;
    text-align: center;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 2.3%;
}
.ui-new-list li {
    font-size: 16px;
    color: #000;
    font-family: SofiaMedium;
    text-align: center;
    margin-top: 25px;
    margin-left: 0px;
    margin-right: 2.5%;
}
.service-client-container .service-text {
    font-size: 14px;
    margin-top: 10px;
}
.service-client-container .service-text:before {
    top: 15px;
}
.fa-angle-left {
    position: absolute;
    left: -30px;
    top: 100px;
}
.fa-angle-right {
    position: absolute;
    right: -30px;
    top: 100px;
}
.fa-angle-left:hover,
.fa-angle-left:focus {
    color: #000;
}
.fa-angle-right:hover,
.fa-angle-right:focus {
    color: #000;
}
.crousel-h4 {
    font-size: 18px;
    font-family: SofiaSemiBold;
    color: #000;
    margin-top: 20px;
}
.crousel-para {
    font-size: 16px;
    font-family: SofiaLight;
    color: #000;
}
.crousel-para span {
    color: #ff337d;
    font-size: 18px;
}

.reviews-card-container{
    /*box-shadow: 0 2px 10px rgba(0, 0, 0, .1);*/
    transition: box-shadow 0.3s ease 0s;
    padding: 10px 15px;
    margin-top: 20px;
}
.reviews-card .expert-dp{
    margin: auto;
    width:80px!important;
    height: 80px!important;
}
.reviews-card .owl-item {height: 0;}
.reviews-card .owl-item.active {height: auto;}
.reviews-card .text-name{
    margin-top: 10px;
}
.reviews-card .text-name .bio{
    font-family: SofiaLight;
    color:#ff337d;
    font-size: 16px;
}
.reviews-card .text-para-reviews{
    font-size: 16px;
}
.search-result-section .scope-heading{
    border-bottom: 1px solid #e8eef3;
    padding-bottom: 5px;
    margin-bottom: 20px;
}
.city-heading{
    font-size: 18px;
    font-family: EinaBold;
    border-bottom: 1px solid #e8eef3;
    padding-bottom: 5px;
    margin-bottom: 10px;
    margin-top: 15px;
}
.expert-by-city{
    list-style: none;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}
.expert-by-city li{
    margin-bottom: 15px;
    width: 25%;
}
.btn-view-more.btn-view-more{
    font-size: 16px;
    color: #ff337d;
    font-family: SofiaRegular;
    padding: 0px;
    text-decoration: underline;
}
.expert-by-city li>a{
    color: #000;
    font-size: 16px;
    font-family: SofiaRegular;
}
.expert-by-city li>a>span{
    text-transform: capitalize;
}
.expert-by-city li>a>span.badge{
    background: #ff337d;
    color: #fff;
}
.expert-by-city li>a:hover,
.expert-by-city li>a:focus{
    color: #ff337d;
}
.expert-card {
    box-shadow: 0 0 1.5rem rgba(0,0,0,.1);
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
    padding: 10px 15px;
    margin: 10px 0px;
    height: 360px;
    position: relative;
}
.platform-nav a{
   font-size: 14px;
    color: #000;
    font-family: SofiaLight;
}
.platform-nav a:nth-last-child(1){
    color: #ff337d;
}
.platform-nav a:hover,
.platform-nav a:focus{
    color: #ff337d;
}
.platform-expert-card{
    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.08);
    background: #fff;
    border: 1px solid rgba(0,0,0,0.01);
    -webkit-transition: box-shadow .3s ease-in-out, transform .3s ease-in-out;
    -moz-transition: box-shadow .3s ease-in-out, transform .3s ease-in-out;
    -o-transition: box-shadow .3s ease-in-out, transform .3s ease-in-out;
    -ms-transition: box-shadow .3s ease-in-out, transform .3s ease-in-out;
    transition: box-shadow .3s ease-in-out, transform .3s ease-in-out;
    padding: 10px 15px;
    margin: 15px 0px;
    display: flex;
    align-items: center;
}
.sales-form{
    box-shadow: 0 2px 4px 0 rgba(162, 162, 162, 0.5);
    border: 1px solid #e0e0e0;
    background-color: #2e385c;
    padding: 30px 30px;
    border-radius: 4px;
    margin-bottom: 20px;
}
.sales-form .btn-block{
    display: initial;
    width: auto;
}
.sales-form .scope-sub-heading{
    margin-top: 0px;
    margin-bottom: 20px;
    text-align: center;
    color: #fff;
}
.sales-form .price .form-control.input-lg{
    font-size: 16px;
}
.our-partner-trust .price .form-control{
    border: 1px solid #000;
}
.expert-details-box{
    padding-right: 20px;
}
.expert-see-details-box{
    text-align: center;
    padding:60px 20px;
    border-left: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
}
.expert-rating-reviews li+li{
    margin-left: 20px;
}
.expert-rating-reviews li .span-text{
    color: #3f4cdd;
    font-family: SofiaRegular;
}
.expert-rating-reviews li .span-text .fa-star{
    color: gold;
}
.skill-expert-dp{
    height: 80px!important;
    width: 80px!important;
    border-radius: 50%;
    object-fit: cover;
    object-position: 0% 0%;
}
.platform-expert-card .blank-dp{
    height: 80px!important;
    width: 80px!important;
    line-height: 60px;
}
.platform-expert-card .blank-dp{
    font-size: 26px!important;
}
.platform-expert-card:hover{
    transition: box-shadow .3s ease-in-out, transform .3s ease-in-out;
    box-shadow: 0 2px 20px 0 rgba(0,0,0,0.16);
}
.search-result-section .expert-card{
    padding: 10px 15px;
    margin: 10px 0px;
    height: 360px;
}
.platform-expert-card .card-practice-area{
  margin-top: 5px;
}
.platform-expert-card .card-practice-area li{
    font-family: SofiaLight;
    color: #666e7f;
    font-size: 12px;
    border: 1.2px solid #666e7f;
    background: #fff;
    padding: 4px 8px;
    border-radius: 25px;
    margin: 5px 0px;
}
.platform-expert-card .card-practice-area li+li{
    margin-left: 5px;
}
.sales-form-column{
padding-left: 20px;
}
.expert-btn .btn+.btn{
    margin-top: 25px;
}
.btn-see-expert-profile{
    background: #fff;
    color: #2854e2;
    border: 1.5px solid #2854e2;
    padding: 8px 20px;
    font-size: 16px;
    font-family: SofiaRegular;
    width: 100%;
}
.btn-see-expert-gig{
    background: #2854e2;
    color: #fff;
    border: 1.5px solid #2854e2;
    padding: 8px 20px;
    font-size: 16px;
    font-family: SofiaRegular;
    width: 100%;
}
.btn-see-expert-gig:hover,
.btn-see-expert-gig:focus{
    background: #2854e2;
    color: #fff;
}
.btn-see-expert-profile:hover,
.btn-see-expert-profile:focus{
    background: #2854e2;
    color: #fff;
}
.expert-brief-bio{
    color: #666e7f;
    font-size: 16px;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}
.expert-brief-bio h1,
.expert-brief-bio h2,
.expert-brief-bio h3,
.expert-brief-bio h4,
.expert-brief-bio h5,
.expert-brief-bio h6{
    font-weight: normal!important;
    font-size: 16px!important;
}
.expert-name{
    font-size: 16px;
    font-family: EinaBold;
    margin-bottom: 5px;
    color: #000;
}
.expert-bio{
    font-size: 14px;
    font-family: SofiaRegular;
    margin-bottom: 0px;
    color: #ff337d;
}
.expert-city{
    font-size: 14px;
    font-family: SofiaRegular;
    margin-bottom: 5px;
    color: #000;
}
.card-list {
    margin-left: 10px;
}
.card-list li {
    font-family: SofiaLight;
    color: #000;
    font-size: 14px;
}
.card-list li {
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.card-list li > b {
    font-size: 18px;
}
.expert-card .blank-dp {
    height: 50px !important;
    width: 50px !important;
    line-height: 40px !important;
}
.expert-card .blank-dp span {
    font-size: 20px !important;
}
.expert-card label {
    margin-left: 0px;
    margin-right: 20px;
    margin-bottom: 20px;
}
.expert-card .fa-star {
    color: gold;
    font-size: 14px;
    margin-left: 2px;
}
.expert-card .xs-heading {
    font-size: 16px;
    color: #000;
    font-family: SofiaRegular;
}
.star-experience-box {
    margin-top: 6px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-evenly;
}
.expert-dp {
    height: 60px !important;
    width: 60px !important;
    border-radius: 50%;
    object-fit: cover;
    object-position: 10% 70%;
}
.expert-card .btn-card {
    background-color: #000;
    padding: 6px 25px;
    color: #fff;
    font-family: SofiaMedium;
    font-size: 16px;
    margin-bottom: 10px;
}
.verified-icon {
    position: absolute;
    margin-top: 2px;
    right: 15px;
    top: 0;
}
.card-practice-area {
    margin-top: 10px;
    margin-bottom: 10px;
}
.card-practice-area li {
    font-family: SofiaLight;
    color: #000;
    font-size: 12px;
    border: 1.2px solid #ff337d;
    background: #fff3f3;
    padding: 4px 5px;
    border-radius: 25px;
    margin:5px 2px;
}
.equal-heaight-box {
    height: 150px;
    margin-bottom: 0px;
}
.container-hero-slider {
    margin-top: 20px;
}
.hero-top-offset {
    margin-top: 3%;
}
.hero-heading {
    font-size: 40px;
    font-family: EinaBold;
    color: #000;
    line-height: 1.3em;
    margin-top: 12%;
    margin-bottom: 20px;
}
.product-search-box {
    margin-top: 20px;
    margin-bottom: 10px;
}
.product-search-box .input-lg {
    /* padding: 30px 16px; */
    padding: 10px 10px;
}
.product-search-box .form-control {
    border: none;
    padding-left: 40px;
    box-shadow: none;
    font-size: 18px;
}
.quote-panel {
    border: none;
    padding: 20px;
    background-color: #FFEED9;
}
.header-search-bar .search-icon, .product-search-box .search-icon {
    font-size: 18px;
    color: #ff337d;
  }
  .header-search-bar .search-icon, .product-search-box .search-icon {
    left: 15px;
    top: 10px;
    position: absolute;
    width: 24px;
    height: 24px;
    z-index: 10;
  }
.product-search-box .search-icon {
    font-size: 18px;
    color: #ff337d;
}
.product-search-box .search-icon {
    left: 10px;
    top: 10px;
    position: absolute;
    /* width: 24px;
    height: 24px; */
}
.search-product {
    position: absolute;
    z-index: 99;
    width: 95%;
    /*-webkit-box-shadow: 0 3px 13px 0 rgba(60,60,60,.4);*/
    /*box-shadow: 0 3px 13px 0 rgba(60,60,60,.4);*/
    /*border-radius: 3px;*/
    /*border: 1px solid #eee;*/
    /*padding: 0px;*/
    /*background-color: #fff;*/
}
.search-product .list-group-item{
    /*max-height: 350px;*/
    /*overflow: hidden;*/
    /*overflow-y: auto;*/
    padding: 6px 15px;
}
.search-product .list-group-item > a{
    /*border-radius: 0px;*/
    font-size: 16px;
    font-family: SofiaLight;
    color: #000;
}
.search-product .list-group-item > a:hover,
.search-product .list-group-item > a:focus{
    color: #ff337d;
}
.search-list-head{
    margin-bottom: 0px;
    border-bottom: 0px solid #e8eef3!important;
    font-size: 15px;
    font-family: EinaBold;
    margin-top: 0px;
    /*margin-bottom: 10px;*/
    padding-bottom: 3px;
}
.expert-card .search-list-head{
    border-bottom: 1px solid #e8eef3!important;
    margin-bottom: 10px;
}
.search-expert-dp{
    width: 40px!important;
    height: 40px!important;
    border-radius: 50%;
    object-fit: cover;
    object-position: 0% 0%;
}
.search-expert .media-heading{
    margin-top: 10px;
    font-size: 16px;
    font-family: SofiaLight;
}
.search-expert .media{
    margin-top: 10px;
}
.search-expert .blank-dp{
    width: 40px!important;
    height: 40px!important;
    line-height: 28px;
}
.search-expert .blank-dp>span {
   font-size: 16px!important;
}
.hero-main {
    position: relative;
    /*background: #fff;*/
    /*margin-top: 60px !important;*/
    -webkit-box-shadow: 0 3px 5px 1px rgba(0, 0, 0, 0.06);
    box-shadow: 0 3px 5px 1px rgba(0, 0, 0, 0.06);
    display: flex;
}
.hero-main .signup-form {
    margin-top: 15px;
}
.hero-main .signup-form .dropdown button {
    font-size: 16px !important;
    border: 1px solid transparent !important;
    padding: 10px;
    background-position: 98% 55%;
    background-color: #fff;
    border-radius: 4px;
}
.hero-main .signup-form .dropdown button:hover,
.hero-main .signup-form .dropdown button:focus {
    background-color: #fff;
}
.hero-main-container {
    background-color: #e5f3ff;
    /* height: 600px; */
    display: flex;
    align-items: center;
    padding: 50px 50px;
}
.img-responsive.banner-img{
    width:90%;
    margin: auto;
}
/*.img-responsive{*/
    /*filter: drop-shadow(10px 10px 4px #ccc);*/
/*}*/
.hero-main-container .text-primary {
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    line-height: 1.4em;
}
.hero-bottom-margin {
    margin-bottom: 40px;
}
.assurance-list {
    margin-top: 2%;
    margin-bottom: 2%;
}
.assurance-list li {
    color: #000;
    font-size: 12px;
    font-family: SofiaRegular;
    margin-left: 0px;
    margin-right: 10px;
}
.hero-left-offset {
    margin-left: 5%;
}
.hero-main:after {
    content: '';
    background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, .3)));
    background: -webkit-linear-gradient(top, transparent, rgba(0, 0, 0, .3));
    background: linear-gradient(180deg, transparent, rgba(0, 0, 0, .3));
    opacity: .4;
    height: 120px;
    width: 100%;
    position: absolute;
    z-index: 0;
    bottom: 0;
}
.hero-background.orange-triangle {
    border-bottom-color: #cff0f8;
}
.hero-background {
    position: absolute;
    bottom: 0;
    right: 0;
    border-bottom: 30vw solid;
    border-left: 80vw solid transparent;
}
#carousel_slider .carousel-inner .item {
    -webkit-transition-property: opacity;
    transition-property: opacity;
}
#carousel_slider .carousel-inner .item,
#carousel_slider .carousel-inner .active.left,
#carousel_slider .carousel-inner .active.right {
    opacity: 0;
}
#carousel_slider .carousel-inner .active,
#carousel_slider .carousel-inner .next.left,
#carousel_slider .carousel-inner .prev.right {
    opacity: 1;
}
#carousel_slider .carousel-inner .next,
#carousel_slider .carousel-inner .prev,
#carousel_slider .carousel-inner .active.left,
#carousel_slider .carousel-inner .active.right {
    left: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
#carousel_slider .carousel-control {
    z-index: 2;
}
.carousel-indicators .active {
    width: 20px;
    height: 15px;
    margin: 0;
    background-color: #000;
    border: none;
    border-radius: 4px;
}
#carousel_slider .carousel-inner .item {
    opacity: 0;
    -webkit-transition: opacity 5s ease;
    -o-transition: opacity 5s ease;
    transition: opacity 5s ease;
    -webkit-transform: translate3d(0, 0, 0) !important;
    transform: translate3d(0, 0, 0) !important;
}
#carousel_slider .carousel-inner .active {
    opacity: 1;
}
#carousel_slider .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
    left: 0;
    opacity: 0;
    z-index: 1;
}
@media all and (transform-3d),
(-webkit-transform-3d) {
    #carousel_slider .carousel-inner > .item.next,
    #carousel_slider .carousel-inner > .item.active.right {
        opacity: 0;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -webkit-transition: opacity 0.6s ease-in-out;
        -o-transition: opacity 0.6s ease-in-out;
        transition: opacity 0.6s ease-in-out;
    }
    #carousel_slider .carousel-inner > .item.prev,
    #carousel_slider .carousel-inner > .item.active.left {
        opacity: 0;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -webkit-transition: opacity 0.6s ease-in-out;
        -o-transition: opacity 0.6s ease-in-out;
        transition: opacity 0.6s ease-in-out;
    }
    #carousel_slider .carousel-inner > .item.next.left,
    #carousel_slider .carousel-inner > .item.prev.right,
    #carousel_slider .carousel-inner > .item.active {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -webkit-transition: opacity 0.6s ease-in-out;
        -o-transition: opacity 0.6s ease-in-out;
        transition: opacity 0.6s ease-in-out;
    }
}
#carousel_slider .carousel-caption .text-white {
    font-size: 18px;
    color: #fff;
    line-height: 1.6em;
    font-family: SofiaRegular;
}
#carousel_slider .carousel-caption .fa-star {
    color: gold;
    font-size: 18px;
    margin-left: 3px;
}
.carousel,
.carousel-inner,
.carousel-inner .item {
    height: 100%;
}
#carousel_slider .carousel-caption {
    text-align: left;
    left: 30%;
    bottom: 0 !important;
    padding-bottom: 15px;
    width: 100%;
}
#carousel_slider {
    z-index: 1;
    margin-top: 0px;
}
#carousel_slider .carousel-inner > .item > img {
    position: absolute;
    right: 10px;
    margin-top: 10px;
}
#carousel_slider .item:nth-child(1) {
    background: none;
    height: 400px;
    width: 100%;
}
#carousel_slider .item:nth-child(2) {
    background: none;
    height: 400px;
    width: 100%;
}
#carousel_slider .item:nth-child(3) {
    background: none;
    height: 400px;
    width: 100%;
}
#carousel_slider .item:nth-child(4) {
    background: none;
    height: 400px;
    width: 100%;
}
#carousel_slider .item:nth-child(5) {
    background: none;
    height: 400px;
    width: 100%;
}
#owl-crousel-slider {
    position: relative;
}
#owl-crousel-slider .carousel-caption {
    position: relative!important;
    margin-top: -50px;
    width: 80%;
    left: 0;
    padding: 2px 10px;
    text-align: left;
    box-shadow: 0 2px 4px 0 rgba(152, 152, 152, 0.5);
    background-color: #ffffff;
    border-radius: 6px;
}
#owl-crousel-slider .item > .slider-crousel img {
    position: relative;
    width: 92%;
    margin-left: 8%;
}
.caption-para img {
    display: inline-block!important;
    width: 20px!important;
    margin-left: 10px!important;
    vertical-align: top!important;
}
.caption-name {
    font-size: 16px;
    font-family: SofiaSemiBold;
    color: #000;
    text-shadow: none;
    margin-top: 5px;
    margin-bottom: 0px;
}
.caption-name span {
    font-family: SofiaLight;
    font-size: 14px;
}
.caption-para {
    font-size: 16px;
    font-family: SofiaLight;
    color: #000;
    text-shadow: none;
}
.caption-para span {
    font-size: 16px;
    font-family: SofiaRegular;
    color: #000;
    text-shadow: none;
}
.animated {
    -webkit-animation-duration: 1000ms;
    animation-duration: 1000ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.owl-animated-out {
    z-index: 1
}
.owl-animated-in {
    z-index: 0
}
.fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
}
@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
.box-hover-container {
    position: relative;
    border: 1px solid #e8eef3;
    height: 400px;
    padding: 30px 50px;
    text-align: center;
    margin-bottom: 40px;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
}
.box-hover-effect {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    padding: 30px 50px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.images-black {
    margin: auto;
    margin-top: 15px;
}
.images-white {
    margin: auto;
    display: none;
    margin-top: 15px;
}
.box-hover-effect:hover {
    background-color: #ff337d;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
    -webkit-transition: background-color 800ms linear;
}
.why-hire .col-md-4:nth-of-type(1) .box-hover-effect:hover {
    background-color: #320583 !important;
}
.why-hire .col-md-4:nth-of-type(2) .box-hover-effect:hover {
    background-color: #000000 !important;
}
.why-hire .col-md-4:nth-of-type(3) .box-hover-effect:hover {
    background-color: #01ACA3 !important;
}
.why-hire .col-md-4:nth-of-type(4) .box-hover-effect:hover {
    background-color: #021548 !important;
}
.why-hire .col-md-4:nth-of-type(5) .box-hover-effect:hover {
    background-color: #043D30 !important;
}
.why-hire .col-md-4:nth-of-type(6) .box-hover-effect:hover {
    background-color: #C10168 !important;
}
.box-hover-effect:hover p {
    color: #fff;
}
.box-hover-effect:hover .images-black {
    display: none;
}
.box-hover-effect:hover .images-white {
    display: block;
}
.para-heading {
    color: #000;
    font-family: SofiaRegular;
    font-size: 16px;
    margin-top: 25px;
    margin-bottom: 20px;
}
.para-sub-heading {
    color: #000;
    font-family: SofiaLight;
    font-size: 16px;
    line-height: 1.5em;
}
/* ==========================================Hover section=================================================== */
.header-bottom-margin{
    margin-bottom: 70px;
}
.with-second-nav-margin{
    margin-bottom: 65px;
}
.page-first-section {
    /*margin-top: 60px;*/
    padding-top: 50px;
    padding-bottom: 10px;
    margin-bottom: 30px;
}
.banner-section {
    padding-bottom: 0px;
    margin-bottom: 0px;
    /*margin-top: 40px;*/
    min-height: 200px;
}

.stepper-horizontal{
    display: flex;
    align-items: center;
    background: #fff;
    height: 50px;
    padding:10px 10px;
    justify-content: left;
    margin-left: 0px;
    margin-bottom: 0px;
    padding-inline-start: 0px;
}
.stepper-horizontal li{
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    font-family: SofiaRegular;
    color: #000;
    padding: 0px;
}
.stepper-horizontal li+li{
    margin-left: 10px;
}
.stepper-horizontal li span{
    background: #e8f2ff;
    color: #252dde;
    font-size: 14px;
    border: 1.5px solid #252dde;
    padding: 5px 10px;
    border-radius: 25px;
}
.stepper-horizontal li span img{
    width:20px;
    vertical-align: middle;
    margin-right: 5px;
}
.stepper-horizontal li span .fa-star{
    color: gold;
}
.stepper-horizontal li span .fa-shield{
    color: green;
}
.hero-main-container .stepper-horizontal{
    background: transparent;
}
.sticky-tab-bar {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    bottom: 50px;
    z-index: 100;
}
.sticky-tab-bar .hero-client-container {
    position: relative!important;
    height: auto!important;
    line-height: normal;
    background-color: #fff7f2;
}
.banner-section .assurance-list {
    margin-top: 2%;
    margin-bottom: 2%;
}
.page-contant {
    padding-top: 20px;
    padding-bottom: 10px;
    margin-bottom: 30px;
}
.scope-container {
    background-color: #fff;
    padding: 0px 0px;
    margin: 0px 15px;
    margin-bottom: 50px;
}
.scope-border {
    height: 75px;
    border-left: 3px solid #ff337d;
    padding: 0px 20px;
}
.scope-heading {
    font-size: 28px;
    font-family: EinaBold;
    color: #000;
    line-height: 1.4em;
}
.scope-sub-heading {
    font-size: 18px;
    font-family: EinaBold;
    color: #000;
}
#owl-crousel-slider .scope-sub-heading {
    margin-top: 10px;
    margin-bottom: 0px;
}
.scope-para {
    font-size: 16px;
    color: #000;
    line-height: 1.6em;
    font-family: SofiaLight;
}
.container-section {
    background-color: #000;
    padding: 50px 0px;
    margin: 0px 15px;
}
.page-contant .text-muted {
    color: #000;
}
.why-hire .heading-lg {
    font-size: 48px;
}
.heading-lg {
    color: #000;
    font-family: EinaBold;
    font-size: 56px;
    margin-top: -5px;
    margin-bottom: 20px;
}
.heading-sm {
    color: #000;
    font-size: 30px;
    line-height: 1.3em;
    font-family: EinaBold;
    margin-top: 0px;
    margin-bottom: 30px;
}
.heading-sm .btn-link {
    color: #2d3feb;
    font-size: 18px;
    font-family: EinaBold;
    margin-top: 10px;
}
.page-contant .heading-md {
    color: #000;
    font-family: EinaBold;
    margin-top: 30px;
    margin-bottom: 30px;
}
.heading-sub-md {
    color: #000;
    font-family: EinaBold;
    margin-top: 50px;
    margin-bottom: 20px;
}
.xs-para {
    font-size: 18px;
    color: #101010;
    line-height: 1.5em;
    margin-bottom: 15px;
    font-family: SofiaLight;
}
.top-offset-1 {
    margin-top: 2%;
}
.col-border::after {
    content: '';
    position: absolute;
    top: 20px;
    height: 150px;
    width: 1px;
    background-color: #676767;
}
.page-contant .job-search .signup-form {
    margin-top: 30px;
}
.page-contant .job-search .signup-form .dropdown button {
    font-size: 14px;
    border: 1px solid #676767 !important;
    background: url('../images/down-arrow.png') no-repeat;
    background-position: 99% 70%;
    background-size: 12px;
}
.btn-enterprise {
    background-color: #fff;
    border: 1px solid #fff;
    /*border: 1px solid #000;*/
    /*border-image-source: linear-gradient(to right, #fb50a4, #ff337d 50%);*/
    /*border-image-slice: 20;*/
    padding: 14px 35px;
    border-radius: 6px;
    font-size: 20px;
    color: #000;
    font-family: SofiaMedium;
    letter-spacing: 1px;
    margin-left: 0px;
    margin-right: 20px;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
}
.btn-enterprise:hover,
.btn-enterprise:focus {
    /*background: -webkit-linear-gradient(to left, #fb50a4, #ff337d);*/
    /*background: linear-gradient(to left, #fb50a4, #ff337d);*/
    color: #000;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
}
.btn-job-post {
    background-color: #ff337d;
    /*background: -webkit-linear-gradient(to left, #fb50a4, #ff337d);*/
    /*background: linear-gradient(to left, #fb50a4, #ff337d);*/
    padding: 12px 35px;
    color: #fff;
    font-family: SofiaMedium;
    border-radius: 4px;
    font-size: 18px;
}
.btn-job-post:hover,
.btn-job-post:focus {
    background-color: #ff337d;
    color: #fff;
    outline: 0px;
}
.hero-main .btn-job-post {
    margin-left: 0px;
    margin-right: 0px;
}
.job-search .form-control {
    box-shadow: none;
    border-radius: 0px;
    color: #000;
    font-family: SofiaLight;
}
.job-search .form-group > input::-webkit-input-placeholder {
    color: #101010 !important;
    font-size: 18px;
    font-family: SofiaLight;
}
.job-search .form-group > input:-moz-placeholder {
    color: #101010 !important;
    font-size: 18px;
    font-family: SofiaLight;
}
.job-search .form-group >::-moz-placeholder {
    color: #101010 !important;
    font-size: 18px;
    font-family: SofiaLight;
}
.col-padding {
    padding-right: 60px;
}
.why-become-partner{
    background: #fff3f3;
    padding-top: 30px;
    padding-bottom: 40px;
}
.column-padding{
padding:10px 20px;
}
.column-padding .xs-para{
   font-size: 18px;
   line-height: 1.4em;
}
.emoji{
    font-size: 28px;
    margin-top: 15px;
}
.col-padding-sm {
    height: 550px;
}
@media (min-width: 768px) {
    .grid-divider {
        position: relative;
        padding: 0;
        /*margin-top: 30px;*/
        /*width: 90%;*/
        /*margin-left: 7%;*/
    }
    .grid-divider >[class*='col-'] {
        position: static;
    }
    .grid-divider >[class*='col-']:nth-child(n+2):before {
        content: "";
        border-left: 1px solid #e8eef3;
        position: absolute;
        top: 0;
        bottom: 0;
    }
    .grid-divider > .col-sm-4:nth-of-type(1) .col-padding {
        padding-right: 0px;
        margin-left: 0px;
        min-height: 360px;
    }
    .grid-divider > .col-sm-4:nth-of-type(2) .col-padding {
        padding: 0px 20px;
        margin-left: 20px;
        min-height: 360px;
    }
    .grid-divider > .col-sm-4:nth-of-type(3) .col-padding {
        padding: 0px 20px;
        margin-left: 20px;
        min-height: 360px;
    }
    .grid-divider > .col-sm-4 > .col-padding:nth-of-type(2) {
        margin-top: 50px;
    }
}
#dashbord-ui {
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 10px 20px, rgba(0, 0, 0, 0.2) 0px 0px 1px;
    padding: 0px;
}
#dashbord-ui .circle-dot1 .fa-circle {
    color: #ff337d;
    font-size: 6px;
}
#dashbord-ui .circle-dot1 {
    top: 10px;
    right: 45px;
    position: absolute;
}
#dashbord-ui .circle-dot2 .fa-circle {
    color: #ff337d;
    font-size: 6px;
}
#dashbord-ui .circle-dot2 {
    top: 5px;
    right: 53px;
    position: absolute;
}
#dashbord-ui .col-xs-10 {
    padding: 20px;
}
#dashbord-ui .ui-sidenav {
    padding: 20px 0px;
    height: 500px;
    background-color: #fff;
    border-left: 1px solid #e8eef3;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
#dashbord-ui .col-xs-2 .img-size {
    height: 35px;
    width: 35px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: -5px;
}
#dashbord-ui .col-xs-2 .name {
    color: #000;
    font-family: SofiaLight;
    margin-left: 5px;
    font-size: 12px;
}
#dashbord-ui .list-inline > li > h3 {
    color: #000;
    font-family: SofiaLight;
    margin-left: 20px;
}
#dashbord-ui .list-inline > li > p {
    color: #979797;
    font-family: SofiaLight;
    margin-bottom: -20px;
    margin-left: 20px;
    margin-top: -5px;
    font-size: 75%;
}
.post-button {
    background-color: #fff;
    color: #ff337d;
    border: 1.2px solid #ff337d;
    font-size: 12px;
    border-radius: 4px;
    padding: 8px 15px;
}
#dashbord-ui .pull-right img {
    width: 25px;
    margin-left: 30px;
}
#dashbord-proposal-list {
    margin-top: 10px;
}
#dashbord-proposal-list h4 {
    color: #000;
    font-family: SofiaLight;
    border-bottom: 1px solid #e8eef3;
    margin-bottom: 30px;
    padding-bottom: 5px;
    font-size: 16px;
}
#dashbord-proposal-list .media-object {
    height: 60px;
    width: 60px;
    object-fit: cover;
}
#dashbord-proposal-list .media-heading {
    color: #000;
    font-family: SofiaLight;
    font-size: 18px;
}
#dashbord-proposal-list .alert-default {
    border: 1px solid #e8eef3;
    background-color: #fff;
}
#dashbord-proposal-list .alert-default .text-muted {
    font-size: 14px;
}
#dashbord-proposal-list .alert-default .btn-login {
    color: #ff337d;
    background-color: #fff;
    border: 1px solid #ff337d;
    padding: 5px 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    cursor: default;
}
#dashbord-proposal-list .alert-default .btn-login:nth-last-child(1) {
    color: #213FD4;
    background-color: #fff;
    border: 1px solid #213FD4;
    padding: 5px 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: default;
}
#dashbord-proposal-list .alert-default a {
    color: #ff337d;
}
#dashbord-proposal-list .alert-default a:hover {
    text-decoration: none;
}
#dashbord-proposal-list h4 span {
    color: #ff337d;
    font-size: 16px;
}
#dashbord-proposal-list h4 small {
    margin-left: 20px;
}
#dashbord-proposal-list h4 span {
    margin-right: 50px;
    color: #ff337d;
}
.twPc-bg {
    background-color: #000;
    background-position: 0 50%;
    background-size: 100% auto;
    border-bottom: 1px solid #e8eef3;
    border-radius: 4px 4px 0 0;
    height: 70px;
    width: 100%;
}
.dp-image {
    width: 65px;
    height: 65px;
    margin-left: 10px;
    margin-top: 15px;
}
.text-INR {
    margin-top: 0px;
    font-size: 18px;
    text-align: center;
    color: #fff;
    line-height: 1.2em;
    font-family: SofiaLight;
}
.text-INR small {
    font-size: 12px;
}
#dashbord-proposal-list {
    height: 400px;
    overflow: auto;
    margin-right: -20px;
}
#dashbord-proposal-list:hover {
    overflow-y: auto;
}
#dashbord-proposal-list .panel {
    margin-bottom: 50px;
}
#dashbord-proposal-list .panel,
#dashbord-proposal-list .panel-body {
    padding: 0px;
}
#dashbord-proposal-list .list-inline {
    margin-left: -5px;
    margin-top: 25px;
}
#dashbord-proposal-list .list-inline > li {
    color: #000;
    font-size: 16px;
    font-family: SofiaLight;
    margin-left: 0px;
}
#dashbord-proposal-list .text-INR {
    font-size: 16px;
    margin-top: -20px;
    width: 100%;
    margin-right: 5px;
}
#dashbord-proposal-list .dp-image {
    width: 60px;
    height: 60px;
}
#dashbord-proposal-list .list-inline > li .fa-star {
    font-size: 10px;
    margin-left: 2px;
    color: gold;
}
#dashbord-proposal-list .list-inline li small {
    color: #000;
}
#dashbord-proposal-list .list-inline li > a {
    color: #000;
}
#dashbord-proposal-list .para {
    color: #000;
    font-family: SofiaLight;
    font-size: 12px;
    margin-bottom: 10px;
    margin-top: 10px;
}
#dashbord-proposal-list .btn-container {
    border-left: 1px solid #cbcbcb;
    padding-top: 5px;
    height: 100px;
    margin-top: 15px;
}
#dashbord-proposal-list .btn-container img {
    width: 20px;
    height: 20px;
    border-radius: 0px;
    margin-top: 10px;
}
#dashbord-proposal-list .btn-pink {
    background-color: #fff;
    border: 1px solid #ff337d;
    color: #ff337d;
    padding: 2px 10px;
    letter-spacing: 1px;
}
#dashbord-proposal-list::-webkit-scrollbar {
    width: 8px;
}
#dashbord-proposal-list::-webkit-scrollbar-track {
    background: #f1f1f1;
}
#dashbord-proposal-list::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
}
#dashbord-proposal-list::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.navi {
    margin-top: 30px;
}
.navi img {
    width: 10px;
}
.navi i {
    font-size: 20px;
}
.navi p {
    border-bottom: 0px solid #0d172e;
    border-top: 0px solid #0d172e;
    color: #000;
    display: block;
    font-size: 12px;
    font-family: SofiaLight;
    font-weight: 500;
    padding: 15px 15px;
    /* margin-left:30px; */
    text-decoration: none;
}
.navi p span {
    margin-left: -10px;
}
.navi i {
    margin-right: 15px;
    color: #5584ff;
}
.navi img {
    width: 20px;
    margin-right: 20px;
    margin-top: -5px;
}
.navi .active {
    background-color: #1a1b1b;
}
.navi .active p {
    background: transparent;
    color: #ff337d;
    font-family: SofiaLight;
    font-size: 12px;
    border-left: 0px solid #5584ff;
}
#aks:hover {
    /*content: url('../images/file-folder-icon.png');*/
}
.navi ul:not(collapsed) .arrow:before,
.navi li:not(collapsed) .arrow:before {
    font-family: FontAwesome;
    content: "\f107";
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
    vertical-align: middle;
    font-size: 14px;
    float: right;
}
.text-normal {
    color: #000;
    line-height: 1.5em;
    font-family: SofiaLight;
}
.text-normal-para {
    color: #000;
    font-size: 20px;
    line-height: 1.4em;
    font-family: SofiaLight;
}
.text-primary {
    font-family: SofiaMedium;
    color: #000;
    font-size: 18px;
    line-height: 1.4em;
}
.text-normal-heading {
    font-size: 22px;
    font-family: EinaBold;
}
.heading-top-margin {
    margin-top: 100px;
}
.dash-list {
    margin-left: 25px;
}
.dash-list li {
    color: #fff;
    font-family: SofiaLight;
    margin-top: 50px;
    line-height: 1.5em;
    font-size: 16px;
}
.dash-list li:before {
    content: " ";
    position: absolute;
    height: 2px;
    width: 20px;
    background-color: #fff;
    left: 10px;
    margin-top: 8px;
}
.mokeup-img-shadow {
    box-shadow: 0 9px 24px rgba(0, 0, 0, .12), 0 9px 24px rgba(0, 0, 0, .12);
}
.img-shadow {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 10px 20px, rgba(0, 0, 0, 0.2) 0px 0px 0px;
}
.left-offset-1 {
    margin-left: 6%;
}
.custum-img-width {
    width: 86%;
    padding: 0px;
}
.c-img-margin {
    margin-left: 15px
}
.error {
    color: #ef0934;
    font-family: EinaBold;
}
.why-wiz-container {
    background-color: #0c202f;
    padding-bottom: 60px;
    padding-left: 50px;
    margin: 15px;
}
.communication-container {
    background-color: #000;
    padding: 50px;
    margin: 15px;
}
.communication-container .text-normal {
    color: #fff;
    font-size: 18px;
}
.communication-container .heading-sm {
    font-size: 40px;
    font-family: EinaBold;
}
.video-audio-mokeup .left-offset-1 {
    margin-left: 5%;
}
.video-audio-mokeup {
    height: 650px;
    background-color: #002c28;
    padding: 50px;
    margin: 15px;
    margin-bottom: 50px;
}
.video-audio-mokeup .text-normal {
    color: #fff;
    font-size: 18px;
}
.video-audio-mokeup .heading-sm {
    font-size: 40px;
    font-family: EinaBold;
    line-height: 1.5em;
}
.work-container {
    padding-top: 20px;
    padding-bottom: 50px;
}
.work-container .neg-margin {
    margin-top: -33px;
}
.service-text {
    color: #101010;
    font-size: 18px;
    margin-left: 50px;
    font-family: SofiaMedium;
}
.service-text:before {
    content: " ";
    position: absolute;
    height: 2px;
    width: 25px;
    background-color: #000;
    left: 25px;
    margin-top: 12px;
}
/* ==========================================END INDEX PAGE=================================================== */

/* ==============================================Listing======================================================= */

.listing-container {
    padding: 0px 60px;
}
.listing-panel .d-accordion .panel-title {
    margin-top: 20px;
    margin-bottom: 10px;
}
.listing-panel .d-accordion p {
    margin-left: 0px;
}
.listing-panel .heading-sm {
    font-family: EinaBold;
    font-size: 22px;
}
.listing-panel .panel {
    box-shadow: 0 2px 10px rgba(0, 0, 0, .1);
    border: 1px solid #e8eef3;
    border-radius: 0px;
    padding: 0px 10px;
    padding-bottom: 10px;
    margin-bottom: 30px;
}
.faqs-list{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.faqs-list li{
    margin-bottom: 20px;
    width: 50%;
    padding: 0px 20px;
}
.text-faq-question {
    font-size: 20px;
    font-family: EinaBold;
    color: #000;
    margin-top: 25px;
    margin-bottom: 10px;
    line-height: 1.4em;
}
.text-faq-para {
    font-size: 18px!important;
    font-family: SofiaLight;
    color: #5F6675!important;
    line-height: 1.5em!important;
    margin-top: 10px;
}
.text-faq-para span{
    font-size: 18px!important;
}
.text-faq-para span a{
    color: #ff337d!important;
}
.text-faq-para span li{
    width: 100%!important;
    margin-bottom: 10px!important;
}
.listing-panel .panel-group.d-accordion .panel {
    box-shadow: none!important;
    border: none !important;
    border-bottom: 1px solid #e8eef3!important;
    margin-bottom: 10px!important;
    padding-bottom: 0px!important;
}
.listing-panel .heading-sm {
    margin-bottom: 30px;
}
.listing-panel .dp-image {
    width: 70px;
    height: 70px;
    margin-left: 0px;
    margin-top: 10px;
    object-position: 10% 0%;
}
.listing-panel .name {
    font-family: EinaBold;
    font-size: 18px;
    margin-top: 10px;
    margin-left: 25px;
}
.listing-panel .name .rating-count {
    font-family: SofiaLight;
    font-size: 14px;
}
.more-info-li {
    padding-inline-start: 0px;
    margin-block-start: 0px;
    margin-left: 0px!important;
    border-bottom: 1px solid #e8eef3;
    margin-top: 5px;
    margin-bottom: 15px;
}
.more-info-li li {
    margin-top: 0px!important;
    margin-bottom: 0px!important;
}
.more-info-li li .span-text {
    font-family: SofiaLight;
    font-size: 16px;
}
.more-info-li li+li {
    margin-left: 30px;
}
.more-info-li li img {
    vertical-align: middle;
    width: 25px;
    height: auto!important;
    margin-left: 0px;
    margin-right: 10px;
}
.text-city {
    font-family: SofiaRegular;
    font-size: 16px;
    margin-left: 25px;
    margin-top: -5px;
}
.text-profile {
    font-family: SofiaMedium;
    font-size: 16px;
    margin-left: 25px;
    margin-top: -5px;
}
.listing-panel .fa-star {
    color: gold;
    margin-left: 2px;
    font-size: 16px;
}
.listing-panel .para {
    margin-left: 25px;
    color: #000;
    font-size: 16px;
    line-height: 1.5em;
    font-family: SofiaLight;
}
.inline-button {
    margin-left: 2%;
    margin-top: 25px;
}
.listing-panel .list-inline {
    margin-left: 10px;
}
.listing-panel .list-inline li {
    margin-top: 10px;
    margin-bottom: 10px;
}
.inline-button .btn-link {
    color: #000;
    font-family: SofiaSemiBold;
    font-size: 16px;
    text-decoration: none;
}
.li-practice-area {
    padding: 5px;
    border: 1.2px solid #000;
    font-family: SofiaLight;
    color: #000;
    border-radius: 4px;
    font-size: 14px;
}
/*.li-practice-area:hover,*/

/*.li-practice-area:focus{*/

/*color:#000!important;*/

/*}*/

.listing-panel .li-practice-area {
    padding: 5px;
    border: 1.2px solid #2d3feb;
    font-family: SofiaLight;
    color: #2d3feb;
    margin-left: 10px;
    border-radius: 4px;
    font-size: 14px;
}
.listing-panel .li-practice-area:hover,
.listing-panel .li-practice-area:focus {
    color: #2d3feb;
}
.btn-job-invite {
    background-color: #000;
    padding: 8px 15px;
    font-family: SofiaLight;
    color: #fff;
    margin-left: 50px;
    border-radius: 0px;
    font-size: 16px;
}
.btn-job-invite:hover,
.btn-job-invite:focus {
    background-color: #fff;
    border: 1.2px solid #000;
    color: #000;
}
.listing-panel .top-margin {
    margin-top: 10%;
}
.listing-panel .list-unstyled li {
    color: #000;
    font-family: SofiaLight;
    line-height: 2em;
    font-size: 14px;
}
.listing-panel .list-unstyled li > img {
    margin-right: 10px;
    width: 15px;
}
.left-offset-margin {
    margin-left: 4%;
}
.question-form .form-control {
    box-shadow: none;
    border-radius: 0px;
    border: none;
    padding-left: 0px;
    padding-top: 20px;
    padding-bottom: 0px;
    border-bottom: 1.2px solid #e8eef3;
}
.question-form .form-group a {
    font-style: italic;
    margin-top: 5px;
    font-size: 16px;
    color: #000;
    font-family: SofiaLight;
}
.question-form .form-group a:hover {
    color: #ff337d;
}
.question-form .form-group select {
    color: #585858 !important;
    font-size: 18px;
    font-family: SofiaLight;
}
.question-form .form-group > input::-webkit-input-placeholder {
    color: #585858 !important;
    font-size: 18px;
    font-family: SofiaLight;
}
.question-form .form-group > input:-moz-placeholder {
    color: #585858 !important;
    font-size: 18px;
    font-family: SofiaLight;
}
.question-form .form-group >::-moz-placeholder {
    color: #585858 !important;
    font-size: 18px;
    font-family: SofiaLight;
}
.question-form textarea {
    margin-top: 30px;
    border: 1.2px solid #e8eef3;
    width: 100%;
    /* resize: none; */
}
.question-form .btn-submit {
    background-color: #000;
    border-radius: 0px;
    padding: 5px 25px;
    color: #fff;
    font-family: SofiaLight;
    font-size: 16px;
}
.btn-search-consultant {
    background-color: #000;
    padding: 12px 25px;
    color: #fff;
    font-size: 16px;
    font-family: SofiaLight;
    float: right;
}
.btn-search-consultant:hover,
.btn-search-consultant:focus {
    color: #fff;
    background-color: #000;
}
.filter-box {
    margin-left: 7%;
}
.filter-form .form-control {
    box-shadow: none;
    border: 1px solid #e8eef3;
}
.filter-form .form-group {
    margin-top: 30px;
}
.filter-form .form-group label {
    font-family: SofiaLight;
    font-weight: normal;
    font-size: 16px;
}
.btn-filter {
    background-color: #ff337d;
    padding: 5px 20px;
    color: #fff;
    font-size: 16px;
    font-family: SofiaLight;
    margin-top: 20px;
}
.btn-reset {
    background-color: #fff;
    padding: 5px 20px;
    border: 1px solid #000;
    color: #000;
    font-size: 16px;
    font-family: SofiaLight;
    margin-left: 15px;
    margin-top: 20px;
}
.btn-filter:hover,
.btn-filter:focus {
    color: #fff;
    background-color: #ff337d;
}
.btn-reset:hover,
.btn-reset:focus {
    color: #000;
    background-color: #fff;
}
.filter-text {
    font-family: SofiaMedium;
    font-size: 16px;
}
.filter-ul {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}
.price-range-filter{
    /*border: 1px solid red;*/
    width: 20%;
}
.filter-ul li+li {
    margin-left: 10px;
}
.filter-ul .form-control {
    border: 1.2px solid #000;
    font-family: SofiaMedium;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url('../images/down.png') no-repeat;
    background-color: #fff;
    background-position: 96% 50%;
    background-size: 12px;
}
.btn-price-filter {
    background-color: #fff;
    color: #000;
    border: 1.2px solid #000;
    font-family: SofiaMedium;
    font-size: 14px;
}
.btn-price-filter:hover,
.btn-price-filter:focus {
    background-color: #ff337d;
    color: #fff;
    border: 1.2px solid #ff337d;
}
.btn-filter-reset {
    background-color: #2d3feb;
    padding: 5px 20px;
    color: #fff;
    font-size: 16px;
    font-family: SofiaMedium;
}
.btn-filter-reset:hover,
.btn-filter-reset:focus {
    color: #fff;
}
.btn-filter-apply {
    background-color: #2d3feb;
    color: #fff;
    margin-left: 10px;
    font-family: SofiaLight;
    font-size: 14px;
}
.btn-filter-apply:hover,
.btn-filter-apply:focus {
    color: #fff;
}
.listing-panel-border:after {
    content: '';
    position: absolute;
    top: 30px;
    right: -60px;
    height: 450px;
    width: 1px;
    background-color: #e8eef3;
}
.listing-panel .text-bold {
    margin-top: 20px;
    font-size: 18px;
}
.listing-panel .btn-book {
    margin-bottom: 20px;
}
.listing-panel .btn-container {
    margin-top: 30px;
}
.listing-panel .blank-dp {
    height: 65px !important;
    width: 65px !important;
    padding-top: 20px !important;
    margin-top: 10px !important;
}
.btn-share {
    border: 1.2px solid #ff337d;
    color: #ff337d;
    padding: 6px 20px;
    font-family: SofiaRegular;
    font-size: 16px;
    width: 100%;
}
.btn-share:hover,
.btn-share:focus {
    border: 1.2px solid #ff337d;
    color: #fff;
    background-color: #ff337d;
}
.listing-panel .btn-container .btn-book {
    background-color: #ff337d;
    color: #fff;
    width: 100%;
    padding: 8px 20px;
    font-family: SofiaRegular;
    font-size: 16px;
}
.filter-btn-container {
    margin-top: 20px;
}
.filter-btn-container .btn-book {
    background-color: #ff337d;
    color: #fff;
    padding: 8px 20px;
    font-family: SofiaRegular;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    width: 150px;
    text-overflow: ellipsis;
}
.submit-form-query textarea {
    border: 1px solid #e8eef3;
    border-radius: 4px;
    width: 100%;
    margin-top: 0px;
    padding: 2px 5px;
    color: #000 !important;
    font-family: SofiaLight;
}
.submit-form-query .form-group {
    margin-top: 30px;
    margin-bottom: 50px;
}
.submit-form-query .form-control {
    box-shadow: none;
    border-radius: 0px;
    height: 45px;
    border: 1px solid #e8eef3;
}
.submit-form-query .form-group label {
    color: #000;
    font-weight: normal;
    font-family: SofiaLight;
    font-size: 16px;
}
@media screen and (max-width: 767px) {
    .filter-ul li+li {
        margin-left: 0px;
        padding-right: 3px;
        padding-left: 3px
    }
    .text-normal-heading {
        font-size: 18px;
        font-family: EinaBold;
    }
    .platform-expert-card{
        display: block;
    }
    .faqs-list li{
        width: 100%;
        padding: 0px 10px;
        margin-bottom: 10px;
    }
    .expert-details-box{
        padding-right: 0px;
    }
    .expert-see-details-box{
        border: none;
        padding: 0px;
    }
    .expert-brief-bio{
        font-size: 14px;
    }
    .sales-form{
        padding: 20px;
        margin-top: 20px;
    }
    .sales-form-column{
        padding-left: 0px;
    }
    .expert-rating-reviews li+li{
        margin-left: 10px;
    }
    .expert-btn{
        width: 100%;
    }
    .expert-btn .btn+.btn{
        margin-top: 15px;
    }
    .expert-by-city li{
        margin-bottom: 10px;
        width: 100%;
    }
    .expert-by-city li>a{
        font-size: 14px;
    }
    .city-heading{
        font-size: 16px;
    }
    .more-info-li li .span-text{
        font-size: 14px;
    }
    .msme-products-wrapper {
        width: 100%;
        padding: 0px;
        margin: 0px;
    }
    .msme-products-wrapper .service-grid-cards-heading{
      font-size: 16px;
    }
    .msme-products-wrapper .service-grid-cards-icon img{
        width: 35px;
    }
    .client-img{
        width: 60px!important;
        height: 60px!important;
        margin: 5px 5px;
    }
    .c-reviews-para{
        font-size: 16px;
        margin-bottom: 5px;
    }
    .c-reviews-name{
        font-size: 16px;
    }
    .c-reviews-about{
        font-size: 14px;
    }
    .expert-sample-container{
        padding: 20px 20px;
    }
    .professional-social-ui.for-business-section .media>img{
        width: 20px!important;
    }
    .talk-to-sales-section .assurance-ul li{
        text-indent: -30px;
        margin-left: 25px;
        font-size: 14px;
    }
    .talk-to-sales-section .assurance-ul li>img{
        width: 20px;
    }
    .stepper-horizontal{
        padding:5px 5px;
        height: auto;
        margin-left: 0px;
        text-align: left;
        margin-bottom: 0px;
        white-space: nowrap;
        overflow: hidden;
        overflow-x: auto;
    }
    .sticky-tab-bar .hero-client-container{
        margin-bottom: 10px;
    }
    .stepper-horizontal li{
        font-size: 9px;
    }
    .stepper-horizontal li span img{
        width:15px;
        vertical-align: middle;
        margin-right: 2px;
    }
    .stepper-horizontal li span{
        background: #e8f2ff;
        color: #252dde;
        font-size: 12px;
        border: 1.2px solid #252dde;
        padding: 4px 6px;
        border-radius: 25px;
    }
    .filter-ul .form-control{
        font-size: 12px;
        padding: 5px 5px;
        height: 25px;
        background-position: 97% 54%;
        background-size: 8px;
        line-height: 1.2;
    }
    .filter-ul .btn-filter-reset{
        font-size: 12px;
        padding: 3px 15px;
    }
    .filter-text{
        font-size: 12px;
    }
    .btn-price-filter{
        font-size: 12px;
        padding: 3px 15px;
        margin-bottom: 2px;
    }
    .m-app-box{
        background-color: #FFEED9;
        margin: 10px;
        padding: 20px 5px;
    }
    .filter-ul {
        margin-bottom: 0px;
        margin-top: 0px;
        width: 100%;
        display: block;
        overflow: hidden;
        overflow-x: auto;
        white-space: nowrap;
        height: auto;
        /*border: 1px solid red;*/
        position: relative;
    }
    .price-range-filter{
        /*border: 1px solid red;*/
        width: 45%;
    }
   .price-range-filter .price-range{
       margin-top: -15px;
       position: absolute;
       width:40%;
   }
   .price-range-filter .ui.label{
        font-size: 12px!important;
    }
    .video-section-ui .top-margin {
        margin-top: 5%;
    }
    .selected-professional-card .xs-heading {
        margin-top: 10px!important;
    }
    .top-margin {
        margin-top: 15px;
    }
    .lock-verified-icon {
        width: 20px!important;
    }
    .browse-more-padding {
        background-color: #d2f4e7;
        padding: 20px 0px;
        margin: 0px 0px;
    }
    .why-become-partner{
        background: #fff3f3;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .column-padding .xs-para{
        font-size: 16px;
        line-height: 1.4em;
    }
    .emoji{
        font-size: 24px;
        margin-top: 15px;
    }
    /*.product-link{*/
    /*    -webkit-column-count: 1;*/
    /*    -moz-column-count: 1;*/
    /*    column-count: 1;*/
    /*}*/
    .product-link li>a{
        font-size: 14px;
        color: #000;
        font-family: SofiaRegular;
    }
    .browse-more-link li a {
        font-size: 12px;
    }
    .browse-more-padding .heading-sm {
        font-size: 18px;
    }
    .browse-more-padding .browse-more-heading {
        font-size: 12px;
    }
    .filter-btn-container {
        margin-top: 20px;
    }
    .general-header {
        height: 50px;
        border-bottom: 1px solid #e8eef3;
    }
    .service-ui-box {
        margin-top: 0px;
    }
    .ui-service-work-container {
        padding-left: 0px;
    }
    .ui-service-work-container .text-name {
        margin-top: 0px;
        font-size: 16px;
    }
    .text-para-reviews {
        font-size: 16px;
    }
    .text-primary {
        margin-top: 10px;
        font-size: 14px;
        margin-bottom: 0px;
    }
    .hero-main .text-bold {
        font-size: 12px;
        margin-top: 10px;
        margin-bottom: 5px;
    }
    .quote-panel .btn-job-post {
        font-size: 16px;
    }
    .product-search-box {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .product-search-box .form-group{
        margin-bottom: 5px;
    }
    .product-search-box .input-lg {
        padding: 8px 16px;
    }
    .product-search-box .form-control {
        font-size: 13px;
        padding-left: 30px;
    }
    .product-search-box .search-icon {
        font-size: 16px;
        color: #ff337d;
    }
    .product-search-box .search-icon {
        left: 10px;
        top: 12px;
    }
    .s-how-it-work .text-name {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .work-icon-box {
        height: auto;
        line-height: normal;
    }
    .work-icon-box img {
        width: 40px !important;
    }
    .step-label{
        margin-top: 20px;
    }
    .step-label span{
        padding: 6px 10px;
        font-size: 12px;
    }
    .work-box-padding .scope-sub-heading {
        line-height: 1.3em;
    }
    .talk-to-sales-section{
        margin-top: 0px;
    }
    .btn-for-business{
        padding: 8px 30px;
        background: #ff337d;
        font-size: 16px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .for-business-banner{
        height: 650px;
        background-color: #09133a!important;
    }
    .for-business-banner .text-white-para{
        font-size: 16px;
        margin-top: 15px;
    }
    .gig-assurance-ul{
        flex-wrap: wrap;
    }
    .gig-assurance-ul li{
        width: 45%;
        margin: 10px 5px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .gig-assurance-ul li img{
        max-width: 25px!important;
    }
    .gig-assurance-ul .service-grid-cards-heading{
        font-size: 14px;
        font-family: SofiaMedium;
    }
    /*.for-business-banner .img-responsive.banner-img{*/
        /*margin-bottom: 20px;*/
        /*margin-top: 20px;*/
    /*}*/
    .video-section-ui.for-business-section .text-black-sub-heading{
        font-size: 20px;
        line-height: 1.4em;
        margin-bottom: 10px;
    }
    .video-section-ui.for-business-section .top-offset-2{
        margin-top: 0%;
    }
    .for-business-banner .img-responsive.banner-img{
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .m-center-btn .btn-for-business{
        font-size: 14px;
        padding: 7px 8px;
    }
    .m-center-btn .btn-for-business .fa{
        font-size: 16px;
        margin-right: 2px;
        vertical-align: top;
    }
    .m-center-btn .btn-for-business+.btn-for-business{
        margin-left: 5px;
    }
    .talk-to-sales-form{
        margin-top: 0px;
        margin-bottom: 10px;
        box-shadow: none;
        padding: 15px 10px 35px 10px;
    }
    .talk-to-sales-form .scope-sub-heading{
        font-size: 18px;
    }
    .talk-to-sales-form .price .form-group .input-lg{
        font-size: 16px;
        height: 40px;
    }
    .talk-to-sales-form .price .form-group{
        margin-bottom: 20px;
    }
    .scope-sub-heading {
        font-size: 16px;
        margin-top: 10px;
    }
    .work-box-padding {
        margin-top: 20px;
        margin-bottom: 10px;
    }
    .work-box-padding .xs-para {
        font-size: 12px;
    }
    .container-hero-slider {
        position: relative;
        bottom: 0;
        right: 0;
    }
    .equal-heaight-box {
        height: auto;
        margin-bottom: 10px;
    }
    #carousel_slider {
        margin-top: 10px;
    }
    .hero-main-container {
        height: auto;
        padding: 0px;
        padding-bottom: 10px;
    }
    .img-responsive.banner-img{
        width:100%;
        margin-bottom: 40px;
    }
    .hero-main-container .text-primary {
        font-size: 16px;
        margin-bottom: 10px;
        line-height: 1.4em;
    }
    .hero-left-offset {
        margin-left: 0%;
    }
    .hero-heading {
        margin-top: 40px;
        font-size: 26px;
        margin-bottom: 20px;
    }
    .ui-new-list {
        text-align: center;
    }
    .ui-new-list .service-text {
        margin-left: 0px;
        font-size: 12px!important;
    }
    .ui-new-list .service-text:before {
        left: 10px;
    }
    .hero-heading span {
        font-size: 18px;
    }
    .assurance-list li {
        font-size: 9px;
        margin-right: 0px;
        margin-bottom: 5px;
    }
    .hero-bottom-margin {
        margin-bottom: 20px;
    }
    .listing-container {
        padding: 0px 15px;
        margin-left: 1%;
    }
    .listing-panel .dp-image {
        height: 60px;
        width: 60px;
    }
    .btn-share {
        border: 1.2px solid #ff337d;
        color: #ff337d;
        padding: 5px 20px;
        width: 100%;
    }
    .listing-panel .text-bold {
        margin-top: 10px;
        font-size: 14px;
        margin-left: 13%;
    }
    .listing-panel-border:after {
        display: none;
    }
    .listing-panel .panel {
        padding: 0px;
    }
    .listing-panel .name {
        margin-top: 5px;
        font-size: 14px;
        margin-left: 10px;
    }
    .listing-panel .fa-star {
        font-size: 10px;
    }
    .listing-panel .text-profile {
        font-size: 12px;
        margin-top: -5px;
        margin-left: 10px;
    }
    .text-city {
        font-size: 12px;
        margin-top: -5px;
        margin-left: 10px;
    }
    .li-practice-area {
        font-size: 10px;
    }
    .listing-panel .btn-container .btn-book {
        padding: 5px 10px;
        font-size: 14px;
    }
    .listing-panel .list-inline li {
        margin-bottom: 0px;
    }
    .li-practice-area {
        padding: 2px;
        margin-left: 5px;
    }
    .btn-search-consultant {
        float: none;
        margin-left: 7%;
    }
    .listing-panel .blank-dp {
        height: 55px !important;
        width: 55px !important;
        padding-top: 12px !important;
    }
    .listing-panel .col-xs-10 {
        width: 88%;
    }
    .listing-panel .col-sm-10.left-offset {
        margin-left: 30px;
    }
    .login-page-header {
        min-height: 50px;
        padding-top: 10px !important;
    }
    .btn-filter-apply {
        /*background-color: transparent;*/
        /*color: #fff;*/
        padding: 2px 5px;
        margin-left: 10px;
        font-family: SofiaLight;
        font-size: 14px;
    }
}
/* ==============================================End of Listing======================================================= */

/* ========================================Body end========================= */

/* ========================================Footer========================= */

.wiz-footer {
    margin-top: 10px;
}
.wiz-footer h4 {
    color: #000;
    font-size: 18px;
    margin-top: 0px;
    font-family: EinaBold;
    margin-bottom: 15px;
}
.wiz-footer img {
    width: 100px;
}
.wiz-footer .footer-column {
    margin-top: 30px;
    margin-bottom: 35px;
    padding: 0px;
}
.wiz-footer .footer-bottom {
    margin-top: 5px;
    padding: 0px;
}
.wiz-footer a {
    color: #000;
    font-family: SofiaLight;
    text-decoration: none !important;
    background-color: transparent;
    font-size: 16px;
}
.wiz-footer .side-card-btn {
    background-color: #3e32f1!important;
    color: #fff!important;
}
.wiz-footer .side-card-heading {
    font-size: 14px;
    font-family: SofiaRegular;
}
.wiz-footer ul.social {
    margin-top: 20px;
    margin-left: 10px;
}
.wiz-footer ul.social li {
    padding: 3px 5px;
}
.wiz-footer ul.social li a i {
    margin-left: 0px;
    margin-right: 10px;
    margin-top: 10px;
    font-size: 20px;
}
.wiz-footer ul.quick-links li a {
    color: #000;
}
.wiz-footer ul.social li a {
    color: #000;
}
.wiz-footer ul.social li a:hover {
    color: #ff337d;
}
.wiz-footer ul.quick-links li a:hover {
    color: #ff337d;
}
.wiz-footer ul.quick-links li {
    padding: 5px 0px;
    margin-bottom: 5px;
}
.wiz-footer ul.quick-links li:hover {
    padding: 5px 0;
}
.wiz-footer ul.quick-links li a i {
    margin-right: 5px;
}
.wiz-footer ul.quick-links li:hover a i {
    font-weight: 700;
}
.wiz-footer p {
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: SofiaLight;
    font-size: 14px;
    color: #000;
}
.wiz-footer p a {
    font-family: SofiaLight;
    font-size: 14px;
    color: #000;
}
.wiz-footer p a:hover {
    color: #ff337d;
}
.wiz-footer h5 {
    margin-top: 10px;
    margin-bottom: 40px;
    line-height: 1.5em;
    font-family: SofiaLight;
    font-size: 14px;
    color: #000;
}
.wiz-footer h5 a {
    margin-top: 30px;
    margin-bottom: 30px;
    line-height: 1.5em;
    font-family: SofiaLight;
    font-size: 14px;
    color: #000;
}
.wiz-footer h5 a:hover {
    margin-top: 30px;
    margin-bottom: 30px;
    line-height: 1.5em;
    font-size: 14px;
    color: #ff337d;
}
/* ========================================Footer end========================= */

.invoice-header .table > tbody > tr > td {
    border-top: 0px solid transparent;
    border-bottom: 3.5px solid #ff337d;
    font-family: EinaBold;
    color: #000;
    padding: 5px 0px;
    font-size: 14px;
}
.invoice-header .table > tbody > tr > td img {
    width: 100px;
    margin-top: 10px;
}
.invoice-address .table > tbody > tr > td {
    border-top: 0px solid transparent;
}
.invoice-address .table > tbody > tr > td p {
    font-family: EinaBold;
    color: #000;
    font-size: 14px;
}
.invoice-table .table > thead {
    background-color: blue;
}
.invoice-table .table > thead > tr > td {
    color: #fff;
    font-family: EinaBold;
    padding-left: 15px;
    padding-right: 15px;
}
.invoice-table .table > thead > tr > td:nth-of-type(2),
.invoice-table .table > tbody > tr > td:nth-of-type(2) {
    border-left: 1.5px solid #ccc;
    border-right: 1.5px solid #ccc;
}
.invoice-table .table > tbody > tr:nth-of-type(2n) {
    background-color: #979797;
}
.invoice-table .table > tbody > tr > td {
    height: 40px;
}
.invoice-table .table > tbody > tr:nth-last-child(1) {
    font-size: 14px;
    font-family: EinaBold;
    color: #000;
}
.invoice-footer {
    margin-top: 250px;
}
.invoice-footer .table > tbody > tr > td {
    border-top: 0px solid transparent;
    padding: 0px;
    padding-bottom: 10px;
    border-bottom: 3.5px solid #ff337d;
    font-family: SofiaLight;
    color: #000;
    font-size: 14px;
}
.calling-screen {
    margin-top: 5%;
    margin-bottom: 3%;
}
.both-party-dp{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: auto;
}
.calling-screen img {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin: auto;

}
#video video {
    position: relative !important;
}
.text-calling {
    color: #fff;
    font-family: EinaBold;
    font-size: 16px;
    margin-bottom: 15px;
}
.calling-footer {
    position: absolute;
    right: 0;
    bottom: 0px;
    left: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #242424;
}
.btn-copy-joining-link{
    background: #fff;
    color: #000;
    padding: 10px 30px;
    font-size: 16px;
    font-family: SofiaMedium;
    margin-top: 2%;
    margin-bottom: 2%;
}
.text-joining-note{
    font-size: 16px;
    color: #fff;
    font-family: SofiaLight;
}
.calling-footer .blank-dp {
    padding-top: 12px !important;
}
.calling-screen .blank-dp {
    height: 100px !important;
    width: 100px !important;
    line-height: 80px !important;
}
.calling-screen .blank-dp span {
    font-size: 28px !important;
}
.text-no-reply {
    color: #fff;
    font-family: SofiaLight;
    margin-top: 20%;
    margin-bottom: 2%;
}
.btn-no-reply {
    background-color: #ff337d;
    color: #fff;
    font-family: SofiaLight;
}
.btn-no-reply:hover,
.btn-no-reply:focus {
    color: #fff;
}
.calling-footer .user-img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-top: -10px;
    float: right;
    margin-right: 5%;
    margin-bottom: 10px;
    object-fit: cover;
    object-position: 50% 70%;
}
.call-connect {
    /*margin-left: 6%;*/
}
.call-connect img {
    display: inline-block;
    margin-left: 50px;
    width: 50px;
    cursor: pointer;
}
.ui-calling-modal {
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 1);
    position: fixed;
}
.calling-modal {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, .5);
    position: fixed;
}
.calling-container {
    height: 370px;
    width: 250px;
    padding: 10px 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    border: 1px solid #fff;
    border-radius: 4px;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, .1);
    background-color: #fff;
}
.calling-container .para {
    margin-top: 10px;
    margin-left: 10px;
    font-family: SofiaLight;
    color: #000;
}
.calling-container .blank-dp {
    padding-top: 12px !important;
}
.calling-user-img {
    width: 80px;
    height: 80px;
    margin-top: 50px;
    border-radius: 50%;
    object-fit: cover;
    object-position: 50% 70%;
}
.call-btn-container {
    text-align: center;
    margin-top: 50px;
}
.call-btn-container img {
    display: inline-block;
    width: 40px;
    margin-left: 10px;
    margin-right: 15px;
    cursor: pointer;
}
.caller-name {
    color: #000;
    font-family: EinaBold;
    font-size: 16px;
    margin-top: 15px;
}
.caller-description {
    color: #000;
    font-family: SofiaLight;
    font-size: 14px;
}
/*==================================Text animation========================*/

@keyframes slide-in {
    0% {
        opacity: 0;
        transform: translateY(-100%)
    }
    60% {
        opacity: 1;
        transform: translateY(10%)
    }
    to {
        opacity: 1;
        transform: translateY(0)
    }
}
@keyframes slide-out {
    0% {
        opacity: 1;
        transform: translateY(0)
    }
    60% {
        opacity: 0;
        transform: translateY(110%)
    }
    to {
        opacity: 0;
        transform: translateY(100%)
    }
}
@keyframes fade-in {
    0% {
        opacity: 0
    }
    to {
        opacity: 1
    }
}
.animated-headline {
    font-size: 45px;
    color: #fff;
    font-family: SofiaRegular;
    margin-bottom: 40px;
}
.home .animated-headline span:not(.animated-headline__words-wrapper) {
    display: inline;
    padding: 0
}
.home .animated-headline span:not(.animated-headline__words-wrapper) {
    display: inline-block
}
.home .animated-headline span:not(.animated-headline__words-wrapper):first-child {
    margin: 0 3px 0 0
}
.home .animated-headline__words-wrapper {
    display: none;
    overflow: hidden;
    position: relative;
    transition: width .3s;
    vertical-align: middle;
    width: auto;
    margin: 0
}
.home .animated-headline__words-wrapper {
    display: inline-block
}
.home .animated-headline__words-wrapper:after {
    background: #213FD4;
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 0;
    z-index: 2;
    transition: width .3s -.1s
}
.home .animated-headline__words-wrapper.is-loading:after {
    width: 100%;
    transition: width 3s
}
.home .animated-headline__words-wrapper b {
    left: 0px;
    opacity: 0;
    position: absolute;
    top: .2em;
    padding-bottom: 10px;
    padding-right: 10px;
    white-space: nowrap
}
.home .animated-headline__words-wrapper b.is-showing {
    opacity: 1;
    position: relative;
    top: -5px;
}
.home .animated-headline__words-wrapper b.is-showing:not(.show-on-load) {
    animation: slide-in .6s
}
.home .animated-headline__words-wrapper b.is-hiding:not(.hide-on-load) {
    animation: slide-out .6s
}
.add-modal-container {
    margin-top: 10%;
    width: 60%;
}
.add-modal-container .modal-content {
    min-height: 480px;
    padding: 10px 20px;
}
.add-modal-container .btn-free-proposal {
    margin-top: 10px;
    padding: 12px 15px;
    font-size: 16px;
}
.add-modal-container .profile-name {
    margin-bottom: 5px;
    font-size: 18px;
}
.add-modal-container .profile-description {
    margin-bottom: 5px;
    font-size: 16px;
}
.add-modal-container .fa-star {
    margin-left: 2px;
    font-size: 20px;
}
.redirect-btn {
    border-radius: 6px;
    background-color: #2b47fb;
    color: #fff;
    padding: 10px;
    font-size: 16px;
    text-align: center;
    font-family: SofiaLight;
    margin-bottom: 20px;
    margin-top: 10px;
    cursor: pointer;
}
.text-more-heading {
    font-family: SofiaMedium;
    line-height: 1.3em;
    font-size: 18px;
    color: #000;
    margin-top: 0px;
    margin-bottom: 20px;
}
.text-more-small-heading {
    font-family: SofiaMedium;
    font-size: 16px;
    color: #000;
    margin-bottom: 0px;
}
.listing-panel .description-tips-list li {
    font-size: 16px;
    color: #000;
}
.slot-page-heading {
    font-size: 24px;
    color: #000;
    font-family: EinaBold;
}
.react-datepicker {
    font-size: 1.3rem !important;
}
.react-datepicker__current-month {
    font-size: 1.5rem !important;
}
.react-datepicker__header {
    padding-top: 6px !important;
}
.react-datepicker__navigation {
    top: 13px !important;
}
.react-datepicker__day-name,
.react-datepicker__day {
    margin: 0.5rem !important;
}
body{
    padding-top: 0px!important;
}
/*==================================End Text animation========================*/

/* ========================================Media query========================= */

@media screen and (max-width: 767px) {
    .wiz-footer .side-card-heading {
        margin-top: 10px;
        text-align: left;
        font-size: 12px;
        font-family: SofiaRegular;
        line-height: 1.3em;
    }
    .professional-social-ui{
        margin-top: 10%;
    }
    .calling-screen{
        margin-top: 15%;
        margin-bottom: 15%;
    }
    #count_down_timer span{
        position: absolute;
        margin-left: -3px;
    }
    .calling-screen img{
        width: 70px;
        height: 70px;
        border-radius: 50%;
    }
    .calling-footer{
        padding-top: 12px;
        padding-bottom: 12px;
    }
    .calling-screen .blank-dp{
        width: 70px!important;
        height: 70px!important;
        line-height: 55px!important;
    }
    .both-party-dp{
        justify-content: space-around;
    }
    .both-party-dp>div{

    }
    .calling-footer .user-img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-top: 0px;
        float: right;
        margin-right: 0%;
        margin-bottom: 10px;
        object-fit: cover;
        object-position: 50% 70%;
    }
    .call-connect {
        margin-left: 0%;
    }
    .call-connect.text-right{
        text-align: center;
    }
    .call-connect img {
        display: inline-block;
        margin-left: 25px;
        width: 45px;
        cursor: pointer;
    }
    .text-calling{
        font-size: 16px;
        margin-top: 10px;
    }
    .public-profile-box .cp-body-box{
        padding:5px;
    }
    .whats-include ul li::before{
        font-size: 14px;
    }
    .whats-include ul li {
        font-size: 14px;
    }
    .slot-page-heading {
        font-size: 18px;
        color: #000;
        font-family: EinaBold;
    }
    .slot-table .xs-heading {
        font-size: 16px;
    }
    .slot-table.table-responsive {
        border: none;
    }
    .valid-session-ui {
        margin-top: 5%;
    }
    .valid-session-ui .text-dark {
        margin-top: 10px!important;
    }
    .add-banner {
        height: 300px!important;
    }
    .add-banner .imageBottomRight {
        top: 22px;
        height: 320px;
    }
    .banner-rating .blockquote {
        font-size: 16px;
    }
    .name-box .text-name{
        font-size: 14px;
        margin-bottom: 0px;
    }
    .add-banner .imageBottomRight img {
        position: absolute;
        max-width: 160px;
        top: 0px;
        right: 10px;
        z-index: 1;
    }
    .banner-rating {
        position: absolute;
        bottom: 120px;
        left: 125px;
    }
    .banner-rating .fa-star {
        color: gold;
        font-size: 18px;
        margin-left: 0px;
        margin-right: 2px;
    }
    .banner-rating .text-white {
        font-size: 18px;
    }
    .add-banner .banner-text-heading {
        margin-top: 35px;
        font-size: 22px;
    }
    .wiz-work {
        background-color: #FFEED9;
        margin: 10px!important;
        padding: 10px 5px;
    }
    .service-head-box {
        margin-bottom: 0px;
        margin-top: 55px;
    }
    .service-head-box .text-white-para {
        font-size: 16px;
        line-height: 1.3em;
        margin-top: 10px;
    }
    .service-head-box .label-warning {
        margin-top: 20px;
        margin-left: 0px;
        font-size: 14px;
        padding: 8px 15px;
    }
    .package-section {
        padding: 0px;
    }
    .package-card {
        height: 425px;
    }
    .package-card.standard-new-service {
        border: none;
        height: 370px;
    }
    .package-text{
        height: 170px;
    }
    .add-crowsel {
        height: auto;
        padding: 10px 10px;
    }
    .new-category-service .add-crowsel{
        border-radius: 0px;
        height: auto;
        padding: 5px 10px;
    }
    .assurance-banner-ul>li{
        font-size: 12px;
        margin-left: 20px;
        margin-bottom: 10px;
        font-family: SofiaLight;
    }
    .assurance-banner-ul>li:before{
        background-size: 10px;
    }
    .add-crowsel .banner-text-heading img{
        width: 110px !important;
    }
    .second-slide-add {
        display: block;
        height: auto;
    }
    .reward-text-box {
        padding: 10px 10px;
        width: 100%;
    }
    .reward-text-box .banner-text-heading {
        font-size: 22px;
    }
    .offer-brand-box {
        padding: 10px 10px;
        width: 100%;
    }
    .book-service-box {
        margin-top: 0px;
        padding: 15px 10px;
    }
    .offer-brand-box .table>tbody>tr>td {
        padding: 10px;
    }
    .offer-brand-box .table>tbody>tr>td img {
        width: 35px!important;
    }
    .add-banner .text-white-para {
        font-size: 16px;
        line-height: 1.2em;
        margin-top: 5px;
    }
    .add-banner .btn-job-post {
        background-color: #ff1e9b;
        color: #fff;
        padding: 10px;
        font-family: SofiaRegular;
        width: 100%;
        display: block;
    }
    .header-bottom-margin{
        margin-bottom: 60px;
    }
    .with-second-nav-margin{
        margin-bottom: 50px;
    }
    .valid-session-ui .blank-dp{
        height: 45px!important;
        width: 45px!important;
        line-height: 20px;
    }
    .valid-session-ui .blank-dp span{
        font-size: 16px!important;
    }
    .page-first-section {
        padding-top: 20px;
    }
    section {
        margin-bottom: 10px !important;
    }
    .ui-section-services {
        height: auto;
    }
    .display-flex {
        display: flex;
        flex-direction: column-reverse;
    }
    .display-flex .left-offset {
        margin-left: 0px;
    }
    .display-flex .service-text {
        font-family: SofiaLight;
        margin-top: 20px;
    }
    .display-flex .heading-sm {
        font-size: 18px;
    }
    .side-btn-container {
        margin-top: 0px;
    }
    .side-btn-container.text-center {
        text-align: left;
    }
    .side-btn-container .title-xs-heading {
        margin-left: 0px;
        font-family: SofiaSemiBold;
    }
    .expert-card {
        padding: 10px 10px;
        margin: 10px 5px;
        height: auto;
    }
    .expert-card .expert-dp {
        width: 70px !important;
        height: 70px !important;
    }
    .card-practice-area li {
        margin-bottom: 5px;
        font-size: 14px;
    }
    .banner-section {
        margin-bottom: 0px !important;
        min-height: 200px!important;
    }
    .banner-section .text-white-para {
        line-height: 1.3em;
        margin-top: 10px;
        font-size: 14px;
    }
    .banner-section .text-white-para b {
        font-size: 12px;
    }
    .filter-strip {
        background-color: #000;
        padding: 5px 0px;
        z-index: 100;
        height: 55px;
        line-height: 55px;
        margin-bottom: 0px;
    }
    /*.filter-form-strip {*/
    /*    margin-top: 5px;*/
    /*}*/
    .listing-panel {
        margin-top: 0px;
    }
    .listing-panel .para {
        font-size: 13px;
        margin-left: 0px;
    }
    .listing-panel .btn-book {
        margin-bottom: 15px;
    }
    .title-xs-heading {
        font-size: 14px;
        margin-top: 5px !important;
    }
    .listing-panel .btn-container {
        margin-top: 10px;
    }
    .listing-panel .li-practice-area {
        font-size: 12px;
        margin-left: 5px;
    }
    .help-me-li {
        margin-top: 5px;
        margin-bottom: 10px;
    }
    .crousel-h4 {
        margin-top: 5px;
        font-size: 14px;
        line-height: 1.2em;
        margin-bottom: 5px;
    }
    .crousel-para {
        font-size: 12px;
    }
    /*.fa-angle-right,*/
    /*.fa-angle-left {*/
    /*    position: relative;*/
    /*    left: 0;*/
    /*    top: 0;*/
    /*}*/
    .owl-theme .owl-nav {
        margin-top: 0px !important;
    }
    .ui-new-list li {
        margin-top: 10px;
    }
    /*.hero-main {*/
    /*    margin-top: 20px !important;*/
    /*}*/
    .hero-main .btn-job-post {
        padding: 8px 20px;
        font-size: 16px;
        margin-bottom: 10px;
    }
    .hero-main .btn-job-post {
        margin-left: 0px;
        margin-right: 0px;
    }
    .hero-main:after {
        position: relative;
        z-index: 0;
        bottom: 0;
    }
    .text-bold .label-info {
        padding: 3px 25px;
        margin-left: 43%;
    }
    .firm-container {
        margin-top: 30px;
        padding: 10px 10px;
        height: auto;
        background-color: #cef7e6;
    }
    .main-header .navbar-brand{
        margin-left: 10px!important;
        padding: 5px 10px!important;
    }
    .header-logo {
        margin-top: 0px;
        width:100px
    }
    .gig-header-logo {
        width: 90px;
        margin-top: 0px;
    }
    .pull-right .header-logo{
        width: 110px
    }
    .navbar-default .navbar-right > li {
        padding-left: 0px;
        padding-right: 20px;
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .navbar-default .navbar-right .dropdown {
        margin-top: 0px;
    }
    .navbar-nav > li > .dropdown-menu {
        background-color: #fff;
        border: none !important;
        box-shadow: none !important;
    }
    .nav .open > a,
    .nav .open > a:focus,
    .nav .open > a:hover {
        background-color: transparent;
    }
    .navbar-default .dropdown-submenu > .dropdown-menu {
        background-color: #fff;
    }
    .navbar-nav > li > .dropdown-menu li > a {
        color: #000;
        font-family: SofiaRegular;
        font-size: 14px;
    }
    /*ul.nav li:hover > ul.dropdown-menu {*/
    /*    display: block;*/
    /*}*/
    .navbar-default .dropdown-submenu > a:after {
        border-color: transparent transparent transparent #000;
        border-style: solid;
        border-width: 5px 0 5px 5px;
        content: " ";
        display: block;
        float: right;
        height: 0;
        margin-right: -10px;
        margin-top: 6px;
        width: 0;
    }
    .navbar-default .dropdown-submenu:hover > a:after {
        border-left-color: #000;
    }
    .navbar-default .navbar-nav .open .dropdown-menu > li > a {
        color: #000;
    }
    .sticky-block {
        position: relative;
        top: 60px;
    }
    .col-border::after {
        display: none;
    }
    .animated-headline {
        font-size: 28px;
        font-family: SofiaRegular;
    }
    .client-logo {
        width: 150px;
        margin: auto;
        margin-bottom: 50px;
    }
    .ui-padding {
        padding: 0px 20px;
    }
    .ui-first-section {
        height: 460px;
        padding: 20px 10px;
    }
    .add-page-section .for-expert-img {
        top: 290px;
        height: auto;
        right: 10px;
    }
    .for-expert-img {
        top: 225px;
        height: auto;
    }
    .for-expert-img img {
        width: 180px;
        top: -80px;
        right: -5px;
    }
    .for-expert-img .banner-rating {
        left: -190px;
        position: relative;
    }
    .ui-first-section .for-expert-img .text-white-para {
        margin-top: 20px;
    }
    .for-expert-img .banner-rating .blockquote {
        margin-left: 0px;
        font-size: 12px;
    }
    /*.banner-rating.text-right{*/
    /*    text-align: left;*/
    /*}*/
    .ui-first-section .top-margin {
        margin-top: 0px;
    }
    .black-line-straight {
        display: none;
    }
    .lg-white-heading {
        font-size: 24px;
        color: #fff;
        font-family: EinaBold;
        margin-top: 15px;
        margin-bottom: 10px;
        line-height: 1.4em;
    }
    .ui-first-section .lg-white-heading {
        margin-top: 5%;
        margin-bottom: 15px;
        font-size: 24px;
    }
    .ui-first-section .white-line {
        margin-bottom: 20px;
    }
    .ui-first-section .text-white-para {
        margin-top: 10px;
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 1.3em;
    }
    .hero-client-container {
        height: 50px;
        line-height: 45px;
    }
    .scope-border {
        height: auto;
    }
    .scope-heading {
        font-size: 18px;
    }
    .add-page-section .banner-text-heading {
        margin-top: 30px;
        font-size: 20px;
        margin-bottom: 10px;
    }
    .add-page-section .text-white-para {
        margin-top: 10px;
        font-size: 16px;
    }
    .text-service-price {
        font-size: 16px;
    }
    .text-installment-price {
        font-size: 14px;
    }
    .btn-book-service {
        padding: 10px 20px;
        font-size: 16px;
    }
    .banner-text-heading {
        font-size: 16px;
        color: #000;
        font-family: EinaBold;
        line-height: 1.3em;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .banner-text-sub-heading {
        font-size: 14px;
        color: #000;
        font-family: EinaBold;
        line-height: 1.3em;
        margin-top: 10px;
        margin-bottom: 5px;
    }
    .banner-text-para{
        font-size: 14px;
        line-height: 1.3em;
        margin-top: 5px;
    }
    .talk-to-sales-section .banner-text-heading{
        font-size: 18px;
    }
    .text-black-sub-heading {
        color: #000;
        font-size: 22px;
        font-family: EinaBold;
        line-height: 1.5em;
        margin-top: 15px;
        margin-bottom: 0px;
    }
    .app-button {
        text-align: center;
    }
    .app-button li img {
        width: 130px;
    }
    .col-mokeup {
        height: auto;
        padding: 30px 0px;
        margin-bottom: 0px;
    }
    .col-mokeup img {
        margin-top: 10px;
        margin: auto;
        width: 70%;
    }
    .col-mokeup .lg-white-heading {
        margin-top: 10px;
    }
    .col-mokeup .text-sub-heading {
        font-size: 22px;
    }
    .small-blank-line {
        margin: auto;
        margin-bottom: 20px;
    }
    /*.col-mokeup .left-offset-1 {*/
    /*    margin-left: 8%;*/
    /*}*/
    .ui-work-container {
        padding-top: 50px;
        padding-bottom: 50px;
        margin-bottom: 50px;
    }
    .ui-work-container .text-black-sub-heading {
        margin-top: 0px;
        text-align: center;
        font-size: 28px;
    }
    .pink-heading {
        text-align: center;
    }
    .pink-heading::before {
        left: 50px;
    }
    .ui-reviews-container {
        padding-left: 0px;
        border: none;
    }
    .ui-reviews-container::after {
        left: 130px;
        margin-top: 0px;
    }
    .our-mission-section {
        padding-top: 0px;
        padding-bottom: 0px;
        margin-bottom: 0px;
    }
    .our-profile-section {
        padding-top: 0px;
        padding-bottom: 0px;
        margin-bottom: 0px;
    }
    .our-value-section {
        padding-top: 0px;
        padding-bottom: 0px;
        margin-bottom: 0px;
    }
    .contact-section {
        margin-top: 0px;
        margin-bottom: 0px;
        padding: 0px 15px;
        height: 150px;
    }
    .contact-section .banner-image-box{
        position: relative;
        top: 0px;
        right: 5px;
        display: inline-block;
    }
    .contact-section .m-banner-box{
        align-self: flex-end;
    }
   .name-box{
        position: absolute;
       top: 75px;
         bottom: 0;
        margin-right: 35px;
    }
   .contact-section .text-name{
       font-size: 12px;
   }
    .contact-section .blockquote{
        font-size: 10px;
        margin-bottom: 5px;
    }
    .contact-section .banner-image{
        width: 100px;
        position: absolute;
        /*right: -80px;*/
        /*margin-top:-55px;*/
        /*bottom: 0px;*/

    }

    .contact-section .scope-heading{
        font-size: 14px;
        margin-top: 10px;
    }
    .btn-talk-sales{
        padding: 6px 15px;
        font-size: 12px;
        margin-top: 5px;
    }
    .page-contant {
        padding-top: 5px;
        padding-bottom: 0px;
        margin-bottom: 0px;
    }
    .custum-img-width {
        width: 100%;
        padding: 0px;
        margin-left: 0%;
    }
    .why-wiz-container {
        background-color: #0c202f;
        padding-left: 10px;
        margin: 15px;
        padding-bottom: 100px;
    }
    .custum-img-width {
        width: 100%;
        padding: 0px;
        margin-left: 0%;
    }
    .c-img-margin {
        margin-left: 0px;
        margin-top: 10px;
    }
    .left-offset-1 {
        margin-left: 0%;
    }
    .img-margin {
        margin-left: 0%;
        width: 100%;
    }
    .text-white-heading {
        font-size: 30px;
        font-family: EinaBold;
        color: #fff;
        text-align: center;
        margin-bottom: 20px;
    }
    .text-black-heading {
        font-size: 32px;
        margin-bottom: 30px;
    }
    .text-dark-para {
        font-size: 14px;
    }
    .heading-lg,
    .text-normal,
    .text-black-heading {
        text-align: center;
    }
    .xs-para {
        font-size: 14px;
    }
    .professional-social-ui .xs-para{
        font-size: 16px;
        margin-bottom: 10px;
    }
    .affiliate-step-box .scope-sub-heading{
        margin-bottom: 15px;
    }
    .gig-panel .xs-para{
        font-size: 14px;
    }
    .text-faq-question {
        font-size: 16px;
        font-family: EinaBold;
        color: #000;
        margin-top: 15px;
        margin-bottom: 10px;
    }
    .text-faq-sub-question {
        font-size: 16px;
        font-family: SofiaMedium;
        color: #000;
        margin-top: 15px;
        margin-bottom: 10px;
    }
    .text-faq-para {
        font-size: 14px!important;
        font-family: SofiaLight;
        color: #000;
    }
    .text-faq-para span{
        font-size: 14px!important;
    }
    .communication-container .heading-sm {
        font-size: 30px;
    }
    .video-audio-mokeup {
        height: auto;
        padding: 15px;
    }
    .video-audio-mokeup .heading-sm {
        font-size: 22px;
    }
    .heading-lg {
        font-size: 30px;
    }
    .crousel-para span {
        font-size: 14px;
    }
    .heading-sm {
        font-size: 20px;
        margin-top: 10px;
        margin-bottom: 15px;
    }
    .listing-panel .heading-sm {
        font-size: 18px;
    }
    .wiz-work .heading-sm {
        font-size: 18px;
    }
    .brief-note {
        font-size: 14px;
    }
    .list-card {
        text-align: center;
    }
    .list-card li img {
        width: 50px!important;
    }
    .ui-md-heading {
        margin-top: 20px;
        color: #000;
        font-size: 16px;
        margin-bottom: 15px;
    }
    .ui-md-heading .btn-link {
        color: #2d3feb;
        font-size: 14px;
        font-family: SofiaLight;
        margin-top: 0px;
    }
    .ui-md-heading .pull-right {
        float: none !important;
    }
    .navbar-default {
        padding: 5px 0px;
        padding-right: 10px;
        border-radius: 0px;
        background-color: #fff;
    }
    .wiz-footer .footer-column {
        margin-top: 0px;
        margin-bottom: 5px;
        padding: 0px 10px;
    }
    .wiz-footer .footer-column img {
        margin-top: 15px;
    }
    .wiz-footer .footer-bottom {
        margin-top: 5px;
        padding: 0px 20px;
    }
    .wiz-footer .text-center {
        text-align: left;
    }
    .wiz-footer .text-right {
        text-align: left
    }
    .wiz-footer p {
        margin-top: 15px;
        margin-bottom: 5px;
        font-size: 12px;
    }
    .wiz-footer .para {
        width: 95%;
        margin: auto;
        font-size: 10px;
        margin-bottom: 20px;
    }
    .wiz-footer a {
        font-size: 12px;
    }
    .wiz-footer h5 a{
        font-size: 12px;
    }
    .wiz-footer h4 {
        color: #000;
        font-size: 14px;
        font-family: EinaBold;
        margin-top: 0px;
    }
    .wiz-footer p a {
        font-family: SofiaLight;
        font-size: 12px;
        color: #000;
    }
    .text-invite {
        margin-top: 20px;
    }
    .heading-center {
        margin-top: 30px;
        margin-bottom: 60px;
    }
    .x-lg-heading {
        color: #000;
        font-size: 24px;
        font-family: EinaBold;
        margin-top: 10%;
        margin-bottom: 5%;
        line-height: 1.3em;
    }
    .vr-line {
        height: 60px;
        margin-top: 70px;
        margin-right: -20px;
        width: 3px;
        background-color: #000;
    }
    .work-container .neg-margin {
        margin-top: 20px;
    }
    .service-text {
        margin-left: 60px;
    }
    .service-text:before {
        left: 40px;
        width: 18px;
    }
    .col-padding {
        padding: 10px;
        height: auto;
    }
    .col-padding .heading-sm {
        text-align: center;
    }
    .container-section {
        background-color: #000;
        padding: 20px;
        margin: 15px 0px;
    }
    .filter-box {
        width: auto;
        margin-left: 0%;
    }
    .ui-new-list li {
        font-size: 12px;
        font-family: SofiaLight;
        margin-right: 10px;
    }
    .ask-question-heading {
        font-size: 20px;
        font-family: EinaBold;
        color: #000;
    }
    .ask-question-sub-heading {
        font-size: 16px;
        font-family: SofiaLight;
        line-height: 1.4em;
        margin-bottom: 20px;
    }
    .include-tips-dropdown {
        display: block;
    }
    .card-list li {
        width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .our-mission-container {
        height: 220px;
        transform: rotateY(180deg);
    }
    .our-mission-container .text-sub-heading {
        font-size: 20px;
        margin-top: 20px;
        font-family: EinaBold;
        line-height: 1.5em!important;
        color: #000;
        margin-left: 10%;
        transform: rotateY(180deg);
    }
    .our-mission-container .white-line-straight {
        display: none;
    }
    .black-line {
        width: 50px;
    }
    .white-line-straight {
        margin-top: 30px;
        margin-bottom: 10px;
        height: 30px;
    }
    .btn-enterprise {
        padding: 6px 20px;
        font-size: 14px;
        border: 1.2px solid #fff;
    }
    #smart-banner-container>iframe{
        z-index: 1100!important;
        position: absolute!important;
        top: -50px!important;
    }
    #smart-banner-container>iframe .close-icon{
      font-size: 24px!important;
        padding: 15px 0px!important;
    }
    #smart-banner body .get-button{
        margin-right: 100px!important;
    }
    body{
        padding-top: 0px!important;
    }
}
.assurance-timeline {
    list-style: none;
    padding: 0px 0px 0px;
    position: relative;
}
.assurance-timeline:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 2px;
    background-color: #eeeeee;
    margin-left: 5px;
    height: 85%;
    margin-top: 10px;
}
.assurance-timeline > li {
    margin-bottom: 20px;
    position: relative;
}
.assurance-timeline > li:before,
.assurance-timeline > li:after {
    content: " ";
    display: table;
}
.assurance-timeline > li:after {
    clear: both;
}
.assurance-timeline > li > .timeline-panel {
    width: 95%;
    float: left;
    padding: 5px;
    position: relative;
}
.assurance-timeline > li > .timeline-badge {
    color: #fff;
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 1.4em;
    text-align: center;
    position: absolute;
    top: 0px;
    margin-left: -10px;
    /*background-color: #999999;*/
    z-index: 100;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
}
.assurance-timeline .timeline-badge img{
    width: 35px
}
.assurance-timeline > li.timeline-inverted > .timeline-panel {
    margin-left:30px;
}
.assurance-timeline .timeline-title {
    margin-top: 0;
    color: #000;
    font-size: 16px;
    font-family: SofiaRegular;
}
@media (min-width: 768px) and (max-width: 1024px) {
    .video-section-ui .text-black-sub-heading {
        font-size: 28px;
    }
    .stepper-horizontal li span{
        font-size: 12px;
    }
    /*.add-crowsel.ecom-service-add img{*/
        /*width: 140px!important;*/
    /*}*/
    .affiliate-home-section.for-expert-section .text-black-heading{
        font-size: 30px;
        margin-top: 0px;
    }
    .for-expert-banner-img{
        margin-left: 0px;
    }
    .banner-text-sub-para{
        font-size: 16px;
    }
    .for-expert-banner-img img{
        max-height: 310px;
    }
    .product-search-box .form-control{
        font-size: 16px;
    }
    .service-head-box{
        padding: 0px 10px;
    }
    .package-card .hire-designer-button {
        font-size: 14px;
    }
    .service-card .message-item .para{
        font-size: 14px;
        line-height: 1.3em;
        margin-top: 5px;
        font-family: SofiaRegular;
    }
    .service-grid-cards{
        justify-content: center;
    }
    .service-grid-cards-wrapper{
        width:23%;
    }
    .assurance-ul li>img {
        display: none;
    }
    .assurance-timeline .timeline-title {
        margin-top: 0;
        color: #000;
        font-size: 14px;
        font-family: SofiaRegular;
    }
    .btn-pay-installment,
    .btn-pay-full {
        font-size: 14px;
    }
    .nav-justify-content-center {
        white-space: nowrap;
        overflow: hidden;
        overflow-x: auto;
    }
    .banner-text-heading {
        font-size: 16px;
        margin-top: 10px!important;
    }
    .new-category-service .add-crowsel.ecom-service-add{
        height: 250px;
    }
    .add-crowsel.ecom-service-add img{
        top: 15px;
        width: 170px!important;
    }
    .assurance-list {
        margin-top: 2%;
        margin-bottom: 2%;
    }
    .navbar-default {
        padding-left: 10px;
        padding-right: 10px;
    }
    .for-expert-img img {
        width: 300px;
    }
    .for-expert-img .banner-rating {
        left: -10px;
    }
    .ui-first-section {
        height: 520px;
    }
    .filter-ul li+li{
        margin-left: 0px;
    }
    .filter-ul .form-control{
        padding: 6px 6px;
    }
    .covid-app-section .scope-heading{
        margin-top: 20px;
        font-size: 22px;
    }
    .main-header .navbar-brand{
        margin-left: 0px!important;
    }
    .header-logo {
        width: 110px;
        margin-top: 0px;
    }
    .navbar-btn {
        padding: 8px 10px!important;
        font-size: 12px;
    }
    .profile-img {
        height: 100px;
        width: 100px
    }
    .assurance-list li {
        margin-right: 5px;
    }
    .hero-main-container {
        height: 440px;
    }
    .hero-heading {
        margin-top: 10%;
        font-size: 22px;
    }
    .hero-main-container .text-primary{
        font-size: 14px;
    }
    .left-offset-1 {
        margin-left: 0%;
    }
    .wiz-footer .footer-column {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .wiz-footer .footer-column .text-right {
        text-align: left;
    }
    #owl-crousel-slider .carousel-caption {
        width: 95%;
    }
    /*.filter-form-strip{*/
    /*    margin-top: -10px;*/
    /*}*/
}
@media (min-width: 768px) and (max-width: 1023px) {
    .nav>li>a {
        padding: 10px 5px;
    }
    .nav-justify-content-center {
        text-align: left;
    }
    .header-logo {
        margin-top: 0px;
        width:110px
    }
    .text-white-para{
        font-size: 16px;
    }
    .top-offset-2{
        margin-top: 7%;
    }
    .secondary-nav {
        top: 60px;
    }
    .covid-text-box{
        position: relative;
        left: 0px;
    }
    .covid-app-section .scope-heading{
        margin-top: 20px;
    }
    .hero-main-container{
        height: 380px;
    }
    .affiliate-home-section .text-black-sub-heading{
        font-size: 26px;
    }
    .affiliate-home-section .banner-text-para{
        font-size: 18px;
    }
    .xs-para{
        font-size: 16px;
    }
    .customer-review-wrapper li{
        width: 45%;
    }
    .top-margin{
        margin-top: 30px;
    }
    .affiliate-home-section .btn-for-business{
        font-size: 18px;
        padding: 8px 30px;
    }
}
@media (min-width: 1024px) and (max-width: 1200px) {
    .hero-left-offset {
        margin-left: 0%;
    }
    .affiliate-home-section .text-black-sub-heading{
        font-size: 36px;
    }
    .affiliate-home-section.professional-social-ui .text-black-sub-heading{
        font-size: 30px;
    }
    .xs-para{
        font-size: 16px;
    }
    .affiliate-home-section .banner-text-para{
        font-size: 18px;
    }
    .assurance-list li {
        white-space: nowrap;
        font-size: 14px;
    }
    #owl-crousel-slider .carousel-caption {
        width: 95%;
        margin-top: -80px;
    }
    #owl-crousel-slider .item > .slider-crousel img {
        /*width:85%;*/
    }
}
@media (min-width: 1450px) and (max-width: 1850px) {
    #owl-crousel-slider .item > .slider-crousel img {
        /*width:90%;*/
    }
    .hero-main-container {
        /*height: 800px;*/
    }
    .gig-banner-quote{
        width: 70%;
    }
    .designer-profile-card .slider-crousel .embed-responsive-16by9{
        padding-bottom: 200px;
    }
    .gig-detail-container{
        width: 100%;
        padding: 0px 50px;
    }
}
@media (min-width: 1850px) {
    /*#owl-crousel-slider .item > .slider-crousel img{*/
    /*    width:65%;*/
    /*}*/
    .hero-heading {
        margin-top: 10%;
    }
}
@media screen and (max-width: 1024px) {
    .ui-new-list li {
        margin-right: 0%;
    }
    .hero-bottom-margin {
        margin-bottom: 10px;
    }
    .col-padding-sm {
        height: auto !important;
    }
}
@media only screen and (max-width: 400px) {
    .add-banner .banner-text-heading {
        margin-top: 25px;
    }
    .add-banner .banner-text-heading {
        font-size: 16px;
    }
    .add-banner {
        height: 250px!important;
    }
    .add-banner .imageBottomRight {
        top: 22px;
        height: 310px;
    }
    .add-banner .imageBottomRight img {
        position: absolute;
        max-width: 130px;
        top: 0px;
        right: 10px;
        z-index: 1;
    }
    .for-business-banner{
        height: 610px;
    }
    .banner-rating {
        position: absolute;
        bottom: 145px;
        left: 145px;
    }
    .banner-rating .fa-star {
        color: gold;
        font-size: 14px;
        margin-left: 0px;
        margin-right: 2px;
    }
    .assurance-list li {
        font-size: 10px;
        margin-right: 0px;
    }
    .ui-new-list .service-text:before {
        left: 5px;
    }
    .listing-panel {
        margin-top: 0px;
    }
    .product-search-box .form-control {
        font-size: 11px;
        padding-left: 30px;
        height: 35px;
    }
    .product-search-box .search-icon{
        top:8px;
    }
    .listing-panel .dp-image {
        height: 55px;
        width: 55px;
    }
    .listing-panel .blank-dp {
        padding-top: 14px !important;
    }
}
@media only screen and (min-width: 1350px) {
    #owl-crousel-slider .crousel-img1 {
        width: 550px!important;
    }
    #owl-crousel-slider .crousel-img2 {
        width: 400px!important;
    }
    #owl-crousel-slider .crousel-img3 {
        width: 425px!important;
    }
    #owl-crousel-slider .crousel-img4 {
        width: 450px!important;
    }
}
@media only screen and (max-width: 767px) {
    #fixedNavbar {
        position: fixed;
        top: 0;
        left: 10px;
        z-index: 99;
        width: 290px;
        height: 100%;
        background-color: #fff;
        overflow: auto;
        padding-right: 5px;
    }
    .menu-overlay {
        display: none;
        background-color: #000;
        bottom: 0;
        left: 0;
        opacity: 0.5;
        filter: alpha(opacity=50);
        /* IE7 & 8 */
        position: fixed;
        right: 0;
        top: 0;
        z-index: 49!important;
    }
    .top-offset-2{
        margin-top: 0%;
    }
    .navbar-fixed-top .navbar-collapse {
        max-height: 100%!important;
    }
    .navbar-default .navbar-nav > li > a {
        font-family: SofiaSemiBold;
    }
    .fa-chevron-down {
        color: #777;
    }
    .mobile-menu .dropdown-menu>li>a {
        white-space: normal;
    }
    .btn-navbar {
        width: 80%;
        padding: 10px 10px!important;
        background-color: #000;
        color: #fff!important;
        font-family: SofiaMedium!important;
        margin-left: 15px;
        margin-bottom: 10px;
    }
    .btn-navbar:hover,
    .btn-navbar:focus {
        background-color: #000;
        color: #fff!important;
    }
    .expert-button {
        width: 80%;
        padding: 10px 10px!important;
        background-color: #000;
        color: #fff!important;
        font-family: EinaBold!important;
        margin-left: 15px;
        margin-top: 15px;
    }
    .expert-button:hover,
    .expert-button:focus {
        background-color: #ff337d!important;
        color: #fff!important;
    }
    .m-login-button {
        width: 80%;
        padding: 10px 10px!important;
        background-color: #ff337d;
        color: #fff!important;
        font-family: EinaBold!important;
        margin-left: 15px;
        margin-top: 20px;
        margin-bottom: 30px;
    }
    .expert-button:hover,
    .expert-button:focus {
        background-color: #ff337d!important;
        color: #fff!important;
    }
    .curated-packages{
        padding: 10px 10px;
        min-height: 150px;
    }
    .curated-packages-nav li>a{
        font-size: 14px;
        white-space: nowrap;
    }
    .curated-packages-nav{
        padding: 10px 10px;
    }
    .curated-packages-sec{
        padding-top: 0px!important;
    }
    .curated-packages-nav-box{
        margin-top: -40px;
        padding: 0px 0px;
    }
   .curated-packages-nav-box ul.nav{
       overflow-x:auto;
        display:inline-flex
    }
    .sub-curated-packages-nav{
        width:100%;
        flex-wrap: nowrap;
        justify-content: flex-start;
        overflow-x:scroll;
        padding: 0px 5px;
        white-space: nowrap;
    }
    .sub-curated-packages-nav li{
        white-space: nowrap;
        /*width:40%;*/
        padding:10px 0px!important;
        margin:0px 5px;
    }
    .sub-curated-packages-nav li+li{
        margin-left: 5px;
    }
    .sub-curated-packages-nav li>a{
        font-size: 12px;
    }
    .sub-curated-packages-nav li>a img{
        width:25px;
        margin-right: 3px;
    }
}
/*=======================Servive page tab============================================*/

.hero-client-container .nav-tabs {
    border-bottom: none;
}
.text-name img {
    width: 50px;
    margin-bottom: 10px;
}
.hero-client-container .list-unstyled {
    margin-top: 10px;
}
.filter-tab {
    padding: 5px;
}
.hero-client-container .filter-tab .list-unstyled {
    margin-top: 0px!important;
}
.hero-client-container .list-unstyled select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url('../images/down.png') no-repeat;
    background-position: 95% 55%;
    background-size: 12px;
}
.hero-client-container form .form-control {
    box-shadow: none;
    outline: none;
}
.hero-client-container.nav-tabs > li.active > a,
.hero-client-container .nav-tabs > li.active > a:focus,
.hero-client-container .nav-tabs > li.active > a:hover {
    border-width: 0;
}
.hero-client-container .nav-tabs > li > a {
    border: none;
    color: #000;
    font-size: 16px;
    font-family: SofiaRegular;
    /*background: #5a4080;*/
    padding: 15px 20px;
}
.hero-client-container .nav-tabs > li.active > a,
.hero-client-container .nav-tabs > li > a:hover {
    border: none;
    color: #1212f6 !important;
    padding: 15px 20px;
    background: #fff;
}
.hero-client-container .nav-tabs > li > a::after {
    content: "";
    background: #1212f6;
    height: 2px;
    position: absolute;
    width: 100%;
    left: 0px;
    bottom: -1px;
    transition: all 250ms ease 0s;
    transform: scale(0);
}
.hero-client-container .nav-tabs > li.active > a::after,
.hero-client-container .nav-tabs > li:hover > a::after {
    transform: scale(1);
}
.hero-client-container .tab-nav > li > a::after {
    background: #5a4080 none repeat scroll 0% 0%;
    color: #fff;
}
.tab-pane {
    padding: 0px 0px;
}
.tab-content {
    padding: 0px
}
.hero-client-container .nav-tabs > li {
    text-align: center;
    padding: 0px;
}
.page-contant .tab-pane {
    /*margin-top: 40px;*/
}
@media only screen and (max-width: 767px) {
    .text-name img {
        width: 40px;
        margin-bottom: 10px;
    }
    .hero-client-container .nav-tabs > li > a {
        border: none;
        color: #000;
        font-size: 13px;
        /*background: #5a4080;*/
        padding: 15px 15px;
    }
    .hero-client-container .nav-tabs > li.active > a,
    .hero-client-container .nav-tabs > li > a:hover {
        border: none;
        color: #1212f6 !important;
        padding: 15px 15px;
        background: #fff;
    }
}
@media only screen and (max-width: 400px) {
    .hero-client-container .nav-tabs > li > a {
        border: none;
        color: #000;
        font-size: 13px;
        /*background: #5a4080;*/
        padding: 15px 8px;
    }
    .hero-client-container .nav-tabs > li.active > a,
    .hero-client-container .nav-tabs > li > a:hover {
        border: none;
        color: #1212f6 !important;
        padding: 15px 8px;
        background: #fff;
    }
    .stepper-horizontal li span{
        font-size: 12px;
        padding: 5px 5px;
    }
}
@media only screen and (max-width: 350px) {
    .service-grid-cards{
        justify-content: center;
    }
    .service-grid-cards-wrapper{
        width:28%;
    }
    .new-category-service .add-crowsel.ecom-service-add .banner-text-heading{
        font-size: 16px;
    }
}
@media screen and (max-width: 767px) {
    .public-profile-box .nav-tabs {
        display: inline-flex;
        width: 100%;
        overflow-x: auto;
        padding:0px 15px;
    }
}

